import { Box, Tabs, Typography } from "@mui/material";
import HigherOrderComponent from "components/HOC/HigherOrderComponent";
import { CustomPaper, SpecialTab } from "components/common/CustomComponents";
import { UserEndpointList } from "constants/endpoints";
import { t } from "i18next";
import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  noPaddingTop?: boolean;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{  py: other.noPaddingTop ? 0 : 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BranchQuery(props: any) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Typography variant="h5" fontWeight={700} mb={2}>
        {/* User Query */}
        {t('common:dashboard.userQuery')}
      </Typography>
      <CustomPaper variant="outlined">
        <Typography variant="h6" fontWeight={700} mb={3}>
        {t('common:table.detail')}

        </Typography>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <SpecialTab
              label={t("common:table.User Commission Statistics")}
              {...a11yProps(0)}
              className="special-first-tab"
            />
            <SpecialTab label={t("common:table.User Commission Details")} {...a11yProps(1)} />
            <SpecialTab
              label={t("common:table.TOP 10")}
              {...a11yProps(1)}
              className="special-last-tab"
            />
          </Tabs>
        </Box>
        <CustomTabPanel  noPaddingTop={true}  value={value} index={0}>
          <Tab1 fisrtElement={true}/>
        </CustomTabPanel>
        <CustomTabPanel noPaddingTop={true} value={value} index={1}>
          <Tab2 />
        </CustomTabPanel>
        <CustomTabPanel noPaddingTop={true} value={value} index={2}>
          <Tab3 />
        </CustomTabPanel>
      </CustomPaper>
    </Box>
  );
}

const Tab1 = (props: any) => {
  console.log(props)
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.CommissionPanel.UserQuery,
    getMethod:"GET",
    showFields: [
      {
        key: "fromAccountId",
        label: "UID",
      },
      {
        key: "isDirectReferral",
        label: "Referral Type",
        dataType: "referral-type",
      },
      {
        key: "rateCommission",
        label: "Rate Commission",
        dataType: "commission-rate",
      },
      {
        key: "totalTradingVolumeValue",
        label: "Total Trading Volume Value",
        dataType: "amount",
      },
      {
        key: "totalFeeValue",
        label: "Total Fee Value",
        dataType: "amount",
      },
    ],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
      {
        key: "isDirectRef",
        label: "Referral Type",
        type: "select",
        options:[    
          {label:  t("common:table.All"), value: ''},
          {label:  t("common:table.Indirect"), value: "0"},
          {label:  t("common:table.Direct"), value: "1"}
        ]
      },
      {
        key: "fromAccountId",
        label: "From Account Id",
      },
    ],
    sortAble:['fromAccountId','isDirectReferral','rateCommission', 'totalTradingVolumeValue','totalFeeValue'],
    ...props,
  })();
};

const Tab2 = (props: any) => {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.CommissionPanel.UserQueryDetail,
    getMethod:"GET",
    showFields: [
      {
        key: "fromAccountId",
        label: "UID",
      },
      {
        key: "isDirectReferral",
        label: "Referral Type",
        dataType: "referral-type",
      },
      {
        key: "totalTradingVolumeValue",
        label: "Total Trading Volume Value",
        dataType: "amount",
      },
      {
        key: "totalFeeValue",
        label: "Total Fee Value",
        dataType: "amount",
      },
      {
        key: "completedDate",
        label: "Completed Date",
        dataType: "date",
      },
    ],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
      {
        key: "isDirectRef",
        label: "Referral Type",
        type: "select",
        options:[    
          {label: "All", value: ''},
          {label: "Indirect", value: "0"},
          {label: "Direct", value: "1"}
        ]
      },
      {
        key: "fromAccountId",
        label: "From Account Id",
      },
    ],
    sortAble:['fromAccountId','isDirectReferral','rateCommission', 'totalTradingVolumeValue','completedDate'],
    ...props,
  })();
};

const Tab3 = (props: any) => {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.CommissionPanel.UserQueryTop10,
    getMethod:"GET",
    showFields: [
      {
        key: "fromAccountId",
        label: "UID",
      },
      {
        key: "totalTradingVolumeValue",
        label: "Total Trading Volume Value",
        dataType: "amount",
      },
      {
        key: "totalFeeValue",
        label: "Total Fee Value",
        dataType: "amount",
      },
      {
        key: "yourCommission",
        label: "Your Commission",
        dataType: "amount",
      },
    ],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
    ],
    ...props,
  })();
};
