import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { IconChevronRight } from "@tabler/icons-react";
import {
  CustomButton,
  CustomPaper,
  CustomTab,
} from "components/common/CustomComponents";
import CustomLargeModal from "components/common/CustomLargeModal";
import DashboardBalances from "components/dashboard/DashboardBalances";
import HistoryData from "components/dashboard/HistoryData";
import RefCommission from "components/dashboard/RefCommission";
import TodayData from "components/dashboard/TodayData";
import TodayHistory from "components/dashboard/TodayHistory";
import { UserEndpointList } from "constants/endpoints";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { get } from "utils/api";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop:0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  const [value, setValue] = React.useState(0);
  const [showHistory, setShowHistory] = React.useState(false);
  const {t} = useTranslation()
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h5" fontWeight={700} mb={2}>
        {t('common:dashboard.dashboard')}
      </Typography>
      <Box mt={2}>
        <DashboardBalances />
      </Box>
      <Box mt={2}>
        <RefCommission />
      </Box>
      <Box mt={2}>
        <CustomPaper variant="outlined">
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h6" fontWeight={700}>
              {t('common:dashboard.dataOverview')}
            </Typography>
            <CustomButton
              variant="outlined"
              onClick={() => setShowHistory(true)}
            >
              <Typography marginBottom='4px' paddingLeft='4px' color='#00DDB3' fontWeight={500}>{t('common:dashboard.history')}</Typography>
              <IconChevronRight size={16} color="#00DDB3"/>

            </CustomButton>
          </Stack>
          <Box sx={{ mt: 3 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: 'transparent',  
                }
              }}
            >
              <CustomTab label={t('common:dashboard.todayData')} {...a11yProps(0)} />
              <CustomTab label={t('common:dashboard.historyData')} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <TodayData />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <HistoryData />
          </CustomTabPanel>
        </CustomPaper>
      </Box>
      <CustomLargeModal
        open={showHistory}
        handleClose={() => setShowHistory(false)}
        title={t('common:modal.commissionIssue')}
        hasCloseButton={true}
      >
        <TodayHistory />
      </CustomLargeModal>
    
    </Box>
  );
}
