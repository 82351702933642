import {
  Hidden,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { IconPencil } from "@tabler/icons-react";
import React from "react";
import { useAppSelector } from "store/hooks";
import { renderDataValue } from "./func";

const DataTableMobile: React.FC<{
  data: TData;
  showFields: TColField[];
  setItemEditing: (item: any) => void;
  updateEndpoint?: string;
}> = (props) => {
  const userStore = useAppSelector((state) => state.user);
  const { information } = userStore;

  const { data, showFields, setItemEditing } = props;
  return (
    <Hidden mdUp>
      {data &&
        data.items?.map((row, index) => (
          <TableRow key={index}>
            <TableCell colSpan={showFields.length}>
              {showFields.map((col, colIndex) => (
                <Stack
                  key={colIndex}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="body2">{col.label}:</Typography>
                  {renderDataValue(row, col)}
                </Stack>
              ))}
              <Stack direction="row" justifyContent={"flex-end"} mt={1}>
                {props.updateEndpoint &&
                  information?.canSetCommission &&
                  row.isDirectRef && (
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => setItemEditing(row)}
                    >
                      <IconPencil />
                    </IconButton>
                  )}
              </Stack>
            </TableCell>
          </TableRow>
        ))}
    </Hidden>
  );
};

export default DataTableMobile;
