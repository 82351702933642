import {
  IconBinaryTree,
  IconDatabase,
  IconExchange,
  IconHome,
  IconLogout,
  IconUserDollar,
  IconUsersGroup,
  IconWallet,
} from "@tabler/icons-react";
import dashboard from 'assets/menu/Dashboard (Default).png'
import dashboardActive from 'assets/menu/homeHover.png'
import dataPanel from 'assets/menu/Data Panel (Default).png'
import dataPanelActive from 'assets/menu/dataPanelHover.png'
import commissionPanel from 'assets/menu/Commission (Default).png'
import commissionPanelActive from 'assets/menu/commission Hover.png'
import userManagement from 'assets/menu/User Manage (Default).png'
import userManagementActive from 'assets/menu/userManageHover.png'
import tradingManagement from 'assets/menu/Trading (Default).png'
import tradingManagementActive from 'assets/menu/tradingHover.png'
import partnerManagement from 'assets/menu/Partner (Default).png'
import partnerManagementActive from 'assets/menu/partnerHover.png'

// export const userMenus: TMenus = [
//   {
//     label: "Dashboard",
//     icon: dashboard,
//     iconActive: dashboardActive,
//     to: basePath,
//   },
//   {
//     label: "Data Panel",
//     icon: dataPanel,
//     iconActive: dataPanelActive,
//     to: "",
//     subMenus: [
//       {
//         label: "Referral Statistics",
//         to: `${basePath}data-panel/referral-statistics`,
//         icon: "",
//       },
//       // {
//       //   label: "Deposit Statistics",
//       //   to: "/data-panel/deposit-statistics",
//       //   icon: "",
//       // },
//       {
//         label: "Trade & Commission",
//         to: `${basePath}data-panel/trade-commission`,
//         icon: "",
//       },
//     ],
//   },
//   {
//     label: "Commission Panel",
//     // icon: <IconBinaryTree />,
//     icon: commissionPanel,
//     iconActive: commissionPanelActive,
//     to: "",
//     subMenus: [
//       {
//         label: "User Query",
//         to: `${basePath}commission-panel/user-query`,
//         icon: "",
//       },
//       // {
//       //   label: "Branch Query",
//       //   to: "/commission-panel/branch-query",
//       //   icon: "",
//       // },
//     ],
//   },
//   {
//     label: "User Management",
//     // icon: <IconUserDollar />,
//     icon: userManagement,
//     iconActive: userManagementActive,
//     to: "",
//     subMenus: [
//       {
//         label: "User Basic Information",
//         to: `${basePath}user-management/user-basic-information`,
//         icon: "",
//       },
//       // {
//       //   label: "User Benefit Management",
//       //   to: "/commission-panel/user-benefit-management",
//       //   icon: "",
//       // },
//     ],
//   },
//   // {
//   //   label: "Deposit & Withdraw",
//   //   icon: <IconWallet />,
//   //   to: "",
//   //   subMenus: [
//   //     {
//   //       label: "Deposit Query",
//   //       to: "/deposit-withdrawal/deposit-query",
//   //       icon: "",
//   //     },
//   //     {
//   //       label: "Withdrawal Query",
//   //       to: "/deposit-withdrawal/withdrawal-query",
//   //       icon: "",
//   //     },
//   //   ],
//   // },
//   {
//     label: "Trading Management",
//     // icon: <IconExchange />,
//     icon: tradingManagement,
//     iconActive: tradingManagementActive,
//     to: "",
//     subMenus: [
//       {
//         label: "Perpetual Futures Trading Query",
//         to: `${basePath}trading-management/perpetual-futures-trading-query`,
//         icon: "",
//       },
//       // {
//       //   label: "Standard Futures Trading Query",
//       //   to: "/trading-management/Standard-futures-trading-query",
//       //   icon: "",
//       // },
//       {
//         label: "Spot Trading Query",
//         to: `${basePath}trading-management/spot-trading-query`,
//         icon: "",
//       },
//     ],
//   },
//   {
//     label: "Partner Management",
//     // icon: <IconUsersGroup />,
//     icon: partnerManagement,
//     iconActive: partnerManagementActive,
//     to: "",
//     subMenus: [
//       {
//         label: "Partner List",
//         to: `${basePath}partner-management/partner-list`,
//         icon: "",
//       },
//       {
//         label: "Partner Data",
//         to: `${basePath}partner-management/partner-data`,
//         icon: "",
//       },
//       // {
//       //   label: "Partner Level Management",
//       //   to: "/partner-management/partner-level-management",
//       //   icon: "",
//       // },
//     ],
//   },
// ];

export const userMenus = (basePath: string) => {
  return [
    {
      label: "dashboard",
      icon: dashboard,
      iconActive: dashboardActive,
      to: basePath,  // Sử dụng basePath từ hook
    },
    {
      label: "dataPanel",
      icon: dataPanel,
      iconActive: dataPanelActive,
      to: "",
      subMenus: [
        {
          label: "referralStatistics",
          to: `${basePath}data-panel/referral-statistics`,
          icon: "",
        },
        {
          label: "tradeCommission",
          to: `${basePath}data-panel/trade-commission`,
          icon: "",
        },
      ],
    },
    {
      label: "commissionPanel",
      icon: commissionPanel,
      iconActive: commissionPanelActive,
      to: "",
      subMenus: [
        {
          label: "userQuery",
          to: `${basePath}commission-panel/user-query`,
          icon: "",
        },
      ],
    },
    {
      label: "userManagement",
      icon: userManagement,
      iconActive: userManagementActive,
      to: "",
      subMenus: [
        {
          label: "userInformation",
          to: `${basePath}user-management/user-basic-information`,
          icon: "",
        },
      ],
    },
    {
      label: "tradingManagement",
      icon: tradingManagement,
      iconActive: tradingManagementActive,
      to: "",
      subMenus: [
        {
          label: "perpetualFuture",
          to: `${basePath}trading-management/perpetual-futures-trading-query`,
          icon: "",
        },
        {
          label: "spotTrading",
          to: `${basePath}trading-management/spot-trading-query`,
          icon: "",
        },
      ],
    },
    {
      label: "partnerManagement",
      icon: partnerManagement,
      iconActive: partnerManagementActive,
      to: "",
      subMenus: [
        {
          label: "partnerList",
          to: `${basePath}partner-management/partner-list`,
          icon: "",
        },
        {
          label: "partnerData",
          to: `${basePath}partner-management/partner-data`,
          icon: "",
        },
      ],
    },
  ];
};
export const userSubMenus = [
  {
    title: "Logout",
    icon: <IconLogout />,
    path: "/submenu2",
  },
];
