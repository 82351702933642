import { Box, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { get, post } from "utils/api";
import CreateForm from "./CreateForm";
import DataTable from "./DataTable";
import SearchBar from "./SearchBar";
import { t } from "i18next";

export default function HigherOrderComponent(props: THOCState) {
  function WrappedComponent() {
    const [data, setData] = React.useState<TData>();
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [refresh, setRefresh] = React.useState(false);
    const [orderBy, setOrderBy] = React.useState("");
    const [order, setOrder] = React.useState<"asc" | "desc">("asc");
    const [exportData, setExportData] = React.useState<any>(null);
    const random = Math.floor(100000 + Math.random() * 900000);
    const [filters, setFilters] = React.useState<{
      [key: string]: string;
    }>({});
    const [extraFilterValues, setExtraFilterValues] = React.useState<{
      [key: string]: string;
    }>({});   
    
    React.useEffect(() => {
      setData(undefined);
      if (props.getMethod === "GET") {
        const filterParams = ()=>{
          if(Object.keys(extraFilterValues)?.length !== 0  || props?.sortAble?.length > 0){
            let query:any = '';
            for (let key in extraFilterValues) {

              if (extraFilterValues[key] && key !=='startDate' && key !== 'endDate') {
                query += `${key}=${extraFilterValues[key]}&`;
              }
            }
            if (extraFilterValues.startDate && extraFilterValues.endDate) {
              query += `sdate=${moment(extraFilterValues.startDate).startOf('day').unix()}&edate=${moment(extraFilterValues.endDate).endOf('day').unix()}&`
            }
            if (props?.sortAble?.length > 0 && orderBy !== '') {
              query += `sort=${orderBy}&direction=${order}&`;
            }
            if (query.endsWith('&')) {
              query = query.slice(0, -1);
            }
            if (query !== '') {
                return`?page=${page}&limit=${pageSize}&${query}`
            }
          }
         
          return `?page=${page}&limit=${pageSize}`
        }
        get(
          props.getEndpoint + filterParams(),
          (data: any) => {
            setData({
              items: data?.list?.data || data?.data?.items,
              pageSize: pageSize,
              page: page,
              itemCount: data?.list?.total,
              pageCount: data?.list?.last_page,
              searchList: [],
            });
          },
          (error: any) => {
            toast.error(error?.detail);
            setData({
              itemCount: 0,
              items: [],
              page: 1,
              pageSize: 10,
              pageCount: 0,
              searchList: [],
            });
          }
        );
      } else {
        const tempFilters = filters;
        if (props.userId) {
          tempFilters.userId = props.userId.toString();
        }
        if (props.UserId) {
          tempFilters.UserId = props.UserId.toString();
        }
        let body: any = {
          page,
          pageSize,
          filters: tempFilters,
          sortBy: orderBy,
          isDesc: order !== "desc",
        };

        if (extraFilterValues.startDate) {
          body = {
            ...body,
            startDate: moment(extraFilterValues.startDate).unix() * 1000,
          };
        }
        if (extraFilterValues.endDate) {
          body = body = {
            ...body,
            endDate: moment(extraFilterValues.endDate).unix() * 1000,
          };
        }
        if (extraFilterValues.referralType) {
          body = { ...body, referralType: extraFilterValues.referralType };
        }
        if (extraFilterValues.accountId) {
          body = { ...body, accountId: Number(extraFilterValues.accountId) };
        }

        for (const key in tempFilters) {
          if (tempFilters.hasOwnProperty(key)) {
            tempFilters[key] = tempFilters[key].toString();
          }
        }

        post(
          props.getEndpoint,
          body,
          (data: TData) => {
            if (data) {
              setData(data);
            } else {
              setData({
                itemCount: 0,
                items: [],
                page: 1,
                pageSize: 10,
                pageCount: 0,
                searchList: [],
              });
            }
          },
          (error: any) => {
            if (
              error.detail ===
              "AccountId: 'Account Id' must not be empty., AccountId: Account id is invalid"
            ) {
              toast.info("Please enter UID");
            } else {
              toast.error(error.detail);
            }
            setData({
              itemCount: 0,
              items: [],
              page: 1,
              pageSize: 10,
              pageCount: 0,
              searchList: [],
            });
          }
        );
      }
    }, [page, pageSize, filters, refresh, orderBy, order, extraFilterValues]);

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setPageSize(parseInt(event.target.value, 10));
      setPage(1);
    };

    const handleRefresh = () => {
      setRefresh(!refresh);
    };

    const handleSetOrderBy = (value: string) => setOrderBy(value);
    const handleSetOrder = (order: "asc" | "desc") => setOrder(order);

    React.useEffect(() => {
      if (document && exportData) {
        document?.getElementById(`export-btn-${random}`)?.click();
        setExportData(null);
      }
    }, [exportData, random]);
    return (
      <Box>
        {!props.userId && props.title && (
          <Typography variant="h6" fontWeight={700} mb={3}>
            {t(`common:table.${props.title}`)}
          </Typography>
        )}
        <SearchBar
          handleFilter={(values) => {
            setPage(1);
            setFilters(values.filters);
            setExtraFilterValues(values.extraFilterValues);
          }}
          filterable={data?.filterable || []}
          extraFilters={props.extraFilters}
          firstElement={props.fisrtElement}
        />
        {props.extraComponent}
        <CreateForm
          fields={props.inputFields}
          handleRefresh={handleRefresh}
          createEndpoint={props.createEndpoint}
          seekEndpoint={props.seekEndpoint}
        />
        <DataTable
          data={data}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleReset={handleRefresh}
          order={order}
          orderBy={orderBy}
          handleSetOrder={handleSetOrder}
          handleSetOrderBy={handleSetOrderBy}
          {...props}
          showFields={props.showFields}
          sortable={props.sortAble}
        />
        <CSVLink
          asyncOnClick={true}
          data={exportData ? exportData : []}
          headers={props.showFields}
          hidden
          id={`export-btn-${random}`}
          filename={`export-${
            props.title ? props.title.toLowerCase().replace(" ", "-") : ""
          }-${moment().format("YYYY-MM-DD_hh-mm-ss")}.csv`}
        />
      </Box>
    );
  }
  return WrappedComponent;
}
