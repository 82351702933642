import { useMemo, useState } from "react";
import { CustomTextField } from "./common/CustomComponents";
import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useChangeParamWithLocale } from "utils/changeParamsLanguages";
import buttonUp from 'assets/button/Icon Expand.png'
export const SelectLanguage = (props: any) => {
    const { langList } = props
    const { t, i18n } = useTranslation();
    const changeParamWithLocale = useChangeParamWithLocale('');
    const [lang, setLang] = useState(localStorage.getItem('lang') || 'en')
    const handleChange = (e: any) => {
        let langSelect = e.target.value
        setLang(langSelect)
        i18n.changeLanguage(langSelect);
        changeParamWithLocale(langSelect);
        localStorage.setItem('lang', langSelect);
    }
    return (
        <>
            <Select
                size="small"
                value={lang}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            marginTop: 0,
                            transform: 'translateY(calc(100% - 10px))',
                            background: 'transparent',
                            boxShadow: 'none',
                            top: '475px !important',
                            '& .Mui-selected': {
                                display:'none',
                                // background: 'none !important',
                                // opacity: 1,
                                // '&:hover': {
                                //     background: 'none',
                                // }
                            },
                            '.expand-button':{
                                display:'none'
                            }
                        },
                    },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                }}

                sx={{
                    width: 'fit-content', 'input': { background: 'white' }, border: 'none',
                    'svg': { display: 'none' },
                    '& .MuiSelect-select': {
                        // background: 'white',
                        paddingRight: '15px !important',
                    },
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '.MuiOutlinedInput-notchedOutline .Mui-focus': { border: 0 },
                    ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                        border: 0,
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                        border: 0,
                    },


                }}
                onChange={handleChange}
            >
                {langList?.map((value: any, index: number) => (
                    <MenuItem value={value.lang} key={index} sx={{ paddingX: 0, justifyContent: 'flex-end', opacity: 0.5, '&:hover': { opacity: 1, background: 'none' } }}>
                        <img src={value.icon} alt={value.title} width={50} />
                        {/* <img className='expand-button'src={buttonUp} alt='' 
                            style={{
                                position:'absolute',
                                right:'42%',
                                top: '3px',
                                display:'none'
                            }}/> */}
                    </MenuItem>
                ))}
            </Select>
        </>
    )
}