import { SvgIcon, useTheme } from "@mui/material";

const EyeOffIcon = (props: any) => {
  const theme = useTheme();

  return (
    <SvgIcon
      viewBox="0 0 32 32"
      {...props}
      style={{
        color: theme.palette.text.secondary,
        width: props?.size || 20,
        height: props?.size || 20,
        ...props.sx,
      }}
    >
      <path
        fill="currentColor"
        d="m5.24 22.51l1.43-1.42A14.06 14.06 0 0 1 3.07 16C5.1 10.93 10.7 7 16 7a12.4 12.4 0 0 1 4 .72l1.55-1.56A14.7 14.7 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68a16 16 0 0 0 4.18 6.17"
      />
      <path
        fill="currentColor"
        d="M12 15.73a4 4 0 0 1 3.7-3.7l1.81-1.82a6 6 0 0 0-7.33 7.33zm18.94-.07a16.4 16.4 0 0 0-5.74-7.44L30 3.41L28.59 2L2 28.59L3.41 30l5.1-5.1A15.3 15.3 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68M20 16a4 4 0 0 1-6 3.44L19.44 14a4 4 0 0 1 .56 2m-4 9a13.05 13.05 0 0 1-6-1.58l2.54-2.54a6 6 0 0 0 8.35-8.35l2.87-2.87A14.54 14.54 0 0 1 28.93 16C26.9 21.07 21.3 25 16 25"
      />
    </SvgIcon>
  );
};

export default EyeOffIcon;
