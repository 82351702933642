import { Box, Grid, Typography } from "@mui/material";
import {
  CustomLoadingButton,
  CustomTextField,
} from "components/common/CustomComponents";
import CustomModal from "components/common/CustomModal";
import { UserEndpointList } from "constants/endpoints";
import { t } from "i18next";
import React from "react";
import { toast } from "react-toastify";
import { post, put, get } from "utils/api";
import { formatNumberWithDecimal } from "utils/format";

const UpdateCommissionRate: React.FC<
  {
    itemEditing: any;
    close: () => void;
    handleRefresh: () => void;
  } & THOCState
> = (props) => {
  const { close, itemEditing, handleRefresh } = props;
  const [loading, setLoading] = React.useState(false);
  const [range, setRange] = React.useState<{
    maximumCommission: number;
    minimumCommission: number;
  }>();
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    if (itemEditing && itemEditing.accountId) {
        // post(
        //   UserEndpointList.PartnerManagement.Estimate,
        //   {
        //     accountId: itemEditing.accountId,
        //   },
        //   (data) => {
        //     setRange(data)
        //   }
        // )
        get(UserEndpointList.PartnerManagement.Estimate + `/${itemEditing.accountId}`, (data) => {
          setRange(data.data)
        })
      
    }
  }, [itemEditing]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setError("");
    const body: { [key: string]: any } = {};
    body.accountId = itemEditing.accountId;
    body.rateCommission = Number(
      formatNumberWithDecimal(e.target.rateCommission.value, 4)
    );
    if (range) {
      // if (
      //   body.rateCommission < range.minimumCommission ||
      //   body.rateCommission > range.maximumCommission
      // ) {
      //   setError("Commission rate must be within the range");
      //   setLoading(false);
      //   return;
      // }
      setLoading(true);
      post(
        props.updateEndpoint,
        body,
        (result) => {
          toast.success(result.message || "Updated successfully");
          handleRefresh();
          close();
          setLoading(false);
        },
        (error: any) => {
          toast.error(error.detail  || error.message);
          setLoading(false);
        }
      );
    }
  };
  return (
    <CustomModal
      handleClose={close}
      open={Boolean(itemEditing)}
      title={t('common:modal.changeCommission')}
      hasCloseButton={true}
    >
      <Box>
        {itemEditing ? (
          <Box p={2} component={"form"} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  id="UID"
                  defaultValue={itemEditing.accountId}
                  size="small"
                  label="UID"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  id="rateCommission"
                  defaultValue={formatNumberWithDecimal(
                    itemEditing.rateCommission,
                    2
                  )}
                  size="small"
                  label={t('common:modal.commissionRate')}
                  fullWidth
                  type="text"
                  inputProps={{
                    step: "any",
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }}
                  onChange={(e) => {
                    let newValue = e.target.value.replace(/[^0-9.]/g, '');
                    const parts = newValue.split('.');
                    if (parts.length > 2) {
                      newValue = parts[0] + '.' + parts[1];
                    }
                    e.target.value = newValue;
                  }}
                />
                <Typography textAlign={"right"} variant="body2">
                  {t('common:modal.within')}{" "}
                  {range
                    ? `${formatNumberWithDecimal(
                        range.minimumCommission
                      )}% ~ ${formatNumberWithDecimal(
                        range.maximumCommission
                      )}%`
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="error" variant="caption" fontWeight={700}>
                  {error} &nbsp;
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomLoadingButton
                  variant="contained"
                  type="submit"
                  loading={loading}
                  fullWidth
                >
                  {/* Update */}
                  {t('common:modal.update')}
                </CustomLoadingButton>
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Box>
    </CustomModal>
  );
};

export default UpdateCommissionRate;
