import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import AppModal from "components/AppModal";
import CopyContent from "components/CopyContent";
import Iconify from "components/Iconify";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import AppTextField from "components/input-fields/AppTextField";
import { Form, FormikProvider, useFormik } from "formik";
import useLocalStorage from "hooks/useLocalStorage";
import { t } from "i18next";
import { createContext, useRef, useState } from "react";
import Countdown from "react-countdown";
import toast from "react-hot-toast";
import { setAccessToken } from "utils/auth";
import { ACCESS_TOKEN_KEY } from "utils/constants";
import { exchangeGet, exchangePost } from "utils/exchange-api";
import { Encrypt, getMachineId } from "utils/utils";
import * as Yup from "yup";

const initialUser: any = {
  profile: null,
  affiliateProfile: null,
  futureProfile: null,
  spotProfile: null,
  accessToken: null,
  accessKey: "",
  gaEnable: false,
  phoneNumberEnable: false,
};
export const UserContext = createContext({
  user: initialUser,
  saveUser: (arg: any) => { },
  loginAccount: (x: any, y: any, z: any) => { },
  loginWithGoogle: (x: any, y: any, z: any) => { },
  loginWithWallet: (x: any, y: any, z: any) => { },
  loginWithPhone: (x: any, y: any, z: any) => { },
  connectWeb3Account: (arg: any) => { },
  logout: (callback: any) => { },
  getProfile: (arg: any) => { },
  connectAccount: (arg: any) => { },
  setStoreUser: (arg: any) => { },
}); // component props type

const UserProvider = ({ children }: any) => {
  const { data: user, storeData: setStoreUser } = useLocalStorage(
    "user",
    initialUser
  );
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [accountConnected, setAccountConnected] = useState<any>("");
  const [loginParams, setLoginParams] = useState<any>(null);
  const inputRef = useRef<any>();
  const passwordRef = useRef<any>();
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openSetPassword, setOpenSetPassword] = useState(false);
  const [verifyMethod, setVerifyMethod] = useState("Email");
  const [allowChangeVerifyMethod, setAllowChangeVerifyMethod] = useState(false);

  const Schema = Yup.object().shape({
    email: Yup.string()
      .required(`${t("Please Enter your email")}`)
      .email()
      .trim(),
    password: Yup.string().required(`${"Please Enter your password"}`).trim(),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Schema,
    onSubmit: async (values: any) => {
      connectAccount({
        email: values.email,
        password: values.password,
        ...accountConnected,
      });
    },
  });

  const saveUser = (updateUser: any) => {
    setStoreUser(updateUser);
  };

  const connectWeb3Account = (account: any) => {
    setAccountConnected(account);
    setLoginParams(account);
  };

  const loginWithWallet = (
    params: any,
    successCallback?: any,
    errorCallback?: any
  ) => {
    const submitParams = {
      address: params?.address,
      signature: params?.signature,
      signMessage: params?.signMessage,
      deviceCode: getMachineId(),
    };
    exchangePost(
      `account/trusted-device`,
      submitParams,
      async (result) => {
        if (!result) return;
        if (result.isNewLogin === true) {
          setOpen(true);
          setAllowChangeVerifyMethod(result.sendOtpByPhone);
          setLoginParams({
            ...params,
            loginType: "Wallet",
            successCallback: successCallback,
            errorCallback: errorCallback,
          });
          handleResendTrustDeviceOTP(params);
          setVerifyMethod("Email");
        } else {
          handleLoginWithWallet(
            {
              ...params,
              deviceCode: result.deviceCode,
            },
            successCallback,
            errorCallback
          );
        }
      },
      (error) => {
        errorCallback(error);
      }
    );
  };

  const handleLoginWithWallet = (
    params: any,
    successCallback: any,
    errorCallback: any
  ) => {
    exchangePost(
      "account/login-by-web3",
      { ...params, trustDevice: true },
      (result) => {
        setStoreUser({
          profile: null,
          accessToken: Encrypt(result.accessToken, ACCESS_TOKEN_KEY),
          accessKey: result.accessKey,
        });
        setAccessToken(result.accessToken);
        if (successCallback) {
          successCallback(result);
        }
      },
      (error) => {
        if (errorCallback) errorCallback(error);
      }
    );
  };

  const connectAccount = (
    params: any,
    successCallback?: any,
    errorCallback?: any
  ) => {
    const submitParams = { ...params };
    setSubmitting(true);
    exchangePost(
      "account/connect-web3-address",
      submitParams,
      (result) => {
        setStoreUser({
          profile: null,
          accessToken: Encrypt(result.accessToken, ACCESS_TOKEN_KEY),
          accessKey: result.accessKey,
        });
        setAccessToken(result.accessToken);
        if (params?.successCallback) {
          params.successCallback(result);
        }
        setAccountConnected(null);
        formik.setValues({ email: "", password: "" });
        setShowEmailInput(false);
        setSubmitting(false);
      },
      (error: any) => {
        setSubmitting(false);
        if (error.code === "GA_CODE_REQUIRED") {
          setAccountConnected(null);
          formik.setValues({ email: "", password: "" });
          setShowEmailInput(false);
          if (params?.errorCallback)
            params.errorCallback({ ...error, ...submitParams });
        } else {
          toast.error(error.msg);
          params.errorCallback({ ...error, skipConnectAccount: true });
        }
      }
    );
  };

  const handleLoginByOtp = () => {
    if (inputRef && inputRef.current && inputRef.current.value.length >= 6) {
      const params = { ...loginParams, otp: inputRef?.current?.value };
      switch (loginParams?.loginType) {
        case "Google":
          handleLoginWithGoogle(
            params,
            loginParams?.successCallback,
            loginParams?.errorCallback
          );
          break;
        case "Wallet":
          handleLoginWithWallet(
            params,
            loginParams?.successCallback,
            loginParams?.errorCallback
          );
          break;
        case "Phone":
          handleLoginWithPhone(
            params,
            loginParams?.successCallback,
            loginParams?.errorCallback
          );
          break;
        default:
          handleLogin(
            params,
            loginParams?.successCallback,
            loginParams?.errorCallback
          );
      }
      setOpenSetPassword(false);
      setOpen(false);
    }
  };

  const handleDeniedLogin = () => {
    setOpen(false);
    setSubmitting(false);
    setAccountConnected(null);
    setOpenSetPassword(false);
    if (loginParams?.errorCallback) {
      loginParams.errorCallback({ code: "Login fail", msg: "User denied" });
    }
  };

  const handleResendTrustDeviceOTP = (params: any) => {
    exchangePost(
      `account/security/trusted-device/send-otp`,
      {
        email: params?.email,
        phoneNumber: params?.phoneNumber,
        phoneIsoCode: params?.phoneIsoCode,
        address: params?.address,
        signature: params?.signature,
        signMessage: params?.signMessage,
        sendOtpByPhone: params?.sendOtpByPhone,
      },
      () => { }
    );
  };

  const loginAccount = (
    params: any,
    successCallback?: any,
    errorCallback?: any
  ) => {
    exchangeGet('get-client-secret', async (res) => {
      if (res) {
        const submitParams = {
          username: params?.email,
          password: params?.password,
          is_ggcaptcha: 1,
          grant_type: "password",
          scope: "*",
          client_id: res.client_id,
          client_secret: res.client_secret,
          // "otp": "013351"
          // deviceCode: getMachineId(),
        };
        exchangePost('login', submitParams,
          async (result) => {
            if (!result) return;
            console.log(result)
            setStoreUser({
              profile: null,
              accessToken: Encrypt(result.access_token, ACCESS_TOKEN_KEY),
              accessKey: result.access_token,
            });
            setAccessToken(result.access_token);
            successCallback(result.access_token)
          },
          (error) => {
            if (params.loginType === "Normal") {
              errorCallback(error);
            }
          }
        )
      }
    })

    // exchangePost(
    //   `account/trusted-device`,
    //   { ...params, deviceCode: getMachineId() },
    //   async (result) => {
    //     if (!result) return;
    //     if (result.isNewLogin) {
    //       setOpen(true);
    //       setAllowChangeVerifyMethod(result.sendOtpByPhone);
    //       setLoginParams({
    //         ...params,
    //         deviceCode: result.deviceCode,
    //         successCallback: successCallback,
    //         errorCallback: errorCallback,
    //       });
    //       handleResendTrustDeviceOTP(params);
    //       setVerifyMethod("Email");
    //     } else {
    //       handleLogin(
    //         { ...params, deviceCode: result.deviceCode },
    //         successCallback,
    //         errorCallback
    //       );
    //     }
    //   },
    //   (error) => {
    //     if (params.loginType === "Normal") {
    //       errorCallback(error);
    //     }
    //   }
    // );
  };

  const handleLogin = (
    params: any,
    successCallback: any,
    errorCallback: any
  ) => {
    exchangePost(
      "account/login",
      { ...params, trustDevice: true },
      (result) => {
        setStoreUser({
          profile: null,
          accessToken: Encrypt(result.accessToken, ACCESS_TOKEN_KEY),
          accessKey: result.accessKey,
        });
        setAccessToken(result.accessToken);
        if (successCallback) {
          successCallback(result);
        }
      },
      (error: any) => {
        if (error.code === "PASSWORD_NOTFOUND") {
          setLoginParams({
            ...params,
            successCallback: successCallback,
            errorCallback: errorCallback,
          });
          setOpenSetPassword(true);
        } else {
          if (errorCallback) errorCallback(error);
        }
      }
    );
  };

  const handleConnectPassword = () => {
    if (
      passwordRef &&
      passwordRef.current &&
      passwordRef.current.value.length >= 0
    ) {
      const params = {
        ...loginParams,
        password: passwordRef.current.value,
      };
      setSubmitting(true);
      exchangePost(
        "account/connect-password",
        params,
        (result) => {
          setSubmitting(false);
          setOpenSetPassword(false);
          if (loginParams.successCallback) loginParams.successCallback();
          setStoreUser({
            profile: null,
            accessToken: Encrypt(result.accessToken, ACCESS_TOKEN_KEY),
            accessKey: result.accessKey,
          });
          setAccessToken(result.accessToken);
        },
        (error: any) => {
          if (error.code === "GA_CODE_REQUIRED" && params.errorCallback) {
            setOpenSetPassword(false);
            params.errorCallback({ ...error, api: "account/connect-password" });
          } else {
            toast.error(error.msg);
          }
          setSubmitting(false);
        }
      );
    }
  };

  const handleLoginWithPhone = (
    params: any,
    successCallback: any,
    errorCallback: any
  ) => {
    exchangePost(
      "account/login-by-phone",
      { ...params, trustDevice: true },
      (result) => {
        setStoreUser({
          profile: null,
          accessToken: Encrypt(result.accessToken, ACCESS_TOKEN_KEY),
          accessKey: result.accessKey,
        });
        setAccessToken(result.accessToken);
        if (successCallback) {
          successCallback(result);
        }
      },
      (error: any) => {
        if (error.code === "PASSWORD_NOTFOUND") {
          setLoginParams({
            ...params,
            successCallback: successCallback,
            errorCallback: errorCallback,
          });
          setOpenSetPassword(true);
        } else {
          if (errorCallback) errorCallback(error);
        }
      }
    );
  };

  const loginWithPhone = (
    params: any,
    successCallback?: any,
    errorCallback?: any
  ) => {
    const submitParams = {
      phoneNumber: params?.phoneNumber,
      password: params?.password,
      phoneIsoCode: params?.phoneIsoCode,
      deviceCode: getMachineId(),
    };
    exchangePost(
      `account/trusted-device`,
      submitParams,
      async (result) => {
        if (!result) return;
        if (result.isNewLogin) {
          setOpen(true);
          setAllowChangeVerifyMethod(result.sendOtpByPhone);
          setLoginParams({
            ...params,
            loginType: "Phone",
            deviceCode: result.deviceCode,
            successCallback: successCallback,
            errorCallback: errorCallback,
          });
          handleResendTrustDeviceOTP({ ...params, sendOtpByPhone: true });
          setVerifyMethod("Phone");
        } else {
          handleLoginWithPhone(
            { ...params, deviceCode: result.deviceCode },
            successCallback,
            errorCallback
          );
        }
      },
      (error) => {
        if (params.loginType === "Phone") {
          errorCallback(error);
        }
      }
    );
  };

  const handleLoginWithGoogle = (
    params: any,
    successCallback: any,
    errorCallback: any
  ) => {
    exchangePost(
      "account/login-by-google",
      { ...params, trustDevice: true },
      (result) => {
        setStoreUser({
          profile: null,
          accessToken: Encrypt(result.accessToken, ACCESS_TOKEN_KEY),
          accessKey: result.accessKey,
        });
        setAccessToken(result.accessToken);
        if (successCallback) {
          successCallback(result);
        }
      },
      (error) => {
        if (errorCallback) errorCallback(error);
      }
    );
  };

  const loginWithGoogle = (
    params: any,
    successCallback?: any,
    errorCallback?: any
  ) => {
    const submitParams = {
      email: params?.email,
      deviceCode: getMachineId(),
      googleAccessToken: params?.accessToken,
    };
    exchangePost(
      `account/trusted-device`,
      submitParams,
      async (result) => {
        if (!result) return;
        if (result.isNewLogin) {
          setOpen(true);
          setAllowChangeVerifyMethod(result.sendOtpByPhone);
          setLoginParams({
            ...params,
            loginType: "Google",
            successCallback: successCallback,
            errorCallback: errorCallback,
          });
          handleResendTrustDeviceOTP(params);
          setVerifyMethod("Email");
        } else {
          handleLoginWithGoogle({ ...params }, successCallback, errorCallback);
        }
      },
      (error) => {
        errorCallback(error);
      }
    );
  };

  const logout = (callback: any) => {
    window.localStorage.removeItem("user");
    setStoreUser({ user: null, accessToken: "" });
    if (callback) callback();
  };

  const getProfile = () => {
    exchangeGet("profile", (profile) => {
      exchangeGet(
        "affiliate/profile",
        (affiliateProfile) => {
          exchangeGet("futures/profile", (futureProfile) => {
            exchangeGet("spot/profile", (spotProfile) => {
              setStoreUser({
                ...user,
                profile: profile,
                affiliateProfile: affiliateProfile,
                futureProfile: futureProfile,
                spotProfile: spotProfile,
              });
            });
            setStoreUser({
              ...user,
              profile: profile,
              affiliateProfile: affiliateProfile,
              futureProfile: futureProfile,
            });
          });
          setStoreUser({
            ...user,
            profile: profile,
            affiliateProfile: affiliateProfile,
          });
        },
        () => {
          setStoreUser({ ...user, profile: profile });
        }
      );
    });
  };

  const handleLinkToRegister = () => {
    window.localStorage.setItem(
      "walletInfo",
      JSON.stringify({
        address: accountConnected?.address,
        signature: accountConnected?.signature,
        signMessage: accountConnected?.signMessage,
      })
    );
    setTimeout(() => {
      window.open(`/register`, "_self");
    }, 10);
  };

  const handleCancelLink = () => {
    setShowEmailInput(false);
  };

  const handleChangeVerifyMethod = (params: any) => {
    if (params.sendOtpByPhone === true) {
      setVerifyMethod("Phone");
    } else {
      setVerifyMethod("Email");
    }
    handleResendTrustDeviceOTP(params);
  };

  const { getFieldProps, errors, touched, handleSubmit } = formik;

  return (
    <UserContext.Provider
      value={{
        user,
        saveUser,
        loginAccount,
        loginWithGoogle,
        loginWithPhone,
        getProfile,
        logout,
        loginWithWallet,
        connectWeb3Account,
        connectAccount,
        setStoreUser,
      }}
    >
      {children}
      <AppModal open={open} onClose={() => setOpen(false)}>
        <Stack p={2}>
          <Typography variant="h6">{t("Trusted device")}</Typography>
          <Typography my={2} color={"text.secondary"}>
            {t(
              "You have never logged in to your account on this device. To continue logging in, please confirm it is you by entering OTP in the box below and clicking Accept"
            )}
          </Typography>
          <AppTextField
            inputRef={inputRef}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 50,
              "& .MuiInputBase-root": { paddingLeft: "0px !important" },
            }}
            placeholder={
              verifyMethod === "Email" ? t("Email OTP") : t("Phone OTP")
            }
            inputProps={{
              autoFocus: true,
              sx: {
                textAlign: "center",
                fontSize: "1.2rem",
                letterSpacing: "1px",
              },
            }}
          />
          <FlexRowAlign py={2} gap={2}>
            <Typography color={"text.secondary"}>
              {t("Don't receive OTP?")}
            </Typography>
            <CustomCountDown
              handleCallback={() =>
                handleResendTrustDeviceOTP({
                  ...loginParams,
                  sendOtpByPhone: Boolean(verifyMethod === "Phone"),
                })
              }
              timer={59000}
            />
          </FlexRowAlign>
          {allowChangeVerifyMethod && (
            <>
              <Divider>{t("Or")}</Divider>
              <Typography variant="h6" mt={2}>
                {t("Change verification method")}
              </Typography>
              <Typography mt={1} mb={2} color={"text.secondary"}>
                {t("Choose verification method")}
              </Typography>
              <Stack spacing={2}>
                {verifyMethod === "Email" ? (
                  <Button
                    onClick={() =>
                      handleChangeVerifyMethod({
                        ...loginParams,
                        sendOtpByPhone: true,
                      })
                    }
                    variant="outlined"
                    sx={{ height: 50, justifyContent: "space-between" }}
                    endIcon={<Iconify icon={"ph:arrow-right"} size={20} />}
                  >
                    <Typography>{t("Phone number verification")}</Typography>
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      handleChangeVerifyMethod({
                        ...loginParams,
                        sendOtpByPhone: false,
                      })
                    }
                    variant="outlined"
                    sx={{ height: 50, justifyContent: "space-between" }}
                    endIcon={<Iconify icon={"ph:arrow-right"} size={20} />}
                  >
                    <Typography>{t("Email verification")}</Typography>
                  </Button>
                )}
              </Stack>
            </>
          )}

          <FlexBetween gap={2} sx={{ marginTop: 4 }}>
            <Button
              sx={{ borderRadius: 50 }}
              autoFocus
              fullWidth
              variant="outlined"
              onClick={() => handleDeniedLogin()}
            >
              {t("Cancel")}
            </Button>
            <LoadingButton
              sx={{ borderRadius: 50 }}
              fullWidth
              variant="contained"
              onClick={() => handleLoginByOtp()}
              autoFocus
            >
              {t("Accept")}
            </LoadingButton>
          </FlexBetween>
        </Stack>
      </AppModal>
      <AppModal
        open={Boolean(accountConnected)}
        onClose={() => handleDeniedLogin()}
        closeButton
      >
        <Stack p={{ xs: 2, md: 3 }}>
          <Typography variant="h5">{t("Link your Dikex account")}</Typography>
          <Typography mt={1} color={"text.secondary"}>
            {t(
              "If you already have a Dikex account, you can connect it to your wallet account"
            )}
          </Typography>
          <Stack
            p={2}
            mt={3}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: "1rem",
            }}
          >
            <Typography color={"text.secondary"}>
              {t("Account connected")}:
            </Typography>
            <CopyContent text={accountConnected?.address} allowCopy />
          </Stack>
          <Stack spacing={2} mt={5}>
            <Collapse in={showEmailInput}>
              <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                  <Stack spacing={1}>
                    <Typography color={"text.secondary"}>
                      {t("Your Dikex account (email)")}
                    </Typography>
                    <Stack spacing={2}>
                      <StyledInput
                        fullWidth
                        placeholder={t("Email")}
                        {...getFieldProps("email")}
                        error={Boolean(errors.email) && Boolean(touched.email)}
                        helperText={Boolean(touched.email) && errors.email}
                      />
                      <StyledInput
                        fullWidth
                        type="password"
                        placeholder={t("Password")}
                        {...getFieldProps("password")}
                        error={
                          Boolean(errors.password) && Boolean(touched.password)
                        }
                        helperText={
                          Boolean(touched.password) && errors.password
                        }
                      />
                    </Stack>
                    <FlexBetween gap={2} pt={3}>
                      <Button
                        disabled={submitting}
                        variant="outlined"
                        onClick={() => handleCancelLink()}
                        sx={{ borderRadius: 40, height: 40, width: "100%" }}
                      >
                        {t("Back")}
                      </Button>
                      <LoadingButton
                        loading={submitting}
                        type="submit"
                        sx={{ height: 40 }}
                      >
                        {t("Link account")}
                      </LoadingButton>
                    </FlexBetween>
                  </Stack>
                </Form>
              </FormikProvider>
            </Collapse>
            <Collapse in={!showEmailInput} sx={{ width: "100%" }}>
              <Stack spacing={2}>
                <LoadingButton
                  onClick={() => setShowEmailInput(true)}
                  sx={{ height: 40 }}
                >
                  {t("Link existing Dikex account")}
                </LoadingButton>
                <Button
                  variant="outlined"
                  onClick={() => handleLinkToRegister()}
                  sx={{ borderRadius: 40, height: 40, width: "100%" }}
                >
                  {t("Sign up new account with connected wallet")}
                </Button>
              </Stack>
            </Collapse>
          </Stack>
        </Stack>
      </AppModal>
      <AppModal
        open={openSetPassword}
        onClose={() => setOpenSetPassword(false)}
      >
        <Stack p={2}>
          <Typography variant="h6">{t("Setup sign in password")}</Typography>
          <Typography mt={1} mb={2} color={"text.secondary"}>
            {t(
              "This is your first time logging into our system. Please set a login password"
            )}
            .
          </Typography>
          <AppTextField
            inputRef={passwordRef}
            type="password"
            placeholder={t("Password")}
            inputProps={{ autoFocus: true }}
          />
          <Alert severity="warning" sx={{ mt: 2 }}>
            {t("Please use this password for subsequent sign in")}.
          </Alert>
          <FlexBetween gap={2} sx={{ marginTop: 4 }}>
            <Button
              sx={{ borderRadius: 50 }}
              autoFocus
              fullWidth
              variant="outlined"
              onClick={() => handleDeniedLogin()}
            >
              {t("Cancel")}
            </Button>
            <LoadingButton
              loading={submitting}
              fullWidth
              variant="contained"
              onClick={() => handleConnectPassword()}
              autoFocus
            >
              {t("Confirm and Sign in")}
            </LoadingButton>
          </FlexBetween>
        </Stack>
      </AppModal>
    </UserContext.Provider>
  );
};

export default UserProvider;

const CustomCountDown = ({ handleCallback, timer }: any) => {
  const [completed, setCompleted] = useState(false);
  const handleClick = () => {
    if (handleCallback) handleCallback();
    setCompleted(false);
  };
  return (
    <>
      {completed ? (
        <Typography
          color={"primary"}
          fontWeight={600}
          sx={{ cursor: "pointer" }}
          onClick={() => handleClick()}
        >
          {t("Resend OTP")}
        </Typography>
      ) : (
        <Countdown
          date={Date.now() + timer}
          onComplete={() => setCompleted(true)}
          renderer={({ seconds }) => (
            <Typography color={"text.secondary"}>
              {t("Resend after")} {seconds}
            </Typography>
          )}
        />
      )}
    </>
  );
};

const StyledInput = styled(AppTextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    background: theme.palette.background.input,
    paddingLeft: "1.5rem",
    borderRadius: "50px",
    overflow: "hidden",
    paddingRight: "0px",
    height: 40,
    border: "none",
    fontSize: "0.85rem",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      outline: "none",
    },
  },
}));
