import { createContext, useState } from "react";

// Create a Context for the pre-submit function
const ConfirmCaptchaContext = createContext({});

// Provider component
const CaptchaConfirmProvider = ({ children }: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePreSubmit = async (action: any) => {
    setIsSubmitting(true);
    // Simulate a pre-submit function (e.g., form validation, API call)
    await new Promise((resolve) => setTimeout(resolve, 1000));
    action();
    setIsSubmitting(false);
  };

  return (
    <ConfirmCaptchaContext.Provider value={{ handlePreSubmit, isSubmitting }}>
      {children}
    </ConfirmCaptchaContext.Provider>
  );
};

export { CaptchaConfirmProvider, ConfirmCaptchaContext };
