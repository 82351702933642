import { Box, Drawer, SxProps } from "@mui/material";
import React from "react";

export default function CustomDrawer(params: {
  children: any;
  open: boolean;
  onClose?: any;
  sx?: SxProps;
}) {
  return (
    <Drawer
      anchor="right"
      open={params.open}
      onClose={params.onClose}
      sx={{
        zIndex: 1300,
      }}
    >
      <Box
        sx={
          params.sx
            ? params.sx
            : {
                width: "100vw",
                maxWidth: 600,
                overflow: "auto",
              }
        }
      >
        {params.children}
      </Box>
    </Drawer>
  );
}
