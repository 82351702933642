export const ACCESS_TOKEN_KEY = process.env.REACT_APP_ACCESS_TOKEN_KEY
  ? process.env.REACT_APP_ACCESS_TOKEN_KEY
  : "LouyiO1igij54zszbC7FlwRe0uxZ";
export const DEVICE_KEY = "uU5tEUmAgvBWArsv";
export const SCOPES_KEY = "AhBcmvr1EkMdPnL5";
export const hostname = window.location.hostname.replace("www.", "");
export let AppConfig: {
  API: string;
  ETHERSCAN_LINK: string;
  EXCHANGE_API: string;
  IMAGE_URL: string;
  WEBSITE_URL: string;
} = {
  API: process.env.REACT_APP_API_BASE_URL
    ? process.env.REACT_APP_API_BASE_URL
    : `https://partner-api-dikex.feliciastation.com/api`,
  ETHERSCAN_LINK: "",
  EXCHANGE_API: process.env.REACT_APP_EXCHANGE_API
    ? process.env.REACT_APP_EXCHANGE_API
    : `https://dikex.feliciastation.com/services/v1/`,
  IMAGE_URL: "https://files.cp-czx.com",
  WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL
    ? process.env.REACT_APP_WEBSITE_URL
    : "https://client.vh-mon.com",
};
