import { Box, Typography } from "@mui/material";
import { CustomPaper } from "components/common/CustomComponents";
import HigherOrderComponent from "components/HOC/HigherOrderComponent";
import { UserEndpointList } from "constants/endpoints";
import { t } from "i18next";

export default function SpotTradingQuery() {
  return (
    <Box>
      <Typography variant="h5" fontWeight={700} mb={2}>
        {t('common:table.Spot Trading Query')}
      </Typography>
      <CustomPaper variant="outlined">
        <TableComponent />
      </CustomPaper>
    </Box>
  );
}

function TableComponent(props: any) {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.TradeManagement.SpotQuery,
    getMethod: 'GET',
    showFields: [
      {
        key: `id`,
        label: "Order ID",
      },
      {
        key: "accountId",
        label: "UID",
      },
      {
        key: "createdTime",
        label: "Open Time",
        dataType: "date-time",
      },
      {
        key: "lastExecuted",
        label: "Last Executed",
        dataType: "date-time",
      },
      {
        key: "spotTradingStatus",
        label: "Status",
      },
      {
        key: "tradingPair",
        label: "Trading Pair",
      },
      {
        key: "spotSide",
        label: "Side",
      },

      {
        key: "avgPrice",
        label: "Avg. Price",
        dataType: "amount",
      },
      {
        key: "filled",
        label: "Filled",
        dataType: "amount",
      },
      {
        key: "qty",
        label: "Transaction amount",
        dataType: "amount",
      },
      {
        key: "fee",
        label: "FEE",
      },
    ],
    title: "detail",
    filterBy: [],
    inputFields: [],
    extraFilters: [
      {
        key: "accountId",
        label: "UID",
      },
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
    ],
    ...props,
  })();
}
