import { AppDispatch } from "store";
import { get } from "utils/api";
import {LiveVotingGameEndpoints, VotingGameEndpoints} from "./constants";

export enum VotingActionTypes {
  SET_VOTING_LIST = "SET_VOTING_LIST",
  SET_LIVE_VOTING_LIST = "SET_LIVE_VOTING_LIST",
  SET_EXPIRED_VOTING_LIST = "SET_EXPIRED_VOTING_LIST",
}

interface AddVotingList {
  type: VotingActionTypes.SET_VOTING_LIST;
  payload: TVotingList;
}
interface AddLiveVotingList {
  type: VotingActionTypes.SET_LIVE_VOTING_LIST;
  payload: TVotingList;
}
interface AddExpiredVotingList {
  type: VotingActionTypes.SET_EXPIRED_VOTING_LIST;
  payload: TVotingList;
}

export const addVotingList = (payload: TVotingList): AddVotingList => ({
  type: VotingActionTypes.SET_VOTING_LIST,
  payload,
});
export const addLiveVotingList = (payload: TVotingList): AddLiveVotingList => ({
  type: VotingActionTypes.SET_LIVE_VOTING_LIST,
  payload,
});
export const addExpiredVotingList = (payload: TVotingList): AddExpiredVotingList => ({
  type: VotingActionTypes.SET_EXPIRED_VOTING_LIST,
  payload,
});

export const getVotingList = () => (dispatch: AppDispatch) => {
  get(
    VotingGameEndpoints.list,
    (data: any) => {
      dispatch(addVotingList(data));
    },
    (error: any) => {
      console.log(error);
    }
  );
};
export const getLiveVotingList = () => (dispatch: AppDispatch) => {
  get(
    LiveVotingGameEndpoints.list,
    (data: any) => {
      dispatch(addVotingList(data));
    },
    (error: any) => {
      console.log(error);
    }
  );
};

export type VotingGameActionsType = AddVotingList;
