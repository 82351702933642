import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import Iconify from "./Iconify";
import { ConstructionOutlined } from "@mui/icons-material";
// ----------------------------------------------------------------------

// CopyContent.propTypes = {
//   children: PropTypes.node,
//   text: PropTypes.string,
//   ellipsis: PropTypes.bool,
//   length: PropTypes.number,
// };

export default function CopyContent({
  children,
  text,
  ellipsis,
  to,
  img,
  hideButton,
  hiddenText,
  confirmMessage,
  ...other
}: any) {
  const theme = useTheme();
  const handleCopy = () => {
    toast.success("Copied");
  };
  return text ? (
    <Stack
      direction={"row"}
      flexGrow={1}
      alignItems="center"
      justifyContent="space-between"
      {...other}
    >
      {hiddenText &&
      <Stack
        direction={"row"}
        sx={{
          cursor: "pointer",
          maxWidth: `calc(100% - ${!hideButton ? "40px" : "0px"})`,
          fontWeight: '700'
        }}
        onClick={() => (to ? window.open(to) : null)}
      >          
        {text}
        {/* <Typography variant="body1" noWrap>
          {text.slice(0, text.length - 5)}
        </Typography> */}
        {/* <Typography variant="body1">
          {text.slice(text.length - 5, text.length)}
        </Typography> */}
      </Stack>
      }
      {!hideButton && (
        <CopyToClipboard text={text} onCopy={() => handleCopy()}>
          <IconButton color="primary" onClick={(e) => e.stopPropagation()}>
            {/* <Iconify
              sx={{ color: theme.palette.text.secondary }}
              icon="solar:copy-line-duotone"
              size={17}
            /> */}
            <img src={img} alt='copyText' width={12}/>
          </IconButton>
        </CopyToClipboard>
      )}
    </Stack>
  ) : null;
}
