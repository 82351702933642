import { ConstructionOutlined } from "@mui/icons-material";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import {
  Box,
  Grid,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { CustomTextField } from "components/common/CustomComponents";
import { UserEndpointList } from "constants/endpoints";
import { t } from "i18next";
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { post, get } from "utils/api";
import { IMAGE_URL } from "utils/constants";
import { formatNumberWithDecimal } from "utils/format";
interface Column {
  key: string;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => any;
}

interface TBalance {
  accountId: 57863;
  amount: 0;
  asset: "ETH";
  commissionType: 0;
  createdAt: "2024-06-04T00:00:01.061967";
  createdBy: 0;
  deletedAt: null;
  email: null;
  id: 21;
  isDeleted: false;
  lastSpentCom: 1717632005888;
  updatedAt: "2024-06-06T00:00:04.698057";
  updatedBy: 0;
  value: 0;
}

export default function TodayHistory(props: any) {
  const [data, setData] = React.useState<any>(undefined);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [assets, setAssets] = React.useState<string[]>([]);
  const [listImg, setListImg] = React.useState<any>([])
  const [Asset, setAsset] = React.useState<any>("All");
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  React.useEffect(() => {
    let query: any = {
      page,
      limit: pageSize,
    };
    
    if (Asset !== "All") {
      query.asset = Asset
    }
    
    if (startDate && endDate) {
      query.sdate = moment(startDate).unix();
      query.edate = moment(endDate).unix();
    }
    const queryString = new URLSearchParams(query).toString();

    get(
      UserEndpointList.Dashboard.TodayHistory + `?${queryString}`, (res) => {
        if (res) {
          setData(res.list);
          const temp: string[] = res.head.filter.asset.option.map((item:any) => item.value);
          const unique:any = temp.filter((v, i, a) => a.indexOf(v) === i);
          unique.unshift('All');
          setAssets(unique);
          setListImg(res.head.filter.asset.option.map((item:any) => item))
          console.log(unique)

        }
        else {
          setData({
            itemCount: 0,
            items: [],
            page: 1,
            pageSize: 10,
            pageCount: 0,
            searchList: [],
          });
        }
      },
      (error: any) => {
        toast.error(error.detail);
        setData({
          itemCount: 0,
          items: [],
          page: 1,
          pageSize: 10,
          pageCount: 0,
          searchList: [],
        });
      }
    );
  }, [Asset, endDate, page, pageSize, startDate]);
  const getImg = (list:[], token:string) =>{
    const founded:any = list.filter((v:any)=> {if(v.value === token) return v})
    return founded.map((v:any)=> v.iconImage)
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const columns: readonly Column[] = [
    {
      key: "createdAt",
      label: t('common:table.Date'),
      format(value) {
        return moment(value).format("YYYY-MM-DD");
      },
    },
    {
      key: "asset",
      label: t('common:table.Asset'),
      format(value) {
        return (
          <Stack direction={"row"} gap={1}>
            <img
              // src={`${IMAGE_URL}/${value}.png`}
              src={getImg(listImg, value)}
              alt={value}
              style={{ width: "20px", height: "20px" }}
            />
            <Typography>{value}</Typography>
          </Stack>
        );
      },
    },
    {
      key: "amount",
      label: t('common:table.Amount'),
      format(value) {
        return <Typography>{formatNumberWithDecimal(value, 8)}</Typography>;
      },
    },
    {
      key: "value",
      label: t('common:table.Value (USDT)'),
      format(value) {
        return <Typography>{formatNumberWithDecimal(value, 8)}</Typography>;
      },
    },
  ];

  return (   
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Grid container spacing={2} sx={{ my: 1 }}>
        <Grid item xs={12} md={5}>
          <CustomTextField
            id={"asset"}
            label={t("common:table.Currency")}
            select
            inputProps={{
              id: "asset",
            }}
            fullWidth
            size="small"
            value={Asset}
            onChange={(e) => setAsset(e.target.value)}
          >
            {assets?.map((option:any) => (
              <MenuItem value={option} key={option}>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  {option !== "All" ? (
                    <img
                      // src={`${IMAGE_URL}/${option}.png`}
                      src={getImg(listImg, option)}
                      alt={option?.label}
                      style={{ width: "20px", height: "20px" }}
                    />
                  ) : (
                    <Box width={20} height={20}>
                      <FormatAlignJustifyIcon fontSize="small" />
                    </Box>
                  )}
                  <Typography>{option}</Typography>
                </Stack>
              </MenuItem>
            ))}
          </CustomTextField>
        </Grid>
        <Grid item xs={6} md={3.5}>
          <CustomTextField
            label={t("common:table.Start Date")}
            fullWidth
            size="small"
            type="date"
            value={moment(startDate).format("YYYY-MM-DD")}
            onChange={(e) => setStartDate(new Date(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} md={3.5}>
          <CustomTextField
            label={t("common:table.End Date")}
            fullWidth
            size="small"
            type="date"
            value={moment(endDate).format("YYYY-MM-DD")}
            onChange={(e) => setEndDate(new Date(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <TableContainer sx={{ height: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <TableCell
                    key={column.key}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{ borderColor:'#F4FCFA' }}
                  >
                    {column.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && (!data.data || data.data.length === 0) && (
              <TableRow sx={{ background:'#E5FCF7', borderColor:'#F4FCFA' }}>
                <TableCell  colSpan={columns.length}>No records found</TableCell>
              </TableRow>
            )}
            {data?.data?.map((row:any) => {
              
              return (
                <TableRow role="checkbox" tabIndex={-1} key={row.code} sx={{
                  "&:hover": {
                    background: "#93ECD9",
                  },
                  background:'#F4FCFA'
                }}>
                  {columns.map((column) => {
                    const value = row[column.key];
                    return (
                      <TableCell key={column.key} align={column.align} sx={{borderColor:'#F4FCFA'}}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {data && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={data.total}
          rowsPerPage={data.per_page}
          page={data.current_page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          style={{ border: "none" }}
          component="div"
          labelRowsPerPage={t('common:table.rowPerPage')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common:table.of')} ${count}`}
        />
      )}
    </Box>
  );
}
