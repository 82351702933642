import { Switch, SwitchProps, styled } from "@mui/material";
import { toast } from "react-toastify";
import { post } from "utils/api";

export default function SwitchBox({
  row,
  col,
  endpoint,
  handleRefresh,
}: {
  row: any;
  col: TColField;
  endpoint?: string;
  handleRefresh: () => void;
}) {
  const defaultChecked = row[col.key];

  const handleChange = (e: any) => {
    if (endpoint) {
      const body: any = {
        id: row.id,
        fields: {},
      };
      body.fields[col.key] = e.target.checked;
      post(`${endpoint}`, body, handleRefresh, (error: any) => {
        toast.error(`Message: ${error.message} Code: ${error.code}`);
      });
    }
  };

  return (
    <IOSSwitch checked={defaultChecked} onChange={handleChange} size="small" />
  );
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
