import { IconButton, Popover, Stack, styled, TextField } from "@mui/material";
import Iconify from "components/Iconify";
import EyeIcon from "icons/EyeIcon";
import EyeOffIcon from "icons/EyeOffIcon";
import { useEffect, useRef, useState } from "react";

const AppTextField = (props: any) => {
  const [currentType, setCurrentType] = useState<any>(null);
  const { InputProps, description, type } = props;
  const { endAdornment, startAdornment } = InputProps
    ? InputProps
    : ({} as any);
  const { ...rest } = props;
  return (
    <>
      <StyledTextField
        {...rest}
        label={null}
        type={currentType || type}
        autoComplete="off"
        InputProps={{
          autoComplete: "off",
          autoCorrect: "off",
          startAdornment: <>{startAdornment}</>,
          endAdornment: (
            <EndAdornmentStyled
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {endAdornment}
              {type === "password" && (
                <IconButton
                  onClick={() => setCurrentType(!currentType)}
                  sx={{ marginRight: description ? "0px" : "8px" }}
                >
                  {currentType ? (
                    <EyeIcon size={25} />
                  ) : (
                    <EyeOffIcon size={25} />
                  )}
                </IconButton>
              )}
              {description && <Description description={description} />}
            </EndAdornmentStyled>
          ),
        }}
      />
    </>
  );
};

export default AppTextField;

const Description = ({ description }: any) => {
  const [open, setOpen] = useState(false);
  const [innerWidth, setInnerWidth] = useState(0);
  const buttonRef = useRef<any>();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setInnerWidth(buttonRef.current.offsetParent.offsetWidth);
  }, [buttonRef]);
  return (
    <>
      <IconButton
        ref={buttonRef}
        onClick={handleOpen}
        sx={{ marginRight: "8px" }}
      >
        <Iconify icon={"bi:info"} sx={{ width: 25, height: 25 }} />
      </IconButton>
      <TooltipStyled
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: innerWidth + 20,
          },
        }}
      >
        {description}
      </TooltipStyled>
    </>
  );
};

const StyledTextField = styled(TextField)(({ theme }: any) => ({
  "& .MuiInputBase-root": {
    background: theme.palette.background.input,
    paddingLeft: "1.5rem",
    borderRadius: "50px",
    overflow: "hidden",
    paddingRight: "0px",
    height: 50,
    border: "none",
    fontSize: "0.85rem",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      outline: "none",
    },
    "& input:-webkit-autofill": {
      webkitBackgroundClip: `${theme.palette.text.primary} !important`,
      webkitTextFillColor: `${theme.palette.background.input} !important`,
      transition: `background-color 50000s ease-in-out 0s`,
      boxShadow: `inset 0 0 20px 20px rgba(0,0,0,0) !important`,
    },
  },
  "& .MuiOutlinedInput-input": {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "0.5rem",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.action.hover,
  },
  "& .MuiInputLabel-root": {
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    fontWeight: 600,
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.text.disabled,
  },
  "& .MuiInputBase-input::placeholder": {
    color: theme.palette.text.secondary,
    opacity: 1,
  },
}));

const EndAdornmentStyled = styled(Stack)(({ theme }) => ({
  height: "100%",
  "& .iconify": {
    color: theme.palette.text.disabled,
  },
}));

const TooltipStyled = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    boxShadow: theme.shadows[1],
    borderRadius: "0.4rem",
    minHeight: "50px",
    padding: "1rem",
    border: `1px solid ${theme.palette.action.disabled}`,
    marginLeft: "18px !important",
    color: theme.palette.text.secondary,
  },
}));
