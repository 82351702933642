import { MenuItem, TextFieldProps } from "@mui/material";
import { CustomTextField } from "components/common/CustomComponents";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function FieldInput({
  field,
  defaultValue,
  ...props
}: TextFieldProps & {
  field: TInput;
  defaultValue?: any;
  [key: string]: any;
}) {
  const {t} = useTranslation()
  if (field.type === "date") {
    return (
      <CustomTextField
        label={t(`common:table.${field.label}`)}
        id={field.key}
        fullWidth
        size="small"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          id: field.key,
          min: props.minDate ? moment(props.minDate).format("YYYY-MM-DD") : undefined
        }}
        defaultValue={moment(defaultValue).format("YYYY-MM-DD")}
        disabled={field.disabled}
        {...props}
      />
    );
  }
  if (field.type === "date-time") {
    return (
      <CustomTextField
        label={t(`common:table.${field.label}`)}
        id={field.key}
        fullWidth
        size="small"
        type="datetime-local"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: "any",
        }}
        defaultValue={moment(defaultValue).format("YYYY-MM-DD HH:mm:ss")}
        disabled={field.disabled}
        {...props}
      />
    );
  }
  if (field.type === "select" || field.type === "boolean") {
    return (
      <CustomTextField
        id={field.key}
        label={t(`common:table.${field.label}`)}
        select
        inputProps={{
          id: field.key,
        }}
        fullWidth
        size="small"
        defaultValue={defaultValue}
        disabled={field.disabled}
        {...props}
      >
        {field.options?.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </CustomTextField>
    );
  }
  if (field.type === "number") {
    return (
      <CustomTextField
        label={t(`common:table.${field.label}`)}
        id={field.key}
        fullWidth
        size="small"
        type="number"
        defaultValue={defaultValue}
        inputProps={{
          step: "any",
        }}
        disabled={field.disabled}
        {...props}
      />
    );
  }
  return (
    <CustomTextField
      label={t(`common:table.${field.label}`)}
      id={field.key}
      fullWidth
      size="small"
      defaultValue={defaultValue}
      disabled={field.disabled}
      {...props}
    />
  );
}
