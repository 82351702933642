import flagEng from 'assets/flags/Language 1.png'
import flagIndo from 'assets/flags/Language 7.png'
import flagSpain from 'assets/flags/Language 5.png'
import flagJapan from 'assets/flags/Language 3.png'
import flagGermany from 'assets/flags/Language 4.png'
import flagFrance from 'assets/flags/Language 6.png'
import flagChina from 'assets/flags/Language 2.png'
export const languageList = [
    {
        icon: flagEng,
        title: "English",
        lang: "en",
    },
    {
        icon: flagIndo,
        title: "Indonesian",
        lang: "ind",
    },
    {
        icon: flagSpain,
        title: "Spanish",
        lang: "es",
    },
    {
        icon: flagJapan,
        title: "Japan",
        lang: "ja",
    },
    {
        icon: flagGermany,
        title: "German",
        lang: "de",
    },
    {
        icon: flagFrance,
        title: "French",
        lang: "fr",
    },
    {
        icon: flagChina,
        title: "Chinese",
        lang: "cn",
    },
];