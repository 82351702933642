import { Reducer } from "redux";
import * as Actions from "./settingActions";
import { SettingStoreConstant } from "./settingConstants";
import { en } from "languages/en";

const initialState: TSettingInitialState = {
  library: en,
  pageLoading: true,
  tableConfigs: [],
  serverConfig: null,
  serverError: null,
  userSelected: null,
  pinballGameShow: false,
  assetOptions: null,
};

export const SettingReducer: Reducer<TSettingInitialState, TReduxAction> = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SettingStoreConstant.SET_ASSETS:
      return { ...state, assetOptions: payload };
    case SettingStoreConstant.SET_SELECTED_USER:
      return { ...state, userSelected: payload };
    case SettingStoreConstant.SET_SERVER_CONFIG:
      return { ...state, serverConfig: payload };
    case SettingStoreConstant.SET_ERROR_CODE:
      return { ...state, serverError: payload };
    case SettingStoreConstant.CHANGE_LANGUAGE:
      return { ...state, library: payload };
    case SettingStoreConstant.SET_TABLE_CONFIG:
      return { ...state, tableConfigs: payload };
    default:
      return { ...state };
  }
};

export const SettingStoreHelper = {
  Actions,
};
