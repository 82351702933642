import { Box, Grid, Stack, Typography } from "@mui/material";
import { CustomPaper } from "components/common/CustomComponents";
import { UserEndpointList } from "constants/endpoints";
import React from "react";
import { get, post } from "utils/api";
import { formatNumberWithDecimal } from "utils/format";
import lineChart from 'assets/chart/Line Chart.png'
import colChart from 'assets/chart/Chart Line.png'
export default function DashboardBalances(props: any) {
  const [data, setData] = React.useState<
    {
      asset: string;
      amount: number;
      value: number;
      commissionType: string;
    }[]
  >([{ asset: "USDT", amount: 0, value: 0, commissionType: 'future' }]);

  React.useEffect(() => {
    get(UserEndpointList.Dashboard.Balance, (data) => {
        setData(data.list.data) 
    });
  }, []);

  return (
    <Grid container spacing={2} justifyItems={"stretch"}>
      {data?.map((item, index) => {
        return (
        <Grid item key={index} >
            <CustomPaper variant="outlined" sx={{maxWidth: '345px'}}  >
            <Grid display='flex' gap={5} >
              <Stack>
                <Typography fontWeight={500}>
                  {item.asset}{" "}
                  <small>{item.commissionType === "future" ? "Futures" : "Spot"}</small>
                </Typography>
                <Box>
                  <Typography variant="h5" fontWeight={700}>
                    {/* {formatNumberWithDecimal(item.amount, 8)} */}
                    {item.amount}
                  </Typography>
                  <Typography fontWeight={500}>
                    ${formatNumberWithDecimal(item.value, 2)}
                  </Typography>
                </Box>
              </Stack>
              <div>
                <img src={item.commissionType === "future" ? colChart: lineChart} alt='img' height={63} width={90}/>
              </div>
          </Grid>
            </CustomPaper>
           
        </Grid>
      )})}
    </Grid>
  );
}
