import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { CustomButton } from "components/common/CustomComponents";
import React from "react";
import { formatFieldName } from "./DataDetails";
import FieldInput from "./FieldInput";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

function getInputConfig(key: string): TInput {
  let result: TInput;
  switch (key) {
    case "IsDirectRef":
      result = {
        col: 12,
        key: "IsDirectRef",
        label: 'Referral Type',
        options: [
          { label: t("common:table.Direct"), value: "1" },
          { label: t("common:table.Indirect"), value: "0" },
        ],
        type: "select",
      };
      break;
    default:
      result = {
        col: 12,
        key,
        label: formatFieldName(key),
        options: [],
        type: "text",
      };
  }
  return result;
}

function SearchBar({
  handleFilter,
  filterable,
  extraFilters,
  firstElement
}: {
  handleFilter: (e: {
    filters: { [key: string]: string };
    extraFilterValues: { [key: string]: string };
  }) => void;
  filterable?: string[];
  extraFilters?: TInput[];
  firstElement?:any
}) {
  const [filters, setFilters] = React.useState<{
    [key: string]: string;
  }>({});
  const [extraFilterValues, setExtraFilterValues] = React.useState<{
    [key: string]: string;
  }>({});
  const theme = useTheme();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleFilter({ filters, extraFilterValues });
  };
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const {t} = useTranslation()
  return (
    <Box component={"form"} onSubmit={handleSubmit} mb={2} p={2} pr={0} sx={{ display: extraFilters ? 'block' : 'none' }}>
      <Grid container spacing={2} justifyContent='space-between' alignItems={"flex-start"} flexWrap='wrap' sx={{ background: '#E5FCF7' , borderTopLeftRadius: firstElement? '0px': '20px' }} pb={2} borderRadius={'20px'}>
        <Grid item xs={6} md={6} lg={8}>
          <Grid container spacing={2} alignItems={"center"} display='flex'>
            {extraFilters?.map((field, index) => {
              const isStartDate = field.key === "startDate";
              const isEndDate = field.key === "endDate";

              const startDateValue = extraFilterValues["startDate"];
              return (
                <Grid item key={index}>
                  <FieldInput
                    sx={{
                      width: '180px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#93ECD9',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00DDB3',
                      },
                      '& .MuiOutlinedInput-root': {
                        background: 'white',
                        borderColor: '#93ECD9',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#93ECD9',
                          borderWidth: '2px',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        '&.Mui-focused': {
                          color: '#00DDB3',
                        },
                      },
                    }}
                    field={field}
                    defaultValue={
                      extraFilterValues[field.key] ? extraFilterValues[field.key] : ""
                    }
                    onChange={(e) => {
                      setExtraFilterValues((prev) => ({
                        ...prev,
                        [field.key]: e.target.value,
                      }));
                    }}
                    minDate={isEndDate ? startDateValue : undefined}

                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        {filterable?.map((field, index) => {
          return (
            <Grid item lg={2} md={4} xs={2} key={index}>
              <FieldInput
                sx={{
                  width: '180px', 'input': { background: 'white', borderRadius: '12px' },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#93ECD9',
                      borderWidth: '2px',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: '#00DDB3',
                    },
                  },
                }}
                field={getInputConfig(field)}
                defaultValue={filters[field] ? filters[field] : ""}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    [field]: e.target.value,
                  }));
                }}

              />
            </Grid>
          );
        })}
        {extraFilters && (
          <Grid item xs={8} md={6} lg={4} display='flex' justifyContent={isLgUp ? 'flex-end' : 'flex-start'}>
            <CustomButton
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mr: 1, width: 100  , background:'#00DDB3', color:'white', fontSize:'15px',
              borderRadius:'15px',
              '&:hover':{
                background:'#00DDB3'
              }
              }}
            >
              <span style={{paddingBottom:'2px'}}>
                {t('common:dashboard.search')}
              </span>
            </CustomButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default React.memo(SearchBar);
