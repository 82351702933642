import { Box, Typography } from "@mui/material";
import { useAppDispatch } from "store/hooks";
import { SettingStoreHelper } from "store/setting/settingReducer";
import { formatEmail } from "utils/format";

export default function AccountComponent(params: {
  walletAddress: any;
  id: any;
  email: any;
  username: any;
  type: any;
  account: any;
}) {
  const dispatch = useAppDispatch();

  const handleShowUserDetail = () => {
    dispatch(SettingStoreHelper.Actions.setSelectedUser(params.account));
  };

  return (
    <Box
      position={"relative"}
      onClick={handleShowUserDetail}
      sx={{ cursor: "pointer" }}
    >
      <Box>
        <Typography variant="body2" fontWeight={600}>
          {formatEmail(params.email, 4)}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" fontWeight={600}>
          UID: {params.id}
          {` `}
          {params.username}
        </Typography>
      </Box>
    </Box>
  );
}
