import UserLayout from "layouts/UserLayout";
import { RouteObject, useRoutes } from "react-router-dom";
import { UserRoutes } from "./userRoutes";

const Router = () => {
  const getLangParams = () => {
    const langList =['ind', 'es', 'ja', 'de', 'fr', 'cn'];
    const url = window.location;
    const splitUrl = url.pathname.split("/").filter(Boolean)[0];
    const foundUrl = langList.find((e) => e.includes(splitUrl));
    return foundUrl;
  };
  const basePath = getLangParams() === "en"
    ? "/"
    : `/${getLangParams() || ""}${getLangParams() ? "/" : ""}`;
  const routes: RouteObject[] = [
    {
      path: "",
      element: <UserLayout />,
      children: UserRoutes(basePath),
    },
  ];
  return useRoutes(routes);
};

export default Router;
