import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from "axios";
import { toast } from "react-toastify";
import { AppConfig } from "settings";
import { getAccessToken } from "./auth";

type TFunction = () => void;
type TErrorFunction = (e?: { title: string; detail: string }) => void;
type TSuccessFunction = (e: any) => void;

type ErrorFunctionType = TFunction | TErrorFunction;
type SuccessFunctionType = TFunction | TSuccessFunction;

export const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export function get(
  endpoint: string,
  successCallback?: SuccessFunctionType,
  errorCallback?: ErrorFunctionType
) {
  myFetch("GET", endpoint, undefined, successCallback, errorCallback);
}

export function post(
  endpoint: string,
  body: any,
  successCallback?: SuccessFunctionType,
  errorCallback?: ErrorFunctionType
) {
  myFetch("POST", endpoint, body, successCallback, errorCallback);
}

export function put(
  endpoint: string,
  body: any,
  successCallback?: SuccessFunctionType,
  errorCallback?: ErrorFunctionType
) {
  myFetch("PUT", endpoint, body, successCallback, errorCallback);
}

export function _delete(
  endpoint: string,
  body: any,
  successCallback?: SuccessFunctionType,
  errorCallback?: ErrorFunctionType
) {
  myFetch("DELETE", endpoint, body, successCallback, errorCallback);
}

export async function postWithFormData(
  endpoint: string,
  body: any,
  successCallback?: SuccessFunctionType,
  errorCallback?: ErrorFunctionType
) {
  let url = AppConfig.API + endpoint;

  let headers = {
    Authorization: "Bearer " + getAccessToken(),
    "Content-Type": "multipart/form-data",
    accept: "application/json",
  };

  const config = { headers };

  let response = null;

  try {
    response = await axios.post(url, body, config);
    _handleSuccess(response, successCallback);
  } catch (error) {
    _handleError(error, errorCallback);
  }
}

export const alertError = (error: any) => {
  alert(error.code + (error.msg ? ": " + error.msg : ""));
};

async function myFetch(
  method: "GET" | "POST" | "PUT" | "DELETE",
  endpoint: string,
  body: any,
  successCallback?: SuccessFunctionType,
  errorCallback?: ErrorFunctionType
) {
  let url = AppConfig.API + endpoint;

  body = JSON.stringify(body);

  let headers: RawAxiosRequestHeaders = defaultHeaders;
  headers["Authorization"] = "Bearer " + getAccessToken();
  headers["Accept-Language"] = "en-US,en;q=0.5";

  const config: AxiosRequestConfig = { headers };

  let response = null;

  try {
    switch (method) {
      case "POST":
        response = await axios.post(url, body, config);
        break;
      case "PUT":
        response = await axios.put(url, body, config);
        break;
      case "DELETE":
        response = await axios.delete(url, config);
        break;
      default:
        response = await axios.get(url, config);
        break;
    }
    _handleSuccess(response, successCallback);
  } catch (error) {
    _handleError(error, errorCallback);
  }
}

const _handleSuccess = (
  response: any,
  successCallback?: SuccessFunctionType
) => {
  const { data } = response;
  console.log(response,data)
  
  let result = data;
  
  // if (data.list) {
  //   result = data; 
  // } else {
  //   result = data;
  // }
  successCallback && successCallback(result);
};

const _handleError = (error: any, errorCallback?: ErrorFunctionType) => {
  const { code, message, response } = error;
  if (code === "ERR_BAD_REQUEST" || code === "ERR_BAD_RESPONSE") {
    errorCallback && errorCallback(response.data);
  } else if (code === "ERR_NETWORK") {
    errorCallback && errorCallback();
    // logout();
  } else {
    errorCallback && errorCallback();
    toast.error(() => `${message} [${code}]`);
  }
};
