import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AppBar,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import CustomDrawer from "components/common/CustomDrawer";

export const formatFieldName = (string: string) => {
  string = string.replace(/([A-Z])/g, " $1");
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function DataDetails({
  data,
  onClose,
}: {
  data: any;
  onClose: () => void;
}) {
  return (
    <CustomDrawer open={data !== null} onClose={onClose}>
      {data && (
        <div>
          <AppBar position="sticky" sx={{ py: 1 }}>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton onClick={onClose}>
                  <ArrowBackIcon fontSize="small" sx={{ fill: "#fff" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="body1">Back</Typography>
              </Grid>
            </Grid>
          </AppBar>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data)
                  .sort()
                  .map((key, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {formatFieldName(key)} <br /> <small>{key}</small>
                        </TableCell>
                        <TableCell>{JSON.stringify(data[key])}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </CustomDrawer>
  );
}
