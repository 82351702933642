import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Fade,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import useUser from "hooks/useUser";
import { t } from "i18next";
import moment from "moment";
import { memo, useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import PinInput from "react-pin-input";
import { exchangePost } from "utils/exchange-api";
import { jsonToUrlParams } from "utils/utils";
import AppModal from "./AppModal";
import Iconify from "./Iconify";
import FlexBetween from "./flexbox/FlexBetween";
import FlexRowAlign from "./flexbox/FlexRowAlign";

const Confirm2Fa = ({
  onClick,
  children,
  sx,
  disabled,
  endpoint,
  phoneNumber,
  phoneEndpoint,
  alwayGaCode,
  submitting,
  otpRequired = "MAIL_AND_PHONE",
  params,
  profile,
}: any) => {
  const mailOTPRef = useRef<any>();
  const phoneOTPRef = useRef<any>();
  const gaRef = useRef<any>();
  const theme = useTheme();
  const { user } = useUser();
  const [requiredMode, setRequiredMode] = useState("BOTH");
  const gaEnable = profile?.gaEnable || user?.profile?.gaEnable;
  const phoneEnable = Boolean(
    profile?.phoneNumberEnable || user?.profile?.phoneNumberEnable
  );
  const [open, setOpen] = useState(false);
  const [mailOTPNextTime, setMailOTPNextTime] = useState<any>("");
  const [phoneOTPNextTime, setPhoneOTPNextTime] = useState<any>("");

  const handleAction = () => {
    setOpen(true);
  };

  const handleCompleted = () => {
    const otp = mailOTPRef?.current?.values?.join("");
    const gaCode = gaRef?.current?.values?.join("");
    const phoneCode = phoneOTPRef?.current?.values?.join("");
    if (otp?.length <= 5) return;
    if (gaEnable && gaCode?.length <= 5) return;
    if (phoneEnable && phoneCode?.length <= 5) setOpen(false);
    setTimeout(() => {
      onClick({ otp: otp, gaCode: gaCode, phoneOtp: phoneCode }, () => {
        setOpen(false);
      });
    }, 10);
  };

  const handleRequestMailOTP = () => {
    if (endpoint) {
      exchangePost(`${endpoint}?${jsonToUrlParams(params)}`, {});
    }
    const temp = moment().add(50, "second").toDate();
    setMailOTPNextTime(temp);
  };

  const handleRequestPhoneOTP = () => {
    const endpointTemp = `${endpoint}?sendOtpByPhone=true&phoneNumber=${
      profile?.phoneNumber || user?.profile.phoneNumber
    }&phoneIsoCode=${
      profile?.phoneIsoCode || user?.profile?.phoneIsoCode
    }&${jsonToUrlParams(params)}`;
    if (phoneEndpoint || endpointTemp) {
      exchangePost(phoneEndpoint || endpointTemp, {});
    }
    const temp = moment().add(50, "second").toDate();
    setPhoneOTPNextTime(temp);
  };
  const handleCloseForm = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (otpRequired && otpRequired.includes("OR")) {
      setRequiredMode("MAIL");
    }
  }, [otpRequired]);

  return (
    <>
      <StyledModal open={open}>
        {open && (
          <Stack width={"100%"} p={2} spacing={3}>
            {(requiredMode === "MAIL" || requiredMode === "BOTH") && (
              <Stack>
                <FlexBetween sx={{ width: "100%" }}>
                  <Typography pl={0.5} fontWeight={600}>
                    {t("Mail OTP")}
                  </Typography>
                  <CustomCountDown
                    nextTime={mailOTPNextTime}
                    handleCallback={() => handleRequestMailOTP()}
                  />
                </FlexBetween>
                <PinInput
                  length={6}
                  initialValue=""
                  ref={mailOTPRef}
                  style={{ marginTop: "1rem" }}
                  secretDelay={200000000}
                  type="numeric"
                  inputMode="number"
                  inputStyle={{
                    color: "inherit",
                    border: `1px solid ${theme.palette.text.disabled}`,
                    borderRadius: "0.4rem",
                  }}
                  inputFocusStyle={{
                    border: `2px solid ${theme.palette.primary.main}`,
                  }}
                  autoSelect={false}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </Stack>
            )}
            {(phoneEndpoint || phoneEnable) && (
              <Stack>
                {(requiredMode === "PHONE" || requiredMode === "BOTH") && (
                  <>
                    <FlexBetween sx={{ width: "100%" }}>
                      <Typography pl={0.5} fontWeight={600}>
                        {t("Phone OTP")}
                      </Typography>
                      <CustomCountDown
                        nextTime={phoneOTPNextTime}
                        handleCallback={() => handleRequestPhoneOTP()}
                      />
                    </FlexBetween>
                    <PinInput
                      length={6}
                      initialValue=""
                      ref={phoneOTPRef}
                      style={{ marginTop: "1rem" }}
                      secretDelay={200000000}
                      type="numeric"
                      inputMode="number"
                      inputStyle={{
                        color: "inherit",
                        border: `1px solid ${theme.palette.text.disabled}`,
                        borderRadius: "0.4rem",
                      }}
                      inputFocusStyle={{
                        border: `2px solid ${theme.palette.primary.main}`,
                      }}
                      autoSelect={false}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                    <FlexRowAlign gap={1} justifyContent="flex-start">
                      <Typography mt={1} color={"text.secondary"}>
                        {t("OTP will be sent to phone number")}:
                      </Typography>
                      <Typography mt={1} color={"warning.main"}>
                        +{phoneNumber || user?.profile?.phoneNumber}
                      </Typography>
                    </FlexRowAlign>
                  </>
                )}
                {requiredMode !== "BOTH" && (
                  <>
                    <Divider sx={{ marginTop: 2 }}>{t("or")}</Divider>
                    <Typography variant="h6" mt={2}>
                      {t("Change verification method")}
                    </Typography>
                    <Typography mt={1} mb={2} color={"text.secondary"}>
                      {t("Choose verification method")}
                    </Typography>
                    <Stack spacing={2}>
                      {requiredMode === "MAIL" ? (
                        <Button
                          onClick={() => setRequiredMode("PHONE")}
                          variant="outlined"
                          sx={{ height: 50, justifyContent: "space-between" }}
                          endIcon={
                            <Iconify icon={"ph:arrow-right"} size={20} />
                          }
                        >
                          <Typography>
                            {t("Phone number verification")}
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          onClick={() => setRequiredMode("MAIL")}
                          variant="outlined"
                          sx={{ height: 50, justifyContent: "space-between" }}
                          endIcon={
                            <Iconify icon={"ph:arrow-right"} size={20} />
                          }
                        >
                          <Typography>{t("Email verification")}</Typography>
                        </Button>
                      )}
                    </Stack>
                  </>
                )}
              </Stack>
            )}

            {(gaEnable || alwayGaCode) && (
              <>
                <Typography mt={3} pl={0.5} fontWeight={600}>
                  {t("Google Authentication")}
                </Typography>
                <PinInput
                  length={6}
                  initialValue=""
                  ref={gaRef}
                  style={{ marginTop: "1rem" }}
                  secretDelay={200000000}
                  type="numeric"
                  inputMode="number"
                  inputStyle={{
                    color: "inherit",
                    border: `1px solid ${theme.palette.text.disabled}`,
                    borderRadius: "0.4rem",
                  }}
                  inputFocusStyle={{
                    border: `2px solid ${theme.palette.primary.main}`,
                  }}
                  autoSelect={false}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </>
            )}
            <FlexBetween gap={2} pt={4}>
              <Button
                sx={{ borderRadius: 50 }}
                onClick={() => handleCloseForm()}
                fullWidth
                variant="outlined"
              >
                {t("Cancel")}
              </Button>
              <LoadingButton
                loading={submitting}
                sx={{ borderRadius: 50 }}
                onClick={() => handleCompleted()}
                fullWidth
                variant="contained"
              >
                {t("Confirm")}
              </LoadingButton>
            </FlexBetween>
          </Stack>
        )}
      </StyledModal>
      <Box sx={{ ...sx }} onClick={() => !disabled && handleAction()}>
        {children}
      </Box>
    </>
  );
};

export default memo(Confirm2Fa);

const StyledModal = styled(AppModal)(({ theme }) => ({
  "& .modal-wrapper": {
    maxWidth: "fit-content",
    padding: "3rem",
    border: `1px solid ${theme.palette.text.disabled}`,
    background: theme.palette.background.default,
  },
}));

const CustomCountDown = ({ handleCallback, nextTime }: any) => {
  const [completed, setCompleted] = useState(true);
  const [countdownTime, setCountdownTime] = useState(-1);
  const handleClick = () => {
    if (handleCallback) handleCallback();
    setCompleted(false);
  };
  useEffect(() => {
    if (nextTime) {
      const temp = moment(nextTime).unix() - moment().unix();
      if (temp > 0) {
        setCountdownTime(temp);
        setCompleted(false);
      }
    }
  }, [nextTime]);

  return (
    <Box>
      {completed ? (
        <Fade in={completed}>
          <Typography
            width={100}
            textAlign={"right"}
            color={"primary"}
            fontWeight={600}
            sx={{ cursor: "pointer" }}
            onClick={() => handleClick()}
          >
            {t("Request OTP")}
          </Typography>
        </Fade>
      ) : (
        <>
          {countdownTime >= 0 && (
            <Countdown
              date={Date.now() + countdownTime * 1000}
              onComplete={() => setCompleted(true)}
              renderer={({ seconds }) => (
                <Typography pr={1} color={"text.secondary"}>
                  {seconds}
                </Typography>
              )}
            />
          )}
        </>
      )}
    </Box>
  );
};
