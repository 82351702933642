import { Box, Tabs, Typography } from "@mui/material";
import HigherOrderComponent from "components/HOC/HigherOrderComponent";
import { CustomPaper, SpecialTab } from "components/common/CustomComponents";
import { UserEndpointList } from "constants/endpoints";
import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BranchQuery(props: any) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Typography variant="h5" fontWeight={700} mb={2}>
        Branch Query
      </Typography>
      <CustomPaper variant="outlined">
        <Typography variant="h6" fontWeight={700} mb={3}>
          Detail
        </Typography>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <SpecialTab
              label={"Branch Commission Statistics"}
              {...a11yProps(0)}
              className="special-first-tab"
            />
            <SpecialTab label={"Branch Commission Details"} {...a11yProps(1)} />
            <SpecialTab
              label={"TOP 10"}
              {...a11yProps(1)}
              className="special-last-tab"
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Tab1 />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Tab2 />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Tab3 />
        </CustomTabPanel>
      </CustomPaper>
    </Box>
  );
}

const Tab1 = (props: any) => {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.Branch.Totals,
    getMethod:"GET",
    showFields: [
      {
        key: "partnerAccountId",
        label: "UID",
      },
      {
        key: "partnerRateCommission",
        label: "Rate Commission",
        dataType: "commission-rate",
      },
      {
        key: "totalTradingVolumeValue",
        label: "Total Trading Volume Value",
        dataType: "amount",
      },
      {
        key: "yourCommissionValue",
        label: "Your Commission Value",
        dataType: "amount",
      },
    ],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
    ],
    ...props,
  })();
};

const Tab2 = (props: any) => {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.Branch.Details,
    getMethod:"GET",
    showFields: [
      {
        key: "refAccountId",
        label: "UID",
      },
      {
        key: "refRateCommission",
        label: "Rate Commission",
        dataType: "commission-rate",
      },
      {
        key: "totalTradingVolumeValue",
        label: "Total Trading Volume Value",
        dataType: "amount",
      },
      {
        key: "yourCommissionValue",
        label: "Your Commission Value",
        dataType: "amount",
      },
      {
        key: "completedDate",
        label: "Completed Date",
        dataType: "date",
      },
    ],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
    ],
    ...props,
  })();
};

const Tab3 = (props: any) => {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.Branch.Top10,
    getMethod:"GET",
    showFields: [
      {
        key: "rank",
        label: "Rank",
      },
      {
        key: "partnerAccountId",
        label: "UID",
      },
      {
        key: "totalTradingVolumeValue",
        label: "Total Trading Volume Value",
        dataType: "amount",
      },
      {
        key: "yourCommissionValue",
        label: "Your Commission Value",
        dataType: "amount",
      },
    ],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
    ],
    ...props,
  })();
};
