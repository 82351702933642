import {
  alpha,
  Hidden,
  IconButton,
  Modal,
  Stack,
  styled,
  SwipeableDrawer,
} from "@mui/material";
import Iconify from "./Iconify";

const AppModal = ({ ...props }: any) => {
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  return (
    <>
      <Hidden mdDown>
        <Modal {...props} disableAutoFocus>
          <Wrapper className="modal-wrapper">
            {props.closeButton && (
              <IconButton
                onClick={() => handleClose()}
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  zIndex: 1000000,
                }}
              >
                <Iconify icon={"mingcute:close-line"} size={20} />
              </IconButton>
            )}
            {props.children}
          </Wrapper>
        </Modal>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawerStyled
          {...props}
          anchor="bottom"
          disableSwipeToOpen={false}
          onClose={handleClose}
          ModalProps={{
            keepMounted: false,
          }}
        >
          {props?.children}
          {props.closeButton && (
            <IconButton
              onClick={() => handleClose()}
              sx={{ position: "absolute", top: 10, right: 10, zIndex: 1000000 }}
            >
              <Iconify icon={"mingcute:close-line"} size={20} />
            </IconButton>
          )}
        </SwipeableDrawerStyled>
      </Hidden>
    </>
  );
};

export default AppModal;

const SwipeableDrawerStyled = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    paddingTop: "30px",
    borderRadius: "15px 15px 0px 0px",
    "&:before": {
      content: '""',
      position: "absolute",
      top: "10px",
      width: "50px",
      height: "5px",
      borderRadius: "5px",
      left: "calc(50% - 25px)",
      background: alpha(theme.palette.text.disabled, 0.4),
    },
  },
}));

const Wrapper = styled(Stack)(({ theme }) => ({
  top: "50%",
  left: "50%",
  padding: 24,
  maxWidth: 500,
  width: "100%",
  borderRadius: "0.5rem",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
  "& form:focus": {
    outline: "none !important",
  },
}));
