import React from "react";
import { useAppDispatch } from "store/hooks";
import { getUserInformation } from "store/user/actions";

export default function ClientCloud() {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getUserInformation());
    // dispatch(SettingStoreHelper.Actions.setServerConfig());
    // dispatch(SettingStoreHelper.Actions.getAssets());
  }, [dispatch]);

  return null;
}
