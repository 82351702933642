import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";

export const CustomLoadingButton = styled(LoadingButton)(({ theme }) => ({
  borderRadius: "50px",
  width: "100%",
  padding: "0.6rem 1.5rem",
  textTransform: "none",
  "&:hover": {
    opacity: 0.9,
  },
}));
