import { useTheme } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import TabPanel from "components/TabPanel";
import { UserEndpointList } from "constants/endpoints";
import { t } from "i18next";
import AuthenticationLayout from "page-sections/authentication/AuthenticationLayout";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SwipeableViews from "react-swipeable-views";
import { useAppDispatch } from "store/hooks";
import { setUserReducer } from "store/user/actions";
import { get } from "utils/api";
import { logout } from "utils/auth";
import { GOOGLE_CLIENT_ID } from "utils/constants";
import { exchangeGet, exchangePost } from "utils/exchange-api";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

const Login = () => {
  const theme = useTheme();
  const [value, setValue] = useState(-1);
  const [loginInfo, setLoginInfo] = useState(null);

  useEffect(() => {
    setValue(0);
  }, []);
  const dispatch = useAppDispatch();

  const handleCompleted = () => {
    window.localStorage.removeItem("blockedTo");
    // dispatch(
    //   setUserReducer({
    //     isLoading: false,
    //     information: { ...exchangeInfo, ...data },
    //   })
    // );
    // get(
    //   UserEndpointList.Partner.Profile,
    //   (data: any) => {
      // exchangePost(``)
    exchangeGet(UserEndpointList.Partner.Profile, (exchangeInfo: any) => {
      console.log(exchangeInfo)
      dispatch(
        setUserReducer({
          isLoading: false,
          information: {
            ...exchangeInfo,
            // ... {
            //   "canSetCommission": false,
            //   "accountId": 56812,
            //   "affiliateProfileId": 22,
            //   "email": "huyhoang.aideahcm@gmail.com",
            //   "name": null,
            //   "inviteCode": "51DjIaTq",
            //   "invitedById": 56810,
            //   "invitedByEmail": "quangnhat.aideahcm@gmail.com",
            //   "isPartner": true,
            //   "status": 0,
            //   "rateCommission": 0.0500,
            //   "rootRefAccountId": 56810,
            //   "levelWithF0": 1,
            //   "phoneNumber": null,
            //   "id": 15,
            //   "isDeleted": false,
            //   "createdAt": "2024-07-30T06:14:56.245507",
            //   "updatedAt": "2024-07-30T07:26:58.579858",
            //   "deletedAt": null,
            //   "createdBy": 0,
            //   "updatedBy": 0
            // }
          },
        })
      );
    });
    // },
    // () => {
    //   toast.error(`You are not partner`);
    //   dispatch(setUserReducer({ isLoading: false }));
    //   logout();
    // }
    // );
  };

  const handleNextStep = (value: any) => {
    setLoginInfo(value);
    setValue(1);
  };

  return (
    <AuthenticationLayout route="/register" title={t("Welcome back!")}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          disabled
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <StepOne
              onCompleted={() => handleCompleted()}
              onNextStep={(value: any) => handleNextStep(value)}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <StepTwo
              onBack={() => setValue(0)}
              loginInfo={loginInfo}
              onCompleted={() => handleCompleted()}
            />
          </TabPanel>
        </SwipeableViews>
      </GoogleOAuthProvider>
    </AuthenticationLayout>
  );
};

export default Login;
