import AuthLayout from "layouts/AuthLayout";
import Login from "pages/auth/Login";
import { RouteObject, useRoutes } from "react-router-dom";

const AuthRouter = () => {
  const routes: RouteObject[] = [
    {
      element: <AuthLayout />,
      children: [
        {
          path: "/*",
          element: (
            // <Page title="Login">
            <Login />
            // </Page>
          ),
        },
      ],
    },
  ];
  return useRoutes(routes);
};

export default AuthRouter;
