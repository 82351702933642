import {
  AppBar,
  Grid,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

// -------------------------------------------------------
const AuthenticationLayout = (props: any) => {
  const theme = useTheme();
  const { children, title, route, routeName, description, layoutHead } = props;
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid item md={6} xs={12} order={downMd ? 1 : 2}>
          <Stack alignItems="center" mt={5}>
            <Box textAlign="center" maxWidth={550} width="100%" padding={4}>
              <Typography fontWeight={500} fontSize={"2rem"}>
                {title}
              </Typography>
              {children}
              <Typography color="text.secondary" mt={4} fontWeight={500}>
                {description}{" "}
                <Link style={{ fontSize: "0.85rem" }} to={route}>
                  {routeName}
                </Link>
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthenticationLayout;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: "none",
  boxShadow: "none",
  padding: "0.7rem",
}));
