import Page from "components/common/Page";
import Dashboard from "pages/Dashboard";
import NotFound from "pages/NotFound";
import BranchQuery from "pages/client/commission-panel/BranchQuery";
import UserQuery from "pages/client/commission-panel/UserQuery";
import DepositStatistics from "pages/client/data-panel/DepositStatistics";
import ReferralStatistics from "pages/client/data-panel/ReferralStatistics";
import TradeCommission from "pages/client/data-panel/TradeCommission";
import DepositQuery from "pages/client/deposit-withdrawal/DepositQuery";
import WithdrawalQuery from "pages/client/deposit-withdrawal/WithdrawQuery";
import PartnerData from "pages/client/partner-management/PartnerData";
import PartnerLevelManagement from "pages/client/partner-management/PartnerLevelManagement";
import PartnerList from "pages/client/partner-management/PartnerList";
import PerpetualFuturesTradingQuery from "pages/client/trading-management/PerpetualFuturesTradingQuery";
import SpotTradingQuery from "pages/client/trading-management/SpotTradingQuery";
import StandardFuturesTradingQuery from "pages/client/trading-management/StandardFuturesTradingQuery";
import UserBasicInformation from "pages/client/user-management/UserBasicInformation";
import UserBenefitManagement from "pages/client/user-management/UserBenefitManagement";

export const UserRoutes=(basePath:string) =>{ 
  return [
  {
    path: basePath,
    element: (
      <Page title="Dashboard">
        <Dashboard />
      </Page>
    ),
  },
  {
    path: `${basePath}data-panel`,
    children: [
      {
        path: `referral-statistics`,
        element: (
          <Page title="Referral Statistics">
            <ReferralStatistics />
          </Page>
        ),
      },
      {
        path: `deposit-statistics`,
        element: <DepositStatistics />,
      },
      {
        path: "trade-commission",
        element: (
          <Page title="Trade & Commission">
            <TradeCommission />
          </Page>
        ),
      },
    ],
  },
  {
    path: `${basePath}commission-panel`,
    children: [
      {
        path: "user-query",
        element: (
          <Page title="User Query">
            <UserQuery />
          </Page>
        ),
      },
      {
        path: "branch-query",
        element: <BranchQuery />,
      },
    ],
  },
  {
    path: `${basePath}user-management`,
    children: [
      {
        path: "user-basic-information",
        element: (
          <Page title="User Basic Information">
            <UserBasicInformation />,
          </Page>
        ),
      },
      {
        path: "user-benefit-management",
        element: <UserBenefitManagement />,
      },
    ],
  },
  {
    path:  `${basePath}deposit-withdrawal`,
    children: [
      {
        path: "deposit-query",
        element: <DepositQuery />,
      },
      {
        path: "withdrawal-query",
        element: <WithdrawalQuery />,
      },
    ],
  },
  {
    path:  `${basePath}trading-management`,
    children: [
      {
        path: "perpetual-futures-trading-query",
        element: <PerpetualFuturesTradingQuery />,
      },
      {
        path: "standard-futures-trading-query",
        element: <StandardFuturesTradingQuery />,
      },
      {
        path: "spot-trading-query",
        element: <SpotTradingQuery />,
      },
    ],
  },
  {
    path:  `${basePath}partner-management`,
    children: [
      {
        path: "partner-list",
        element: (
          <Page title="Partner List">
            <PartnerList />
          </Page>
        ),
      },
      {
        path: "partner-data",
        element: <PartnerData />,
      },
      {
        path: "partner-level-management",
        element: <PartnerLevelManagement />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <Page title="Dashboard">
        <Dashboard />
      </Page>
    ),
  },
]
}


// export const UserRoutes = (basePath:string) => {
//   console.log(basePath, '22321312312321')
//   return [
//     {
//       path: basePath,
//       element: (
//         <Page title="Dashboard">
//           <Dashboard />
//         </Page>
//       ),
//     },
//     {
//       path: `${basePath}data-panel`,
//       children: [
//         {
//           path: "referral-statistics",
//           element: (
//             <Page title="Referral Statistics">
//               <ReferralStatistics />
//             </Page>
//           ),
//         },
//         {
//           path: "deposit-statistics",
//           element: <DepositStatistics />,
//         },
//         {
//           path: "trade-commission",
//           element: (
//             <Page title="Trade & Commission">
//               <TradeCommission />
//             </Page>
//           ),
//         },
//       ],
//     },
//     {
//       path: `${basePath}commission-panel`,
//       children: [
//         {
//           path: "user-query",
//           element: (
//             <Page title="User Query">
//               <UserQuery />
//             </Page>
//           ),
//         },
//         {
//           path: "branch-query",
//           element: <BranchQuery />,
//         },
//       ],
//     },
//     {
//       path: `${basePath}user-management`,
//       children: [
//         {
//           path: "user-basic-information",
//           element: (
//             <Page title="User Basic Information">
//               <UserBasicInformation />
//             </Page>
//           ),
//         },
//         {
//           path: "user-benefit-management",
//           element: <UserBenefitManagement />,
//         },
//       ],
//     },
//     {
//       path: `${basePath}deposit-withdrawal`,
//       children: [
//         {
//           path: "deposit-query",
//           element: <DepositQuery />,
//         },
//         {
//           path: "withdrawal-query",
//           element: <WithdrawalQuery />,
//         },
//       ],
//     },
//     {
//       path: `${basePath}trading-management`,
//       children: [
//         {
//           path: "perpetual-futures-trading-query",
//           element: <PerpetualFuturesTradingQuery />,
//         },
//         {
//           path: "standard-futures-trading-query",
//           element: <StandardFuturesTradingQuery />,
//         },
//         {
//           path: "spot-trading-query",
//           element: <SpotTradingQuery />,
//         },
//       ],
//     },
//     {
//       path: `${basePath}partner-management`,
//       children: [
//         {
//           path: "partner-list",
//           element: (
//             <Page title="Partner List">
//               <PartnerList />
//             </Page>
//           ),
//         },
//         {
//           path: "partner-data",
//           element: <PartnerData />,
//         },
//         {
//           path: "partner-level-management",
//           element: <PartnerLevelManagement />,
//         },
//       ],
//     },
//     {
//       path: "*",
//       element: (
//         <Page title="Not Found">
//           <NotFound />
//         </Page>
//       ),
//     },
//   ];
// };