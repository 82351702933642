import { LoadingButton } from "@mui/lab";
import { ButtonBase, Stack, Typography, styled, useTheme } from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import useUser from "hooks/useUser";
import { t } from "i18next";
import { useState } from "react";
import toast from "react-hot-toast";
import PinInput from "react-pin-input";

const StepTwo = ({ loginInfo, onCompleted, onBack }: any) => {
  const theme = useTheme();
  const [otp, setOtp] = useState("");
  const {
    loginAccount,
    loginWithGoogle,
    connectAccount,
    loginWithWallet,
    loginWithPhone,
  } = useUser();
  const [submitting, setSubmitting] = useState(false);
  const handleLoginWithGa = (otp: any) => {
    const params = {
      ...loginInfo,
      gaCode: otp,
    };
    setSubmitting(true);
    switch (loginInfo.loginType) {
      case "Google":
        loginWithGoogle(
          params,
          () => {
            setSubmitting(false);
            onCompleted();
          },
          (error: any) => {
            setSubmitting(false);
            toast.error(`${error.msg}`);
          }
        );
        break;
      case "Wallet":
        if (loginInfo.skipConnectAccount === true) {
          loginWithWallet(
            params,
            () => {
              setSubmitting(false);
              onCompleted();
            },
            (error: any) => {
              setSubmitting(false);
              toast.error(`${error.msg}`);
            }
          );
        } else
          connectAccount({
            ...params,
            successCallback: () => {
              setSubmitting(false);
              onCompleted();
            },
            errorCallback: (error: any) => {
              setSubmitting(false);
              toast.error(`${error.msg}`);
            },
          });
        break;
      case "Phone":
        loginWithPhone(
          params,
          () => {
            setSubmitting(false);
            onCompleted();
          },
          (error: any) => {
            setSubmitting(false);
            toast.error(`${error.msg}`);
          }
        );
        break;
      default:
        loginAccount(
          params,
          () => {
            setSubmitting(false);
            onCompleted();
          },
          (error: any) => {
            setSubmitting(false);
            toast.error(`${error.msg}`);
          }
        );
    }
  };
  return (
    <Stack height={"100%"} alignItems={"center"} justifyContent={"center"}>
      <Typography color={theme.palette.text.secondary}>
        {t("Please enter GA code in the box below")}
      </Typography>
      <PinInput
        length={6}
        initialValue=""
        style={{ marginTop: "2rem" }}
        secretDelay={200000000}
        type="numeric"
        inputMode="number"
        inputStyle={{
          color: "inherit",
          border: `1px solid ${theme.palette.text.disabled}`,
          borderRadius: "0.4rem",
        }}
        inputFocusStyle={{ border: `2px solid ${theme.palette.primary.main}` }}
        onComplete={(value, index) => {
          handleLoginWithGa(value);
          setOtp(value);
        }}
        onChange={(value) => {
          setOtp(value);
        }}
        autoSelect={true}
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
      />
      <FlexBetween pt={5} spacing={1} gap={2} sx={{ width: "100%" }}>
        <StyledButton onClick={() => onBack()}>{t("Back")}</StyledButton>
        <LoadingButton
          onClick={() => handleLoginWithGa(otp)}
          loading={submitting}
          disabled={!Boolean(otp && otp.length === 6)}
          variant="contained"
          sx={{ maxWidth: 300, width: "100%" }}
        >
          {t("Sign in")}
        </LoadingButton>
      </FlexBetween>
    </Stack>
  );
};

export default StepTwo;

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  width: "100%",
  padding: 12,
  borderRadius: "50px",
  fontWeight: 600,
  border: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down(454)]: {
    width: "100%",
    marginBottom: 8,
  },
}));
