import { Stack, Typography, useTheme } from "@mui/material";
import useUser from "hooks/useUser";
import { t } from "i18next";
import { useEffect, useState } from "react";
import QrCode from "react-qr-code";
import { setAccessToken } from "utils/auth";
import { ACCESS_TOKEN_KEY } from "utils/constants";
import { exchangePost } from "utils/exchange-api";
import { Encrypt } from "utils/utils";

const LoginByQr = ({ onCompleted }: any) => {
  const theme = useTheme();
  const { setStoreUser } = useUser();
  const [loginKey, setLoginKey] = useState("");
  const generateKey = (length: any) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleLoginSuccess = (result: any) => {
    setStoreUser({
      profile: null,
      accessToken: Encrypt(result.accessToken, ACCESS_TOKEN_KEY),
      accessKey: result.accessKey,
    });
    setAccessToken(result.accessToken);
    onCompleted();
  };

  const handleLoginQr = (key: any) => {
    exchangePost(`account/login-qr?webKey=${key}`, null, (result) => {
      if (!result) return;
      handleLoginSuccess(result);
    });
  };

  useEffect(() => {
    setLoginKey(generateKey(64));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      handleLoginQr(loginKey);
    }, 2000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginKey]);

  return (
    <Stack mt={4} alignItems={"center"}>
      {loginKey && (
        <QrCode
          size={80}
          bgColor={"transparent"}
          fgColor={"#000"}
          style={{
            height: 200,
            width: 200,
            padding: "25px",
            borderRadius: "2rem",
            background: "#fff",
            border: `1px solid ${theme.palette.divider}`,
          }}
          value={loginKey}
          viewBox={`0 0 200 200`}
        />
      )}
      <Typography mt={2} variant="body2">
        {t("Scan this code with the Dikex app to log in instantly.")}
      </Typography>
    </Stack>
  );
};

export default LoginByQr;
