import { Box } from "@mui/material";

const TabPanel = (props: any) => {
  const { children, value, index, padding, ...other } = props;

  return (
    <div {...other}>
      {value === index && <Box padding={"1px"}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
