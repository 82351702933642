import useUser from "hooks/useUser";
import { ACCESS_TOKEN_KEY } from "./constants";
import CryptoJS from "crypto-js";


export const searchByName = (listData, searchValue) => {
  if (searchValue.length > 0) {
    const searchResult = listData.filter(item => item.name.toLocaleLowerCase().match(searchValue.toLowerCase()));
    return searchResult;
  } else {
    return listData;
  }
};
export const getRoute = pathname => {
  const str = pathname.split("/");
  return `/${str[1]}`;
};

export const Encrypt = (message, privateKey) =>
  CryptoJS.AES.encrypt(message, privateKey).toString();

export const Decrypt = (ciphertext, privateKey) => {
  var bytes = CryptoJS.AES.decrypt(ciphertext, privateKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

// Auth --------------------------------------------------------------------------
export const isLoggedIn = () => {
  const accessToken = getAccessToken()
  return Boolean(accessToken)
};

export const setAccessToken = (accessToken) => {
  localStorage.setItem(
    ACCESS_TOKEN_KEY,
    Encrypt(accessToken, ACCESS_TOKEN_KEY)
  );
};

export const getAccessToken = () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    return Decrypt(user.accessToken, ACCESS_TOKEN_KEY);
  } catch (error) {
    return null
  }

};

export const logout = (callback) => {
  localStorage.removeItem('user');
  if (callback) {
    callback();
  } else window.location.reload();
};

export const jsonToUrlParams = (json) => {
  if (!json) return ''
  return Object.keys(json)
    .map(key => json[key] !== undefined ? encodeURIComponent(key) + '=' + encodeURIComponent(json[key]) : undefined)
    .filter(item => item !== undefined)
    .join('&');
}

export const mathPlus = (base, value) => {
  const x = JSON.stringify(base).split('.')[1]?.length | 0
  const y = JSON.stringify(value).split('.')[1]?.length | 0
  const factor = Math.pow(10, Math.max(x, y))
  return (Math.round(base * factor + value * factor) / factor)
}

export const mathMul = (base, value) => {
  const x = JSON.stringify(base).split('.')[1]?.length | 0
  const y = JSON.stringify(value).split('.')[1]?.length | 0
  const factor = Math.pow(10, Math.max(x, y))
  return (Math.round(base * value * factor) / factor)
}

export function objectToArray(obj) {
  if (!obj) {
    return [];
  }
  return Object.entries(obj).map(([key, value]) => ({ key, ...value }));
}

export function getMachineId() {

  let machineId = localStorage.getItem('MachineId');

  if (!machineId) {
    machineId = crypto.randomUUID();
    localStorage.setItem('MachineId', machineId);
  }

  return machineId;
}

export const generateDecimalOptions = (length) => {
  return [
    ...Array.from({ length }, (_, index) => {
      const exponent = length - (index);
      return (1 / Math.pow(10, exponent)).toFixed(exponent < 0 ? 0 : exponent);
    }), '0', '10']
};

export const ring_ring = () => {
  const bell = document.getElementById("alert-audio")
  if (bell) {
    bell.play()
  }
}

export function dataURLtoBlob(dataURL) {
  const parts = dataURL.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
}