import { Box, Typography } from "@mui/material";
import HigherOrderComponent from "components/HOC/HigherOrderComponent";
import { CustomPaper } from "components/common/CustomComponents";
import { UserEndpointList } from "constants/endpoints";

export default function DepositQuery() {
  return (
    <Box>
      <Typography variant="h5" fontWeight={700} mb={2}>
        Deposit Query
      </Typography>
      <CustomPaper variant="outlined">
        <TableComponent />
      </CustomPaper>
    </Box>
  );
}

function TableComponent(props: any) {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.InOutManagement.DepositQuery,
    showFields: [
      {
        key: "accountEmail",
        label: "Account",
        dataType: "account",
        subKey4: "accountId",
      },
      {
        key: "amount",
        label: "Amount",
        dataType: "amount",
      },
      {
        key: "asset",
        label: "Asset",
      },
      {
        key: "createdDate",
        label: "Date",
        dataType: "date",
      },
    ],
    title: "Detail",
    filterBy: [],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
    ],
    ...props,
  })();
}
