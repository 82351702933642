import { Reducer } from "redux";
import { VotingActionTypes, VotingGameActionsType } from "./actions";

type TInitialState = {
  votingList: TVotingList;
};

const initialState: TInitialState = {
  votingList: null,
};

export const VotingReducer: Reducer<TInitialState, VotingGameActionsType> = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case VotingActionTypes.SET_VOTING_LIST:
      return { ...state, votingList: payload };
    default:
      return { ...state };
  }
};
