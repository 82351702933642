export const en = {
  whitepaper: `Whitepaper`,
  litepaper: `Litepaper`,
  go_to_app: `Log-in`,
  move_to_earn: `MOVE TO EARN`,
  subtitle_intro: `Sprint is a Web3 walking fitness rewards application that allows users to exercise with their NFT and earn money.`,
  active_users: `Active Users`,
  runners: `Runners`,
  process_infomation: `PROCESS INFORMATION`,
  total: `Total`,
  burned: `Burned`,
  watch_nft: `WATCH NFT`,
  box_nft: `NFT BOX`,
  about_us: `ABOUT US`,
  sprint_and_more: `SPRINT AND MORE`,
  subtitle_about: `Sprint is the technological revolution in health and fitness improvement. NFT rewards and SWP tokens are used for a valid user file everyday.`,
  about_title_1: ` Easy to use`,
  about_subtitle_1: `Don’t need to understand NFT or Crypto to use the app. You can start by downloading the app, participating in the exercise, or purchasing a simple NFT`,
  about_title_2: `Build a habit`,
  about_subtitle_2: `Sprint’s realistic rewards and social media platforms make it easier to create active, healthy, and exercise routines`,
  about_title_3: `Easy to earn`,
  about_subtitle_3: `If you own NFT and walk, run, and exercise, you can get a reward from Sprint`,
  collection: `COLLECTION`,
  box_info: `BOXES INFORMATION`,
  token: `TOKEN`,
  token_economy: `TOKEN ECONOMY`,
  subtitle_token: `Sprint issues 2 separate types of Tokens to avoid conflicts between the reward system and the project finance system. Each type of token has different tasks and functions.`,
  describe_watch: `DESCRIBE WATCH NFT`,
  user_manual: `USER MANUAL`,
  the_app: `THE APP`,
  step_1: `Step 1`,
  step_1_label: `Download the Sprint app`,
  step_2: `Step 2`,
  step_2_label: `Register an account on the app's start screen.`,
  step_3: `Step 3`,
  step_3_label: `Connect your wallet or skip this step`,
  step_4: `Step 4`,
  step_4_label: `Buy a pair of NFT Watch or use free version NFT`,
  step_5: `Step 5`,
  step_5_label: `Experience the app and start exercising`,
  step_6: `Step 6`,
  step_6_label: `Invite friends around to exercising together.`,
  meet: `MEET`,
  our_partner: `OUR PARTNER`,
  about_sprint: `ABOUT SPRINT`,
  subscribe_subtitle: `Subscribe to our newsletter to stay up to date on launches, events, updates and more...`,
  email_address: `Email address `,
  subscribe: `SUBSCRIBE`,
  ecosystem: `ECOSYSTEM`,
  sprint_app: `Sprint App`,
  tokenomics: `Tokenomics`,
  nft_marketplace: `NFT Marketplace`,
  document: `DOC`,
  terms_of_use: `Terms of use`,
  privacy_policy: `Privacy Policy`,
  contact_us: `CONTACT US`,
  join_us: `JOIN US`,
};
