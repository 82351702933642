export function truncNumber(num: number, decimalPlaces: number) {
  const factor = Math.pow(10, decimalPlaces);
  const truncatedValue = Math.trunc(num * factor) / factor;
  return truncatedValue.toFixed(decimalPlaces);
}

export function formatNumberWithDecimal(str: any, length = 0) {
  try {
    if (str) {
      if (typeof str === "number") {
        str = truncNumber(str, length);
      } else {
        str = str.replace(/[^\d.]/g, "");
      }
      str += "";
      const x = str.split(".");
      let x1 = x[0];
      const x2 = x[1];
      let x3 = "";
      if (x2) {
        let decimalPlaces = x2.replace(/0+$/, "").length;
        if (decimalPlaces > length) {
          decimalPlaces = length;
        }
        x3 = decimalPlaces > 0 ? "." + x2.slice(0, decimalPlaces) : "";
      }
      if (!x1) x1 = "0";
      const rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1,$2");
      }
      let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
      return result;
    } else {
      return "0";
    }
  } catch (error) {
    console.log(error);
  }
}

export function formatEmail(string: any, length = 10) {
  if (string) {
    const email = string.split("@");
    return email[0].substring(0, length) + "***@" + email[1];
  } else return null;
}
