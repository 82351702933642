import HigherOrderComponent from "components/HOC/HigherOrderComponent";
import { UserEndpointList } from "constants/endpoints";

export default function TodayData(props: any) {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.Dashboard.TradeToday,
    getMethod: "GET",
    showFields: [
      {
        key: "refType",
        label: "Ref Type",
        dataType: "special-referral-type",
      },
      {
        key: "referral",
        label: "Referral",
      },
      {
        key: "commission",
        label: "Commission",
        // dataType: "Unsettled",
      },
      {
        key: "totalFeeValue",
        label: "Total Fee (USDT)",
        dataType: "amount",
      },
      {
        key: "totalVolumeValue",
        label: "Trading Volume (USDT)",
        dataType: "amount",
      },
    ],
    filterBy: [],
    ...props,
  })();
}
