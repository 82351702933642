import { Box, Typography } from "@mui/material";
import HigherOrderComponent from "components/HOC/HigherOrderComponent";
import { CustomPaper } from "components/common/CustomComponents";
import { UserEndpointList } from "constants/endpoints";
import { t } from "i18next";

export default function PartnerList() {
  return (
    <Box>
      <Typography variant="h5" fontWeight={700} mb={2}>
        {/* Partner List */}
        {t('common:dashboard.partnerList')}
      </Typography>
      <CustomPaper variant="outlined">
        <TableComponent />
      </CustomPaper>
    </Box>
  );
}

function TableComponent(props: any) {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.PartnerManagement.PartnerList,
    updateEndpoint: UserEndpointList.PartnerManagement.ChangeSettingCommission,
    getMethod:"GET",
    showFields: [
      {
        key: `email`,
        dataType: "account",
        label: "Account",
        subKey4: "accountId",
      },
      {
        key: "isDirectRef",
        label: "Referral Type",
        dataType: "referral-type",
      },
      {
        key: "rateCommission",
        label: "Rate Commission",
        dataType: "commission-rate",
      },
      {
        key: "createdAt",
        label: "Created At",
        dataType: "date",
      },
    ],
    title: "detail",
    filterBy: [
      {
        key: "AccountId",
        label: "UID",
      },
      {
        key: "email",
        label: "Email",
      },
      {
        key: "InvitedById",
        label: "Invited By",
      },

      {
        key: "isDirectRef",
        label: "Referral Type",
        type: "select",
        options: [
          { label: "Direct", value: true },
          { label: "Indirect", value: false },
        ],
      },
    ],
    inputFields: [
      {
        key: "accountId",
        disabled: true,
        type: "number",
        label: "Account ID",
      },
      {
        key: "rateCommission",
        type: "number",
        label: "Rate Commission",
      },
    ],
    extraFilters: [
      {
        key: "invitedById",
        label: "Invited By Id",
      },
      // {
      //   key: "phoneNumber",
      //   label: "Phone Number",
      // },
      {
        key: "isDirectRef",
        label: "Referral Type",
        type: "select",
        options:[    
          {label:  t("common:table.All"), value: ''},
          {label:  t("common:table.Indirect"), value: "0"},
          {label:  t("common:table.Direct"), value: "1"}
        ]
      },
      {
        key: "accountId",
        label: "Account Id",
      },
      {
        key: "email",
        label: "Email",
      },
      
    ],
    sortAble:['email','isDirectRef','rateCommission','createdAt'],
    ...props,
  })();
}
