import { combineReducers } from "redux";
import { SettingReducer } from "./setting/settingReducer";
import { UserReducer } from "./user/reducer";
import { VotingReducer } from "./voting/reducer";

export const rootReducer = combineReducers({
  settings: SettingReducer,
  user: UserReducer,
  voting: VotingReducer,
});
