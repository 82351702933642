export const kr={
    whitepaper:`백서`,
    litepaper:`라이트페이퍼`,
    go_to_app:`앱으로 이동`,
    move_to_earn:`MOVE TO EARN`,
    subtitle_intro:`Sprint는 유저가 자신의 NFT와 운동하고 돈을 얻을 수 있는 Web3 걷기 피트니스 보상 애플리케이션입니다`,
    active_users:`활성 유저`,
    runners:`런너`,
    process_infomation:`프로세스 정보`,
    total:`총`,
    burned:`탔음`,
    watch_nft:`워치 NFT`,
    box_nft:`NFT 상자`,
    about_us:`회사 소개`,
    sprint_and_more:`SPRINT 및 기타`,
    subtitle_about:`Sprint는 건강과 피트니스 개선을 위한 기술적 혁명입니다. NFT 보상과 SWP 토큰은 매일 유효한 유저에게 지급됩니다.`,
    about_title_1:`사용하기 쉬움`,
    about_subtitle_1:`앱을 사용하기 위해 NFT나 암호화폐에 대해 이해할 필요가 없습니다. 앱을 다운로드하고 운동에 참여하거나 간단한 NFT를 구매하여 시작할 수 있습니다.`,
    about_title_2:`습관 형성`,
    about_subtitle_2:`Sprint의 현실적인 보상과 소셜 미디어 플랫폼은 더 활동적이고 건강하며 운동 습관을 형성하기 쉽게 만듭니다.`,
    about_title_3:`얻기 쉬움`,
    about_subtitle_3:`만약 NFT를 소유하고 걷거나 뛰며 운동한다면, Sprint로부터 보상을 얻을 수 있습니다.`,
    collection:`컬렉션`,
    box_info:`BOXES INFORMATION`,
    token:`토큰`,
    token_economy:`토큰 이코노미`,
    subtitle_token:`Sprint는 보상 시스템과 프로젝트 금융 시스템 간의 충돌을 피하기 위해 두 가지 별도의 토큰을 발행합니다. 각 유형의 토큰에는 다른 역할과 기능이 있습니다.`,
    describe_watch:`워치 NFT 설명`,
    user_manual:`유저 안내`,
    the_app:`THE APP`,
    step_1:`Step 1`,
    step_1_label:`Sprint 앱 다운로드`,
    step_2:``,
    step_2_label:`앱의 시작 화면에서 계정 등록`,
    step_3:`Step 3`,
    step_3_label:`지갑을 연결하거나 건너뛰기`,
    step_4:`Step 4`,
    step_4_label:`NFT 워치 한 쌍을 구매하거나 무료 버전 NFT를 사용`,
    step_5:`Step 5`,
    step_5_label:`앱을 살펴보고 운동을 시작하세요`,
    step_6:`Step 6`,
    step_6_label:`친구들을 초대하여 같이 운동`,
    meet:`만나다`,
    our_partner:`파트너`,
    about_sprint:`SPRINT 소개`,
    subscribe_subtitle:`출시, 이벤트, 업데이트 등에 대한 최신 정보를 알아보려면 뉴스레터를 구독하세요 `,
    email_address:`이메일 주소`,
    subscribe:`구독`,
    ecosystem:`에코시스템`,
    sprint_app:`Sprint App`,
    tokenomics:`토크노믹스`,
    nft_marketplace:`NFT 마켓플레이스`,
    document:`문서`,
    terms_of_use:`이용약관`,
    privacy_policy:`개인정보정책`,
    contact_us:`문의`,
    join_us:`우리와 함께`,
}