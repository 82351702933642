import HigherOrderComponent from "components/HOC/HigherOrderComponent";
import { UserEndpointList } from "constants/endpoints";

export default function FuturesTradingQuery(props: any) {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.TradeManagement.FuturesQuery,
    updateEndpoint: UserEndpointList.PartnerManagement.ChangeSettingCommission,
    showFields: [
      {
        key: `accountId`,
        label: "UID",
      },
      {
        key: "side",
        label: "Side",
      },
      {
        key: "type",
        label: "Type",
      },
      {
        key: "base",
        label: "Base",
      },
      {
        key: "quote",
        label: "Quote",
      },
      {
        key: "avgPrice",
        label: "Avg Price",
        dataType: "amount",
        align: "right",
      },
    ],
    title: "Futures Trading Query",
    filterBy: [
      {
        key: "accountId",
        label: "Account ID",
      },
      {
        key: "email",
        label: "Email",
      },
    ],
    inputFields: [
      {
        key: "accountId",
        disabled: true,
        type: "number",
        label: "Account ID",
      },
      {
        key: "rateCommission",
        type: "number",
        label: "Rate Commission",
      },
    ],
    ...props,
  })();
}
