import {
  Box,
  CircularProgress,
  Hidden,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { IconPencil } from "@tabler/icons-react";
import React from "react";
import { useAppSelector } from "store/hooks";
import DataDetails from "./DataDetails";
import DataTableMobile from "./DataTableMobile";
import SwitchBox from "./SwitchBox";
import UpdateCommissionRate from "./UpdateCommissionRate";
import { renderDataValue } from "./func";
import { formatNumberWithDecimal } from "utils/format";
import { useTranslation } from "react-i18next";

const DataTable: React.FC<
  {
    data: TData;
    handleChangePage: any;
    handleChangeRowsPerPage: any;
    handleReset: () => void;
    orderBy: string;
    order: "asc" | "desc";
    handleSetOrderBy: any;
    handleSetOrder: any;
    sortable: string[] | undefined;
  } & THOCState
> = (props) => {
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [itemEditing, setItemEditing] = React.useState(null);
  const {
    data,
    showFields,
    handleChangeRowsPerPage,
    handleChangePage,
    handleReset,
    order,
    orderBy,
    handleSetOrder,
    handleSetOrderBy,
  } = props;
  const userStore = useAppSelector((state) => state.user);
  const { information } = userStore;

  const handleSort = (key: any) => {
    if (key === orderBy) {
      if (order === "desc") handleSetOrder("asc");
      else handleSetOrder("desc");
    } else {
      handleSetOrderBy(key);
      handleSetOrder("desc");
    }
  };
  const {t} = useTranslation()
  return (
    <>
      <Box sx={ { maxWidth: "100%"}}>
        <TableContainer
          sx={{
            whiteSpace: "nowrap",
            overflow: "auto",
            borderRadius: "16px",
            borderTopLeftRadius: !props?.extraFilters ? '0px' :'16px'
          }}
        >
          <Table stickyHeader size="medium">
            <Hidden mdDown>
              <TableHead>
                <TableRow>
                  {showFields.map((col, index) => {
                    const checkSortable = props.sortable?.find(
                      (s) => s.toLowerCase() === col.key.toLowerCase()
                    );
                    return (
                      <TableCell
                        key={index}
                        style={{ fontWeight: "700" }}
                        sortDirection={orderBy === col.key ? order : false}
                        align={col.align}
                        sx={{
                            background: props?.extraFilters ?"white":'#E5FCF7',
                            borderColor:'#F4FCFA'
                        }}
                      >
                        {checkSortable ? (
                          <TableSortLabel
                            active={orderBy === checkSortable}
                            direction={
                              orderBy === checkSortable ? order : "asc"
                            }
                            onClick={() => handleSort(checkSortable)}
                          >
                            {t(`common:table.${col.label}`)}
                          </TableSortLabel>
                        ) : (
                          t(`common:table.${col.label}`)
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    sx={{ position: "sticky", right: 0, background: props?.extraFilters ? "white":'#E5FCF7', borderColor:'#F4FCFA' }}
                  ></TableCell>
                </TableRow>
              </TableHead>
            </Hidden>
            <TableBody>
              <Hidden mdDown>
                {data &&
                  data.items?.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        sx={{
                          "&:hover": {
                            background: "#93ECD9",
                          },
                          background:'#F4FCFA'
                        }}
                      >
                        {showFields.map((col, colIndex) => (
                          <SingleCell
                            col={col}
                            row={row}
                            key={colIndex}
                            endpoint={props.updateEndpoint}
                            handleRefresh={props.handleReset}
                          />
                        ))}
                        <TableCell sx={{borderColor:'#F4FCFA'}}>
                          {props.updateEndpoint &&
                            information?.canSetCommission &&
                            row.isDirectRef === 1 && (
                              <IconButton
                                color="primary"
                                onClick={() => setItemEditing(row)}
                                size="small"
                              >
                                <IconPencil color="#00DDB3"/>
                              </IconButton>
                            )}
                            
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </Hidden>
              <DataTableMobile
                data={data}
                showFields={showFields}
                setItemEditing={setItemEditing}
                updateEndpoint={props.updateEndpoint}
              />
              {data && (!data.items || data.items.length === 0) && (
                <TableRow>
                  <TableCell colSpan={showFields.length + 1}>
                    {/* No records found */}
                    {t('common:table.noRecord')}
                  </TableCell>
                </TableRow>
              )}
              {!data && (
                <TableRow>
                  <TableCell
                    colSpan={showFields.length}
                    sx={{ textAlign: "center" }}
                  >
                    <CircularProgress size={20} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {data && data.itemCount ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={data.itemCount}
            rowsPerPage={data.pageSize}
            page={data.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            style={{ border: "none" }}
            component="div"
            labelRowsPerPage={t('common:table.rowPerPage')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common:table.of')} ${count}`}
          />
        ) : null}
      </Box>
      <DataDetails data={selectedRow} onClose={() => setSelectedRow(null)} />
      <UpdateCommissionRate
        handleRefresh={handleReset}
        itemEditing={itemEditing}
        close={() => setItemEditing(null)}
        {...props}
      />
    </>
  );
};

export default DataTable;

const SingleCell = ({
  col,
  row,
  endpoint,
  handleRefresh,
}: {
  col: TColField;
  row: any;
  endpoint: string;
  handleRefresh: () => void;
}) => {
  if (col.dataType === "switch-box") {
    return (
      <TableCell align={col.align} sx={{borderColor:'#F4FCFA'}}>
        <SwitchBox
          col={col}
          row={row}
          endpoint={endpoint}
          handleRefresh={handleRefresh}
        />
      </TableCell>
    );
  }
  if (col.dataType === "double") {
    return (
      <TableCell align={col.align} sx={{borderColor:'#F4FCFA', background:'#F4FCFA'}}>
        {formatNumberWithDecimal(row.avgPrice, 3)} | {formatNumberWithDecimal(row.price, 3)}
      </TableCell>
    );
  }
  
  return <TableCell align={col.align} sx={{borderColor:'#F4FCFA'}}>{renderDataValue(row, col)}</TableCell>;
};
