import { createConfig, http } from "wagmi";
import { mainnet } from "wagmi/chains";
import { metaMask } from "wagmi/connectors";

export const Version = "1.0.1";

const getConfig = () => {
  if (window.location.hostname.indexOf("cp-czx.com") >= 0)
    return {
      API: "/services/v1/",
      SOCKET: "/signalr",
      SPOT_DEFAULT_PAIR: "BTCUSDT",
      FUTURE_DEFAULT_PAIR: "BTCUSDTP",
      CAPTCHA_ID: "4fd59505ecb4bee7cdb727f33000e060",
      GOOGLE_CLIENT_ID:
        "708107113432-imo700e14af7akkpq4ph3pvr15r18arl.apps.googleusercontent.com",
      IMAGE_URL: "https://files.cp-czx.com",
      TIDIO_SNIPPET:
        '<script src="//code.tidio.co/59uqgh34nnqyvnarz8hp0uddhvgfcs4b.js" async></script>',
    };

  if (window.location.hostname.indexOf("dikex.com") >= 0)
    return {
      API: "/services/v1/",
      SOCKET: "/signalr",
      SPOT_DEFAULT_PAIR: "BTCUSDT",
      FUTURE_DEFAULT_PAIR: "BTCUSDTP",
      CAPTCHA_ID: "9a94207668d2c634045795a697e900d0",
      GOOGLE_CLIENT_ID:
        "210961425820-ca27v4ofpsfmc3f5nkgsb839pf1aafqc.apps.googleusercontent.com",
      IMAGE_URL: "https://files.dikex.com",
      TIDIO_SNIPPET: "",
    };

  return {
    API: "https://dikex.feliciastation.com/services/v1/",
    SOCKET: "https://dikex.feliciastation.com/signalr",
    CAPTCHA_ID: "a25456922c89cc0c7b7f28d92e541363",
    SPOT_DEFAULT_PAIR: "BTCUSDT",
    FUTURE_DEFAULT_PAIR: "BTCUSDTP",
    GOOGLE_CLIENT_ID:
      "35029835855-euerlou26vljbceoh3jh15nm9v84hsne.apps.googleusercontent.com",
    IMAGE_URL: "https://files.feliciastation.com/dikex",
    TIDIO_SNIPPET:
      '<script src="//code.tidio.co/g2a7ga2nc4dyxewojvr7srt9xsyqqqnf.js" async></script>',
  };
};

export const SupportedCurrency = ["USD", "KRW"];

//----------------------------------------------------------------------------
export const ACCESS_TOKEN_KEY = "CBt3gpbqeMdWPNG1";

export const API = getConfig().API;
export const WEBSOCKET = getConfig().SOCKET;
export const CAPTCHA_ID = getConfig().CAPTCHA_ID;
export const SPOT_DEFAULT_PAIR = getConfig().SPOT_DEFAULT_PAIR;
export const FUTURE_DEFAULT_PAIR = getConfig().FUTURE_DEFAULT_PAIR;
export const GOOGLE_CLIENT_ID = getConfig().GOOGLE_CLIENT_ID;
export const IMAGE_URL = getConfig().IMAGE_URL;
export const TIDIO_SNIPPET = getConfig().TIDIO_SNIPPET;

//----------------------------------------------------------------------------
export const SPOT_DEFAULT_ROUTE = "/trade/spot/BTCUSDT";
export const FUTURES_DEFAULT_ROUTE = "/trade/futures/BTCUSDTP";
//----------------------------------------------------------------------------

export const defaultPageSize = 10;
export const defaultSide = {
  BUY: "BUY",
  SELL: "SELL",
};
export const TimeInForceOptions = [
  { key: "GTC", value: 0, label: "GTC" },
  { key: "POST_ONLY", value: 1, label: "Post only" },
  { key: "IOC", value: 2, label: "IOC" },
  { key: "FOK", value: 3, label: "FOK" },
];

export const MarginTypes = {
  ISOLATED: {
    key: "ISOLATED",
    label: "Isolated",
  },
  CROSS: {
    key: "CROSS",
    label: "Cross",
  },
};

export const PriceMode = {
  MARK: {
    value: "MARK_PRICE",
    label: "Mark price",
  },
  LAST: {
    value: "LAST_PRICE",
    label: "Last price",
  },
};

export const TPSLCalculationMode = {
  ROI: {
    value: "ROI",
    label: "ROI",
  },
  Change: {
    value: "Change",
    label: "Change",
  },
  PnL: {
    value: "PnL",
    label: "PnL",
  },
};

export const QueryKeys = {
  connectSocket: "connectSocket",
};

export const TransactionTypeOptions = [
  { label: "WITHDRAWAL_INT", value: "WITHDRAWAL_INT" },
  { label: "WITHDRAWAL_EXT", value: "WITHDRAWAL_EXT" },
  { label: "DEPOSIT_INTERNAL", value: "DEPOSIT_INTERNAL" },
  { label: "DEPOSIT_EXTERNAL", value: "DEPOSIT_EXTERNAL" },
  { label: "PROMOTION", value: "PROMOTION" },
  { label: "TRANSFER", value: "TRANSFER" },
  { label: "PLACE_ORDER", value: "PLACE_ORDER" },
  { label: "WITHDRAW_ORDER", value: "WITHDRAW_ORDER" },
  { label: "TRADE", value: "TRADE" },
  { label: "ORDER_RETURN", value: "ORDER_RETURN" },
  { label: "TRADE_FEE", value: "TRADE_FEE" },
  { label: "INIT_TEST", value: "INIT_TEST" },
  { label: "WITHDRAW_EXT_FEE", value: "WITHDRAW_EXT_FEE" },
  { label: "POSITION_PNL", value: "POSITION_PNL" },
  { label: "POSITION_FEE", value: "POSITION_FEE" },
  { label: "POSITION_LIQUIDATED", value: "POSITION_LIQUIDATED" },
  { label: "PAID_FUNDING_FEE", value: "PAID_FUNDING_FEE" },
  { label: "RECEIVED_FUNDING_FEE", value: "RECEIVED_FUNDING_FEE" },
  { label: "WITHDRAW_INT_FEE", value: "WITHDRAW_INT_FEE" },
];

//Wallet ----------------------------------------------------------------------------

export const WalletConfig = createConfig({
  chains: [mainnet],
  autoConnect: false,
  connectors: [
    metaMask({
      extensionOnly: true,
    }),
  ],

  transports: {
    [mainnet.id]: http(),
  },
});

//Layout ----------------------------------------------------------------------------

const Pro_left = {
  lg: [
    { i: "pair-overview", x: 0, y: 0, w: 26, h: 4.5, static: true },
    { i: "chart", x: 6, y: 4.5, w: 14, h: 47, static: true },
    { i: "orderbook", x: 20, y: 4.5, w: 6, h: 47, static: true },
    { i: "trade-form", x: 0, y: 4.5, w: 6, h: 47, static: true },
    { i: "balance-info", x: 0, y: 51.5, w: 6, h: 45, static: true },
    { i: "history", x: 6, y: 51.5, w: 20, h: 45, static: true },
  ],
  md: [
    { i: "pair-overview", x: 0, y: 0, w: 26, h: 4.5, static: true },
    { i: "chart", x: 8, y: 4.5, w: 12, h: 47, static: true },
    { i: "orderbook", x: 20, y: 4.5, w: 6, h: 47, static: true },
    { i: "trade-form", x: 0, y: 4.5, w: 8, h: 47, static: true },
    { i: "balance-info", x: 0, y: 51.5, w: 8, h: 45, static: true },
    { i: "history", x: 8, y: 51.5, w: 18, h: 45, static: true },
  ],
  sm: [
    { i: "pair-overview", x: 0, y: 0, w: 26, h: 4.5, static: true },
    { i: "orderbook", x: 14, y: 4.5, w: 12, h: 64, static: true },
    { i: "trade-form", x: 0, y: 4.5, w: 14, h: 47, static: true },
    { i: "balance-info", x: 0, y: 51.5, w: 14, h: 17, static: true },
    { i: "chart", x: 0, y: 51.5, w: 26, h: 47, static: true },
    { i: "history", x: 0, y: 115.5, w: 26, h: 45, static: true },
  ],
};
const Pro_right = {
  lg: [
    { i: "pair-overview", x: 0, y: 0, w: 26, h: 4.5, static: true },
    { i: "chart", x: 0, y: 4.5, w: 14, h: 47, static: true },
    { i: "orderbook", x: 14, y: 4.5, w: 6, h: 47, static: true },
    { i: "trade-form", x: 21, y: 4.5, w: 6, h: 47, static: true },
    { i: "balance-info", x: 21, y: 51.5, w: 6, h: 45, static: true },
    { i: "history", x: 0, y: 51.5, w: 20, h: 45, static: true },
  ],
  md: [
    { i: "pair-overview", x: 0, y: 0, w: 26, h: 4.5, static: true },
    { i: "chart", x: 0, y: 4.5, w: 12, h: 47, static: true },
    { i: "orderbook", x: 12, y: 4.5, w: 6, h: 47, static: true },
    { i: "trade-form", x: 18, y: 4.5, w: 8, h: 47, static: true },
    { i: "balance-info", x: 21, y: 51.5, w: 8, h: 45, static: true },
    { i: "history", x: 0, y: 51.5, w: 18, h: 45, static: true },
  ],
  sm: [
    { i: "pair-overview", x: 0, y: 0, w: 26, h: 4.5, static: true },
    { i: "orderbook", x: 0, y: 4.5, w: 12, h: 64, static: true },
    { i: "trade-form", x: 12, y: 4.5, w: 14, h: 47, static: true },
    { i: "balance-info", x: 12, y: 51.5, w: 14, h: 17, static: true },
    { i: "chart", x: 0, y: 51.5, w: 26, h: 47, static: true },
    { i: "history", x: 0, y: 115.5, w: 26, h: 45, static: true },
  ],
};
const Spot_Standard = {
  lg: [
    { i: "pair-overview", x: 0, y: 0, w: 26, h: 4.5, static: true },
    { i: "pair-selector", x: 0, y: 4.5, w: 6, h: 70, static: true },
    { i: "balance-info", x: 0, y: 74.5, w: 6, h: 17, static: true },
    { i: "chart", x: 6, y: 4.5, w: 14, h: 40, static: true },
    { i: "orderbook", x: 20, y: 4.5, w: 6, h: 87, static: true },
    { i: "trade-form", x: 6, y: 44.5, w: 14, h: 47, static: true },
    { i: "history", x: 0, y: 91.5, w: 26, h: 45, static: true },
  ],
  md: [
    { i: "pair-overview", x: 0, y: 0, w: 26, h: 4.5, static: true },
    // { i: "pair-selector", x: 0, y: 4.5, w: 6, h: 70, static: true },
    { i: "balance-info", x: 17, y: 74.5, w: 9, h: 17, static: true },
    { i: "chart", x: 0, y: 4.5, w: 17, h: 40, static: true },
    { i: "orderbook", x: 17, y: 4.5, w: 9, h: 70, static: true },
    { i: "trade-form", x: 0, y: 44.5, w: 17, h: 47, static: true },
    { i: "history", x: 0, y: 91.5, w: 26, h: 45, static: true },
  ],
  sm: [
    { i: "pair-overview", x: 0, y: 0, w: 26, h: 4.5, static: true },
    { i: "pair-selector", x: 0, y: 4.5, w: 12, h: 47, static: true },
    { i: "orderbook", x: 0, y: 4.5, w: 12, h: 47, static: true },
    { i: "trade-form", x: 12, y: 4.5, w: 14, h: 47, static: true },
    { i: "chart", x: 0, y: 51.5, w: 26, h: 47, static: true },
    { i: "history", x: 0, y: 98.5, w: 26, h: 45, static: true },
  ],
};

export const SpotTradingPanel = {
  "Pro-left": Pro_left,
  "Pro-right": Pro_right,
  Standard: Spot_Standard,
};
