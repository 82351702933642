import { en } from "languages/en";
import { jp } from "languages/jp";
import { kr } from "languages/kr";
import { vn } from "languages/vn";
import { AppDispatch } from "store";
import { get } from "utils/api";
import { SettingEndpoints, SettingStoreConstant } from "./settingConstants";

export const _changeLanguage =
  (lang: string | undefined | null = "en") =>
  (dispatch: AppDispatch) => {
    let library;
    if (lang) localStorage.setItem("lang", lang);
    switch (lang) {
      case "vn":
        library = { ...en, ...vn };
        break;
      case "kr":
        library = { ...en, ...kr };
        break;
      case "jp":
        library = { ...en, ...jp };
        break;
      default:
        localStorage.setItem("lang", "en");
        library = en;
        break;
    }
    dispatch({
      type: SettingStoreConstant.CHANGE_LANGUAGE,
      payload: library,
    });
  };

export const _showAppError = (error: any | null) => (dispatch: AppDispatch) => {
  dispatch({
    type: SettingStoreConstant.SET_ERROR_CODE,
    payload: error,
  });
};

export const _setLoading = (e: any) => (dispatch: AppDispatch) => {};

export const setServerConfig = () => async (dispatch: AppDispatch) => {
  get(SettingEndpoints.SERVER_CONFIG, (res: OkServerConfigResponse) => {
    dispatch({
      type: SettingStoreConstant.SET_SERVER_CONFIG,
      payload: res.AppConfig,
    });
  });
};

export const getTableConfig = () => async (dispatch: AppDispatch) => {
  get(SettingEndpoints.Table_Configs, (res: TTableConfigs) => {
    dispatch({
      type: SettingStoreConstant.SET_TABLE_CONFIG,
      payload: res,
    });
  });
};

export const setSelectedUser =
  (user: { userId?: number; walletAddress?: string } | undefined) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: SettingStoreConstant.SET_SELECTED_USER,
      payload: user,
    });
  };

export const getAssets = () => async (dispatch: AppDispatch) => {
  get("/v1/asset", (data: any) => {
    dispatch({
      type: SettingStoreConstant.SET_ASSETS,
      payload: data.map((e: any) => ({
        label: e.name,
        value: e.id,
      })),
    });
  });
};
