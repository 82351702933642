import { Check, Close } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import moment from "moment";
import { IMAGE_URL } from "utils/constants";
import { formatNumberWithDecimal } from "utils/format";
import AccountComponent from "./AccountComponent";
import { t } from "i18next";

export const renderDataValue = (row: any, col: TColField) => {
  const value = row[col.key];
  const type = col.dataType;
  let renderValue: any = <Typography>--</Typography>;
  switch (type) {
    case "Unsettled":
      renderValue = <Typography>Unsettled</Typography>;
      break;
    case "coin-image":
      renderValue = (
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <img
            src={`${IMAGE_URL}/${value}.png`}
            alt="coin"
            style={{ width: "20px", height: "20px", borderRadius: "50%" }}
          />
          <Typography>{value}</Typography>
        </Stack>
      );
      break;
    case "commission-rate":
      renderValue = (
        <Typography>{formatNumberWithDecimal(value, 2)}%</Typography>
      );
      break;
    case "amount":
      renderValue = (
        <Typography>{formatNumberWithDecimal(value, 8)}</Typography>
      );
      break;
    case "date-time":
      renderValue = (
        <Typography>{moment(value).format("YYYY-MM-DD HH-mm-ss")}</Typography>
      );
      break;
    case "date":
      renderValue = (
        <Typography>{moment(value).format("YYYY-MM-DD")}</Typography>
      );
      break;
    case "checkbox":
      renderValue = value ? <Check color="success" /> :<Close color="error" />;
      // renderValue = (
      //   <Typography>{value ? "true":"false"}</Typography>
      // );
      break;
    case "referral-type":
      renderValue = (
        <Typography fontWeight={900}>
          {value ? t("common:table.Direct") : t("common:table.Indirect")}
        </Typography>
        
      );
      break;
    case "special-referral-type":
      renderValue = (
        <Typography fontWeight={900}>
          {value === 1 ? t("common:table.Direct") : value === 0 ? t("common:table.All") : t("common:table.Indirect")}
        </Typography>
      );
      break;
    case "account":
      renderValue = (
        <AccountComponent
          email={value}
          walletAddress={col.subKey && row[col.subKey]}
          username={col.subKey2 && row[col.subKey2]}
          type={col.subKey3 && row[col.subKey3]}
          id={col.subKey4 && row[col.subKey4]}
          account={row}
        />
      );
      break;

    default:
      renderValue = <Typography>{value}</Typography>;
  }

  switch (col.key) {
    case "closeOdrId":
      renderValue = (
        <Typography>{row.closeOdrId || row.liquidatedOdrId}</Typography>
      );
      break;
    case "size":
      renderValue = (
        <Typography>
          {row.filledSize || row.size} | {row.size}
        </Typography>
      );
      break;
    case "futuresSide":
      renderValue = <Typography>{row.size > 0 ? "LONG" : "SHORT"}</Typography>;
      break;
    case "spotSide":
      renderValue = <Typography>{row.side ? "SELL" : "BUY"}</Typography>;
      break;
    case "avgOpenPrice":
      renderValue = (
        <Typography>
          {formatNumberWithDecimal(row.avgOpenPrice, 8)} |{" "}
          {formatNumberWithDecimal(row.markPrice, 8)}
        </Typography>
      );
      break;
      case "filledQuantity":
        renderValue = (
          <Typography>
            {formatNumberWithDecimal(row.filledQuantity, 8)} |{" "}
            {formatNumberWithDecimal(row.orderQuantity, 8)}
          </Typography>
        );
        break;
    case "tradingPair":
      
      renderValue = (
        <Typography>
          {/* {row.base}-{row.quote} */}
          {row.tradingPair.replace('/',' | ').toUpperCase()}
        </Typography>
      );
      break;
    case "mode":
      renderValue = <Typography>{value ? "HEDGE" : "ONE_WAY"}</Typography>;
      break;
    case "marginType":
      renderValue = <Typography>{value ? "ISOLATE" : "CROSS"}</Typography>;
      break;
    case "firstDepositAmount":
      renderValue = (
        <Typography>
          {
            <Typography>
              {value
                ? `${formatNumberWithDecimal(value, 8)} ${
                    row.firstDepositAsset
                  }`
                : "--"}
            </Typography>
          }
        </Typography>
      );
      break;
    case "spotTradingStatus":
      const list = ["NEW", "PARTIALLY_FILLED", "FILLED"];
      // renderValue = <Typography>{list[row.status]}</Typography>;
      renderValue = <Typography>{row.spotTradingStatus}</Typography>;
      break;
  }

  return renderValue;
};
