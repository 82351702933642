import {
  Backdrop,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ClientCloud from "components/client/ClientCloud";
import UserProvider from "contexts/userContext";
import React from "react";
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthRouter from "routes/auth-router";
import Router from "routes/routes";
import { useAppSelector } from "store/hooks";
import "styles/index.scss";
import { WalletConfig } from "utils/constants";
import { WagmiProvider } from "wagmi";
import "./i18n";

function App() {
  const queryClient = new QueryClient();
  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
          fontSize: 12,
        },
        palette: {
          mode: "light",
        },
      }),
    []
  );

  React.useEffect(() => {
    console.log("Version: 1.0.3");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <WagmiProvider config={WalletConfig}>
          <QueryClientProvider client={queryClient}>
            <ClientCloud />
            <CssBaseline />
            <LoadingScreen />
            <ToastContainer />
            <Toaster
              position="top-right"
              containerStyle={{ zIndex: 999999999999 }}
            />
          </QueryClientProvider>
        </WagmiProvider>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;

const LoadingScreen = () => {
  const userStore = useAppSelector((state) => state.user);
  const { information, isLoading } = userStore;
  console.log(information)
  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return <>{information ? <Router /> : <AuthRouter />}</>;
};
