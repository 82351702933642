import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import CopyContent from "components/CopyContent";
import Iconify from "components/Iconify";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import useUser from "hooks/useUser";
import { t } from "i18next";
import LayoutPopover from "layouts/layout-parts/LayoutPopover";
import { Fragment, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setUserReducer } from "store/user/actions";
import { logoutReturnTo } from "utils/auth";
import avatar from 'assets/avatar/AVT.png'
import rankIcon from 'assets/avatar/rank.png'
import copyBtn from 'assets/button/Copy.png'

export const vipColors = [
  "#ABABAB",
  "#e8b381",
  "#6D9ADA",
  "#FEAF52",
  "#7658E5",
  "#61DAEA",
];

const ProfilePopover = () => {
  const anchorRef = useRef(null);
  const user = useAppSelector((state) => state.user);
  const profile = user.information;
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { logout: exchangeLogout } = useUser();

  const handleLogout = () => {
    exchangeLogout(() => { });
    logoutReturnTo();
    dispatch(
      setUserReducer({
        information: null,
      })
    );
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      {/* <Box maxWidth={200} mr={2}>
        <AuthContent
          onOpen={(value: any) => setOpen(value)}
          onClose={() => setOpen(false)}
          anchorRef={anchorRef}
        />
      </Box>
      <LayoutPopover
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        open={open}
        anchorEl={anchorRef.current}
        hoverMode
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        <UserOverview profile={profile} />
        <Divider />
        <Box pt={1}>
          <StyledMenuItem sx={{ width: "100%" }} onClick={() => handleLogout()}>
            <Iconify icon="majesticons:logout-line" size={25} />
            {t("Sign Out")}
          </StyledMenuItem>
        </Box>
      </LayoutPopover> */}
      <UserOverview profile={profile} />

    </Fragment>
  );
};

export default ProfilePopover;

export const AuthContent = ({ onOpen, onClose, anchorRef, size }: any) => {
  const user = useAppSelector((state) => state.user);
  const profile = user.information;

  return (
    <StyledButtonBase
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
      ref={anchorRef}
      sx={{ width: size, height: size }}
    >
      {profile?.avatar ? (
        <Avatar
          sx={{ width: size || 30, height: size || 30 }}
          src={profile?.avatar}
        />
      ) : (
        <Iconify icon="lets-icons:user-box-duotone" size={25} />
      )}{" "}
    </StyledButtonBase>
  );
};

export const UserOverview = ({ profile }: any) => {
  const theme = useTheme();
  const isVerified = profile?.verifyStatus?.toLowerCase() === "verified";
  return (
    <>
      <FlexRowAlign p={2} pb={1} sx={{ alignItems: "center", paddingRight: 0 }}>
        {profile?.avatar ? (
          <Avatar sx={{ width: 40, height: 40 }} src={profile?.avatar} />
        ) : (
          <img src={avatar} alt='avatar' width={57} height={57}/>
        )}
        <Stack px={2} minWidth={300}>
          <FlexRowAlign justifyContent="flex-start" gap={2}>
            <Box>
              <Typography variant="body2" fontWeight={800} color='#191919' >
                {profile?.username}
              </Typography>
              <Typography fontWeight={800}>{profile?.email}</Typography>
            </Box>
          </FlexRowAlign>
          {profile?.id && (
            <FlexRowAlign
              width="fit-content"
              justifyContent="flex-start"
              gap={1}
            >
              <Typography color={"text.secondary"} fontWeight={500}>UID</Typography>
              <CopyContent
                text={`${profile?.accountId}`}
                textSx={{ whitespace: "nowrap" }}
                img={copyBtn}
                hiddenText={true}
              />
            </FlexRowAlign>
          )}
          <Grid display='flex' gap={1}>
            <Chip
              size="small"
              icon={
                isVerified ? (
                  <Iconify
                    size={17}
                    icon={"solar:verified-check-bold-duotone"}
                    sx={{ color: `${theme.palette.success.main} !important` }}
                  />
                ) : (
                  <Iconify
                    icon={"majesticons:close"}
                    size={17}
                    sx={{ color: `white !important` }}
                  />
                )
              }
              label={
                <Typography
                  fontWeight={600}
                  color={"white"}
                >
                  {isVerified ? t("common:dashboard.verify") :  t("common:dashboard.unverify")}
                </Typography>
              }
              // variant="contained"
              sx={{
                padding: "3px 0.5rem 3px 5px",
                background: isVerified ? '#00DDB3' : '#E70000',
                borderRadius: '8px'
              }}
            />
            <Chip
              size="small"
              icon={
                // <Iconify
                //   icon={"solar:medal-star-bold-duotone"}
                //   sx={{ color: `${vipColors[profile?.accountLevel]} !important` }}
                // />
                <img src={rankIcon} alt='rank' width={12}/>
              }
              label={
                <Typography
                  sx={{ color: `white !important` }}
                  fontWeight={600}
                  color='white'
                >
                  VIP {profile?.accountLevel}
                </Typography>
              }
              // variant="contained"
              sx={{
                padding: "3px 0.5rem 3px 5px",
                background: '#00DDB3',
                borderRadius: '8px'
              }}
            />
          </Grid>
        </Stack>
      </FlexRowAlign>
    </>
  );
};

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  borderRadius: 40,
  minHeight: 40,
  minWidth: 40,
  fontWeight: 600,
  "& .iconify": {
    color: theme.palette.text.secondary,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    "& .iconify": {
      color: theme.palette.primary.main,
    },
  },
}));

const StyledLoginButton = styled(Button)(({ theme }) => ({
  borderRadius: 50,
  height: 37,
  fontWeight: 600,
  whiteSpace: "nowrap",
  width: "100%",
}));

const StyledRegisterButton = styled(LoadingButton)(({ theme }) => ({
  padding: "0px 1rem",
  fontWeight: 600,
  whiteSpace: "nowrap",
  height: 35,
  "&:hover": {
    background: theme.palette.action.hover,
  },
}));

const StyledMenuItem = styled(ButtonBase)(({ theme }) => ({
  justifyContent: "flex-start",
  gap: "1rem",
  padding: "0.7rem 1rem",
  borderRadius: "0.4rem",
  fontWeight: 600,
  fontSize: "0.75rem",
  color: theme.palette.text.secondary,
  "& .iconify": {
    color: theme.palette.text.secondary,
  },
  "&:hover, &.active": {
    background: theme.palette.action.hover,
    color: theme.palette.text.primary,
  },
}));
