export const UserEndpointList = {
  Login: `/v1/oauth/token`,
  Partner: {
    Profile: `profile`,
    GetChangeCommissionRequest: `/partner/get-change-commission-request`,
  },
  Dashboard: {
    Balance: `dashboard/balance`,
    BalanceIssuance: `dashboard/balance-issuance`,
    RateCommission: `dashboard/rate-commission`,
    RateCommissionHistory: `dashboard/rate-commission/history`,
    TradeHistory: `dashboard/trade/history`,
    TradeToday: `dashboard/trade/today`,
    TodayHistory: `dashboard/balance-issuance/history`,
  },
  DataPanel: {
    TradeCommission: {
      Overview: `data-panel/trade-commission/overview`,
      Volume: `data-panel/trade-commission/volume`,
      Commission: `data-panel/trade-commission/commission`,
      Traders: `data-panel/trade-commission/traders`,
    },
    RefStatistic: `data-panel/ref-statistic`,
  },
  PartnerManagement: {
    PartnerList: `partner-management/partner-list`,
    PartnerData: `partner-management/partner-data`,
    ChangeSettingCommission: `partner-management/setting-commission`,
    Estimate: `partner-management/estimate-setting-commission`,
  },
  TradeManagement: {
    SpotQuery: `trade-management/spot-query`,
    FuturesQuery: `trade-management/futures-query`,
  },
  UserManagement: {
    UserRef: `user-management/ref-user`,
  },
  CommissionPanel: {
    UserQuery: `commission-panel/user-query`,
    UserQueryDetail: `commission-panel/user-query/detail`,
    UserQueryTop10: `commission-panel/user-query/top10`,
  },
  Branch: {
    Details: `/branch/details`,
    Totals: `/branch/totals`,
    Top10: `/branch/top10-branch-query`,
  },
  InOutManagement: {
    DepositQuery: `/in-out-management/deposit-query`,
    WithdrawalQuery: `/in-out-management/withdraw-query`,
  },
};
