import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { IconX } from "@tabler/icons-react";
import useResponsive from "hooks/useResponsive";
import React from "react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "20px",
  transform: "translate(-50%, -50%)",
  width: { xs: 340, sm: 400 },
  background: "#fff",
  boxShadow: "0px 2px 0px #000",
};

const headStyle = {
  padding: "16px 32px",
  borderRadius: "20px 20px 0 0",
  borderBottom: "1px solid #E5FCF7",
};

type Props = {
  children?: React.ReactNode;
  open: boolean;
  sx?: any;
  title?: string;
  hasClickOver?: boolean;
  hasCloseButton?: boolean;
  disableCloseButton?: boolean;
  handleClose: () => void;
};

export default function CustomModal({
  children,
  open,
  sx,
  title,
  hasClickOver,
  hasCloseButton,
  disableCloseButton,
  handleClose,
}: Props) {
  const onClose = () => {
    if (hasClickOver) {
      handleClose();
    }
  };
  const isMobile = useResponsive("down", "sm");
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          {hasCloseButton ? (
            <IconButton
              sx={{
                position: "absolute",
                top: 8,
                right: 16,
                "& svg": {
                  color: title ? "#ddd" : "black",
                },
              }}
              onClick={handleClose}
              disabled={disableCloseButton}
              size="large"
            >
              <IconX size={20} />
            </IconButton>
          ) : null}
          {title ? (
            <Box sx={headStyle}>
              <Typography
                variant={isMobile ? "body1" : "h6"}
                fontWeight={600}
                color={"#00DDB3"}
                textTransform={"uppercase"}
                textAlign='center'
              >
                {title}
              </Typography>
            </Box>
          ) : null}
          <Box sx={{ p: 4, ...sx }}>{children}</Box>
        </Box>
      </Fade>
    </Modal>
  );
}
