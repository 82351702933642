import { Box, Tabs, Typography } from "@mui/material";
import HigherOrderComponent from "components/HOC/HigherOrderComponent";
import { CustomPaper, SpecialTab } from "components/common/CustomComponents";
import { UserEndpointList } from "constants/endpoints";
import { t } from "i18next";
import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  noPaddingTop?: boolean;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{  py: other.noPaddingTop ? 0 : 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TradeCommission(props: any) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Typography variant="h5" fontWeight={700} mb={2}>
        {/* Trade & Commission */}
        {t('common:dashboard.tradeCommission')}
      </Typography>
      <CustomPaper variant="outlined">
        <Typography variant="h6" fontWeight={700} mb={3}>
          {t('common:table.detail')}
        </Typography>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <SpecialTab
              className="special-first-tab"
              label={t("common:table.overview")}
              {...a11yProps(0)}
            />
            <SpecialTab label={t("common:table.Trading Volume (USDT)")} {...a11yProps(1)} />
            <SpecialTab label={t("common:table.Total Traders")} {...a11yProps(1)} />
            <SpecialTab
              className="special-last-tab"
              label={t("common:table.Commission (USDT)")}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel noPaddingTop={true} value={value} index={0}>
          <Tab1 fisrtElement={true}/>
        </CustomTabPanel>
        <CustomTabPanel noPaddingTop={true} value={value} index={1}>
          <Tab2 />
        </CustomTabPanel>
        <CustomTabPanel noPaddingTop={true} value={value} index={2}>
          <Tab3 />
        </CustomTabPanel>
        <CustomTabPanel noPaddingTop={true} value={value} index={3}>
          <Tab4 />
        </CustomTabPanel>
      </CustomPaper>
    </Box>
  );
}

const Tab1 = (props: any) => {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.DataPanel.TradeCommission.Overview,
    getMethod: 'GET',
    showFields: [
      {
        key: "date",
        label: "Date",
        dataType: "date",
      },
      {
        key: "totalTradingVolumeValue",
        label: "Total Trading Volume Value",
        dataType: "amount",
      },
      {
        key: "totalTraders",
        label: "Total Traders",
        dataType: "amount",
      },
      {
        key: "totalFeeValue",
        label: "Total Fee Value",
        dataType: "amount",
      },
      {
        key: "yourCommission",
        label: "Your Commission",
        dataType: "amount",
      },
    ],
    filterBy: [],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
    ],
    sortAble:['date','totalTradingVolumeValue','totalTraders', 'totalFeeValue'],
    ...props,
  })();
};

const Tab2 = (props: any) => {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.DataPanel.TradeCommission.Volume,
    getMethod: 'GET',
    showFields: [
      {
        key: "date",
        label: "Date",
        dataType: "date",
      },
      {
        key: "totalDirectReferral",
        label: "Direct Referral",
        dataType: "amount",
      },
      {
        key: "totalInDirectReferral",
        label: "Indirect Referral",
        dataType: "amount",
      },
      {
        key: "all",
        label: "Total",
        dataType: "amount",
      },
    ],
    filterBy: [],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
    ],
    sortAble:['date','totalDirectReferral','totalInDirectReferral', 'all'],
    ...props,
  })();
};

const Tab3 = (props: any) => {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.DataPanel.TradeCommission.Traders,
    getMethod: 'GET',
    showFields: [
      {
        key: "date",
        label: "Date",
        dataType: "date",
      },
      {
        key: "all",
        label: "Total Traders",
        dataType: "amount",
      },
      {
        key: "totalDirectReferral",
        label: "Direct Referral",
        dataType: "amount",
      },
      {
        key: "totalInDirectReferral",
        label: "Indirect Referral",
        dataType: "amount",
      },
    ],
    filterBy: [],
    sortAble:['date', 'all','totalDirectReferral','totalInDirectReferral'],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
    ],
    ...props,
  })();
};

const Tab4 = (props: any) => {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.DataPanel.TradeCommission.Commission,
    getMethod: 'GET',
    showFields: [
      {
        key: "date",
        label: "Date",
        dataType: "date",
      },
      {
        key: "totalDirectReferral",
        label: "Direct Referral",
        dataType: "amount",
      },
      {
        key: "totalInDirectReferral",
        label: "Indirect Referral",
        dataType: "amount",
      },
      {
        key: "all",
        label: "Total",
        dataType: "amount",
      },
    ],
    filterBy: [],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
    ],
    ...props,
  })();
};
