import i18next from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: {
      Account: "Account",
      "Email is not valid": "Email is not valid",
      "Please Enter your email": "Please Enter your email",
      "Please Enter your password": "Please Enter your password",
      "Invalid Password": "Invalid Password",
      "Please check your e-mail for the verification code we just sent you and enter that code in the box below":
        "Please check your e-mail for the verification code we just sent you and enter that code in the box below",
      "Please Enter your name": "Please Enter your name",
      Username: "Username",
      Email: "Email",
      Password: "Password",
      "Sign Up": "Sign Up",
      "Terms of Service & Privacy Policy": "Terms of Service & Privacy Policy",
      "By signing up, I agree to our": "By signing up, I agree to our",
      OR: "OR",
      Back: "Back",
      Register: "Register",
      Thank: "Thank",
      "Your account has been created. We'll take you to the login page momentarily":
        "Your account has been created. We'll take you to the login page momentarily",
      "Forgot Password": "Forgot Password",
      "Sign In": "Sign In",
      WRONG_CREDENTIALS: "Wrong credentials",
      Settings: "Settings",
      "Sign Out": "Sign Out",
      Dashboard: "Dashboard",
      "Security Checkup": "Security Checkup",
      "Two-Factor Authentication (2FA)": "Two-Factor Authentication (2FA)",
      "Authentication Settings": "Authentication Settings",
      "Sign in password": "Sign in password",
      "The password that used to log in to your account":
        "The password that used to log in to your account",
      Manager: "Manager",
      "Confirm Password": "Confirm Password",
      "Please enter confirm password": "Please enter confirm password",
      Cancel: "Cancel",
      Save: "Save",
      "Current Password": "Current Password",
      "Change password success": "Change password success",
      PASSWORD_WRONG: "The current password is invalid",
      "Google Authentication (GA 2FA)": "Google Authentication (GA 2FA)",
      "Use Google Authenticator to protect your account and transactions":
        "Use Google Authenticator to protect your account and transactions",
      ON: "ON",
      OFF: "OFF",
      "Enable Google authentication": "Enable Google authentication",
      "Scan with Google Authenticator App on your phone":
        "Scan with Google Authenticator App on your phone",
      "Enter this code in the app": "Enter this code in the app",
      Continue: "Continue",
      "Enter GA code to enable": "Enter GA code to enable",
      "Please Enter your Ga Code": "Please Enter your Ga Code",
      "Ga code": "Ga code",
      "Enable GA Authentication success": "Enable GA Authentication success",
      "Disabled Google Authentication": "Disabled Google Authentication",
      "Enter GA code to disable": "Enter GA code to disable",
      Disable: "Disable",
      "Disable GA Authentication success": "Disable GA Authentication success",
      "Reset password": "Reset password",
      Submit: "Submit",
      "Forgot Password?": "Forgot Password?",
      "Your password has been reset. We'll take you to the login page momentarily":
        "Your password has been reset. We'll take you to the login page momentarily",
      "Please enter GA code in the box below":
        "Please enter GA code in the box below",
      "Don't receive OTP?": "Don't receive OTP?",
      "Resend after": "Resend after",
      "Resend OTP": "Resend OTP",
      "Resend OTP success": "Resend OTP success",
      "Advanced Security": "Advanced Security",
      "Contains at least 8 characters": "Contains at least 8 characters",
      "At least one uppercase": "At least one uppercase",
      "At least one lowercase": "At least one lowercase",
      "At least one number": "At least one number",
      "At least one special character": "At least one special character",
      "Anti-phishing code": "Anti-phishing code",
      "All emails we sends to you will include your anti-phishing code so you know it's from us":
        "All emails we sends to you will include your anti-phishing code so you know it's from us",
      "Set Anti-phishing Code": "Set Anti-phishing Code",
      "What is an anti-phishing code?": "What is an anti-phishing code?",
      "The anti-phishing code is a string of characters that you set yourself, which can help you identify websites and emails that are counterfeit":
        "The anti-phishing code is a string of characters that you set yourself, which can help you identify websites and emails that are counterfeit",
      "Where will the anti-phishing code appear?":
        "Where will the anti-phishing code appear?",
      "When set, this code will be included in emails from Diket. Any other emails without this code are scams":
        "When set, this code will be included in emails from Diket. Any other emails without this code are scams",
      "Please Enter Anti-phishing code": "Please Enter Anti-phishing code",
      "Withdrawal whitelist": "Withdrawal whitelist",
      "When enabled, you'll only be able to withdraw to whitelisted addresses":
        "When enabled, you'll only be able to withdraw to whitelisted addresses",
      Close: "Close",
      "All coin": "All coin",
      "Not found": "Not found",
      "Update success": "Update success",
      "Not verified": "Not verified",
      "Update your name": "Update your name",
      "Update Referral ID": "Update Referral ID",
      "Anti-phishing  protect": "Anti-phishing  protect",
      "Invite Code": "Invite Code",
      "Please Enter invite code": "Please Enter invite code",
      "Update Profile": "Update Profile",
      Enable: "Enable",
      "Enable withdraw whitelist": "Enable withdraw whitelist",
      "Disable withdraw whitelist": "Disable withdraw whitelist",
      "Please enter login password": "Please enter login password",
      "All network": "All network",
      "Add new address": "Add new address",
      Address: "Address",
      "Please Enter address": "Please Enter address",
      Tag: "Tag",
      Note: "Note",
      "Invalid address": "Invalid address",
      "Add address success": "Add address success",
      Delete: "Delete",
      Confirm: "Confirm",
      "Are you sure you want to delete?": "Are you sure you want to delete?",
      "Delete success": "Delete success",
      "Invite code [ Optional ]": "Invite code [ Optional ]",
      "Invited friends": "Invited friends",
      Level: "Level",
      "View team": "View team",
      Funding: "Funding",
      Spot: "Spot",
      Future: "Future",
      "24H Change": "24H Change",
      "24H High": "24H High",
      "24H Low": "24H Low",
      "24H Turnover": "24H Turnover",
      "24H Volume": "24H Volume",
      Price: "Price",
      Amount: "Amount",
      Total: "Total",
      "Order book": "Order book",
      "Market Trades": "Market Trades",
      BUY: "BUY",
      SELL: "SELL",
      Limit: "Limit",
      Market: "Market",
      "TP/SL": "TP/SL",
      Available: "Available",
      "Order price": "Order price",
      Quantity: "Quantity",
      Coin: "Coin",
      "Price must be greater than or equal to":
        "Price must be greater than or equal to",
      "Price must be less than or equal to":
        "Price must be less than or equal to",
      "Quantity must be greater than or equal to":
        "Quantity must be greater than or equal to",
      "Quantity must be less than or equal to":
        "Quantity must be less than or equal to",
      "You have never logged in to your account on this device. To continue logging in, please confirm it is you by entering OTP in the box below and clicking Accept":
        "You have never logged in to your account on this device. To continue logging in, please confirm it is you by entering OTP in the box below and clicking Accept",
      "Trusted device": "Trusted device",
      Accept: "Accept",
      "Type OTP here": "Type OTP here",
      "Min quantity is": "Min quantity is",
      "Mail OTP confirm": "Mail OTP confirm",
      "Request OTP": "Request OTP",
      "Request success": "Request success",
      Balances: "Balances",
      "24h Fluctuations": "24h Fluctuations",
      "Latest price": "Latest price",
      "Order success": "Order success",
      "Open order": "Open order",
      "Order history": "Order history",
      Symbol: "Symbol",
      Side: "Side",
      "Time in Force": "Time in Force",
      Filled: "Filled",
      Type: "Type",
      "Cancel order success": "Cancel order success",
      Date: "Date",
      "Close this order": "Close this order",
      Status: "Status",
      "Market price": "Market price",
      "Trigger price": "Trigger price",
      "Trigger Type": "Trigger Type",
      "Limit price": "Limit price",
      "Trailing Percent": "Trailing Percent",
      Time: "Time",
      "Select interval": "Select interval",
      "Order detail": "Order detail",
      Fee: "Fee",
      "The Total Value Of Assets": "The Total Value Of Assets",
      Deposit: "Deposit",
      Withdraw: "Withdraw",
      Transfer: "Transfer",
      "Assets Overview": "Assets Overview",
      "Spot Account": "Spot Account",
      "Future Account": "Future Account",
      "Select A Coin": "Select A Coin",
      "Choose A Networks": "Choose A Networks",
      Tips: "Tips",
      "Do not deposit assets order than": "Do not deposit assets order than",
      "as doing so may result in the irretrievability of the deposited assets":
        "as doing so may result in the irretrievability of the deposited assets",
      "Deposits below the minimum amount will not be credited and cannot be refunded":
        "Deposits below the minimum amount will not be credited and cannot be refunded",
      "Ensure the security of your computer and browser to prevent information from being tampered with or leaked":
        "Ensure the security of your computer and browser to prevent information from being tampered with or leaked",
      Effect: "Effect",
      "Buy/Long": "Buy/Long",
      Expense: "Expense",
      Maximum: "Maximum",
      "Margin mode": "Margin mode",
      "Under cross margin mode, the full amount of funds in your available balance will be drawn to prevent liquidation. Any realized PnL from other positions can be drawn to add margin to a negative position. Under isolated margin mode, your liability is limited to the initial margin posted. The margin that the position can lose is limited to the initial margin allocated. The position is liquidated when the initial margin is used up. The remaining available margin will not be drawn to cover the loss.":
        "Under cross margin mode, the full amount of funds in your available balance will be drawn to prevent liquidation. Any realized PnL from other positions can be drawn to add margin to a negative position. Under isolated margin mode, your liability is limited to the initial margin posted. The margin that the position can lose is limited to the initial margin allocated. The position is liquidated when the initial margin is used up. The remaining available margin will not be drawn to cover the loss.",
      "Adjust leverage": "Adjust leverage",
      Leverage: "Leverage",
      "Maximum position at current leverage":
        "Maximum position at current leverage",
      "Selecting to trade with a leverage greater than 10x can easily lead to liquidation. Please adjust with caution":
        "Selecting to trade with a leverage greater than 10x can easily lead to liquidation. Please adjust with caution",
      Positions: "Positions",
      "Select futures": "Select futures",
      "Est. liq. price": "Est. liq. price",
      "Mark price": "Mark price",
      "Average price": "Average price",
      Position: "Position",
      "Open orders": "Open orders",
      Margin: "Margin",
      "Unrealized Pnl (ROE)": "Unrealized Pnl (ROE)",
      "Realized Pnl": "Realized Pnl",
      "Limit order": "Limit order",
      "Market order": "Market order",
      Action: "Action",
      "Adjust Leverage success": "Adjust Leverage success",
      "Adjust margin": "Adjust margin",
      "Close Position": "Close Position",
      "Limit Close": "Limit Close",
      "Current price": "Current price",
      "Open price": "Open price",
      "Max close": "Max close",
      "Close price": "Close price",
      "Close quantity": "Close quantity",
      "Close success": "Close success",
      "Market Close": "Market Close",
      "Welcome to Dikex": "Welcome to Dikex",
      "Have an account?": "Have an account?",
      "Enter your email": "Enter your email",
      "Referral code (Optional)": "Referral code (Optional)",
      "Close position": "Close position",
      "Increase margin": "Increase margin",
      "Decrease margin": "Decrease margin",
      "Increase Amount": "Increase Amount",
      Max: "Max",
      "Can increase by": "Can increase by",
      "Est. liq. Price": "Est. liq. Price",
      "Est. liq. Price after increase": "Est. liq. Price after increase",
      "Decrease Amount": "Decrease Amount",
      "Can decrease by": "Can decrease by",
      "Please input amount": "Please input amount",
      "Amount must be greater than": "Amount must be greater than",
      "Amount must be less than": "Amount must be less than",
      "Position history": "Position history",
      Futures: "Futures",
      "Open time": "Open time",
      "Avg. entry price": "Avg. entry price",
      "Avg. exit price": "Avg. exit price",
      "Position PNL": "Position PNL",
      "Close/Liq. time": "Close/Liq. time",
      "Post only": "Post only",
      bullish: "bullish",
      bearish: "bearish",
      "Close amount": "Close amount",
      "Take profit": "Take profit",
      Clear: "Clear",
      "Trailing percent must be greater than 0%":
        "Trailing percent must be greater than 0%",
      "When the {{mode}} reaches": "When the {{mode}} reaches",
      "the position will be closed. Expected return":
        "the position will be closed. Expected return",
      "Last price": "Last price",
      "Stop loss": "Stop loss",
      Trigger: "Trigger",
      "Total margin": "Total margin",
      "Filled Size": "Filled Size",
      "Filled margin": "Filled margin",
      "Est. liq. Price after decrease": "Est. liq. Price after decrease",
      "Take profit success": "Take profit success",
      "Stop loss success": "Stop loss success",
      "Tiered maintenance margin rate": "Tiered maintenance margin rate",
      "I have read and agreed to the Dikex":
        "I have read and agreed to the Dikex",
      "User Agreement": "User Agreement",
      and: "and",
      "Privacy policy": "Privacy policy",
      "Already have an account?": "Already have an account?",
      "You're almost there": "You're almost there",
      "Welcome back!": "Welcome back!",
      "Forgot your password?": "Forgot your password?",
      "Don't worry, If happens to all of us":
        "Don't worry, If happens to all of us",
      "Enter a new password": "Enter a new password",
      "Once you reset your password, any withdrawals and P2P sales will be put on hold for 24 hours.":
        "Once you reset your password, any withdrawals and P2P sales will be put on hold for 24 hours.",
      Asset: "Asset",
      "Total Balance": "Total Balance",
      "Today's PnL": "Today's PnL",
      "Transaction history": "Transaction history",
      Crypto: "Crypto",
      "Hide zero - balance assets": "Hide zero - balance assets",
      Reserved: "Reserved",
      "Available Balance": "Available Balance",
      Actions: "Actions",
      Trade: "Trade",
      "Buy Crypto": "Buy Crypto",
      "USDT-M futures": "USDT-M futures",
      Frozen: "Frozen",
      "Asset Account": "Asset Account",
      "Unrealized PnL": "Unrealized PnL",
      "Estimated assets": "Estimated assets",
      "Trade Smarter": "Trade Smarter",
      With: "With",
      "Sign up now and get a welcome gift of":
        "Sign up now and get a welcome gift of",
      "Email / Phone number": "Email / Phone number",
      "Sign up Now": "Sign up Now",
      "Sign Up for Rewards": "Sign Up for Rewards",
      "Top Gainers": "Top Gainers",
      "New Listings": "New Listings",
      "Catch Your Next Trading Opportunity":
        "Catch Your Next Trading Opportunity",
      "Hot Derivatives": "Hot Derivatives",
      "Hot Coins": "Hot Coins",
      "Search Coins": "Search Coins",
      "Trade Anytime, Anywhere": "Trade Anytime, Anywhere",
      "Open new positions instantly, whether it's on Dikex App or Web.":
        "Open new positions instantly, whether it's on Dikex App or Web.",
      "Buy, Sell, And Trade On The Go": "Buy, Sell, And Trade On The Go",
      "Manage your holdings from your mobile device":
        "Manage your holdings from your mobile device",
      "Take Control Of Your Wealth": "Take Control Of Your Wealth",
      "Rest assured you (and only you) have access to your funds":
        "Rest assured you (and only you) have access to your funds",
      "GET IT ON": "GET IT ON",
      "Download on the": "Download on the",
      "Your Trusted Crypto Exchange": "Your Trusted Crypto Exchange",
      "Let's Trade Now": "Let's Trade Now",
      Fiat: "Fiat",
      "Over 149 fiat currencies supported":
        "Over 149 fiat currencies supported",
      "Futures, spot, Margin, bot....": "Futures, spot, Margin, bot....",
      Earn: "Earn",
      "Boost your crypto profits safely and easily!":
        "Boost your crypto profits safely and easily!",
      "We are the most trusted cryptocurrency platform.":
        "We are the most trusted cryptocurrency platform.",
      Clarity: "Clarity",
      "We help you make sense of the coins, the terms, the dense charts and market changes.":
        "We help you make sense of the coins, the terms, the dense charts and market changes.",
      Confidence: "Confidence",
      "Our markets are always up to date,sparking curiosity with real-world relevance.":
        "Our markets are always up to date,sparking curiosity with real-world relevance.",
      Community: "Community",
      "We supports the crypto community, putting data in the hands which need it most.":
        "We supports the crypto community, putting data in the hands which need it most.",
      "Our Commitment to Compliance and Your Protection":
        "Our Commitment to Compliance and Your Protection",
      "Triple-Layer Asset": "Triple-Layer Asset",
      "Experience Triple Fund Protection with our cold wallet storage, ensuring strong security for user assets through comprehensive protection, for a safe and reliable experience.":
        "Experience Triple Fund Protection with our cold wallet storage, ensuring strong security for user assets through comprehensive protection, for a safe and reliable experience.",
      "User funds are securely stored offline in cold wallets. ":
        "User funds are securely stored offline in cold wallets. ",
      "Privacy Protection": "Privacy Protection",
      "We integrate a privacy-first approach in all our products and services.":
        "We integrate a privacy-first approach in all our products and services.",
      "We are transparent about the data we gather from you, clearly stating how we utilize and share it.":
        "We are transparent about the data we gather from you, clearly stating how we utilize and share it.",
      "Advanced Data Protection": "Advanced Data Protection",
      "Data is encrypted during storage and while in transit. Strict authorization controls are in place to ensure that only you can access your personal and private information.":
        "Data is encrypted during storage and while in transit. Strict authorization controls are in place to ensure that only you can access your personal and private information.",
      "Real-Time Monitoring": "Real-Time Monitoring",
      "Dikex's risk controls actively monitor and analyze user behavior in real-time. Upon detecting suspicious activity, withdrawals undergo enhanced authentication measures for added security.":
        "Dikex's risk controls actively monitor and analyze user behavior in real-time. Upon detecting suspicious activity, withdrawals undergo enhanced authentication measures for added security.",
      "Security by Design": "Security by Design",
      "Our system prioritizes security, featuring a secure development life cycle, thorough security testing, and continuous bug bounty programs.":
        "Our system prioritizes security, featuring a secure development life cycle, thorough security testing, and continuous bug bounty programs.",
      "Need Help?": "Need Help?",
      "24/7 Customer Support": "24/7 Customer Support",
      "Get 24/7 support from our friendly Customer Support agents.":
        "Get 24/7 support from our friendly Customer Support agents.",
      "Chat now": "Chat now",
      FAQs: "FAQs",
      "Explore FAQs for detailed instructions on specific features.":
        "Explore FAQs for detailed instructions on specific features.",
      "Learn more": "Learn more",
      Learn: "Learn",
      "Discover a range of trading guides tailored for beginners and advanced traders alike.":
        "Discover a range of trading guides tailored for beginners and advanced traders alike.",
      "Embark on Your Crypto Journey Today!":
        "Embark on Your Crypto Journey Today!",
      "Discover how specific cryptocurrencies work — and get a bit of each crypto to try out for yourself.":
        "Discover how specific cryptocurrencies work — and get a bit of each crypto to try out for yourself.",
      "Add to Favorites": "Add to Favorites",
      "Add funds": "Add funds",
      "Trade Smarter With Dikex": "Trade Smarter With Dikex",
      "Becoming Street": "Becoming Street",
      "Becoming Street Smarter": "Becoming Street Smarter",
      Trending: "Trending",
      Markets: "Markets",
      Fees: "Fees",
      "Trading pair": "Trading pair",
      Change: "Change",
      "Account Assets": "Account Assets",
      "Total unrealized PnL": "Total unrealized PnL",
      "Trading bonuses": "Trading bonuses",
      "Total realized PnL": "Total realized PnL",
      "Fund leverage": "Fund leverage",
      Used: "Used",
      "Account perks": "Account perks",
      "Current limit": "Current limit",
      "Identity verification": "Identity verification",
      "Level 1 identity verification": "Level 1 identity verification",
      "Crypto deposit": "Crypto deposit",
      "Fiat deposit": "Fiat deposit",
      "P2P trading": "P2P trading",
      "Verification requirements": "Verification requirements",
      "Government-issued documents": "Government-issued documents",
      "Face recognition verification": "Face recognition verification",
      "Estimated review time: 60 min(s)": "Estimated review time: 60 min(s)",
      Verify: "Verify",
      "FREQUENTLY ASKED QUESTIONS": "FREQUENTLY ASKED QUESTIONS",
      "Why do I need to verify my identity?":
        "Why do I need to verify my identity?",
      "Why can't I select my country/region?":
        "Why can't I select my country/region?",
      "What documents can I use to verify my identity?":
        "What documents can I use to verify my identity?",
      More: "More",
      "Update referral": "Update referral",
      "Fill your name": "Fill your name",
      "Verify your document": "Verify your document",
      "We are reviewing your information": "We are reviewing your information",
      Waiting: "Waiting",
      Hi: "Hi",
      "Please complete all verification steps":
        "Please complete all verification steps",
      Processing: "Processing",
      Verified: "Verified",
      "Please select coin and network": "Please select coin and network",
      "Please contact our customer support team if deposit services of the funds you deposited have been temporarily suspended. Once the deposited funds are confirmed on the blockchain, they will be secured and credited to your account as soon as the deposit services resume. You can also use another blockchain to complete the deposit.":
        "Please contact our customer support team if deposit services of the funds you deposited have been temporarily suspended. Once the deposited funds are confirmed on the blockchain, they will be secured and credited to your account as soon as the deposit services resume. You can also use another blockchain to complete the deposit.",
      "How to Deal with Wrong Deposit?": "How to Deal with Wrong Deposit?",
      "How to Deal with Deposit not Credited?":
        "How to Deal with Deposit not Credited?",
      "How to Deposit Cryptocurrency to Dikex Account?":
        "How to Deposit Cryptocurrency to Dikex Account?",
      to: "to",
      "Contract Address": "Contract Address",
      Next: "Next",
      "If your withdrawals have been suspended, please contact our customer support for assistance. We assure you that your funds are secured and should you need immediate access to your fund, you can also submit another withdrawal via other networks.":
        "If your withdrawals have been suspended, please contact our customer support for assistance. We assure you that your funds are secured and should you need immediate access to your fund, you can also submit another withdrawal via other networks.",
      "Account email": "Account email",
      "Enter email": "Enter email",
      "Select email": "Select email",
      "Add Withdrawal Address": "Add Withdrawal Address",
      Normal: "Normal",
      Universal: "Universal",
      "Withdrawal Limit": "Withdrawal Limit",
      "Add withdrawal address": "Add withdrawal address",
      "For the safety of your funds, our customer support team may contact you by phone to confirm your withdrawal request. Please pay attention to incoming calls":
        "For the safety of your funds, our customer support team may contact you by phone to confirm your withdrawal request. Please pay attention to incoming calls",
      "Be aware of scams! Dikex will never ask you for your account, password or any personal information, nor will it request private transfers or withdrawals":
        "Be aware of scams! Dikex will never ask you for your account, password or any personal information, nor will it request private transfers or withdrawals",
      "Withdrawal Limit Adjustment?": "Withdrawal Limit Adjustment?",
      "How to Withdraw Cryptocurrency from Dikex Account?":
        "How to Withdraw Cryptocurrency from Dikex Account?",
      "Withdrawal Limits": "Withdrawal Limits",
      "Single limit": "Single limit",
      "Daily Limit": "Daily Limit",
      "Daily Available": "Daily Available",
      "Monthly Limit": "Monthly Limit",
      "Monthly Available": "Monthly Available",
      "Address, email, uid": "Address, email, uid",
      "Edit notes": "Edit notes",
      "Delete address": "Delete address",
      "Are you sure you want to delete from your address book?":
        "Are you sure you want to delete from your address book?",
      "Funding rate / countdown": "Funding rate / countdown",
      "Dikex provides you with a powerful API, enabling you to access market data, perform trades, transfer assets, and make withdrawals":
        "Dikex provides you with a powerful API, enabling you to access market data, perform trades, transfer assets, and make withdrawals",
      "When you create a new API, you need to set a passphrase to call the API and query the secret key. Make sure to keep your passphrase safe as if you lose it, you will need to create a new API key":
        "When you create a new API, you need to set a passphrase to call the API and query the secret key. Make sure to keep your passphrase safe as if you lose it, you will need to create a new API key",
      "The secret and passphrase are used for private API calls and should never be disclosed":
        "The secret and passphrase are used for private API calls and should never be disclosed",
      "For security reasons, IPs must be bound to API keys. Up to 30 IPs can be bound to each key. If no IP is bound, you may be restricted from accessing certain functions, such as withdrawing assets or publishing and editing P2P ads":
        "For security reasons, IPs must be bound to API keys. Up to 30 IPs can be bound to each key. If no IP is bound, you may be restricted from accessing certain functions, such as withdrawing assets or publishing and editing P2P ads",
      "Address book": "Address book",
      "Create Api key": "Create Api key",
      Create: "Create",
      "Please enter API-Key name": "Please enter API-Key name",
      Name: "Name",
      "Api-key name": "Api-key name",
      "IP, domain, ...": "IP, domain, ...",
      Scopes: "Scopes",
      "Add key success": "Add key success",
      Key: "Key",
      Edit: "Edit",
      "Edit Api key": "Edit Api key",
      "Update key success": "Update key success",
      Update: "Update",
      "Liq. Price": "Liq. Price",
      "Spot trading volume (30D) (USDT)": "Spot trading volume (30D) (USDT)",
      or: "or",
      "Asset balance (USD)": "Asset balance (USD)",
      "Maker fee": "Maker fee",
      "Taker fee": "Taker fee",
      "24h withdraw limit": "24h withdraw limit",
      "Futures trading volume (30D) (USDT)":
        "Futures trading volume (30D) (USDT)",
      "Fees and withdrawal limits": "Fees and withdrawal limits",
      "Dikex VIPs make trades with some of the lowest fees in the market and keep more of their profits":
        "Dikex VIPs make trades with some of the lowest fees in the market and keep more of their profits",
      "Vip Services": "Vip Services",
      "Cancel All": "Cancel All",
      "Are you sure to cancel all order?": "Are you sure to cancel all order?",
      "of total": "of total",
      "Asset allocation": "Asset allocation",
      "Recent Activity": "Recent Activity",
      "In order": "In order",
      History: "History",
      "Funding Rate": "Funding Rate",
      "Spot records": "Spot records",
      "Select symbol": "Select symbol",
      "All Futures": "All Futures",
      "Funding Rate History": "Funding Rate History",
      "Period Time": "Period Time",
      Hours: "Hours",
      From: "From",
      To: "To",
      "Base interest rate": "Base interest rate",
      "Futures records": "Futures records",
      "All Coin": "All Coin",
      "Select coin": "Select coin",
      "Fund History": "Fund History",
      "Select type": "Select type",
      "All Type": "All Type",
      "Edit photo": "Edit photo",
      "Select photo": "Select photo",
      "Use photo": "Use photo",
      "Select another photo": "Select another photo",
      "Update avatar success": "Update avatar success",
      "Do not remind me again": "Do not remind me again",
      "Confirm settings": "Confirm settings",
      "Show Margin Type description": "Show Margin Type description",
      "When enabled, you will see the description each time you open Margin Type popup":
        "When enabled, you will see the description each time you open Margin Type popup",
      "Quantity is too small": "Quantity is too small",
      "Account Id": "Account Id",
      "Created time": "Created time",
      "Key successfully created": "Key successfully created",
      "Your key has been created": "Your key has been created",
      "API key": "API key",
      "Secret key": "Secret key",
      "Make sure you copy secret key below as it won't be shown again":
        "Make sure you copy secret key below as it won't be shown again",
      Page: "Page",
      "Page size": "Page size",
      "Load more": "Load more",
      "Nothing found": "Nothing found",
      Filter: "Filter",
      Direction: "Direction",
      "Order Type": "Order Type",
      Turnover: "Turnover",
      Unfilled: "Unfilled",
      "Avg. filed price": "Avg. filed price",
      Maker: "Maker",
      Taker: "Taker",
      "Tx Hash": "Tx Hash",
      "Transaction fee": "Transaction fee",
      "No data": "No data",
      "Sell/Short": "Sell/Short",
      Home: "Home",
      Favorites: "Favorites",
      Assets: "Assets",
      "Spot Trading": "Spot Trading",
      "Futures Trading": "Futures Trading",
      Chart: "Chart",
      Orderbook: "Orderbook",
      "Filter by date": "Filter by date",
      "Funding rate": "Funding rate",
      "Margin rate": "Margin rate",
      "Liq. price": "Liq. price",
      "Liquidation Clearance": "Liquidation Clearance",
      "Closing PnL": "Closing PnL",
      "Close at": "Close at",
      "Open at": "Open at",
      "Filter by coin": "Filter by coin",
      Network: "Network",
      "Transfer Network": "Transfer Network",
      "Address/Address Tag": "Address/Address Tag",
      Notes: "Notes",
      "Filter by network": "Filter by network",
      Search: "Search",
      "Est. PnL": "Est. PnL",
      "Deposit records": "Deposit records",
      "Withdraw records": "Withdraw records",
      "Continue with Google": "Continue with Google",
      "Already processing. Please wait or confirm on your wallet":
        "Already processing. Please wait or confirm on your wallet",
      "Login to Dikex": "Login to Dikex",
      "Link your Dikex account": "Link your Dikex account",
      "If you already have a Dikex account, you can connect it to your wallet account":
        "If you already have a Dikex account, you can connect it to your wallet account",
      "Link existing Dikex account": "Link existing Dikex account",
      "Your Dikex account (email)": "Your Dikex account (email)",
      "Phone setting": "Phone setting",
      "Please Enter your phone": "Please Enter your phone",
      "Phone number": "Phone number",
      "When enabled, you will allow to receive OTP via phone number":
        "When enabled, you will allow to receive OTP via phone number",
      "Phone OTP": "Phone OTP",
      "Enable phone number": "Enable phone number",
      "OTP will be sent to phone number": "OTP will be sent to phone number",
      "Setup sign in password": "Setup sign in password",
      "This is your first time logging into our system. Please set a login password":
        "This is your first time logging into our system. Please set a login password",
      "Please use this password for subsequent logins":
        "Please use this password for subsequent logins",
      "Confirm and Sign in": "Confirm and Sign in",
      "Change verification method": "Change verification method",
      "Choose verification method": "Choose verification method",
      "Phone number verification": "Phone number verification",
      "Email OTP": "Email OTP",
      "Confirm order": "Confirm order",
      Long: "Long",
      Short: "Short",
      "Address Tag": "Address Tag",
      "Enter The Withdrawal Address Tag": "Enter The Withdrawal Address Tag",
      "Withdraw Address Tag": "Withdraw Address Tag",
      "Please Enter Address Tag": "Please Enter Address Tag",
      "Please Enter Address": "Please Enter Address",
      "Address Is Not Valid": "Address Is Not Valid",
      "Please Enter phone number": "Please Enter phone number",
      "About Dikex": "About Dikex",
      ABOUT_CONTENT:
        "Behind Dikex is a group of early adopters who believe in a blockchain-based future. Since 2018, we have built an exchange committed to helping users trade smarter by providing a secure, one-stop crypto investment solution.",
      "Our Story": "Our Story",
      ABOUT_STORY:
        "Dikex was founded with a steadfast belief in being the last 10% who remain committed even in the face of adversity. With a background in traditional finance, Dikex's founding team discovered blockchain technology in 2015, but it was viewed as 'tulip mania' by the industry back then. In 2018, we became intrigued by cryptocurrency after studying the Bitcoin whitepaper and Ethereum ecosystem. We believed that cryptocurrency would play an important role in the future and even benefit the un banked groups. However, the market crash chased away most speculators and less than 10% of the people chose to stick to their convictions and rebuild. Dikex was then officially founded in the same year. Born in a bear market, Dikex insists on putting users first, focusing on product innovation, and advocating long-term development with the spirit of earnestness. The company aims to inspire people to embrace crypto and improve the way they trade, one at a time.",
      "We believe in a more equitable future world driven by crypto evolution.":
        "We believe in a more equitable future world driven by crypto evolution.",
      Mission: "Mission",
      "We empower the future of finance by ensuring secure, efficient and smart digital transactions.":
        "We empower the future of finance by ensuring secure, efficient and smart digital transactions.",
      "Our Value": "Our Value",
      "Our Team": "Our Team",
      "With 1500+ employees from across 60 countries and regions, the Dikex team boasts professionals from all corners of the world, giving us a truly global presence.":
        "With 1500+ employees from across 60 countries and regions, the Dikex team boasts professionals from all corners of the world, giving us a truly global presence.",
      "Our Services": "Our Services",
      "Trade USDT-M, USDC-M, and Coin-M futures. Go long or short on over 200 coins with high liquidity and low fees.":
        "Trade USDT-M, USDC-M, and Coin-M futures. Go long or short on over 200 coins with high liquidity and low fees.",
      "Build your portfolio from over 750+ promising listed tokens. Join Dikex Launchpad to get early access to crypto gems.":
        "Build your portfolio from over 750+ promising listed tokens. Join Dikex Launchpad to get early access to crypto gems.",
      "Start Your Crypto Journey Now!": "Start Your Crypto Journey Now!",
      "Trade on a platform trusted by millions of users":
        "Trade on a platform trusted by millions of users",
      "Join us": "Join us",
      "Largest crypto copy trading platform":
        "Largest crypto copy trading platform",
      "25 million": "25 million",
      "Registered users worldwide": "Registered users worldwide",
      "10 billion USDT": "10 billion USDT",
      "Daily trading volume": "Daily trading volume",
      "Users First": "Users First",
      "We genuinely care about the voice of each and every user":
        "We genuinely care about the voice of each and every user",
      "Integrity & Honesty": "Integrity & Honesty",
      "We prioritize earning trust by consistently adhering to responsible and ethical standards.":
        "We prioritize earning trust by consistently adhering to responsible and ethical standards.",
      "Open Communication": "Open Communication",
      "We communicate transparently and promptly, sharing all the good, the bad, and the ugly.":
        "We communicate transparently and promptly, sharing all the good, the bad, and the ugly.",
      "Deliver Results": "Deliver Results",
      "Change won't happen itself. We take deliberate actions to make it happen.":
        "Change won't happen itself. We take deliberate actions to make it happen.",
      "Dikex global community": "Dikex global community",
      COMMUNITY_WELCOME:
        "Welcome to the Dikex global community! Here, you can stay updated with the latest platform developments and gain access to professional market insights. Additionally, you can engage in discussions about trading strategies with fellow crypto enthusiasts from around the world. Visit the link below and embark on your crypto journey today!",
      "Business inquiries": "Business inquiries",
      "Quant & MM": "Quant & MM",
      "VIP Services": "VIP Services",
      "Enjoy higher discounts and perks": "Enjoy higher discounts and perks",
      "Fee Schedule": "Fee Schedule",
      "VIP perks": "VIP perks",
      "Withdrawal Fees": "Withdrawal Fees",
      "Trade Now": "Trade Now",
      "Withdrawal Fee": "Withdrawal Fee",
      "Platform fee rate": "Platform fee rate",
      "My fee rate": "My fee rate",
      "Product news": "Product news",
      "Search questions, keywords, articles":
        "Search questions, keywords, articles",
      Announcement: "Announcement",
      "API Trading'": "API Trading'",
      "Download/Register/Log in": "Download/Register/Log in",
      Policies: "Policies",
      "Deposit/Withdraw": "Deposit/Withdraw",
      "KYB Verification Guideline": "KYB Verification Guideline",
      Other: "Other",
      "Video Guide for Beginners": "Video Guide for Beginners",
      Savings: "Savings",
      Support: "Support",
      "Recent activity": "Recent activity",
      "Invite friends to get up to 1,62 USDT in cash":
        "Invite friends to get up to 1,62 USDT in cash",
      "You both can get rewards": "You both can get rewards",
      "Referral code": "Referral code",
      "Referral link": "Referral link",
      Invite: "Invite",
      "Referral history": "Referral history",
      "Terms and Conditions": "Terms and Conditions",
      "Task progress": "Task progress",
      "In the past 7 days": "In the past 7 days",
      "In the past 30 days": "In the past 30 days",
      "In the past 120 days": "In the past 120 days",
      "Privacy Policy": "Privacy Policy",
      "Related articles": "Related articles",
      "Legal Statement": "Legal Statement",
      "Term of Use": "Term of Use",
      "Risk Disclosure": "Risk Disclosure",
      "How can we help?": "How can we help?",
      "Still can't solve your problem?": "Still can't solve your problem?",
      "Our multilingual online customer service helps you solve your problems":
        "Our multilingual online customer service helps you solve your problems",
      Contact: "Contact",
      "Announcement center": "Announcement center",
      "All topics": "All topics",
      "Maintenance or system updates": "Maintenance or system updates",
      "Coin Listings": "Coin Listings",
      Latest: "Latest",
      FAQ: "FAQ",
      "API Announcement": "API Announcement",
      Guideline: "Guideline",
      "Last News": "Last News",
      "Blockchain Assets": "Blockchain Assets",
      "Latest Activities": "Latest Activities",
      "Beginner's guide": "Beginner's guide",
      "Features overview": "Features overview",
      "Risk management": "Risk management",
      "Trading mechanism": "Trading mechanism",
      "Self-Service": "Self-Service",
      "Latest articles": "Latest articles",
      "KYC Submission": "KYC Submission",
      "Reset 2FA": "Reset 2FA",
      "Demo Version": "Demo Version",
      "Submit a Ticket": "Submit a Ticket",
      Company: "Company",
      "About dikex": "About dikex",
      Product: "Product",
      "Referral Program": "Referral Program",
      "Vip Program": "Vip Program",
      "Submit A Request": "Submit A Request",
      "Help Center": "Help Center",
      "Legal & Disclosures": "Legal & Disclosures",
      "Terms of User": "Terms of User",
      "Start Crypto Journey Now!": "Start Crypto Journey Now!",
      Today: "Today",
      "View more": "View more",
      Losers: "Losers",
      New: "New",
      Volume: "Volume",
      Referrals: "Referrals",
      "Affiliate Program": "Affiliate Program",
      "Trade faster and more flexibly with the Dikex API":
        "Trade faster and more flexibly with the Dikex API",
      "Create API keys": "Create API keys",
      "View document": "View document",
      "How to connect to the Dikex API?": "How to connect to the Dikex API?",
      CONNECT_DIKEX_API:
        "The Dikex API allows developers to perform program trading, data acquisition, integration of copy trading services, and ad publishing",
      "Apply/Manage API keys": "Apply/Manage API keys",
      User: "User",
      "Deploy API": "Deploy API",
      "Request/Subscribe": "Request/Subscribe",
      "Trading API": "Trading API",
      "Market WebSocket": "Market WebSocket",
      "Designed for institutional and professional users":
        "Designed for institutional and professional users",
      "Specialized solutions based on user needs":
        "Specialized solutions based on user needs",
      "Contact Us": "Contact Us",
      "Custom-tailored solutions": "Custom-tailored solutions",
      "Better funding rate": "Better funding rate",
      "Lower latency": "Lower latency",
      "Higher trading limit": "Higher trading limit",
      "Contact BD": "Contact BD",
      "Market makers": "Market makers",
      "Quantitative trading": "Quantitative trading",
      "Free standardized API": "Free standardized API",
      "SDK in five programming languages": "SDK in five programming languages",
      "24/7 professional customer service":
        "24/7 professional customer service",
      "Third-party platform": "Third-party platform",
      "Share Dikex's liquidity": "Share Dikex's liquidity",
      "Integrate Dikex copy trading": "Integrate Dikex copy trading",
      "Non-disclosure broker model": "Non-disclosure broker model",
      "1. How to create API keys?": "1. How to create API keys?",
      CREATE_API_KEYS:
        "1.1. Log in to your Dikex account, then go to the API management page in your user center. 1.2 Select Create API key. 1.3 Create your notes and passphrase, configure your permissions settings, then fill in your IP address. Verify your account, then select Next.",
      "2. Is it free to use API?": "2. Is it free to use API?",
      "Using the API is free.": "Using the API is free.",
      "3. What is the difference between WebSocket and REST and which one should I choose?":
        "3. What is the difference between WebSocket and REST and which one should I choose?",
      DIFFERENCE_WEBSOCKET_REST:
        "WebSocket is a better choice if you need real-time market trends and data updates, such as price movements and volume. WebSocket is a message-based protocol that provides more efficient data transfer with lower latency than the REST API. The REST API is a better choice if you only need to occasionally check on market data, such as historical trades and candlestick chart data. It is based on the HTTP protocol and features a wide range of applications. It is also a better choice for developing automated trading programs.",
      "4. Is the API symbol case-sensitive?":
        "4. Is the API symbol case-sensitive?",
      "Yes it is. The symbol must be in uppercase.":
        "Yes it is. The symbol must be in uppercase.",
      "Market API": "Market API",
      "Dikex VIP service": "Dikex VIP service",
      "Dikex provides a full range of exclusive perks to VIP users.":
        "Dikex provides a full range of exclusive perks to VIP users.",
      "Vip News": "Vip News",
      "Vip Trial Pass": "Vip Trial Pass",
      "VIP requirements": "VIP requirements",
      vip_requirement_1:
        "30-day trading volume: The total volume from all main and sub-account trades from 12:00:00 AM to 23:59:59 PM for 30 days. Sub-accounts will have the same funding rate as the main account when created.",
      vip_requirement_2:
        "'Assets: All account assets, including spot, USDC-M Futures, USDT-M Futures, Coin-M Futures, P2P, and Earn assets in main and sub-accounts. Calculated at 12:00 AM (UTC+0) the next day and converted based on the coin-USDT conversion rate at the calculation time. Spot assets include available, locked-up, and frozen assets; futures assets include margined and frozen assets, excluding unrealized PnL; while P2P assets include available assets (frozen assets do not count as 'available').",
      vip_requirement_3:
        "24-hour withdrawal limit: Dikex will set a user's daily withdrawal limit based on their VIP level. All coins will be calculated in USD, and the final amount after conversion must be less than the withdrawal limit of the corresponding VIP level. The withdrawal limit for users who have not completed KYC verification is 20,000 USD.",
      vip_requirement_4:
        "If a user's spot trading volume, futures trading volume, or assets qualify for different VIP levels, the user may enjoy the VIP level of the highest one. If a user meets any of the VIP level requirements of any trading type, all other trading types will be upgraded to that respective VIP level. For example, if a user meets the VIP requirements for spot trading VIP 1, they will be automatically upgraded to futures trading VIP 1 as well.",
      vip_requirement_5:
        "VIP levels will be automatically updated each day at 1:00 AM (UTC+0). If a user's trading behavior triggers the risk control system, Dikex reserves the right to adjust their VIP level.",
      "Institutional users are not eligible for the VIP program.":
        "Institutional users are not eligible for the VIP program.",
      vip_service_contact:
        "Dikex reserves the right of final interpretation for the VIP program. If you have any further questions, contact us at vip@dikex.com.",
      "Exclusive VIP perks": "Exclusive VIP perks",
      "Being a Dikex VIP has never been more rewarding.":
        "Being a Dikex VIP has never been more rewarding.",
      "Hot project watchlist": "Hot project watchlist",
      "Premium VIP customer service": "Premium VIP customer service",
      "Express deposits and withdrawals": "Express deposits and withdrawals",
      "Cutting-edge security": "Cutting-edge security",
      "When it comes to security, we go the extra mile.":
        "When it comes to security, we go the extra mile.",
      "Proof-of-Reserves": "Proof-of-Reserves",
      "We guarantee a minimum 1:1 reserve ratio to cover customer funds":
        "We guarantee a minimum 1:1 reserve ratio to cover customer funds",
      "Cold storage": "Cold storage",
      "Most digital assets are stored in offline multi-signature wallets":
        "Most digital assets are stored in offline multi-signature wallets",
      "Become a VIP today": "Become a VIP today",
      "If you're a VIP on another exchange, we offer you VIP at one level higher.":
        "If you're a VIP on another exchange, we offer you VIP at one level higher.",
      "Do you want to change the current language?":
        "Do you want to change the current language?",
      "Exclusive account manager": "Exclusive account manager",
      "Sign in": "Sign in",
      "to get referral code": "to get referral code",
      "No records yet.": "No records yet.",
      "friends now!": "friends now!",
      "Three Steps to Attractive Commissions with Our Referral Program":
        "Three Steps to Attractive Commissions with Our Referral Program",
      "Join The Dikex Referral program Today!":
        "Join The Dikex Referral program Today!",
      "Generate passive income and work towards financial independence.":
        "Generate passive income and work towards financial independence.",
      "Sign up & get your referral code": "Sign up & get your referral code",
      "Create an account in minutes and start earning commission.":
        "Create an account in minutes and start earning commission.",
      "Share your referral link": "Share your referral link",
      "Invite friends or fans to register as new users using your exclusive referral link or invitation code.":
        "Invite friends or fans to register as new users using your exclusive referral link or invitation code.",
      "Earn 5% on Every Referral Trade": "Earn 5% on Every Referral Trade",
      "Receive a 5% commission on every trade your referrals make-no limits, just continuous earnings!":
        "Receive a 5% commission on every trade your referrals make-no limits, just continuous earnings!",
      "Continue with MetaMask": "Continue with MetaMask",
      "New Here?": "New Here?",
      "Create an account": "Create an account",
      "Order quantity": "Order quantity",
      "Invitation ID": "Invitation ID",
      "Invitation Link": "Invitation Link",
      Referral: "Referral",
      "Total number of addresses": "Total number of addresses",
      "You can add up to 500 on-chain addresses and internal addresses":
        "You can add up to 500 on-chain addresses and internal addresses",
      "On-chain Address": "On-chain Address",
      "Internal Transfer Address": "Internal Transfer Address",
      "After verification": "After verification",
      "Commission records": "Commission records",
      "Withdrawal records": "Withdrawal records",
      "Your name": "Your name",
      "Enter your mail to reset the password":
        "Enter your mail to reset the password",
      "Back to sign in": "Back to sign in",
      Login: "Login",
      Profile: "Profile",
      "Manage Addresses": "Manage Addresses",
      "Identity Verification": "Identity Verification",
      Affiliates: "Affiliates",
      "Futures Account": "Futures Account",
      "Futures Records": "Futures Records",
      "Spot Records": "Spot Records",
      "Deposit Records": "Deposit Records",
      "Withdraw Records": "Withdraw Records",
      "Commission Records": "Commission Records",
      "Select An Account": "Select An Account",
      "Transfer Amount": "Transfer Amount",
      "Transfer out": "Transfer out",
      "Transfer in": "Transfer in",
      "Choose A Network": "Choose A Network",
      "Deposit Address": "Deposit Address",
      "Minimum Deposit Amount": "Minimum Deposit Amount",
      "Transfer To": "Transfer To",
      Wallet: "Wallet",
      "Withdraw To": "Withdraw To",
      "Choose from address book": "Choose from address book",
      "Confirm that the receiving platform accepts the crypto and network you have selected. You may lose your assets if you choose an incompatible network":
        "Confirm that the receiving platform accepts the crypto and network you have selected. You may lose your assets if you choose an incompatible network",
      "On-chain": "On-chain",
      "Internal Transfer": "Internal Transfer",
      "Select Network": "Select Network",
      "Withdraw Address": "Withdraw Address",
      "Transfer records": "Transfer records",
      Reload: "Reload",
      Balance: "Balance",
      Buy: "Buy",
      "Deposit Confirmation": "Deposit Confirmation",
      "Phone Number": "Phone Number",
      "I have read and agree to the above agreement":
        "I have read and agree to the above agreement",
      "Mail OTP": "Mail OTP",
      "Index price": "Index price",
      Success: "Success",
      Source: "Source",
      Order: "Order",
      "Flash close": "Flash close",
      "Full position TP/SL": "Full position TP/SL",
      Batch: "Batch",
      Trailing: "Trailing",
      "Partial position TP/SL": "Partial position TP/SL",
      "Trailing TP/SL": "Trailing TP/SL",
      Details: "Details",
      Lite: "Lite",
      "the position will close {{amount}} at {{price}}. Expected return":
        "the position will close {{amount}} at {{price}}. Expected return",
      "Continue to add": "Continue to add",
      "By order time": "By order time",
      "By trigger price": "By trigger price",
      "Cancel all orders": "Cancel all orders",
      "Execution price": "Execution price",
      "Can not place tp/sl order": "Can not place tp/sl order",
      "Your position is too small to place TP/SL":
        "Your position is too small to place TP/SL",
      "Please input TP price": "Please input TP price",
      "Please input SL price": "Please input SL price",
      "Please input trigger price": "Please input trigger price",
      "TP/SL Market": "TP/SL Market",
      "Callback rate": "Callback rate",
      "Remaining quantity for TP/SL": "Remaining quantity for TP/SL",
      "Trailing Stop": "Trailing Stop",
      "Filled orders only": "Filled orders only",
      "Show current": "Show current",
      "Other options": "Other options",
      "Filter by type": "Filter by type",
      "Filter by futures": "Filter by futures",
      "Trigger MMR": "Trigger MMR",
      "Current MMR": "Current MMR",
      "When MMR ≥ {{mmrTrigger}}%, the position will be closed at market price. The higher the MMR, the higher the risk. Position liquidation/reduction will be triggered when the MMR reaches 100%.":
        "When MMR ≥ {{mmrTrigger}}%, the position will be closed at market price. The higher the MMR, the higher the risk. Position liquidation/reduction will be triggered when the MMR reaches 100%.",
      "Update MMR success": "Update MMR success",
      "Maintenance margin": "Maintenance margin",
      "Maintenance margin rate (MMR)": "Maintenance margin rate (MMR)",
      "Position tier intro": "Position tier intro",
      "TP buy price": "TP buy price",
      "Share profit": "Share profit",
      "Shared at": "Shared at",
      "PnL amount": "PnL amount",
      "Scan QR to sign up to Dikex now.": "Scan QR to sign up to Dikex now.",
      Download: "Download",
      Copy: "Copy",
      "Copy image success": "Copy image success",
      "Full TP/SL": "Full TP/SL",
      "Partial TP/SL": "Partial TP/SL",
      "SL buy price": "SL buy price",
      "SL sell price": "SL sell price",
      "Trading panel": "Trading panel",
      "Can not place TP order": "Can not place TP order",
      "Your position is too small to place TP":
        "Your position is too small to place TP",
      "Can not place SL order": "Can not place SL order",
      "Your position is too small to place SL":
        "Your position is too small to place SL",
      References: "References",
      Standard: "Standard",
      "Pro-Left": "Pro-Left",
      "Pro-Right": "Pro-Right",
      //
      "Candlestick time": "Candlestick time",
      "TP/SL order": "TP/SL order",
      "Trailing stop order": "Trailing stop order",
      "Trailing percent": "Trailing percent",
      "Copy Image": "Copy Image",
      "Sponsor not found": "Sponsor not found",
      "If you continue to register, the system will skip updating the sponsor. You can update later in the settings.":
        "If you continue to register, the system will skip updating the sponsor. You can update later in the settings.",
      "Spot trading notifications": "Spot trading notifications",
      "Spot notification sounds": "Spot notification sounds",
      "Spot notifications": "Spot notifications",
      "Trigger order": "Trigger order",
      "Futures trading notifications": "Futures trading notifications",
      "Futures notification sounds": "Futures notification sounds",
      "Post only order": "Post only order",
      "Futures notifications": "Futures notifications",
      "Vip Upgrade Progress": "Vip Upgrade Progress",
      "Spot Vol": "Spot Vol",
      "Futures Vol": "Futures Vol",
      "Account Assets": "Account Assets",
      Loading: "Loading",
      "Your VIP upgrade progress": "Your VIP upgrade progress",
      "You still need": "You still need",
      "to reach": "to reach",
      "User level": "User level",
      "Fast track application": "Fast track application",
      "Spot fees": "Spot fees",
      "Current tier": "Current tier",
      "Maker/Taker": "Maker/Taker",
      "Futures transaction fees": "Futures transaction fees",
      "Spot order confirmation": "Spot order confirmation",
      "Futures order confirmation": "Futures order confirmation",
      "Help center": "Help center",
      "Futures trading help center": "Futures trading help center",
      "See other questions": "See other questions",
      "Beginner's Guide": "Beginner's Guide",
      "Futures overview": "Futures overview",
      "Risk management": "Risk management",
      "Trading mechanism": "Trading mechanism",
      "Feature guide": "Feature guide",
      "Spot trading help center": "Spot trading help center",
      "Spot guide": "Spot guide",
      Guideline: "Guideline",
      "Blockchain Assets": "Blockchain Assets",
      "Please inter trigger price": "Please inter trigger price",
      "Please check your phone number ({{phone}}) for the verification code we just sent you and enter that code in the box below":
        "Please check your phone number ({{phone}}) for the verification code we just sent you and enter that code in the box below",
      "Since we still need your email, please enter it before registering.":
        "Since we still need your email, please enter it before registering.",
      "Your email": "Your email",
      "Email already exists": "Email already exists",
      "Scan this code with the Dikex app to log in instantly.":
        "Scan this code with the Dikex app to log in instantly.",
      Activities: "Activities",
      "Change password": "Change password",
      "Enable GA": "Enable GA",
      "Disable GA": "Disable GA",
      "Update username": "Update username",
      "Update Anti-fishing code": "Update Anti-fishing code",
      "Enable withdrawal whitelist": "Enable withdrawal whitelist",
      "Disable withdrawal whitelist": "Disable withdrawal whitelist",
      "Disable phone number": "Disable phone number",
      "All type": "All type",
      Device: "Device",
      "Remove device": "Remove device",
      "Login at": "Login at",
      "Device manager": "Device manager",
      Seconds: "Seconds",
      Minutes: "Minutes",
      Hours: "Hours",
      Days: "Days",
      Notifications: "Notifications",
      ALL: "ALL",
      Campaigns: "Campaigns",
      "System messages": "System messages",
      Previous: "Previous",
      "Mark all as read": "Mark all as read",
      "Mark all as read successfully": "Mark all as read successfully",
      "Mark all as read failed": "Mark all as read failed",
      "Show less": "Show less",
      "Show more": "Show more",
      "Alert logs": "Alert logs",
      "Create notice successfully": "Create notice successfully",
      "Create notice failed": "Create notice failed",
      Pair: "Pair",
      "Alert type": "Alert type",
      "Price Rise": "Price Rise",
      "Price 24h Rise Percent": "Price 24h Rise Percent",
      "Price 24h Fall Percent": "Price 24h Fall Percent",
      "Price Rise To Percent": "Price Rise To Percent",
      "Price Fall To Percent": "Price Fall To Percent",
      "Entering Channel": "Entering Channel",
      "Exiting Channel": "Exiting Channel",
      "Price Alert": "Price Alert",
      "Upper Bound": "Upper Bound",
      "Lower Bound": "Lower Bound",
      "The upper bound must be greater than the lower bound":
        "The upper bound must be greater than the lower bound",
      Frequency: "Frequency",
      "Only Once": "Only Once",
      "Once A Day": "Once A Day",
      "Every Time": "Every Time",
      "When the condition reaches the set value, it will raise an alert via the selected method, and will not be received again after one reminder":
        "When the condition reaches the set value, it will raise an alert via the selected method, and will not be received again after one reminder",
      "Remind once a day (between 0:00 and 24:00). It will be triggered when the condition reaches the next day":
        "Remind once a day (between 0:00 and 24:00). It will be triggered when the condition reaches the next day",
      "Remind continuously until cancelled. In order not to disturb you, we set the reminder rules:":
        "Remind continuously until cancelled. In order not to disturb you, we set the reminder rules:",
      "No more than 1 times per second": "No more than 1 times per second",
      "No more than 2 times per minute": "No more than 2 times per minute",
      "No more than 3 times within 1 hour":
        "No more than 3 times within 1 hour",
      "No more than 10 times a day": "No more than 10 times a day",
      "Please select alerts to delete": "Please select alerts to delete",
      "Selected alerts deleted successfully!":
        "Selected alerts deleted successfully!",
      "Failed to delete selected alerts!": "Failed to delete selected alerts!",
      "Update alert status successfully!": "Update alert status successfully!",
      "Update alert status failed!": "Update alert status failed!",
      "Alert monitoring": "Alert monitoring",
      "Bot creation notice": "Bot creation notice",
      //
      Resolutions: "Resolutions",
      Done: "Done",
      "Chart types": "Chart types",
      Bars: "Bars",
      Candles: "Candles",
      "Hollow Candles": "Hollow Candles",
      Line: "Line",
      Area: "Area",
      Baseline: "Baseline",
      Indicators: "Indicators",
      "Search indicator": "Search indicator",
      "Reset chart": "Reset chart",
      "Fullscreen trigger": "Fullscreen trigger",
    },
  },
  vn: {
    translation: {
      Account: "Tài khoản",
      "Email is not valid": "Email không hợp lệ",
      "Please Enter your email": "Vui lòng nhập {{email}} của bạn",
      "Please Enter your password": "Vui lòng nhập {{password}} của bạn",
      "Invalid Password": "Mật khẩu không hợp lệ",
      "Please check your e-mail for the verification code we just sent you and enter that code in the box below":
        "Vui lòng kiểm tra email của bạn để lấy mã xác minh chúng tôi vừa gửi và nhập mã đó vào ô bên dưới",
      "Please Enter your name": "Vui lòng nhập {{name}} của bạn",
      Username: "Tên người dùng",
      Email: "Email",
      Password: "Mật khẩu",
      "Sign Up": "Đăng ký",
      "Terms of Service & Privacy Policy":
        "Điều khoản dịch vụ & Chính sách bảo mật",
      "By signing up, I agree to our":
        "Bằng cách đăng ký, tôi đồng ý với chúng tôi",
      OR: "HOẶC",
      Back: "Quay lại",
      Register: "Đăng ký",
      Thank: "Cảm ơn",
      "Your account has been created. We'll take you to the login page momentarily":
        "Tài khoản của bạn đã được tạo. Chúng tôi sẽ đưa bạn đến trang đăng nhập trong giây lát",
      "Forgot Password": "Quên mật khẩu",
      "Sign In": "Đăng nhập",
      WRONG_CREDENTIALS: "Thông tin không đúng",
      Settings: "Cài đặt",
      "Sign Out": "Đăng xuất",
      Dashboard: "Bảng điều khiển",
      "Security Checkup": "Kiểm tra bảo mật",
      "Two-Factor Authentication (2FA)": "Xác thực hai yếu tố (2FA)",
      "Authentication Settings": "Cài đặt xác thực",
      "Sign in password": "Mật khẩu đăng nhập",
      "The password that used to log in to your account":
        "Mật khẩu dùng để đăng nhập vào tài khoản của bạn",
      Manager: "Quản lý",
      "Confirm Password": "Xác nhận mật khẩu",
      "Please enter confirm password": "Vui lòng nhập lại mật khẩu",
      Cancel: "Hủy",
      Save: "Lưu",
      "Current Password": "Mật khẩu hiện tại",
      "Change password success": "Thay đổi mật khẩu thành công",
      PASSWORD_WRONG: "Mật khẩu hiện tại không hợp lệ",
      "Google Authentication (GA 2FA)": "Xác thực Google (GA 2FA)",
      "Use Google Authenticator to protect your account and transactions":
        "Sử dụng Google Authenticator để bảo vệ tài khoản và giao dịch của bạn",
      ON: "BẬT",
      OFF: "TẮT",
      "Enable Google authentication": "Kích hoạt xác thực Google",
      "Scan with Google Authenticator App on your phone":
        "Quét với ứng dụng Google Authenticator trên điện thoại của bạn",
      "Enter this code in the app": "Nhập mã này vào ứng dụng",
      Continue: "Tiếp tục",
      "Enter GA code to enable": "Nhập mã GA để kích hoạt",
      "Please Enter your Ga Code": "Vui lòng nhập mã GA của bạn",
      "Ga code": "Mã GA",
      "Enable GA Authentication success": "Kích hoạt xác thực GA thành công",
      "Disabled Google Authentication": "Vô hiệu hóa xác thực Google",
      "Enter GA code to disable": "Nhập mã GA để vô hiệu hóa",
      Disable: "Vô hiệu hóa",
      "Disable GA Authentication success": "Vô hiệu hóa xác thực GA thành công",
      "Reset password": "Đặt lại mật khẩu",
      Submit: "Gửi",
      "Forgot Password?": "Quên mật khẩu?",
      "Your password has been reset. We'll take you to the login page momentarily":
        "Mật khẩu của bạn đã được đặt lại. Chúng tôi sẽ đưa bạn đến trang đăng nhập trong giây lát",
      "Please enter GA code in the box below":
        "Vui lòng nhập mã GA vào ô bên dưới",
      "Don't receive OTP?": "Không nhận được OTP?",
      "Resend after": "Gửi lại sau",
      "Resend OTP": "Gửi lại OTP",
      "Resend OTP success": "Gửi lại OTP thành công",
      "Advanced Security": "Bảo mật nâng cao",
      "Contains at least 8 characters": "Ít nhất 8 ký tự",
      "At least one uppercase": "Ít nhất một chữ cái viết hoa",
      "At least one lowercase": "Ít nhất một chữ cái viết thường",
      "At least one number": "Ít nhất một chữ số",
      "At least one special character": "Ít nhất một ký tự đặc biệt",
      "Anti-phishing code": "Mã chống lừa đảo",
      "All emails we sends to you will include your anti-phishing code so you know it's from us":
        "Tất cả email chúng tôi gửi đến bạn sẽ bao gồm mã chống lừa đảo của bạn để bạn biết rằng nó từ chúng tôi",
      "Set Anti-phishing Code": "Thiết lập Mã chống lừa đảo",
      "What is an anti-phishing code?": "Mã chống lừa đảo là gì?",
      "The anti-phishing code is a string of characters that you set yourself, which can help you identify websites and emails that are counterfeit":
        "Mã chống lừa đảo là một chuỗi ký tự mà bạn tự thiết lập, giúp bạn nhận biết các trang web và email là giả mạo",
      "Where will the anti-phishing code appear?":
        "Mã chống lừa đảo sẽ xuất hiện ở đâu?",
      "When set, this code will be included in emails from Diket. Any other emails without this code are scams":
        "Khi được thiết lập, mã này sẽ được bao gồm trong các email từ Diket. Bất kỳ email nào khác không có mã này đều là lừa đảo",
      "Please Enter Anti-phishing code": "Vui lòng nhập mã chống lừa đảo",
      "Withdrawal whitelist": "Danh sách trắng rút tiền",
      "When enabled, you'll only be able to withdraw to whitelisted addresses":
        "Khi kích hoạt, bạn chỉ có thể rút tiền đến các địa chỉ được liệt kê trong danh sách trắng",
      Close: "Đóng",
      "All coin": "Tất cả đồng coin",
      "Not found": "Không tìm thấy",
      "Update success": "Cập nhật thành công",
      "Not verified": "Chưa xác minh",
      "Update your name": "Cập nhật tên của bạn",
      "Update Referral ID": "Cập nhật ID giới thiệu của bạn",
      "Anti-phishing  protect": "Bảo vệ chống lừa đảo",
      "Invite Code": "Mã mời",
      "Please Enter invite code": "Vui lòng nhập mã mời",
      "Update Profile": "Cập nhật Hồ sơ",
      Enable: "Bật",
      "Enable withdraw whitelist": "Bật danh sách trắng rút tiền",
      "Disable withdraw whitelist": "Tắt danh sách trắng rút tiền",
      "Please enter login password": "Vui lòng nhập mật khẩu đăng nhập",
      "All network": "Tất cả mạng",
      "Add new address": "Thêm địa chỉ mới",
      Address: "Địa chỉ",
      "Please Enter address": "Vui lòng nhập địa chỉ",
      Tag: "Thẻ",
      Note: "Ghi chú",
      "Invalid address": "Địa chỉ không hợp lệ",
      "Add address success": "Thêm địa chỉ thành công",
      Delete: "Xóa",
      Confirm: "Xác nhận",
      "Are you sure you want to delete?": "Bạn có chắc muốn xóa?",
      "Delete success": "Xóa thành công",
      "Invite code [ Optional ]": "Mã mời [Tùy chọn]",
      "Invited friends": "Bạn bè đã mời",
      Level: "Cấp độ",
      "View team": "Xem nhóm",
      Funding: "Quỹ",
      Spot: "Giao ngay",
      Future: "Tương lai",
      "24H Change": "Thay đổi 24 giờ",
      "24H High": "Cao trong 24 giờ",
      "24H Low": "Thấp trong 24 giờ",
      "24H Turnover": "Doanh số trong 24 giờ",
      "24H Volume": "Khối lượng trong 24 giờ",
      Price: "Giá",
      Amount: "Số lượng",
      Total: "Tổng cộng",
      "Order book": "Sổ lệnh",
      "Market Trades": "Giao dịch thị trường",
      BUY: "MUA",
      SELL: "BÁN",
      Limit: "Giới hạn",
      Market: "Thị trường",
      "TP/SL": "Lợi nhuận/Cắt lỗ",
      Available: "Sẵn có",
      "Order price": "Giá đặt lệnh",
      Quantity: "Số lượng",
      Coin: "Đồng Coin",
      "Price must be greater than or equal to": "Giá phải lớn hơn hoặc bằng",
      "Price must be less than or equal to": "Giá phải nhỏ hơn hoặc bằng",
      "Quantity must be greater than or equal to":
        "Số lượng phải lớn hơn hoặc bằng",
      "Quantity must be less than or equal to":
        "Số lượng phải nhỏ hơn hoặc bằng",
      "You have never logged in to your account on this device. To continue logging in, please confirm it is you by entering OTP in the box below and clicking Accept":
        "Bạn chưa bao giờ đăng nhập vào tài khoản của mình trên thiết bị này. Để tiếp tục đăng nhập, vui lòng xác nhận đó là bạn bằng cách nhập OTP vào ô bên dưới và nhấp vào Chấp nhận",
      "Trusted device": "Thiết bị đáng tin cậy",
      Accept: "Chấp nhận",
      "Type OTP here": "Nhập OTP ở đây",
      "Min quantity is": "Số lượng tối thiểu là",
      "Mail OTP confirm": "Xác nhận OTP qua Email",
      "Request OTP": "Yêu cầu OTP",
      "Request success": "Yêu cầu thành công",
      Balances: "Số dư",
      "24h Fluctuations": "Biến động trong 24 giờ",
      "Latest price": "Giá mới nhất",
      "Order success": "Đặt lệnh thành công",
      "Open order": "Lệnh mở",
      "Order history": "Lịch sử đặt lệnh",
      Symbol: "Biểu tượng",
      Side: "Bên",
      "Time in Force": "Thời gian hiệu lực",
      Filled: "Đã khớp",
      Type: "Loại",
      "Cancel order success": "Hủy lệnh thành công",
      Date: "Ngày",
      "Close this order": "Đóng lệnh này",
      Status: "Trạng thái",
      "Market price": "Giá thị trường",
      "Trigger price": "Giá kích hoạt",
      "Trigger Type": "Loại kích hoạt",
      "Limit price": "Giá giới hạn",
      "Trailing Percent": "Phần trăm theo dõi",
      Time: "Thời gian",
      "Select interval": "Chọn khoảng thời gian",
      "Order detail": "Chi tiết đặt lệnh",
      Fee: "Phí",
      "The Total Value Of Assets": "Tổng giá trị tài sản",
      Deposit: "Nạp tiền",
      Withdraw: "Rút tiền",
      Transfer: "Chuyển tiền",
      "Assets Overview": "Tổng Quan Tài Sản",
      "Spot Account": "Tài Khoản Giao Ngay",
      "Future Account": "Tài Khoản Hợp Đồng Tương Lai",
      "Select A Coin": "Chọn Một Đồng Coin",
      "Choose A Networks": "Chọn Một Mạng Lưới",
      Tips: "Mẹo",
      "Do not deposit assets order than": "Không nạp tài sản khác ngoài",
      "as doing so may result in the irretrievability of the deposited assets":
        "vì làm như vậy có thể dẫn đến việc tài sản đã nạp không thể thu hồi",
      "Deposits below the minimum amount will not be credited and cannot be refunded":
        "Các khoản nạp dưới số lượng tối thiểu sẽ không được ghi có và không thể hoàn lại",
      "Ensure the security of your computer and browser to prevent information from being tampered with or leaked":
        "Đảm bảo an ninh cho máy tính và trình duyệt của bạn để ngăn chặn thông tin bị giả mạo hoặc rò rỉ",
      Effect: "Hiệu lực",
      "Buy/Long": "Mua/Long",
      Expense: "Chi phí",
      Maximum: "Tối đa",
      "Margin mode": "Chế độ ký quỹ",
      "Under cross margin mode, the full amount of funds in your available balance will be drawn to prevent liquidation. Any realized PnL from other positions can be drawn to add margin to a negative position. Under isolated margin mode, your liability is limited to the initial margin posted. The margin that the position can lose is limited to the initial margin allocated. The position is liquidated when the initial margin is used up. The remaining available margin will not be drawn to cover the loss.":
        "Dưới chế độ ký quỹ chéo, toàn bộ số tiền trong số dư khả dụng của bạn sẽ được sử dụng để ngăn chặn thanh lý. Bất kỳ lãi lỗ đã thực hiện từ các vị thế khác có thể được sử dụng để thêm ký quỹ cho vị thế âm. Dưới chế độ ký quỹ riêng, trách nhiệm của bạn được giới hạn ở mức ký quỹ ban đầu đã đăng. Ký quỹ mà vị thế có thể mất được giới hạn ở mức ký quỹ ban đầu đã phân bổ. Vị thế sẽ bị thanh lý khi mức ký quỹ ban đầu được sử dụng hết. Số ký quỹ khả dụng còn lại sẽ không được sử dụng để bù đắp khoản lỗ.",
      "Adjust leverage": "Điều chỉnh đòn bẩy",
      Leverage: "Đòn bẩy",
      "Maximum position at current leverage":
        "Vị thế tối đa ở mức đòn bẩy hiện tại",
      "Selecting to trade with a leverage greater than 10x can easily lead to liquidation. Please adjust with caution":
        "Việc chọn giao dịch với đòn bẩy lớn hơn 10x có thể dễ dàng dẫn đến thanh lý. Vui lòng điều chỉnh cẩn thận",
      Positions: "Vị thế",
      "Select futures": "Chọn hợp đồng tương lai",
      "Est. liq. price": "Giá ước tính thanh lý",
      "Mark price": "Giá đánh dấu",
      "Average price": "Giá trung bình",
      Position: "Vị thế",
      "Open orders": "Lệnh mở",
      Margin: "Ký quỹ",
      "Unrealized Pnl (ROE)": "Lãi lỗ chưa thực hiện (ROE)",
      "Realized Pnl": "Lãi lỗ đã thực hiện",
      "Limit order": "Lệnh giới hạn",
      "Market order": "Lệnh thị trường",
      Action: "Hành động",
      "Adjust Leverage success": "Điều chỉnh đòn bẩy thành công",
      "Adjust margin": "Điều chỉnh ký quỹ",
      "Close Position": "Đóng vị thế",
      "Limit Close": "Đóng giới hạn",
      "Current price": "Giá hiện tại",
      "Open price": "Giá mở",
      "Max close": "Đóng tối đa",
      "Close price": "Giá đóng",
      "Close quantity": "Số lượng đóng",
      "Close success": "Đóng thành công",
      "Market Close": "Đóng thị trường",
      "Welcome to Dikex": "Chào mừng đến với Dikex",
      "Have an account?": "Có tài khoản?",
      "Enter your email": "Nhập email của bạn",
      "Referral code (Optional)": "Mã giới thiệu (Tùy chọn)",
      "Close position": "Đóng vị thế",
      "Increase margin": "Tăng ký quỹ",
      "Decrease margin": "Giảm ký quỹ",
      "Increase Amount": "Tăng số lượng",
      Max: "Tối đa",
      "Can increase by": "Có thể tăng lên",
      "Est. liq. Price": "Giá ước tính thanh lý",
      "Est. liq. Price after increase": "Giá ước tính thanh lý sau khi tăng",
      "Decrease Amount": "Giảm số lượng",
      "Can decrease by": "Có thể giảm xuống",
      "Please input amount": "Vui lòng nhập số lượng",
      "Amount must be greater than": "Số lượng phải lớn hơn",
      "Amount must be less than": "Số lượng phải nhỏ hơn",
      "Position history": "Lịch sử vị thế",
      Futures: "Hợp đồng tương lai",
      "Open time": "Thời gian mở",
      "Avg. entry price": "Giá vào trung bình",
      "Avg. exit price": "Giá ra trung bình",
      "Position PNL": "Lãi lỗ vị thế",
      "Close/Liq. time": "Thời gian đóng/thanh lý",
      "Post only": "Chỉ đăng",
      bullish: "tăng giá",
      bearish: "giảm giá",
      "Close amount": "Số lượng đóng",
      "Take profit": "Chốt lời",
      Clear: "Xóa",
      "Trailing percent must be greater than 0%":
        "Tỷ lệ kéo theo phải lớn hơn 0%",
      "When the {{mode}} reaches": "Khi {{mode}} đạt đến",
      "the position will be closed. Expected return":
        "vị thế sẽ được đóng. Lợi nhuận dự kiến",
      "Last price": "Giá cuối cùng",
      "Stop loss": "Cắt lỗ",
      Trigger: "Kích hoạt",
      "Total margin": "Tổng ký quỹ",
      "Filled Size": "Kích thước đã điền",
      "Filled margin": "Ký quỹ đã điền",
      "Est. liq. Price after decrease": "Giá ước tính thanh lý sau khi giảm",
      "Take profit success": "Chốt lời thành công",
      "Stop loss success": "Cắt lỗ thành công",
      "Tiered maintenance margin rate": "Tỷ lệ ký quỹ bảo dưỡng phân tầng",
      "I have read and agreed to the Dikex": "Tôi đã đọc và đồng ý với Dikex",
      "User Agreement": "Thỏa Thuận Người Dùng",
      and: "và",
      "Privacy policy": "Chính Sách Bảo Mật",
      "Already have an account?": "Đã có tài khoản?",
      "You're almost there": "Bạn gần như đã xong",
      "Welcome back!": "Chào mừng trở lại!",
      "Forgot your password?": "Quên mật khẩu?",
      "Don't worry, If happens to all of us":
        "Đừng lo lắng, Điều này xảy ra với tất cả chúng ta",
      "Enter a new password": "Nhập mật khẩu mới",
      "Once you reset your password, any withdrawals and P2P sales will be put on hold for 24 hours.":
        "Khi bạn đặt lại mật khẩu, mọi giao dịch rút tiền và bán P2P sẽ bị tạm dừng trong 24 giờ.",
      Asset: "Tài sản",
      "Total Balance": "Tổng số dư",
      "Today's PnL": "Lãi lỗ hôm nay",
      "Transaction history": "Lịch sử giao dịch",
      Crypto: "Tiền mã hóa",
      "Hide zero - balance assets": "Ẩn tài sản có số dư bằng không",
      Reserved: "Đã đặt",
      "Available Balance": "Số dư khả dụng",
      Actions: "Hành động",
      Trade: "Giao dịch",
      "Buy Crypto": "Mua Tiền mã hóa",
      "USDT-M futures": "Hợp đồng tương lai USDT-M",
      Frozen: "Đã đóng băng",
      "Asset Account": "Tài khoản tài sản",
      "Unrealized PnL": "Lãi lỗ chưa thực hiện",
      "Estimated assets": "Tài sản ước tính",
      "Trade Smarter": "Giao Dịch Thông Minh Hơn",
      With: "Với",
      "Sign up now and get a welcome gift of":
        "Đăng ký ngay và nhận quà chào mừng trị giá",
      "Email / Phone number": "Email / Số điện thoại",
      "Sign up Now": "Đăng Ký Ngay",
      "Sign Up for Rewards": "Đăng Ký Để Nhận Phần Thưởng",
      "Top Gainers": "Những Đồng Coin Tăng Nhiều Nhất",
      "New Listings": "Niêm Yết Mới",
      "Catch Your Next Trading Opportunity":
        "Bắt Kịp Cơ Hội Giao Dịch Tiếp Theo Của Bạn",
      "Hot Derivatives": "Các Sản Phẩm Phái Sinh Nóng",
      "Hot Coins": "Các Đồng Coin Nóng",
      "Search Coins": "Tìm Kiếm Đồng Coin",
      "Trade Anytime, Anywhere": "Giao Dịch Mọi Lúc, Mọi Nơi",
      "Open new positions instantly, whether it's on Dikex App or Web.":
        "Mở các vị thế mới ngay lập tức, cho dù đó là trên Ứng Dụng Dikex hoặc Trang Web.",
      "Buy, Sell, And Trade On The Go": "Mua, Bán Và Giao Dịch Mọi Lúc",
      "Manage your holdings from your mobile device":
        "Quản lý tài sản của bạn từ thiết bị di động",
      "Take Control Of Your Wealth": "Kiểm Soát Tài Sản Của Bạn",
      "Rest assured you (and only you) have access to your funds":
        "Hãy yên tâm rằng bạn (và chỉ bạn) có quyền truy cập vào quỹ của mình",
      "GET IT ON": "TẢI VỀ",
      "Download on the": "Tải về trên",
      "Your Trusted Crypto Exchange":
        "Sàn Giao Dịch Tiền Mã Hóa Đáng Tin Cậy Của Bạn",
      "Let's Trade Now": "Hãy Giao Dịch Ngay",
      Fiat: "Tiền pháp định",
      "Over 149 fiat currencies supported":
        "Hỗ trợ hơn 149 loại tiền pháp định",
      "Futures, spot, Margin, bot....":
        "Hợp đồng tương lai, giao ngay, Ký quỹ, bot....",
      Earn: "Kiếm tiền",
      "Boost your crypto profits safely and easily!":
        "Tăng lợi nhuận tiền mã hóa của bạn một cách an toàn và dễ dàng!",
      "We are the most trusted cryptocurrency platform.":
        "Chúng tôi là nền tảng tiền mã hóa đáng tin cậy nhất.",
      Clarity: "Rõ ràng",
      "We help you make sense of the coins, the terms, the dense charts and market changes.":
        "Chúng tôi giúp bạn hiểu rõ về các đồng tiền, thuật ngữ, biểu đồ phức tạp và thay đổi thị trường.",
      Confidence: "Tự tin",
      "Our markets are always up to date, sparking curiosity with real-world relevance.":
        "Thị trường của chúng tôi luôn được cập nhật, khơi dậy sự tò mò với sự liên quan đến thực tế.",
      Community: "Cộng đồng",
      "We supports the crypto community, putting data in the hands which need it most.":
        "Chúng tôi hỗ trợ cộng đồng tiền mã hóa, đặt dữ liệu vào tay những người cần nhất.",
      "Our Commitment to Compliance and Your Protection":
        "Cam kết tuân thủ và bảo vệ của chúng tôi",
      "Triple-Layer Asset": "Tài sản Ba Lớp",
      "Experience Triple Fund Protection with our cold wallet storage, ensuring strong security for user assets through comprehensive protection, for a safe and reliable experience.":
        "Trải nghiệm Bảo vệ Tài sản Ba Lớp với lưu trữ ví lạnh của chúng tôi, đảm bảo an ninh mạnh mẽ cho tài sản người dùng thông qua bảo vệ toàn diện, mang lại trải nghiệm an toàn và đáng tin cậy.",
      "User funds are securely stored offline in cold wallets.":
        "Quỹ của người dùng được lưu trữ ngoại tuyến an toàn trong ví lạnh.",
      "Privacy Protection": "Bảo vệ Quyền riêng tư",
      "We integrate a privacy-first approach in all our products and services.":
        "Chúng tôi tích hợp phương pháp ưu tiên quyền riêng tư trong tất cả sản phẩm và dịch vụ của mình.",
      "We are transparent about the data we gather from you, clearly stating how we utilize and share it.":
        "Chúng tôi minh bạch về dữ liệu chúng tôi thu thập từ bạn, nêu rõ cách chúng tôi sử dụng và chia sẻ nó.",
      "Advanced Data Protection": "Bảo vệ Dữ liệu Nâng cao",
      "Data is encrypted during storage and while in transit. Strict authorization controls are in place to ensure that only you can access your personal and private information.":
        "Dữ liệu được mã hóa trong quá trình lưu trữ và truyền tải. Các biện pháp kiểm soát ủy quyền nghiêm ngặt được thực hiện để đảm bảo chỉ bạn có thể truy cập thông tin cá nhân và riêng tư của mình.",
      "Real-Time Monitoring": "Giám sát Thời gian Thực",
      "Dikex's risk controls actively monitor and analyze user behavior in real-time. Upon detecting suspicious activity, withdrawals undergo enhanced authentication measures for added security.":
        "Các biện pháp kiểm soát rủi ro của Dikex tích cực giám sát và phân tích hành vi người dùng trong thời gian thực. Khi phát hiện hoạt động đáng ngờ, việc rút tiền sẽ trải qua các biện pháp xác thực tăng cường để tăng cường bảo mật.",
      "Security by Design": "Bảo mật theo Thiết kế",
      "Our system prioritizes security, featuring a secure development life cycle, thorough security testing, and continuous bug bounty programs.":
        "Hệ thống của chúng tôi ưu tiên bảo mật, bao gồm một vòng đời phát triển bảo mật, kiểm tra bảo mật kỹ lưỡng và các chương trình săn lỗi liên tục.",
      "Need Help?": "Cần Giúp đỡ?",
      "24/7 Customer Support": "Hỗ trợ Khách hàng 24/7",
      "Get 24/7 support from our friendly Customer Support agents.":
        "Nhận hỗ trợ 24/7 từ các nhân viên Hỗ trợ Khách hàng thân thiện của chúng tôi.",
      "Chat now": "Trò chuyện ngay",
      FAQs: "Câu hỏi Thường gặp",
      "Explore FAQs for detailed instructions on specific features.":
        "Khám phá Câu hỏi Thường gặp để có hướng dẫn chi tiết về các tính năng cụ thể.",
      "Learn more": "Tìm hiểu thêm",
      Learn: "Học hỏi",
      "Discover a range of trading guides tailored for beginners and advanced traders alike.":
        "Khám phá một loạt các hướng dẫn giao dịch được thiết kế riêng cho cả người mới bắt đầu và nhà giao dịch nâng cao.",
      "Embark on Your Crypto Journey Today!":
        "Khởi đầu Hành trình Tiền mã hóa của bạn Hôm nay!",
      "Discover how specific cryptocurrencies work — and get a bit of each crypto to try out for yourself.":
        "Khám phá cách các loại tiền mã hóa cụ thể hoạt động — và nhận một chút mỗi loại tiền mã hóa để tự mình thử nghiệm.",
      "Add to Favorites": "Thêm vào Yêu thích",
      "Add funds": "Thêm tiền",
      "Trade Smarter With Dikex": "Giao dịch Thông minh hơn với Dikex",
      "Becoming Street": "Trở thành Phố",
      "Becoming Street Smarter": "Trở thành Phố Thông minh hơn",
      Trending: "Xu hướng",
      Markets: "Thị trường",
      Fees: "Phí",
      "Trading pair": "Cặp giao dịch",
      Change: "Thay đổi",
      "Account Assets": "Tài sản Tài khoản",
      "Total unrealized PnL": "Tổng lãi/lỗ chưa thực hiện",
      "Trading bonuses": "Tiền thưởng giao dịch",
      "Total realized PnL": "Tổng lãi/lỗ đã thực hiện",
      "Fund leverage": "Đòn bẩy Quỹ",
      Used: "Đã sử dụng",
      "Account perks": "Quyền lợi Tài khoản",
      "Current limit": "Hạn mức hiện tại",
      "Identity verification": "Xác minh danh tính",
      "Level 1 identity verification": "Xác minh danh tính Cấp 1",
      "Crypto deposit": "Nạp tiền mã hóa",
      "Fiat deposit": "Nạp tiền pháp định",
      "P2P trading": "Giao dịch P2P",
      "Verification requirements": "Yêu cầu xác minh",
      "Government-issued documents": "Tài liệu do chính phủ cấp",
      "Face recognition verification": "Xác minh nhận dạng khuôn mặt",
      "Estimated review time: 60 min(s)": "Thời gian xem xét ước tính: 60 phút",
      Verify: "Xác minh",
      "FREQUENTLY ASKED QUESTIONS": "CÂU HỎI THƯỜNG GẶP",
      "Why do I need to verify my identity?":
        "Tại sao tôi cần xác minh danh tính của mình?",
      "Why can't I select my country/region?":
        "Tại sao tôi không thể chọn quốc gia/khu vực của mình?",
      "What documents can I use to verify my identity?":
        "Tôi có thể sử dụng tài liệu nào để xác minh danh tính?",
      More: "Thêm",
      "Update referral": "Cập nhật giới thiệu",
      "Fill your name": "Điền tên của bạn",
      "Verify your document": "Xác minh tài liệu của bạn",
      "We are reviewing your information":
        "Chúng tôi đang xem xét thông tin của bạn",
      Waiting: "Đang chờ",
      Hi: "Xin chào",
      "Please complete all verification steps":
        "Vui lòng hoàn thành tất cả các bước xác minh",
      Processing: "Đang xử lý",
      Verified: "Đã xác minh",
      "Please select coin and network": "Vui lòng chọn đồng tiền và mạng lưới",
      "Please contact our customer support team if deposit services of the funds you deposited have been temporarily suspended. Once the deposited funds are confirmed on the blockchain, they will be secured and credited to your account as soon as the deposit services resume. You can also use another blockchain to complete the deposit.":
        "Vui lòng liên hệ với đội ngũ hỗ trợ khách hàng của chúng tôi nếu dịch vụ nạp tiền của bạn tạm thời bị đình chỉ. Khi các khoản tiền nạp được xác nhận trên blockchain, chúng sẽ được bảo đảm và ghi có vào tài khoản của bạn ngay khi dịch vụ nạp tiền hoạt động trở lại. Bạn cũng có thể sử dụng một blockchain khác để hoàn tất việc nạp tiền.",
      "How to Deal with Wrong Deposit?":
        "Làm thế nào để xử lý khi nạp tiền sai?",
      "How to Deal with Deposit not Credited?":
        "Làm thế nào để xử lý khi nạp tiền không được ghi có?",
      "How to Deposit Cryptocurrency to Dikex Account?":
        "Làm thế nào để nạp tiền mã hóa vào tài khoản Dikex?",
      to: "đến",
      "Contract Address": "Địa chỉ Hợp đồng",
      Next: "Tiếp theo",
      "If your withdrawals have been suspended, please contact our customer support for assistance. We assure you that your funds are secured and should you need immediate access to your fund, you can also submit another withdrawal via other networks.":
        "Nếu việc rút tiền của bạn bị đình chỉ, vui lòng liên hệ với đội ngũ hỗ trợ khách hàng của chúng tôi để được trợ giúp. Chúng tôi đảm bảo rằng quỹ của bạn được bảo đảm và nếu bạn cần truy cập ngay lập tức vào quỹ của mình, bạn cũng có thể nộp một yêu cầu rút tiền khác thông qua các mạng lưới khác.",
      "Account email": "Email tài khoản",
      "Enter email": "Nhập email",
      "Select email": "Chọn email",
      "Add Withdrawal Address": "Thêm Địa chỉ Rút tiền",
      Normal: "Bình thường",
      Universal: "Toàn cầu",
      "Withdrawal Limit": "Hạn mức Rút tiền",
      "Add withdrawal address": "Thêm địa chỉ rút tiền",
      "For the safety of your funds, our customer support team may contact you by phone to confirm your withdrawal request. Please pay attention to incoming calls":
        "Vì sự an toàn của quỹ của bạn, đội ngũ hỗ trợ khách hàng của chúng tôi có thể liên hệ với bạn qua điện thoại để xác nhận yêu cầu rút tiền của bạn. Vui lòng chú ý đến các cuộc gọi đến",
      "Be aware of scams! Dikex will never ask you for your account, password or any personal information, nor will it request private transfers or withdrawals":
        "Cảnh giác với các trò lừa đảo! Dikex sẽ không bao giờ yêu cầu bạn cung cấp tài khoản, mật khẩu hoặc bất kỳ thông tin cá nhân nào, cũng như không yêu cầu chuyển khoản hoặc rút tiền riêng tư",
      "Withdrawal Limit Adjustment?": "Điều chỉnh Hạn mức Rút tiền?",
      "How to Withdraw Cryptocurrency from Dikex Account?":
        "Làm thế nào để Rút tiền mã hóa từ tài khoản Dikex?",
      "Withdrawal Limits": "Hạn mức Rút tiền",
      "Single limit": "Hạn mức đơn lẻ",
      "Daily Limit": "Hạn mức Hàng ngày",
      "Daily Available": "Còn hàng ngày",
      "Monthly Limit": "Giới hạn hàng tháng",
      "Monthly Available": "Còn hàng tháng",
      "Address, email, uid": "Địa chỉ, email, uid",
      "Edit notes": "Chỉnh sửa ghi chú",
      "Delete address": "Xóa địa chỉ",
      "Are you sure you want to delete from your address book?":
        "Bạn có chắc muốn xóa khỏi sổ địa chỉ của bạn không?",
      "Funding rate / countdown": "Tỷ lệ tài trợ / đếm ngược",
      "Dikex provides you with a powerful API, enabling you to access market data, perform trades, transfer assets, and make withdrawals":
        "Dikex cung cấp cho bạn một API mạnh mẽ, cho phép bạn truy cập dữ liệu thị trường, thực hiện giao dịch, chuyển tài sản và thực hiện rút tiền",
      "When you create a new API, you need to set a passphrase to call the API and query the secret key. Make sure to keep your passphrase safe as if you lose it, you will need to create a new API key":
        "Khi bạn tạo một API mới, bạn cần thiết lập một cụm từ mật khẩu để gọi API và truy vấn khóa bí mật. Đảm bảo giữ cụm từ mật khẩu của bạn an toàn vì nếu bạn mất nó, bạn sẽ cần tạo một khóa API mới",
      "The secret and passphrase are used for private API calls and should never be disclosed":
        "Khóa bí mật và cụm từ mật khẩu được sử dụng cho các cuộc gọi API riêng tư và không bao giờ được tiết lộ",
      "For security reasons, IPs must be bound to API keys. Up to 30 IPs can be bound to each key. If no IP is bound, you may be restricted from accessing certain functions, such as withdrawing assets or publishing and editing P2P ads":
        "Vì lý do bảo mật, các địa chỉ IP phải được ràng buộc với các khóa API. Đến 30 IP có thể được ràng buộc với mỗi khóa. Nếu không có IP nào được ràng buộc, bạn có thể bị hạn chế truy cập vào một số chức năng nhất định, chẳng hạn như rút tiền hoặc xuất bản và chỉnh sửa quảng cáo P2P",
      "Address book": "Sổ địa chỉ",
      "Create Api key": "Tạo khóa Api",
      Create: "Tạo",
      "Please enter API-Key name": "Vui lòng nhập tên Khóa API",
      Name: "Tên",
      "Api-key name": "Tên khóa Api",
      "IP, domain, ...": "IP, miền, ...",
      Scopes: "Phạm vi",
      "Add key success": "Thêm khóa thành công",
      Key: "Khóa",
      Edit: "Chỉnh sửa",
      "Edit Api key": "Chỉnh sửa khóa Api",
      "Update key success": "Cập nhật khóa thành công",
      Update: "Cập nhật",
      "Liq. Price": "Giá thanh khoản",
      "Spot trading volume (30D) (USDT)":
        "Khối lượng giao dịch Spot (30D) (USDT)",
      or: "hoặc",
      "Asset balance (USD)": "Số dư tài sản (USD)",
      "Maker fee": "Phí Maker",
      "Taker fee": "Phí Taker",
      "24h withdraw limit": "Giới hạn rút trong 24 giờ",
      "Futures trading volume (30D) (USDT)":
        "Khối lượng giao dịch tương lai (30D) (USDT)",
      "Fees and withdrawal limits": "Phí và giới hạn rút",
      "Dikex VIPs make trades with some of the lowest fees in the market and keep more of their profits":
        "VIP của Dikex thực hiện giao dịch với một số phí thấp nhất trên thị trường và giữ lại nhiều lợi nhuận hơn",
      "Vip Services": "Dịch vụ VIP",
      "Cancel All": "Hủy tất cả",
      "Are you sure to cancel all order?":
        "Bạn có chắc muốn hủy tất cả các đơn hàng không?",
      "of total": "tổng cộng",
      "Asset allocation": "Phân bổ tài sản",
      "Recent Activity": "Hoạt động gần đây",
      "In order": "Đang được đặt",
      History: "Lịch sử",
      "Funding Rate": "Tỷ lệ tài trợ",
      "Spot records": "Hồ sơ Spot",
      "Select symbol": "Chọn biểu tượng",
      "All Futures": "Tất cả Tương lai",
      "Funding Rate History": "Lịch sử Tỷ lệ tài trợ",
      "Period Time": "Thời gian Kỳ",
      Hours: "Giờ",
      From: "Từ",
      To: "Đến",
      "Base interest rate": "Lãi suất cơ bản",
      "Futures records": "Hồ sơ Tương lai",
      "All Coin": "Tất cả Coin",
      "Select coin": "Chọn coin",
      "Fund History": "Lịch sử Quỹ",
      "Select type": "Chọn loại",
      "All Type": "Tất cả Loại",
      "Edit photo": "Chỉnh sửa ảnh",
      "Select photo": "Chọn ảnh",
      "Use photo": "Sử dụng ảnh",
      "Select another photo": "Chọn ảnh khác",
      "Update avatar success": "Cập nhật ảnh đại diện thành công",
      "Do not remind me again": "Đừng nhắc tôi lại",
      "Confirm settings": "Xác nhận cài đặt",
      "Show Margin Type description": "Hiển thị mô tả Loại Ký quỹ",
      "When enabled, you will see the description each time you open Margin Type popup":
        "Khi được bật, bạn sẽ thấy mô tả mỗi khi mở hộp thoại Loại Ký quỹ",
      "Quantity is too small": "Số lượng quá nhỏ",
      "Account Id": "ID Tài khoản",
      "Created time": "Thời gian tạo",
      "Key successfully created": "Khóa được tạo thành công",
      "Your key has been created": "Khóa của bạn đã được tạo",
      "API key": "Khóa API",
      "Secret key": "Khóa bí mật",
      "Make sure you copy secret key below as it won't be shown again":
        "Đảm bảo bạn sao chép khóa bí mật ở dưới vì nó sẽ không được hiển thị lại",
      Page: "Trang",
      "Page size": "Kích thước trang",
      "Load more": "Tải thêm",
      "Nothing found": "Không tìm thấy gì",
      Filter: "Bộ lọc",
      Direction: "Hướng",
      "Order Type": "Loại Đơn hàng",
      Turnover: "Doanh số",
      Unfilled: "Chưa hoàn thành",
      "Avg. filed price": "Giá trung bình đã gửi",
      Maker: "Tạo lệnh",
      Taker: "Khớp lệnh",
      "Tx Hash": "Băm Giao dịch",
      "Transaction fee": "Phí giao dịch",
      "No data": "Không có dữ liệu",
      "Sell/Short": "Bán/Bán ngắn",
      Home: "Trang chủ",
      Favorites: "Yêu thích",
      Assets: "Tài sản",
      "Spot Trading": "Giao dịch Spot",
      "Futures Trading": "Giao dịch Tương lai",
      Chart: "Biểu đồ",
      Orderbook: "Sổ đặt hàng",
      "Filter by date": "Lọc theo ngày",
      "Funding rate": "Tỷ lệ tài trợ",
      "Margin rate": "Tỷ lệ Ký quỹ",
      "Liq. price": "Giá thanh khoản",
      "Liquidation Clearance": "Thanh lý vượt mức",
      "Closing PnL": "Lãi/lỗ đóng",
      "Close at": "Đóng tại",
      "Open at": "Mở tại",
      "Filter by coin": "Lọc theo coin",
      Network: "Mạng lưới",
      "Transfer Network": "Mạng lưới chuyển",
      "Address/Address Tag": "Địa chỉ/Thẻ Địa chỉ",
      Notes: "Ghi chú",
      "Filter by network": "Lọc theo mạng",
      Search: "Tìm kiếm",
      "Est. PnL": "Lợi nhuận ước tính",
      "Deposit records": "Ghi chép gửi tiền",
      "Withdraw records": "Ghi chép rút tiền",
      "Continue with Google": "Tiếp tục với Google",
      "Already processing. Please wait or confirm on your wallet":
        "Đã xử lý. Vui lòng đợi hoặc xác nhận trên ví của bạn",
      "Login to Dikex": "Đăng nhập vào Dikex",
      "Link your Dikex account": "Liên kết tài khoản Dikex của bạn",
      "If you already have a Dikex account, you can connect it to your wallet account":
        "Nếu bạn đã có một tài khoản Dikex, bạn có thể kết nối nó với tài khoản ví của bạn",
      "Link existing Dikex account": "Liên kết tài khoản Dikex hiện tại",
      "Your Dikex account (email)": "Tài khoản Dikex của bạn (email)",
      "Phone setting": "Cài đặt điện thoại",
      "Please Enter your phone": "Vui lòng nhập số điện thoại của bạn",
      "Phone number": "Số điện thoại",
      "When enabled, you will allow to receive OTP via phone number":
        "Khi được bật, bạn sẽ cho phép nhận OTP qua số điện thoại",
      "Phone OTP": "OTP điện thoại",
      "Enable phone number": "Kích hoạt số điện thoại",
      "OTP will be sent to phone number": "OTP sẽ được gửi đến số điện thoại",
      "Setup sign in password": "Thiết lập mật khẩu đăng nhập",
      "This is your first time logging into our system. Please set a login password":
        "Đây là lần đầu tiên bạn đăng nhập vào hệ thống của chúng tôi. Vui lòng đặt mật khẩu đăng nhập",
      "Please use this password for subsequent logins":
        "Vui lòng sử dụng mật khẩu này cho các lần đăng nhập sau",
      "Confirm and Sign in": "Xác nhận và Đăng nhập",
      "Change verification method": "Thay đổi phương thức xác minh",
      "Choose verification method": "Chọn phương thức xác minh",
      "Phone number verification": "Xác minh số điện thoại",
      "Email OTP": "OTP email",
      "Confirm order": "Xác nhận đơn hàng",
      Long: "Mua vào",
      Short: "Bán ra",
      "Address Tag": "Thẻ địa chỉ",
      "Enter The Withdrawal Address Tag": "Nhập Thẻ Địa chỉ Rút tiền",
      "Withdraw Address Tag": "Thẻ Địa chỉ Rút tiền",
      "Please Enter Address Tag": "Vui lòng nhập Thẻ Địa chỉ",
      "Please Enter Address": "Vui lòng nhập Địa chỉ",
      "Address Is Not Valid": "Địa chỉ Không Hợp lệ",
      "Please Enter phone number": "Vui lòng nhập số điện thoại",
      "About Dikex": "Về Dikex",
      ABOUT_CONTENT:
        "Đằng sau Dikex là một nhóm người sớm nhận bản thân, tin tưởng vào một tương lai dựa trên blockchain. Từ năm 2018, chúng tôi đã xây dựng một sàn giao dịch cam kết giúp người dùng giao dịch một cách thông minh hơn bằng cách cung cấp một giải pháp đầu tư tiền điện tử an toàn, một điểm dừng duy nhất.",
      "Our Story": "Câu chuyện của chúng tôi",
      ABOUT_STORY:
        "Dikex được thành lập với niềm tin vững chắc vào việc trở thành 10% cuối cùng vẫn kiên trì ngay cả khi đối mặt với khó khăn. Với một nền tảng tài chính truyền thống, nhóm sáng lập của Dikex đã khám phá công nghệ blockchain vào năm 2015, nhưng vào thời điểm đó, nó được xem là 'điên cuồng tiền hoa' bởi ngành công nghiệp. Vào năm 2018, chúng tôi bắt đầu quan tâm đến tiền điện tử sau khi nghiên cứu Bản di chúc Bitcoin và hệ sinh thái Ethereum. Chúng tôi tin rằng tiền điện tử sẽ đóng một vai trò quan trọng trong tương lai và thậm chí có lợi cho các nhóm không có tài khoản ngân hàng. Tuy nhiên, sự suy giảm của thị trường đã đuổi đi hầu hết các nhà đầu tư và ít hơn 10% người đã chọn kiên trì với niềm tin của họ và tái thiết. Dikex sau đó được thành lập chính thức vào cùng năm đó. Sinh ra trong thị trường gấu, Dikex khẳng định luôn đặt người dùng lên hàng đầu, tập trung vào đổi mới sản phẩm và tán thành phát triển dài hạn với tinh thần chân thành. Công ty nhắm mục tiêu truyền cảm hứng cho mọi người tiếp nhận tiền điện tử và cải thiện cách họ giao dịch, một người một lần.",
      "We believe in a more equitable future world driven by crypto evolution.":
        "Chúng tôi tin vào một thế giới tương lai công bằng hơn được thúc đẩy bởi sự tiến hóa của tiền điện tử.",
      Mission: "Nhiệm vụ",
      "We empower the future of finance by ensuring secure, efficient and smart digital transactions.":
        "Chúng tôi thúc đẩy tương lai của ngành tài chính bằng cách đảm bảo giao dịch kỹ thuật số an toàn, hiệu quả và thông minh.",
      "Our Value": "Giá trị của chúng tôi",
      "Our Team": "Đội ngũ của chúng tôi",
      "With 1500+ employees from across 60 countries and regions, the Dikex team boasts professionals from all corners of the world, giving us a truly global presence.":
        "Với hơn 1500 nhân viên từ hơn 60 quốc gia và khu vực, đội ngũ Dikex tự hào có các chuyên gia từ mọi nơi trên thế giới, mang lại cho chúng tôi sự hiện diện toàn cầu thực sự.",
      "Our Services": "Dịch vụ của chúng tôi",
      "Trade USDT-M, USDC-M, and Coin-M futures. Go long or short on over 200 coins with high liquidity and low fees.":
        "Giao dịch tương lai USDT-M, USDC-M và Coin-M. Mua vào hoặc bán ra trên hơn 200 đồng tiền với thanh khoản cao và phí thấp.",
      "Build your portfolio from over 750+ promising listed tokens. Join Dikex Launchpad to get early access to crypto gems.":
        "Xây dựng danh mục từ hơn 750+ mã thông báo được liệt kê triển vọng. Tham gia Dikex Launchpad để có quyền truy cập sớm vào những viên ngọc tiền điện tử.",
      "Start Your Crypto Journey Now!":
        "Bắt đầu Hành trình Tiền điện tử của Bạn Ngay Bây Giờ!",
      "Trade on a platform trusted by millions of users":
        "Giao dịch trên một nền tảng được hàng triệu người dùng tin cậy",
      "Join us": "Tham gia cùng chúng tôi",
      "Largest crypto copy trading platform":
        "Nền tảng giao dịch sao chép tiền điện tử lớn nhất",
      "25 million": "25 triệu",
      "Registered users worldwide": "Người dùng đã đăng ký trên toàn thế giới",
      "10 billion USDT": "10 tỷ USDT",
      "Daily trading volume": "Khối lượng giao dịch hàng ngày",
      "Users First": "Người dùng đầu tiên",
      "We genuinely care about the voice of each and every user":
        "Chúng tôi thực sự quan tâm đến ý kiến của từng người dùng",
      "Integrity & Honesty": "Chính trực & Trung thực",
      "We prioritize earning trust by consistently adhering to responsible and ethical standards.":
        "Chúng tôi ưu tiên kiếm được sự tin cậy bằng cách tuân thủ một cách nhất quán các tiêu chuẩn có trách nhiệm và đạo đức.",
      "Open Communication": "Giao tiếp mở cửa",
      "We communicate transparently and promptly, sharing all the good, the bad, and the ugly.":
        "Chúng tôi giao tiếp một cách minh bạch và nhanh chóng, chia sẻ tất cả những điều tốt đẹp, xấu xí và xấu xa.",
      "Deliver Results": "Đạt được Kết quả",
      "Change won't happen itself. We take deliberate actions to make it happen.":
        "Sự thay đổi sẽ không xảy ra tự nhiên. Chúng tôi thực hiện các hành động cố ý để thực hiện nó.",
      "Dikex global community": "Cộng đồng toàn cầu của Dikex",
      COMMUNITY_WELCOME:
        "Chào mừng đến với cộng đồng toàn cầu của Dikex! Ở đây, bạn có thể cập nhật những phát triển mới nhất của nền tảng và tiếp cận thông tin thị trường chuyên nghiệp. Ngoài ra, bạn cũng có thể tham gia vào các cuộc thảo luận về chiến lược giao dịch với các người yêu tiền điện tử từ khắp nơi trên thế giới. Ghé thăm liên kết dưới đây và bắt đầu hành trình tiền điện tử của bạn ngay hôm nay!",
      "Business inquiries": "Yêu cầu kinh doanh",
      "Quant & MM": "Quant & MM",
      "VIP Services": "Dịch vụ VIP",
      "Enjoy higher discounts and perks":
        "Tận hưởng ưu đãi và phúc lợi cao hơn",
      "Fee Schedule": "Lịch trình phí",
      "VIP perks": "Phúc lợi VIP",
      "Withdrawal Fees": "Phí rút tiền",
      "Trade Now": "Giao dịch Ngay",
      "Withdrawal Fee": "Phí rút tiền",
      "Platform fee rate": "Tỷ lệ phí của nền tảng",
      "My fee rate": "Tỷ lệ phí của tôi",
      "Product news": "Tin tức sản phẩm",
      "Search questions, keywords, articles":
        "Tìm kiếm câu hỏi, từ khóa, bài viết",
      Announcement: "Thông báo",
      "API Trading'": "Giao dịch API",
      "Download/Register/Log in": "Tải về/Đăng ký/Đăng nhập",
      Policies: "Chính sách",
      "Deposit/Withdraw": "Nạp tiền/Rút tiền",
      "KYB Verification Guideline": "Hướng dẫn Xác minh KYB",
      Other: "Khác",
      "Video Guide for Beginners": "Hướng dẫn video cho người mới bắt đầu",
      Savings: "Tiết kiệm",
      Support: "Hỗ trợ",
      "Recent activity": "Hoạt động gần đây",
      "Invite friends to get up to 1,62 USDT in cash":
        "Mời bạn bè để nhận lên đến 1,62 USDT tiền mặt",
      "You both can get rewards": "Cả hai bạn đều có thể nhận phần thưởng",
      "Referral code": "Mã giới thiệu",
      "Referral link": "Liên kết giới thiệu",
      Invite: "Mời",
      "Referral history": "Lịch sử giới thiệu",
      "Terms and Conditions": "Điều khoản và Điều kiện",
      "Task progress": "Tiến độ công việc",
      "In the past 7 days": "Trong 7 ngày qua",
      "In the past 30 days": "Trong 30 ngày qua",
      "In the past 120 days": "Trong 120 ngày qua",
      "Privacy Policy": "Chính sách bảo mật",
      "Related articles": "Bài viết liên quan",
      "Legal Statement": "Tuyên bố pháp lý",
      "Term of Use": "Điều khoản sử dụng",
      "Risk Disclosure": "Thông báo rủi ro",
      "How can we help?": "Chúng tôi có thể giúp gì cho bạn?",
      "Still can't solve your problem?":
        "Vẫn không thể giải quyết vấn đề của bạn?",
      "Our multilingual online customer service helps you solve your problems":
        "Dịch vụ khách hàng trực tuyến đa ngôn ngữ của chúng tôi sẽ giúp bạn giải quyết vấn đề",
      Contact: "Liên hệ",
      "Announcement center": "Trung tâm thông báo",
      "All topics": "Tất cả chủ đề",
      "Maintenance or system updates": "Bảo trì hoặc cập nhật hệ thống",
      "Coin Listings": "Danh sách đồng tiền",
      Latest: "Mới nhất",
      FAQ: "Câu hỏi thường gặp",
      "API Announcement": "Thông báo API",
      Guideline: "Hướng dẫn",
      "Last News": "Tin tức cuối cùng",
      "Blockchain Assets": "Tài sản Blockchain",
      "Latest Activities": "Hoạt động mới nhất",
      "Beginner's guide": "Hướng dẫn cho người mới bắt đầu",
      "Features overview": "Tổng quan tính năng",
      "Risk management": "Quản lý rủi ro",
      "Trading mechanism": "Cơ chế giao dịch",
      "Self-Service": "Tự phục vụ",
      "Latest articles": "Bài viết mới nhất",
      "KYC Submission": "Nộp KYC",
      "Reset 2FA": "Đặt lại 2FA",
      "Demo Version": "Phiên bản Demo",
      "Submit a Ticket": "Gửi yêu cầu",
      Company: "Công ty",
      "About dikex": "Về dikex",
      Product: "Sản phẩm",
      "Referral Program": "Chương trình giới thiệu",
      "Vip Program": "Chương trình Vip",
      "Submit A Request": "Gửi yêu cầu",
      "Help Center": "Trung tâm trợ giúp",
      "Legal & Disclosures": "Pháp lý & Tiết lộ",
      "Terms of User": "Điều khoản người dùng",
      "Start Crypto Journey Now!": "Bắt đầu Hành trình Crypto ngay bây giờ!",
      Today: "Hôm nay",
      "View more": "Xem thêm",
      Losers: "Những người thua cuộc",
      New: "Mới",
      Volume: "Khối lượng",
      Referrals: "Giới thiệu",
      "Affiliate Program": "Chương trình liên kết",
      "Trade faster and more flexibly with the Dikex API":
        "Giao dịch nhanh hơn và linh hoạt hơn với API Dikex",
      "Create API keys": "Tạo khóa API",
      "View document": "Xem tài liệu",
      "How to connect to the Dikex API?":
        "Làm thế nào để kết nối với API Dikex?",
      CONNECT_DIKEX_API:
        "API Dikex cho phép các nhà phát triển thực hiện giao dịch theo chương trình, thu thập dữ liệu, tích hợp dịch vụ giao dịch sao chép và xuất bản quảng cáo",
      "Apply/Manage API keys": "Áp dụng/Quản lý khóa API",
      User: "Người dùng",
      "Deploy API": "Triển khai API",
      "Request/Subscribe": "Yêu cầu/Đăng ký",
      "Trading API": "Giao dịch API",
      "Market WebSocket": "Thị trường WebSocket",
      "Designed for institutional and professional users":
        "Thiết kế cho người dùng tổ chức và chuyên nghiệp",
      "Specialized solutions based on user needs":
        "Giải pháp chuyên biệt dựa trên nhu cầu của người dùng",
      "Contact Us": "Liên hệ chúng tôi",
      "Custom-tailored solutions": "Giải pháp được tùy chỉnh",
      "Better funding rate": "Tỷ lệ tài trợ tốt hơn",
      "Lower latency": "Độ trễ thấp hơn",
      "Higher trading limit": "Giới hạn giao dịch cao hơn",
      "Contact BD": "Liên hệ BD",
      "Market makers": "Người làm thị trường",
      "Quantitative trading": "Giao dịch định lượng",
      "Free standardized API": "API tiêu chuẩn miễn phí",
      "SDK in five programming languages": "SDK trong năm ngôn ngữ lập trình",
      "24/7 professional customer service":
        "Dịch vụ khách hàng chuyên nghiệp 24/7",
      "Third-party platform": "Nền tảng bên thứ ba",
      "Share Dikex's liquidity": "Chia sẻ thanh khoản của Dikex",
      "Integrate Dikex copy trading": "Tích hợp giao dịch sao chép của Dikex",
      "Non-disclosure broker model": "Mô hình môi giới không tiết lộ thông tin",
      "1. How to create API keys?": "1. Làm thế nào để tạo khóa API?",
      CREATE_API_KEYS:
        "1.1. Đăng nhập vào tài khoản Dikex của bạn, sau đó đi đến trang quản lý API trong trung tâm người dùng của bạn. 1.2 Chọn Tạo khóa API. 1.3 Tạo ghi chú và mật khẩu của bạn, cấu hình cài đặt quyền của bạn, sau đó điền vào địa chỉ IP của bạn. Xác minh tài khoản của bạn, sau đó chọn Tiếp theo.",
      "2. Is it free to use API?": "2. Có miễn phí sử dụng API không?",
      "Using the API is free.": "Sử dụng API là miễn phí.",
      "3. What is the difference between WebSocket and REST and which one should I choose?":
        "3. Sự khác biệt giữa WebSocket và REST là gì và tôi nên chọn cái nào?",
      DIFFERENCE_WEBSOCKET_REST:
        "WebSocket là sự lựa chọn tốt hơn nếu bạn cần các xu hướng thị trường thời gian thực và cập nhật dữ liệu, chẳng hạn như di chuyển giá và khối lượng. WebSocket là một giao thức dựa trên tin nhắn cung cấp chuyển dữ liệu hiệu quả hơn với độ trễ thấp hơn so với REST API. REST API là sự lựa chọn tốt hơn nếu bạn chỉ cần kiểm tra dữ liệu thị trường đôi khi, chẳng hạn như giao dịch lịch sử và dữ liệu biểu đồ nến. Nó dựa trên giao thức HTTP và có một loạt các ứng dụng. Nó cũng là sự lựa chọn tốt hơn cho việc phát triển các chương trình giao dịch tự động.",
      "4. Is the API symbol case-sensitive?":
        "4. API có phân biệt chữ hoa chữ thường không?",
      "Yes it is. The symbol must be in uppercase.":
        "Có, nó phải phân biệt chữ hoa chữ thường. Biểu tượng phải là chữ hoa.",
      "Market API": "API thị trường",
      "Dikex VIP service": "Dịch vụ VIP của Dikex",
      "Dikex provides a full range of exclusive perks to VIP users.":
        "Dikex cung cấp một loạt các ưu đãi độc quyền cho người dùng VIP.",
      "Vip News": "Tin tức VIP",
      "Vip Trial Pass": "Thẻ VIP dùng thử",
      "VIP requirements": "Yêu cầu VIP",
      vip_requirement_1:
        "Khối lượng giao dịch trong 30 ngày: Tổng khối lượng từ tất cả các giao dịch tài khoản chính và tài khoản phụ từ 12:00:00 AM đến 23:59:59 PM trong 30 ngày. Các tài khoản phụ sẽ có cùng tỷ lệ tài trợ với tài khoản chính khi được tạo ra.",
      vip_requirement_2:
        "'Tài sản: Tất cả các tài sản tài khoản, bao gồm tài sản lưu ký, tương lai USDC-M, tương lai USDT-M, tương lai Coin-M, P2P và tài sản Earn trong tài khoản chính và tài khoản phụ. Tính vào lúc 12:00 AM (UTC+0) ngày hôm sau và chuyển đổi dựa trên tỷ giá quy đổi coin-USDT vào thời điểm tính toán. Tài sản lưu ký bao gồm tài sản có sẵn, tài sản bị khóa và tài sản đóng băng; tài sản tương lai bao gồm tài sản ký quỹ và tài sản đóng băng, không bao gồm Lợi nhuận/Lỗ chưa thực hiện; trong khi tài sản P2P bao gồm tài sản có sẵn (tài sản đóng băng không tính là 'có sẵn').",
      vip_requirement_3:
        "Giới hạn rút tiền trong 24 giờ: Dikex sẽ thiết lập giới hạn rút tiền hàng ngày của người dùng dựa trên cấp độ VIP của họ. Tất cả các đồng coin sẽ được tính toán bằng USD, và số tiền cuối cùng sau khi quy đổi phải nhỏ hơn giới hạn rút tiền của cấp độ VIP tương ứng. Giới hạn rút tiền cho người dùng chưa hoàn thành xác minh KYC là 20.000 USD.",
      vip_requirement_4:
        "Nếu khối lượng giao dịch tài sản lưu ký của một người dùng, khối lượng giao dịch tương lai hoặc tài sản đủ điều kiện cho các cấp độ VIP khác nhau, người dùng có thể tận hưởng cấp độ VIP cao nhất. Nếu một người dùng đáp ứng bất kỳ yêu cầu cấp độ VIP nào của bất kỳ loại giao dịch nào, tất cả các loại giao dịch khác sẽ được nâng cấp lên cấp độ VIP tương ứng đó. Ví dụ, nếu một người dùng đáp ứng yêu cầu VIP giao dịch lưu ký VIP 1, họ sẽ tự động được nâng cấp lên giao dịch tương lai VIP 1.",
      vip_requirement_5:
        "Cấp độ VIP sẽ được cập nhật tự động mỗi ngày vào lúc 1:00 AM (UTC+0). Nếu hành vi giao dịch của một người dùng kích hoạt hệ thống kiểm soát rủi ro, Dikex giữ quyền điều chỉnh cấp độ VIP của họ.",
      "Institutional users are not eligible for the VIP program.":
        "Người dùng tổ chức không đủ điều kiện tham gia chương trình VIP.",
      vip_service_contact:
        "Dikex giữ quyền quyết định cuối cùng cho chương trình VIP. Nếu bạn có bất kỳ câu hỏi nào khác, liên hệ chúng tôi qua vip@dikex.com.",
      "Exclusive VIP perks": "Ưu đãi VIP độc quyền",
      "Being a Dikex VIP has never been more rewarding.":
        "Việc trở thành VIP của Dikex chưa bao giờ là đáng giá như vậy.",
      "Hot project watchlist": "Danh sách theo dõi dự án nóng",
      "Premium VIP customer service": "Dịch vụ khách hàng VIP cao cấp",
      "Express deposits and withdrawals": "Nạp và rút tiền nhanh chóng",
      "Cutting-edge security": "Bảo mật tiên tiến",
      "When it comes to security, we go the extra mile.":
        "Khi nói đến bảo mật, chúng tôi luôn chú trọng hơn.",
      "Proof-of-Reserves": "Chứng minh dự trữ",
      "We guarantee a minimum 1:1 reserve ratio to cover customer funds":
        "Chúng tôi đảm bảo tỷ lệ dự trữ tối thiểu 1:1 để bảo vệ quỹ của khách hàng",
      "Cold storage": "Lưu trữ lạnh",
      "Most digital assets are stored in offline multi-signature wallets":
        "Hầu hết tài sản kỹ thuật số được lưu trữ trong ví nhiều chữ ký ngoại tuyến",
      "Become a VIP today": "Trở thành VIP ngay hôm nay",
      "If you're a VIP on another exchange, we offer you VIP at one level higher.":
        "Nếu bạn là VIP trên sàn giao dịch khác, chúng tôi sẽ cung cấp VIP ở một cấp độ cao hơn.",
      "Do you want to change the current language?":
        "Bạn có muốn thay đổi ngôn ngữ hiện tại không?",
      "Exclusive account manager": "Quản lý tài khoản độc quyền",
      "Sign in": "Đăng nhập",
      "to get referral code": "để nhận mã giới thiệu",
      "No records yet.": "Chưa có bản ghi nào.",
      "friends now!": "bạn bè ngay bây giờ!",
      "Three Steps to Attractive Commissions with Our Referral Program":
        "Ba bước để nhận hoa hồng hấp dẫn với Chương trình giới thiệu của chúng tôi",
      "Join The Dikex Referral program Today!":
        "Tham gia Chương trình giới thiệu Dikex ngay hôm nay!",
      "Generate passive income and work towards financial independence.":
        "Tạo ra thu nhập pasive và hướng tới sự độc lập tài chính.",
      "Sign up & get your referral code":
        "Đăng ký và nhận mã giới thiệu của bạn",
      "Create an account in minutes and start earning commission.":
        "Tạo tài khoản chỉ trong vài phút và bắt đầu kiếm hoa hồng.",
      "Share your referral link": "Chia sẻ liên kết giới thiệu của bạn",
      "Invite friends or fans to register as new users using your exclusive referral link or invitation code.":
        "Mời bạn bè hoặc người hâm mộ đăng ký làm người dùng mới bằng liên kết giới thiệu hoặc mã mời độc quyền của bạn.",
      "Earn 5% on Every Referral Trade":
        "Kiếm 5% trên mỗi giao dịch giới thiệu",
      "Receive a 5% commission on every trade your referrals make-no limits, just continuous earnings!":
        "Nhận hoa hồng 5% trên mỗi giao dịch mà người giới thiệu của bạn thực hiện - không có giới hạn, chỉ là thu nhập liên tục!",
      "Continue with MetaMask": "Tiếp tục với MetaMask",
      "New Here?": "Mới ở đây?",
      "Create an account": "Tạo tài khoản",
      "Order quantity": "Số lượng đặt hàng",
      "Invitation ID": "ID lời mời",
      "Invitation Link": "Liên kết lời mời",
      Referral: "Giới thiệu",
      "Total number of addresses": "Tổng số địa chỉ",
      "You can add up to 500 on-chain addresses and internal addresses":
        "Bạn có thể thêm tối đa 500 địa chỉ trên chuỗi và địa chỉ nội bộ",
      "On-chain Address": "Địa chỉ trên chuỗi",
      "Internal Transfer Address": "Địa chỉ chuyển nội bộ",
      "After verification": "Sau khi xác minh",
      "Commission records": "Hồ sơ hoa hồng",
      "Withdrawal records": "Hồ sơ rút tiền",
      "Your name": "Tên của bạn",
      "Enter your mail to reset the password":
        "Nhập email của bạn để đặt lại mật khẩu",
      "Back to sign in": "Quay lại đăng nhập",
      Login: "Đăng nhập",
      Profile: "Hồ sơ",
      "Manage Addresses": "Quản Lý Địa Chỉ",
      "Identity Verification": "Xác Minh Danh Tính",
      Affiliates: "Chi Nhánh",
      "Futures Account": "Tài Khoản Hợp Đồng Tương Lai",
      "Futures Records": "Hồ Sơ Hợp Đồng Tương Lai",
      "Spot Records": "Hồ Sơ Giao Dịch Ngay",
      "Deposit Records": "Hồ Sơ Gửi Tiền",
      "Withdraw Records": "Hồ Sơ Rút Tiền",
      "Commission Records": "Hồ Sơ Hoa Hồng",
      "Select An Account": "Chọn Tài Khoản",
      "Transfer Amount": "Số Tiền Chuyển",
      "Transfer out": "Chuyển Ra",
      "Transfer in": "Chuyển Vào",
      "Choose A Network": "Chọn Mạng Lưới",
      "Deposit Address": "Địa Chỉ Gửi Tiền",
      "Minimum Deposit Amount": "Số Tiền Gửi Tối Thiểu",
      "Transfer To": "Chuyển Đến",
      Wallet: "Ví",
      "Withdraw To": "Rút Về",
      "Choose from address book": "Chọn Từ Sổ Địa Chỉ",
      "Confirm that the receiving platform accepts the crypto and network you have selected. You may lose your assets if you choose an incompatible network":
        "Xác Nhận Rằng Nền Tảng Nhận Chấp Nhận Tiền Điện Tử Và Mạng Lưới Bạn Đã Chọn. Bạn Có Thể Mất Tài Sản Nếu Chọn Mạng Không Tương Thích",
      "On-chain": "Trên Chuỗi",
      "Internal Transfer": "Chuyển Nội Bộ",
      "Select Network": "Chọn Mạng Lưới",
      "Withdraw Address": "Địa Chỉ Rút Tiền",
      "Transfer records": "Hồ Sơ Chuyển Tiền",
      Reload: "Tải Lại",
      Balance: "Số Dư",
      Buy: "Mua",
      "Deposit Confirmation": "Xác nhận gửi tiền",
      "Phone Number": "Số điện thoại",
      "I have read and agree to the above agreement":
        "Tôi đã đọc và đồng ý với thỏa thuận trên",
      "Mail OTP": "OTP Email",
      "Index price": "Giá chỉ số",
      Success: "Thành công",
      Source: "Nguồn",
      Order: "Đơn hàng",
      "Flash close": "Đóng nhanh",
      "Full position TP/SL": "Chốt lời/Cắt lỗ toàn bộ vị thế",
      Batch: "Lô",
      Trailing: "Điều chỉnh",
      "Partial position TP/SL": "Chốt lời/Cắt lỗ một phần vị thế",
      "Trailing TP/SL": "Điều chỉnh chốt lời/cắt lỗ",
      Details: "Chi tiết",
      Lite: "Nhẹ",
      "the position will close {{amount}} at {{price}}. Expected return":
        "vị thế sẽ đóng {{amount}} tại {{price}}. Lợi nhuận dự kiến",
      "Continue to add": "Tiếp tục thêm",
      "By order time": "Theo thời gian đặt hàng",
      "By trigger price": "Theo giá kích hoạt",
      "Cancel all orders": "Hủy tất cả đơn hàng",
      "Execution price": "Giá thực hiện",
      "Can not place tp/sl order": "Không thể đặt lệnh chốt lời/cắt lỗ",
      "Your position is too small to place TP/SL":
        "Vị thế của bạn quá nhỏ để đặt chốt lời/cắt lỗ",
      "Please input TP price": "Vui lòng nhập giá chốt lời",
      "Please input SL price": "Vui lòng nhập giá cắt lỗ",
      "Please input trigger price": "Vui lòng nhập giá kích hoạt",
      "TP/SL Market": "Thị trường chốt lời/cắt lỗ",
      "Callback rate": "Tỷ lệ gọi lại",
      "Remaining quantity for TP/SL": "Số lượng còn lại cho chốt lời/cắt lỗ",
      "Trailing Stop": "Điều chỉnh dừng lỗ",
      "Filled orders only": "Chỉ đơn hàng đã thực hiện",
      "Show current": "Hiển thị hiện tại",
      "Other options": "Tùy chọn khác",
      "Filter by type": "Lọc theo loại",
      "Filter by futures": "Lọc theo hợp đồng tương lai",
      "Trigger MMR": "Kích hoạt MMR",
      "Current MMR": "MMR hiện tại",
      "When MMR ≥ {{mmrTrigger}}%, the position will be closed at market price. The higher the MMR, the higher the risk. Position liquidation/reduction will be triggered when the MMR reaches 100%.":
        "Khi MMR ≥ {{mmrTrigger}}%, vị thế sẽ được đóng ở giá thị trường. MMR càng cao, rủi ro càng lớn. Thanh lý/giảm vị thế sẽ được kích hoạt khi MMR đạt 100%.",
      "Update MMR success": "Cập nhật MMR thành công",
      "Maintenance margin": "Biên bảo trì",
      "Maintenance margin rate (MMR)": "Tỷ lệ biên bảo trì (MMR)",
      "Position tier intro": "Giới thiệu cấp độ vị thế",
      "TP buy price": "Giá mua chốt lời",
      "Share profit": "Chia sẻ lợi nhuận",
      "Shared at": "Chia sẻ lúc",
      "PnL amount": "Số lượng PnL",
      "Scan QR to sign up to Dikex now.": "Quét mã QR để đăng ký Dikex ngay.",
      Download: "Tải xuống",
      Copy: "Sao chép",
      "Copy image success": "Sao chép hình ảnh thành công",
      "Full TP/SL": "Chốt lời/cắt lỗ toàn bộ",
      "Partial TP/SL": "Chốt lời/cắt lỗ một phần",
      "SL buy price": "Giá mua cắt lỗ",
      "SL sell price": "Giá bán cắt lỗ",
      "Trading panel": "Bảng điều khiển giao dịch",
      "Can not place TP order": "Không thể đặt lệnh chốt lời",
      "Your position is too small to place TP":
        "Vị thế của bạn quá nhỏ để đặt chốt lời",
      "Can not place SL order": "Không thể đặt lệnh cắt lỗ",
      "Your position is too small to place SL":
        "Vị thế của bạn quá nhỏ để đặt cắt lỗ",
      References: "Tài liệu tham khảo",
      Standard: "Tiêu chuẩn",
      "Pro-Left": "Chuyên nghiệp-Trái",
      "Pro-Right": "Chuyên nghiệp-Phải",
      "Candlestick time": "Thời gian nến",
      "TP/SL order": "Lệnh TP/SL",
      "Trailing stop order": "Lệnh dừng lỗ kéo theo",
      "Trailing percent": "Phần trăm kéo theo",
      "Copy Image": "Sao chép hình ảnh",
      "Sponsor not found": "Không tìm thấy nhà tài trợ",
      "If you continue to register, the system will skip updating the sponsor. You can update later in the settings.":
        "Nếu bạn tiếp tục đăng ký, hệ thống sẽ bỏ qua việc cập nhật nhà tài trợ. Bạn có thể cập nhật sau trong cài đặt.",
      "Spot trading notifications": "Thông báo giao dịch giao ngay",
      "Spot notification sounds": "Âm thanh thông báo giao ngay",
      "Spot notifications": "Thông báo giao ngay",
      "Trigger order": "Lệnh kích hoạt",
      "Futures trading notifications": "Thông báo giao dịch hợp đồng tương lai",
      "Futures notification sounds": "Âm thanh thông báo hợp đồng tương lai",
      "Post only order": "Chỉ đăng lệnh",
      "Futures notifications": "Thông báo hợp đồng tương lai",
      "Vip Upgrade Progress": "Tiến độ nâng cấp VIP",
      "Spot Vol": "Khối lượng giao ngay",
      "Futures Vol": "Khối lượng hợp đồng tương lai",
      "Account Assets": "Tài sản tài khoản",
      Loading: "Đang tải",
      "Your VIP upgrade progress": "Tiến độ nâng cấp VIP của bạn",
      "You still need": "Bạn vẫn cần",
      "to reach": "để đạt đến",
      "User level": "Cấp độ người dùng",
      "Fast track application": "Ứng dụng cấp tốc",
      "Spot fees": "Phí giao ngay",
      "Current tier": "Cấp hiện tại",
      "Maker/Taker": "Người đặt/Người khớp",
      "Futures transaction fees": "Phí giao dịch hợp đồng tương lai",
      "Spot order confirmation": "Xác nhận lệnh giao ngay",
      "Futures order confirmation": "Xác nhận lệnh hợp đồng tương lai",
      "Help center": "Trung tâm trợ giúp",
      "Futures trading help center":
        "Trung tâm trợ giúp giao dịch hợp đồng tương lai",
      "See other questions": "Xem câu hỏi khác",
      "Beginner's Guide": "Hướng dẫn cho người mới",
      "Futures overview": "Tổng quan về hợp đồng tương lai",
      "Risk management": "Quản lý rủi ro",
      "Trading mechanism": "Cơ chế giao dịch",
      "Feature guide": "Hướng dẫn tính năng",
      "Spot trading help center": "Trung tâm trợ giúp giao dịch giao ngay",
      "Spot guide": "Hướng dẫn giao ngay",
      Guideline: "Hướng dẫn",
      "Blockchain Assets": "Tài sản chuỗi khối",
      "Please inter trigger price": "Vui lòng nhập giá kích hoạt",
      "Please check your phone number ({{phone}}) for the verification code we just sent you and enter that code in the box below":
        "Vui lòng kiểm tra số điện thoại của bạn ({{phone}}) để lấy mã xác minh chúng tôi vừa gửi và nhập mã đó vào ô dưới đây",
      "Since we still need your email, please enter it before registering.":
        "Vì chúng tôi vẫn cần email của bạn, vui lòng nhập trước khi đăng ký.",
      "Your email": "Email của bạn",
      "Email already exists": "Email đã tồn tại",
      "Scan this code with the Dikex app to log in instantly.":
        "Quét mã này bằng ứng dụng Dikex để đăng nhập ngay lập tức.",
      Activities: "Hoạt động",
      "Change password": "Đổi mật khẩu",
      "Enable GA": "Bật GA",
      "Disable GA": "Tắt GA",
      "Update username": "Cập nhật tên người dùng",
      "Update Anti-fishing code": "Cập nhật mã chống giả mạo",
      "Enable withdrawal whitelist": "Bật danh sách rút tiền",
      "Disable withdrawal whitelist": "Tắt danh sách rút tiền",
      "Disable phone number": "Vô hiệu hóa số điện thoại",
      "All type": "Tất cả loại",
      Device: "Thiết bị",
      "Remove device": "Xóa thiết bị",
      "Login at": "Đăng nhập lúc",
      "Device manager": "Quản lý thiết bị",
      Seconds: "Giây",
      Minutes: "Phút",
      Hours: "Giờ",
      Days: "Ngày",
      Notifications: "Thông báo",
      ALL: "Tất cả",
      Campaigns: "Chiến dịch",
      "System messages": "Tin nhắn hệ thống",
      Previous: "Trước",
      "Mark all as read": "Đánh dấu tất cả là đã đọc",
      "Mark all as read successfully": "Đánh dấu tất cả là đã đọc thành công",
      "Mark all as read failed": "Đánh dấu tất cả là đã đọc thất bại",
      "Show less": "Hiển thị ít hơn",
      "Show more": "Hiển thị nhiều hơn",
      "Alert logs": "Nhật ký cảnh báo",
      "Create notice successfully": "Tạo thông báo thành công",
      "Create notice failed": "Tạo thông báo thất bại",
      Pair: "Cặp",
      "Alert type": "Loại cảnh báo",
      "Price Rise": "Giá tăng",
      "Price 24h Rise Percent": "Phần trăm tăng giá trong 24h",
      "Price 24h Fall Percent": "Phần trăm giảm giá trong 24h",
      "Price Rise To Percent": "Giá tăng đến phần trăm",
      "Price Fall To Percent": "Giá giảm đến phần trăm",
      "Entering Channel": "Vào kênh",
      "Exiting Channel": "Thoát khỏi kênh",
      "Price Alert": "Cảnh báo giá",
      "Upper Bound": "Giới hạn trên",
      "Lower Bound": "Giới hạn dưới",
      "The upper bound must be greater than the lower bound":
        "Giới hạn trên phải lớn hơn giới hạn dưới",
      Frequency: "Tần suất",
      "Only Once": "Chỉ một lần",
      "Once A Day": "Một lần một ngày",
      "Every Time": "Mỗi lần",
      "When the condition reaches the set value, it will raise an alert via the selected method, and will not be received again after one reminder":
        "Khi điều kiện đạt đến giá trị đã đặt, nó sẽ gửi cảnh báo qua phương pháp được chọn, và sẽ không nhận lại cảnh báo sau một lần nhắc nhở",
      "Remind once a day (between 0:00 and 24:00). It will be triggered when the condition reaches the next day":
        "Nhắc nhở một lần một ngày (giữa 0:00 và 24:00). Nó sẽ được kích hoạt khi điều kiện đạt đến ngày tiếp theo",
      "Remind continuously until cancelled. In order not to disturb you, we set the reminder rules:":
        "Nhắc nhở liên tục cho đến khi hủy bỏ. Để không làm phiền bạn, chúng tôi đặt ra các quy tắc nhắc nhở:",
      "No more than 1 times per second": "Không quá 1 lần mỗi giây",
      "No more than 2 times per minute": "Không quá 2 lần mỗi phút",
      "No more than 3 times within 1 hour": "Không quá 3 lần trong vòng 1 giờ",
      "No more than 10 times a day": "Không quá 10 lần một ngày",
      "Please select alerts to delete": "Vui lòng chọn cảnh báo để xóa",
      "Selected alerts deleted successfully!":
        "Đã xóa cảnh báo được chọn thành công!",
      "Failed to delete selected alerts!": "Xóa cảnh báo được chọn thất bại!",
      "Update alert status successfully!":
        "Cập nhật trạng thái cảnh báo thành công!",
      "Update alert status failed!": "Cập nhật trạng thái cảnh báo thất bại!",
      "Alert monitoring": "Giám sát cảnh báo",
      "Bot creation notice": "Thông báo tạo bot",
    },
  },
  kr: {
    translation: {
      Account: "계정",
      "Email is not valid": "유효하지 않은 이메일입니다",
      "Please Enter your email": "이메일을 입력하세요",
      "Please Enter your password": "비밀번호를 입력하세요",
      "Invalid Password": "유효하지 않은 비밀번호",
      "Please check your e-mail for the verification code we just sent you and enter that code in the box below":
        "방금 전송한 인증 코드를 확인하고 아래 상자에 해당 코드를 입력하세요",
      "Please Enter your name": "이름을 입력하세요",
      Username: "사용자 이름",
      Email: "이메일",
      Password: "비밀번호",
      "Sign Up": "가입하기",
      "Terms of Service & Privacy Policy": "서비스 약관 및 개인정보 보호 정책",
      "By signing up, I agree to our": "가입함으로써, 저는 우리의에 동의합니다",
      OR: "또는",
      Back: "뒤로",
      Register: "등록",
      Thank: "감사합니다",
      "Your account has been created. We'll take you to the login page momentarily":
        "귀하의 계정이 생성되었습니다. 잠시 후에 로그인 페이지로 이동합니다",
      "Forgot Password": "비밀번호를 잊으셨나요?",
      "Sign In": "로그인",
      WRONG_CREDENTIALS: "잘못된 자격 증명",
      Settings: "설정",
      "Sign Out": "로그아웃",
      Dashboard: "대시보드",
      "Security Checkup": "보안 점검",
      "Two-Factor Authentication (2FA)": "이중 인증 (2FA)",
      "Authentication Settings": "인증 설정",
      "Sign in password": "로그인 비밀번호",
      "The password that used to log in to your account":
        "귀하의 계정에 로그인하는 데 사용되는 비밀번호",
      Manager: "관리자",
      "Confirm Password": "비밀번호 확인",
      "Please enter confirm password": "비밀번호를 확인하세요",
      Cancel: "취소",
      Save: "저장",
      "Current Password": "현재 비밀번호",
      "Change password success": "비밀번호 변경 성공",
      PASSWORD_WRONG: "현재 비밀번호가 유효하지 않습니다",
      "Google Authentication (GA 2FA)": "Google 인증 (GA 2FA)",
      "Use Google Authenticator to protect your account and transactions":
        "Google Authenticator를 사용하여 계정 및 거래를 보호하세요",
      ON: "켜짐",
      OFF: "끄기",
      "Enable Google authentication": "Google 인증 활성화",
      "Scan with Google Authenticator App on your phone":
        "휴대폰에서 Google Authenticator 앱으로 스캔하세요",
      "Enter this code in the app": "앱에서 이 코드를 입력하세요",
      Continue: "계속",
      "Enter GA code to enable": "활성화하려면 GA 코드를 입력하세요",
      "Please Enter your Ga Code": "Ga 코드를 입력하세요",
      "Ga code": "Ga 코드",
      "Enable GA Authentication success": "GA 인증 활성화 성공",
      "Disabled Google Authentication": "Google 인증 비활성화됨",
      "Enter GA code to disable": "비활성화하려면 GA 코드를 입력하세요",
      Disable: "비활성화",
      "Disable GA Authentication success": "GA 인증 비활성화 성공",
      "Reset password": "비밀번호 재설정",
      Submit: "제출",
      "Forgot Password?": "비밀번호를 잊으셨나요?",
      "Your password has been reset. We'll take you to the login page momentarily":
        "비밀번호가 재설정되었습니다. 잠시 후에 로그인 페이지로 이동합니다",
      "Please enter GA code in the box below":
        "아래 상자에 GA 코드를 입력하세요",
      "Don't receive OTP?": "OTP를 받지 못하셨나요?",
      "Resend after": "이후 재전송",
      "Resend OTP": "OTP 재전송",
      "Resend OTP success": "OTP 재전송 성공",
      "Advanced Security": "고급 보안",
      "Contains at least 8 characters": "최소 8자 이상 포함",
      "At least one uppercase": "적어도 하나의 대문자",
      "At least one lowercase": "적어도 하나의 소문자",
      "At least one number": "적어도 하나의 숫자",
      "At least one special character": "적어도 하나의 특수 문자",
      "Anti-phishing code": "사기 방지 코드",
      "All emails we sends to you will include your anti-phishing code so you know it's from us":
        "당사가 귀하에게 보내는 모든 이메일에는 사기 방지 코드가 포함되어 있으므로 당사의 이메일임을 알 수 있습니다",
      "Set Anti-phishing Code": "사기 방지 코드 설정",
      "What is an anti-phishing code?": "사기 방지 코드란 무엇인가요?",
      "The anti-phishing code is a string of characters that you set yourself, which can help you identify websites and emails that are counterfeit":
        "사기 방지 코드는 직접 설정하는 문자열로, 위조된 웹 사이트 및 이메일을 식별하는 데 도움이 될 수 있습니다",
      "Where will the anti-phishing code appear?":
        "사기 방지 코드는 어디에 표시됩니까?",
      "When set, this code will be included in emails from Diket. Any other emails without this code are scams":
        "설정되면, 이 코드는 Diket의 이메일에 포함됩니다. 이 코드가 없는 다른 이메일은 사기입니다",
      "Please Enter Anti-phishing code": "사기 방지 코드를 입력하세요",
      "Withdrawal whitelist": "출금 화이트리스트",
      "When enabled, you'll only be able to withdraw to whitelisted addresses":
        "활성화되면 화이트리스트에 등록된 주소로만 출금할 수 있습니다",
      Close: "닫기",
      "All coin": "모든 코인",
      "Not found": "찾을 수 없음",
      "Update success": "업데이트 성공",
      "Not verified": "확인되지 않음",
      "Update your name": "이름 업데이트",
      "Update Referral ID": "추천 ID 업데이트",
      "Anti-phishing  protect": "사기 방지 보호",
      "Invite Code": "초대 코드",
      "Please Enter invite code": "초대 코드를 입력하세요",
      "Update Profile": "프로필 업데이트",
      Enable: "활성화",
      "Enable withdraw whitelist": "출금 화이트리스트 활성화",
      "Disable withdraw whitelist": "출금 화이트리스트 비활성화",
      "Please enter login password": "로그인 비밀번호를 입력하세요",
      "All network": "모든 네트워크",
      "Add new address": "새 주소 추가",
      Address: "주소",
      "Please Enter address": "주소를 입력하세요",
      Tag: "태그",
      Note: "노트",
      "Invalid address": "유효하지 않은 주소",
      "Add address success": "주소 추가 성공",
      Delete: "삭제",
      Confirm: "확인",
      "Are you sure you want to delete?": "정말로 삭제하시겠습니까?",
      "Delete success": "삭제 성공",
      "Invite code [ Optional ]": "초대 코드 [ 선택 사항 ]",
      "Invited friends": "초대한 친구들",
      Level: "레벨",
      "View team": "팀 보기",
      Funding: "자금 지원",
      Spot: "스팟",
      Future: "선물",
      "24H Change": "24시간 변동",
      "24H High": "24시간 최고가",
      "24H Low": "24시간 최저가",
      "24H Turnover": "24시간 거래량",
      "24H Volume": "24시간 거래량",
      Price: "가격",
      Amount: "수량",
      Total: "총액",
      "Order book": "주문서",
      "Market Trades": "시장 거래",
      BUY: "매수",
      SELL: "매도",
      Limit: "한도",
      Market: "시장",
      "TP/SL": "TP/SL",
      Available: "사용 가능",
      "Order price": "주문 가격",
      Quantity: "수량",
      Coin: "코인",
      "Price must be greater than or equal to":
        "가격은 이상 또는 이상이어야 합니다",
      "Price must be less than or equal to": "가격은 이하 또는 이하여야 합니다",
      "Quantity must be greater than or equal to": "수량은 이상이어야 합니다",
      "Quantity must be less than or equal to": "수량은 이하여야 합니다",
      "You have never logged in to your account on this device. To continue logging in, please confirm it is you by entering OTP in the box below and clicking Accept":
        "이 장치에서 계정에 로그인한 적이 없습니다. 계속해서 로그인하려면 아래 상자에 OTP를 입력하고 수락을 클릭하여 본인 확인하세요",
      "Trusted device": "신뢰할 수 있는 장치",
      Accept: "수락",
      "Type OTP here": "여기에 OTP를 입력하세요",
      "Min quantity is": "최소 수량은",
      "Mail OTP confirm": "메일 OTP 확인",
      "Request OTP": "OTP 요청",
      "Request success": "요청 성공",
      Balances: "잔액",
      "24h Fluctuations": "24시간 변동",
      "Latest price": "최신 가격",
      "Order success": "주문 성공",
      "Open order": "미체결 주문",
      "Order history": "주문 내역",
      Symbol: "심볼",
      Side: "측면",
      "Time in Force": "유지 기간",
      Filled: "체결됨",
      Type: "유형",
      "Cancel order success": "주문 취소 성공",
      Date: "날짜",
      "Close this order": "이 주문 닫기",
      Status: "상태",
      "Market price": "시장 가격",
      "Trigger price": "트리거 가격",
      "Trigger Type": "트리거 유형",
      "Limit price": "한도 가격",
      "Trailing Percent": "트레일링 비율",
      Time: "시간",
      "Select interval": "간격 선택",
      "Order detail": "주문 상세 정보",
      Fee: "수수료",
      "The Total Value Of Assets": "자산 총 가치",
      Deposit: "입금",
      Withdraw: "출금",
      Transfer: "전송",
      "Assets Overview": "자산 개요",
      "Spot Account": "현물 계좌",
      "Future Account": "선물 계좌",
      "Select A Coin": "코인 선택",
      "Choose A Networks": "네트워크 선택",
      Tips: "팁",
      "Do not deposit assets order than": "이 외의 자산을 입금하지 마십시오",
      "as doing so may result in the irretrievability of the deposited assets":
        "그렇게 하면 입금된 자산을 회수할 수 없게 될 수 있습니다",
      "Deposits below the minimum amount will not be credited and cannot be refunded":
        "최소 금액 이하의 입금은 인정되지 않으며 환불되지 않습니다",
      "Ensure the security of your computer and browser to prevent information from being tampered with or leaked":
        "정보가 변조되거나 유출되지 않도록 컴퓨터와 브라우저의 보안을 확인하세요",
      Effect: "효과",
      "Buy/Long": "매수/롱",
      Expense: "비용",
      Maximum: "최대",
      "Margin mode": "마진 모드",
      "Under cross margin mode, the full amount of funds in your available balance will be drawn to prevent liquidation. Any realized PnL from other positions can be drawn to add margin to a negative position. Under isolated margin mode, your liability is limited to the initial margin posted. The margin that the position can lose is limited to the initial margin allocated. The position is liquidated when the initial margin is used up. The remaining available margin will not be drawn to cover the loss.":
        "크로스 마진 모드에서는 청산을 방지하기 위해 사용 가능한 잔액의 전체 금액이 인출됩니다. 다른 포지션에서 실현된 PnL은 마이너스 포지션에 마진을 추가하기 위해 인출될 수 있습니다. 아이솔레이티드 마진 모드에서는 책임이 초기 마진으로 제한됩니다. 포지션이 잃을 수 있는 마진은 할당된 초기 마진으로 제한됩니다. 초기 마진이 소진되면 포지션이 청산됩니다. 남은 사용 가능한 마진은 손실을 보상하기 위해 인출되지 않습니다.",
      "Adjust leverage": "레버리지 조정",
      Leverage: "레버리지",
      "Maximum position at current leverage": "현재 레버리지에서 최대 포지션",
      "Selecting to trade with a leverage greater than 10x can easily lead to liquidation. Please adjust with caution":
        "10배 이상의 레버리지로 거래하는 것은 쉽게 청산으로 이어질 수 있습니다. 신중하게 조정하십시오",
      Positions: "포지션",
      "Select futures": "선물 선택",
      "Est. liq. price": "예상 청산가",
      "Mark price": "마크 가격",
      "Average price": "평균 가격",
      Position: "포지션",
      "Open orders": "미체결 주문",
      Margin: "마진",
      "Unrealized Pnl (ROE)": "미실현 손익 (ROE)",
      "Realized Pnl": "실현 손익",
      "Limit order": "지정가 주문",
      "Market order": "시장가 주문",
      Action: "동작",
      "Adjust Leverage success": "레버리지 조정 성공",
      "Adjust margin": "마진 조정",
      "Close Position": "포지션 닫기",
      "Limit Close": "지정가 닫기",
      "Current price": "현재 가격",
      "Open price": "개시 가격",
      "Max close": "최대 닫기",
      "Close price": "종가",
      "Close quantity": "종료 수량",
      "Close success": "종료 성공",
      "Market Close": "시장가 닫기",
      "Welcome to Dikex": "Dikex에 오신 것을 환영합니다",
      "Have an account?": "계정이 있습니까?",
      "Enter your email": "이메일을 입력하세요",
      "Referral code (Optional)": "추천 코드 (선택 사항)",
      "Close position": "포지션 닫기",
      "Increase margin": "마진 증가",
      "Decrease margin": "마진 감소",
      "Increase Amount": "금액 증가",
      Max: "최대",
      "Can increase by": "증가할 수 있는 양",
      "Est. liq. Price": "예상 청산가",
      "Est. liq. Price after increase": "증가 후 예상 청산가",
      "Decrease Amount": "금액 감소",
      "Can decrease by": "감소할 수 있는 양",
      "Please input amount": "금액을 입력하세요",
      "Amount must be greater than": "금액은 수량보다 커야 합니다",
      "Amount must be less than": "금액은 수량보다 작아야 합니다",
      "Position history": "포지션 기록",
      Futures: "선물",
      "Open time": "개시 시간",
      "Avg. entry price": "평균 진입가",
      "Avg. exit price": "평균 종료가",
      "Position PNL": "포지션 손익",
      "Close/Liq. time": "종료/청산 시간",
      "Post only": "포스트 온리",
      bullish: "강세",
      bearish: "약세",
      "Close amount": "종료 수량",
      "Take profit": "이익 실현",
      Clear: "지우기",
      "Trailing percent must be greater than 0%":
        "이동 비율은 0%보다 커야 합니다",
      "When the {{mode}} reaches": "{{mode}}가 도달하면",
      "the position will be closed. Expected return":
        "포지션이 종료됩니다. 예상 수익",
      "Last price": "최종 가격",
      "Stop loss": "손절",
      Trigger: "트리거",
      "Total margin": "총 마진",
      "Filled Size": "채워진 크기",
      "Filled margin": "채워진 마진",
      "Est. liq. Price after decrease": "감소 후 예상 청산가",
      "Take profit success": "이익 실현 성공",
      "Stop loss success": "손절 성공",
      "Tiered maintenance margin rate": "계층화된 유지 마진 비율",
      "I have read and agreed to the Dikex": "Dikex에 동의했습니다",
      "User Agreement": "사용자 동의",
      and: "그리고",
      "Privacy policy": "개인정보 보호정책",
      "Already have an account?": "이미 계정이 있습니까?",
      "You're almost there": "거의 다 왔습니다",
      "Welcome back!": "다시 오신 것을 환영합니다!",
      "Forgot your password?": "비밀번호를 잊으셨습니까?",
      "Don't worry, If happens to all of us":
        "걱정하지 마세요, 우리 모두에게 일어날 수 있습니다",
      "Enter a new password": "새 비밀번호를 입력하세요",
      "Once you reset your password, any withdrawals and P2P sales will be put on hold for 24 hours.":
        "비밀번호를 재설정하면 인출 및 P2P 판매가 24시간 동안 보류됩니다.",
      Asset: "자산",
      "Total Balance": "총 잔액",
      "Today's PnL": "오늘의 손익",
      "Transaction history": "거래 내역",
      Crypto: "암호화폐",
      "Hide zero - balance assets": "0 잔액 자산 숨기기",
      Reserved: "예약됨",
      "Available Balance": "사용 가능한 잔액",
      Actions: "동작",
      Trade: "거래",
      "Buy Crypto": "암호화폐 구매",
      "USDT-M futures": "USDT-M 선물",
      Frozen: "동결됨",
      "Asset Account": "자산 계좌",
      "Unrealized PnL": "미실현 손익",
      "Estimated assets": "예상 자산",
      "Trade Smarter": "Trade Smarter",
      With: "With",
      "Sign up now and get a welcome gift of":
        "Sign up now and get a welcome gift of",
      "Email / Phone number": "이메일 / 전화번호",
      "Sign up Now": "지금 가입",
      "Sign Up for Rewards": "보상을 받기",
      "Top Gainers": "상승 상위",
      "New Listings": "새로운 상장",
      "Catch Your Next Trading Opportunity": "다음 거래 기회를 포착하세요~",
      "Hot Derivatives": "인기 파생상품",
      "Hot Coins": "인기 코인",
      "Search Coins": "코인 검색",
      "Trade Anytime, Anywhere": "언제 어디서나 거래",
      "Open new positions instantly, whether it's on Dikex App or Web.":
        "웹에서 바로 새로운 포지션을 열 수 있다.",
      "Buy, Sell, And Trade On The Go": "이동 중에도 매수, 매도 및 거래",
      "Manage your holdings from your mobile device":
        "모바일 장치에서 보유 자산을 관리",
      "Take Control Of Your Wealth": "재산을 관리",
      "Rest assured you (and only you) have access to your funds":
        "안심하세요, 당신만이 자금에 접근할 수 있다.",
      "GET IT ON": "받기",
      "Download on the": "다운로드",
      "Your Trusted Crypto Exchange": "신뢰하는 거래소",
      "Let's Trade Now": "지금 거래하세요",
      Fiat: "법정화폐",
      "Over 149 fiat currencies supported": "149개 이상의 법정 화폐 지원",
      "Futures, spot, Margin, bot....": "선물, 현물, 마진, 봇...",
      Earn: "수익",
      "Boost your crypto profits safely and easily!":
        "안전하고 쉽게 수익을 증가",
      "We are the most trusted cryptocurrency platform.":
        "가장 신뢰받는 거래소 플랫폼",
      Clarity: "명확성",
      "We help you make sense of the coins, the terms, the dense charts and market changes.":
        "코인, 용어, 복잡한 차트와 시장 변화를 이해하는 데 도움",
      Confidence: "자신감",
      "Our markets are always up to date, sparking curiosity with real-world relevance.":
        "시장은 항상 최신 상태를 유지하며, 현실적인 관련성으로 호기심을 자극",
      Community: "커뮤니티",
      "We supports the crypto community, putting data in the hands which need it most.":
        "암호화폐 커뮤니티를 지원하며, 데이터를 가장 필요한 사람들에게 제공.",
      "Our Commitment to Compliance and Your Protection":
        "준수와 보호에 대한 약속",
      "Triple-Layer Asset": "3중 자산 보호",
      "Experience Triple Fund Protection with our cold wallet storage, ensuring strong security for user assets through comprehensive protection, for a safe and reliable experience.":
        "콜드 월렛 스토리지를 통해 트리플 펀드 보호를 경험해 보세요. 종합적인 보호로 사용자 자산의 강력한 보안을 보장하여 안전하고 신뢰할 수 있는 경험을 제공",
      "User funds are securely stored offline in cold wallets.":
        "사용자 자산은 오프라인 콜드 월렛에 안전하게 보관",
      "Privacy Protection": "개인정보 보호",
      "We integrate a privacy-first approach in all our products and services.":
        "모든 제품과 서비스에 개인정보 보호를 최우선으로 하는 접근 방식을 통합",
      "We are transparent about the data we gather from you, clearly stating how we utilize and share it.":
        "여러분에게서 수집한 데이터에 대해 투명하게 공개하며, 그것을 어떻게 사용하고 공유하는지 명확하게 설명",
      "Advanced Data Protection": "고급 데이터 보호",
      "Data is encrypted during storage and while in transit. Strict authorization controls are in place to ensure that only you can access your personal and private information.":
        "데이터는 저장 중 및 전송 중에 암호화됩니다. 엄격한 권한 제어가 있어 오직 여러분만이 개인 및 비공개 정보를 접근할 수 있습니다.",
      "Real-Time Monitoring": "실시간 모니터링",
      "Dikex's risk controls actively monitor and analyze user behavior in real-time. Upon detecting suspicious activity, withdrawals undergo enhanced authentication measures for added security.":
        "Dikex의 리스크 통제는 사용자 행동을 실시간으로 적극 모니터링하고 분석합니다. 의심스러운 활동이 감지되면, 출금은 추가 보안을 위해 강화된 인증 절차를 거칩니다.",
      "Security by Design": "디자인에 의한 보안",
      "Our system prioritizes security, featuring a secure development life cycle, thorough security testing, and continuous bug bounty programs.":
        "우리 시스템은 보안을 최우선으로 하며, 안전한 개발 생명 주기, 철저한 보안 테스트, 지속적인 버그 바운티 프로그램을 특징으로 합니다.",
      "Need Help?": "도움이 필요하신가요?",
      "24/7 Customer Support": "24/7 고객 지원",
      "Get 24/7 support from our friendly Customer Support agents.":
        "친절한 고객 지원 담당자로부터 24/7 지원.",
      "Chat now": "지금 채팅",
      FAQs: "자주 묻는 질문",
      "Explore FAQs for detailed instructions on specific features.":
        "특정 기능에 대한 자세한 지침은 자주 묻는 질문을 참조",
      "Learn more": "자세히 알아보기",
      Learn: "학습",
      "Discover a range of trading guides tailored for beginners and advanced traders alike.":
        "초보자와 고급 거래자를 위한 다양한 거래 가이드를 발견",
      "Embark on Your Crypto Journey Today!":
        "오늘부터 암호화폐 여정을 시작하세요!",
      "Discover how specific cryptocurrencies work — and get a bit of each crypto to try out for yourself.":
        "특정 암호화폐가 어떻게 작동하는지 알아보고, 각 암호화폐를 직접 시도해보세요!",
      "Add to Favorites": "즐겨찾기에 추가",
      "Add funds": "자금 추가",
      "Trade Smarter With Dikex": "Dikex와 함께 스마트하게 거래하세요",
      "Becoming Street": "거리에서",
      "Becoming Street Smarter": "더 스마트하게 거리에서",
      Trending: "트렌드",
      Markets: "시장",
      Fees: "수수료",
      "Trading pair": "거래 쌍",
      Change: "변동",
      "Account Assets": "계정 자산",
      "Total unrealized PnL": "총 미실현 손익",
      "Trading bonuses": "거래 보너스",
      "Total realized PnL": "총 실현 손익",
      "Fund leverage": "자금 레버리지",
      Used: "사용됨",
      "Account perks": "계정 특전",
      "Current limit": "현재 한도",
      "Identity verification": "신원 확인",
      "Level 1 identity verification": "레벨 1 신원 확인",
      "Crypto deposit": "암호화폐 입금",
      "Fiat deposit": "법정 화폐 입금",
      "P2P trading": "P2P 거래",
      "Verification requirements": "확인 요구 사항",
      "Government-issued documents": "정부 발행 문서",
      "Face recognition verification": "안면 인식 확인",
      "Estimated review time: 60 min(s)": "예상 검토 시간: 60분",
      Verify: "확인",
      "FREQUENTLY ASKED QUESTIONS": "자주 묻는 질문",
      "Why do I need to verify my identity?": "왜 신원을 확인해야 하나요?",
      "Why can't I select my country/region?":
        "왜 내 국가/지역을 선택할 수 없나요?",
      "What documents can I use to verify my identity?":
        "신원을 확인하기 위해 어떤 문서를 사용할 수 있나요?",
      More: "더보기",
      "Update referral": "추천 업데이트",
      "Fill your name": "이름 입력",
      "Verify your document": "문서 확인",
      "We are reviewing your information": "정보를 검토 중입니다",
      Waiting: "대기 중",
      Hi: "안녕하세요",
      "Please complete all verification steps": "모든 확인 단계를 완료하세요",
      Processing: "처리 중",
      Verified: "확인됨",
      "Please select coin and network": "코인과 네트워크를 선택하세요",
      "Please contact our customer support team if deposit services of the funds you deposited have been temporarily suspended. Once the deposited funds are confirmed on the blockchain, they will be secured and credited to your account as soon as the deposit services resume. You can also use another blockchain to complete the deposit.":
        "입금 서비스가 일시 중단된 경우 고객 지원팀에 문의하세요. 입금된 자금이 블록체인에서 확인되면 입금 서비스가 재개되는 즉시 자금이 보관되고 계정에 적립됩니다. 다른 블록체인을 사용하여 입금을 완료할 수도 있습니다.",
      "How to Deal with Wrong Deposit?": "잘못된 입금 처리 방법?",
      "How to Deal with Deposit not Credited?":
        "입금이 적립되지 않았을 때 처리 방법?",
      "How to Deposit Cryptocurrency to Dikex Account?":
        "Dikex 계정에 암호화폐를 입금하는 방법?",
      to: "에",
      "Contract Address": "컨트랙트 주소",
      Next: "다음",
      "If your withdrawals have been suspended, please contact our customer support for assistance. We assure you that your funds are secured and should you need immediate access to your fund, you can also submit another withdrawal via other networks.":
        "출금이 중단된 경우 지원을 위해 고객 지원팀에 문의하세요. 자금이 안전하게 보호되며 즉시 접근이 필요한 경우 다른 네트워크를 통해 출금 요청을 제출할 수 있습니다.",
      "Account email": "계정 이메일",
      "Enter email": "이메일 입력",
      "Select email": "이메일 선택",
      "Add Withdrawal Address": "출금 주소 추가",
      Normal: "일반",
      Universal: "유니버설",
      "Withdrawal Address": "출금 주소 추가",
      "For the safety of your funds, our customer support team may contact you by phone to confirm your withdrawal request. Please pay attention to incoming calls":
        "귀하의 자금 안전을 위해 고객 지원팀이 전화로 연락하여 출금 요청을 확인할 수 있습니다. 수신 전화에 주의하세요.",
      "Be aware of scams! Dikex will never ask you for your account, password or any personal information, nor will it request private transfers or withdrawals":
        "사기에 주의하세요! Dikex는 귀하의 계정, 비밀번호 또는 개인 정보를 요청하지 않으며, 개인 이체나 출금을 요청하지 않습니다.",
      "Withdrawal Limit Adjustment?": "출금 한도 조정?",
      "How to Withdraw Cryptocurrency from Dikex Account?":
        "Dikex 계정에서 암호화폐를 출금하는 방법?",
      "Withdrawal Limits": "출금 한도",
      "Single limit": "단일 한도",
      "Daily Limit": "일일 한도",
      "Daily Available": "일일 사용 가능",
      "Monthly Limit": "월별 한도",
      "Monthly Available": "월별 사용 가능",
      "Address, email, uid": "주소, 이메일, UID",
      "Edit notes": "메모 편집",
      "Delete address": "주소 삭제",
      "Are you sure you want to delete from your address book?":
        "주소록에서 삭제하시겠습니까?",
      "Funding rate / countdown": "자금 비율 / 카운트 다운",
      "Dikex provides you with a powerful API, enabling you to access market data, perform trades, transfer assets, and make withdrawals":
        "Dikex는 강력한 API를 제공하여 시장 데이터에 액세스하고 거래를 수행하며 자산을 이체하고 인출할 수 있습니다.",
      "When you create a new API, you need to set a passphrase to call the API and query the secret key. Make sure to keep your passphrase safe as if you lose it, you will need to create a new API key":
        "새 API를 생성할 때 API를 호출하고 비밀 키를 조회하려면 암호구를 설정해야 합니다. 암호구를 분실하면 새 API 키를 생성해야 합니다.",
      "The secret and passphrase are used for private API calls and should never be disclosed":
        "비밀 및 암호구는 개인 API 호출에 사용되며 절대로 공개되어서는 안됩니다.",
      "For security reasons, IPs must be bound to API keys. Up to 30 IPs can be bound to each key. If no IP is bound, you may be restricted from accessing certain functions, such as withdrawing assets or publishing and editing P2P ads":
        "보안상의 이유로 IP는 API 키에 바인딩되어야 합니다. 각 키에 최대 30개의 IP를 바인딩할 수 있습니다. IP가 바인딩되지 않은 경우 자산 인출이나 P2P 광고 게시 및 편집과 같은 특정 기능에 액세스하는 것이 제한될 수 있습니다.",
      "Address book": "주소록",
      "Create Api key": "API 키 생성",
      Create: "생성",
      "Please enter API-Key name": "API 키 이름을 입력하세요",
      Name: "이름",
      "Api-key name": "API 키 이름",
      "IP, domain, ...": "IP, 도메인, ...",
      Scopes: "범위",
      "Add key success": "키 추가 성공",
      Key: "키",
      Edit: "편집",
      "Edit Api key": "API 키 편집",
      "Update key success": "키 업데이트 성공",
      Update: "업데이트",
      "Liq. Price": "유동성 가격",
      "Spot trading volume (30D) (USDT)": "스팟 거래량 (30일) (USDT)",
      or: "또는",
      "Asset balance (USD)": "자산 잔액 (USD)",
      "Maker fee": "메이커 수수료",
      "Taker fee": "테이커 수수료",
      "24h withdraw limit": "24시간 출금 한도",
      "Futures trading volume (30D) (USDT)": "선물 거래량 (30일) (USDT)",
      "Fees and withdrawal limits": "수수료 및 출금 한도",
      "Dikex VIPs make trades with some of the lowest fees in the market and keep more of their profits":
        "Dikex VIP는 시장에서 가장 낮은 수수료로 거래하고 수익의 일부를 보존합니다.",
      "Vip Services": "VIP 서비스",
      "Cancel All": "모두 취소",
      "Are you sure to cancel all order?": "모든 주문을 취소하시겠습니까?",
      "of total": "총",
      "Asset allocation": "자산 할당",
      "Recent Activity": "최근 활동",
      "In order": "순서대로",
      History: "역사",
      "Funding Rate": "자금 비율",
      "Spot records": "스팟 기록",
      "Select symbol": "심볼 선택",
      "All Futures": "모든 선물",
      "Funding Rate History": "자금 비율 기록",
      "Period Time": "기간 시간",
      Hours: "시간",
      From: "부터",
      To: "까지",
      "Base interest rate": "기준 이자율",
      "Futures records": "선물 기록",
      "All Coin": "모든 코인",
      "Select coin": "코인 선택",
      "Fund History": "자금 기록",
      "Select type": "유형 선택",
      "All Type": "모든 유형",
      "Edit photo": "사진 편집",
      "Select photo": "사진 선택",
      "Use photo": "사진 사용",
      "Select another photo": "다른 사진 선택",
      "Update avatar success": "아바타 업데이트 성공",
      "Do not remind me again": "다시 알리지 않음",
      "Confirm settings": "설정 확인",
      "Show Margin Type description": "마진 유형 설명 표시",
      "When enabled, you will see the description each time you open Margin Type popup":
        "활성화되면 마진 유형 팝업을 열 때마다 설명이 표시됩니다.",
      "Quantity is too small": "수량이 너무 작습니다",
      "Account Id": "계정 ID",
      "Created time": "생성 시간",
      "Key successfully created": "키가 성공적으로 생성되었습니다",
      "Your key has been created": "키가 생성되었습니다",
      "API key": "API 키",
      "Secret key": "비밀 키",
      "Make sure you copy secret key below as it won't be shown again":
        "비밀 키를 아래로 복사하여 표시되지 않도록 하십시오.",
      Page: "페이지",
      "Page size": "페이지 크기",
      "Load more": "더 불러오기",
      "Nothing found": "찾을 수 없음",
      Filter: "필터",
      Direction: "방향",
      "Order Type": "주문 유형",
      Turnover: "거래액",
      Unfilled: "미체결",
      "Avg. filed price": "평균 체결 가격",
      Maker: "메이커",
      Taker: "테이커",
      "Tx Hash": "트랜잭션 해시",
      "Transaction fee": "거래 수수료",
      "No data": "데이터 없음",
      "Sell/Short": "매도/공매도",
      Home: "홈",
      Favorites: "즐겨찾기",
      Assets: "자산",
      "Spot Trading": "스팟 거래",
      "Futures Trading": "선물 거래",
      Chart: "차트",
      Orderbook: "주문서",
      "Filter by date": "날짜별로 필터링",
      "Funding rate": "자금 비율",
      "Margin rate": "마진 비율",
      "Liq. price": "유동성 가격",
      "Liquidation Clearance": "청산 정리",
      "Closing PnL": "마감 손익",
      "Close at": "마감 시간",
      "Open at": "시작 시간",
      "Filter by coin": "코인별로 필터링",
      Network: "네트워크",
      "Transfer Network": "전송 네트워크",
      "Address/Address Tag": "주소/주소 태그",
      Notes: "노트",
      "Filter by network": "네트워크별로 필터링",
      Search: "검색",
      "Est. PnL": "예상 수익/손실",
      "Deposit records": "입금 내역",
      "Withdraw records": "출금 내역",
      "Continue with Google": "Google 계정으로 계속하기",
      "Already processing. Please wait or confirm on your wallet":
        "이미 처리 중입니다. 기다리거나 지갑에서 확인하세요.",
      "Login to Dikex": "Dikex에 로그인",
      "Link your Dikex account": "Dikex 계정 연결",
      "If you already have a Dikex account, you can connect it to your wallet account":
        "이미 Dikex 계정이 있으면 지갑 계정에 연결할 수 있습니다.",
      "Link existing Dikex account": "기존 Dikex 계정 연결",
      "Your Dikex account (email)": "귀하의 Dikex 계정 (이메일)",
      "Phone setting": "전화 설정",
      "Please Enter your phone": "전화번호를 입력하세요.",
      "Phone number": "전화번호",
      "When enabled, you will allow to receive OTP via phone number":
        "활성화되면 전화번호로 OTP를 받을 수 있습니다.",
      "Phone OTP": "전화 OTP",
      "Enable phone number": "전화번호 사용 설정",
      "OTP will be sent to phone number": "OTP가 전화번호로 전송됩니다.",
      "Setup sign in password": "로그인 비밀번호 설정",
      "This is your first time logging into our system. Please set a login password":
        "이번이 처음으로 시스템에 로그인하는 것입니다. 로그인 비밀번호를 설정하세요.",
      "Please use this password for subsequent logins":
        "다음 로그인에는 이 비밀번호를 사용하세요.",
      "Confirm and Sign in": "확인 및 로그인",
      "Change verification method": "인증 방법 변경",
      "Choose verification method": "인증 방법 선택",
      "Phone number verification": "전화번호 인증",
      "Email OTP": "이메일 OTP",
      "Confirm order": "주문 확인",
      Long: "매수",
      Short: "매도",
      "Address Tag": "주소 태그",
      "Enter The Withdrawal Address Tag": "출금 주소 태그 입력",
      "Withdraw Address Tag": "출금 주소 태그",
      "Please Enter Address Tag": "주소 태그를 입력하세요.",
      "Please Enter Address": "주소를 입력하세요.",
      "Address Is Not Valid": "주소가 유효하지 않습니다.",
      "Please Enter phone number": "전화번호를 입력하세요.",
      "About Dikex": "Dikex 소개",
      ABOUT_CONTENT:
        "Dikex 뒤에는 블록체인 기반의 미래를 믿는 초기 참여자들이 모여 있습니다. 2018년 이후, 우리는 안전하고 편리하며 스마트한 디지털 거래를 보장하여 사용자가 더 현명하게 거래할 수 있도록 돕는 거래소를 구축해 왔습니다.",
      "Our Story": "우리의 이야기",
      ABOUT_STORY:
        "Dikex는 굳은 신념을 가진 10% 중에 마지막이 되기를 원했습니다. 전통적인 금융 배경을 가진 Dikex의 창업팀은 2015년에 블록체인 기술을 발견했지만 당시 산업에서는 '튤립 열매 마니아'로 여겨졌습니다. 2018년, 우리는 비트코인 백서와 이더리움 생태계를 연구한 후 암호화폐에 관심을 갖게 되었습니다. 우리는 암호화폐가 미래에서 중요한 역할을 할 것이라고 믿었으며, 미개하게 남겨진 그룹에게도 혜택이 될 것이라고 생각했습니다. 그러나 시장 대폭락으로 대부분의 투기가 떠나갔고, 10% 미만의 사람들만이 그들의 신념을 고수하고 재건하기를 선택했습니다. Dikex는 이후 공식적으로 창립되었습니다. 곰 시장에서 태어난 Dikex는 사용자를 우선으로 생각하고 제품 혁신에 초점을 맞추며 열성의 정신으로 장기적인 발전을 지지합니다. 회사는 사람들이 암호화폐를 받아들이고 거래 방식을 개선하도록 영감을 주고자 합니다.",
      "We believe in a more equitable future world driven by crypto evolution.":
        "우리는 암호화폐의 진화에 의해 이끌리는 보다 공정한 미래 세계를믿습니다.",
      Mission: "미션",
      "We empower the future of finance by ensuring secure, efficient and smart digital transactions.":
        "우리는 안전하고 효율적이며 스마트한 디지털 거래를 보장하여 재정의 미래를 지원합니다.",
      "Our Value": "우리의 가치",
      "Our Team": "우리 팀",
      "With 1500+ employees from across 60 countries and regions, the Dikex team boasts professionals from all corners of the world, giving us a truly global presence.":
        "60여 개국 및 지역에서 온 1500명 이상의 직원으로 구성된 Dikex 팀은 전 세계 각지의 전문가들로 구성되어 있어 정말로 전 세계적인 존재감을 가지고 있습니다.",
      "Our Services": "우리의 서비스",
      "Trade USDT-M, USDC-M, and Coin-M futures. Go long or short on over 200 coins with high liquidity and low fees.":
        "USDT-M, USDC-M 및 Coin-M 선물을 거래하세요. 높은 유동성과 낮은 수수료로 200개 이상의 코인에 대해 매수 또는 매도하세요.",
      "Build your portfolio from over 750+ promising listed tokens. Join Dikex Launchpad to get early access to crypto gems.":
        "750개 이상의 유망한 상장 토큰 중에서 포트폴리오를 구축하세요. Dikex 런치패드에 가입하여 암호화폐 젬에 빨리 접근하세요.",
      "Start Your Crypto Journey Now!": "지금 암호화폐 여행을 시작하세요!",
      "Trade on a platform trusted by millions of users":
        "수백만 명의 사용자가 신뢰하는 플랫폼에서 거래하세요",
      "Join us": "우리와 함께하세요",
      "Largest crypto copy trading platform":
        "가장 큰 암호화폐 복사 거래 플랫폼",
      "25 million": "2500만",
      "Registered users worldwide": "전 세계 등록 사용자",
      "10 billion USDT": "100억 USDT",
      "Daily trading volume": "일일 거래량",
      "Users First": "사용자 우선",
      "We genuinely care about the voice of each and every user":
        "우리는 모든 사용자의 의견을 진심으로 중요하게 생각합니다.",
      "Integrity & Honesty": "정직과 성실",
      "We prioritize earning trust by consistently adhering to responsible and ethical standards.":
        "우리는 책임감 있고 윤리적인 기준을 일관되게 준수함으로써 신뢰를 얻는 데 중점을 둡니다.",
      "Open Communication": "개방적인 의사 소통",
      "We communicate transparently and promptly, sharing all the good, the bad, and the ugly.":
        "우리는 투명하고 신속하게 의사 소통하며 좋은 것과 나쁜 것, 못생긴 것을 모두 공유합니다.",
      "Deliver Results": "결과 제공",
      "Change won't happen itself. We take deliberate actions to make it happen.":
        "변화는 자연스럽게 일어나지 않습니다. 우리는 그것을 실현하기 위해 의도적인 행동을 취합니다.",
      "Dikex global community": "Dikex 글로벌 커뮤니티",
      COMMUNITY_WELCOME:
        "Dikex 글로벌 커뮤니티에 오신 것을 환영합니다! 여기에서 최신 플랫폼 개발 내용을 확인하고 전문적인 시장 통찰력에 액세스할 수 있습니다. 또한 전 세계의 다른 암호화폐 애호가들과 거래 전략에 대한 토론에 참여할 수 있습니다. 아래 링크를 방문하여 오늘부터 암호화폐 여행을 시작하세요!",
      "Business inquiries": "비즈니스 문의",
      "Quant & MM": "Quant & MM",
      "VIP Services": "VIP 서비스",
      "Enjoy higher discounts and perks": "더 높은 할인 및 혜택 즐기기",
      "Fee Schedule": "수수료 일정",
      "VIP perks": "VIP 혜택",
      "Withdrawal Fees": "출금 수수료",
      "Trade Now": "지금 거래",
      "Withdrawal Fee": "출금 수수료",
      "Platform fee rate": "플랫폼 수수료율",
      "My fee rate": "나의 수수료율",
      "Product news": "제품 뉴스",
      "Search questions, keywords, articles": "질문, 키워드, 기사 검색",
      Announcement: "공지",
      "API Trading'": "API 거래",
      "Download/Register/Log in": "다운로드/등록/로그인",
      Policies: "정책",
      "Deposit/Withdraw": "입금/출금",
      "KYB Verification Guideline": "KYB 인증 가이드라인",
      Other: "기타",
      "Video Guide for Beginners": "초보자를 위한 비디오 가이드",
      Savings: "저축",
      Support: "지원",
      "Recent activity": "최근 활동",
      "Invite friends to get up to 1,62 USDT in cash":
        "친구를 초대하여 최대 1.62 USDT 현금으로 받으세요",
      "You both can get rewards": "둘 다 보상을 받을 수 있습니다.",
      "Referral code": "추천 코드",
      "Referral link": "추천 링크",
      Invite: "초대",
      "Referral history": "추천 내역",
      "Terms and Conditions": "약관 및 조건",
      "Task progress": "작업 진행 상황",
      "In the past 7 days": "지난 7일 동안",
      "In the past 30 days": "지난 30일 동안",
      "In the past 120 days": "지난 120일 동안",
      "Privacy Policy": "개인 정보 보호 정책",
      "Related articles": "관련 기사",
      "Legal Statement": "법적 성명",
      "Term of Use": "이용 약관",
      "Risk Disclosure": "위험 공개",
      "How can we help?": "어떻게 도와드릴까요?",
      "Still can't solve your problem?": "아직 문제를 해결할 수 없습니까?",
      "Our multilingual online customer service helps you solve your problems":
        "다국어 온라인 고객 서비스가 문제를 해결하는 데 도움을 드립니다",
      Contact: "문의하기",
      "Announcement center": "공지 센터",
      "All topics": "모든 주제",
      "Maintenance or system updates": "유지 관리 또는 시스템 업데이트",
      "Coin Listings": "코인 목록",
      Latest: "최신",
      FAQ: "자주 묻는 질문",
      "API Announcement": "API 공지",
      Guideline: "지침",
      "Last News": "최신 뉴스",
      "Blockchain Assets": "블록체인 자산",
      "Latest Activities": "최신 활동",
      "Beginner's guide": "초보자 가이드",
      "Features overview": "기능 개요",
      "Risk management": "리스크 관리",
      "Trading mechanism": "거래 메커니즘",
      "Self-Service": "셀프 서비스",
      "Latest articles": "최신 기사",
      "KYC Submission": "KYC 제출",
      "Reset 2FA": "2FA 재설정",
      "Demo Version": "데모 버전",
      "Submit a Ticket": "티켓 제출",
      Company: "회사",
      "About dikex": "디케스 소개",
      Product: "제품",
      "Referral Program": "추천 프로그램",
      "Vip Program": "VIP 프로그램",
      "Submit A Request": "요청 제출",
      "Help Center": "도움말 센터",
      "Legal & Disclosures": "법적 사항 및 공개",
      "Terms of User": "사용자 약관",
      "Start Crypto Journey Now!": "Start Crypto Journey Now!",
      Today: "오늘",
      "View more": "더 보기",
      Losers: "종료",
      New: "새로운",
      Volume: "거래량",
      Referrals: "추천",
      "Affiliate Program": "제휴 프로그램",
      "Trade faster and more flexibly with the Dikex API":
        "Dikex API를 사용하여 더 빠르고 유연하게 거래하세요.",
      "Create API keys": "API 키 생성",
      "View document": "문서 보기",
      "How to connect to the Dikex API?": "Dikex API에 연결하는 방법은?",
      CONNECT_DIKEX_API:
        "Dikex API를 사용하면 개발자가 프로그램거래, 데이터 획득, 복사 거래 서비스 통합 및 광고 게시를 수행할 수 있습니다.",
      "Apply/Manage API keys": "API 키 신청/관리",
      User: "사용자",
      "Deploy API": "API 배포",
      "Request/Subscribe": "요청/구독",
      "Trading API": "거래 API",
      "Market WebSocket": "시장 WebSocket",
      "Designed for institutional and professional users":
        "기관 및 전문가 사용자를 위해 설계되었습니다.",
      "Specialized solutions based on user needs":
        "사용자 요구에 따른 특별한 솔루션",
      "Contact Us": "문의하기",
      "Custom-tailored solutions": "맞춤형 솔루션",
      "Better funding rate": "더 나은 자금 조달률",
      "Lower latency": "낮은 대기 시간",
      "Higher trading limit": "높은 거래 한도",
      "Contact BD": "BD 연락처",
      "Market makers": "시장 메이커",
      "Quantitative trading": "정량적 거래",
      "Free standardized API": "무료 표준 API",
      "SDK in five programming languages": "다섯 가지 프로그래밍 언어의 SDK",
      "24/7 professional customer service": "24시간 365일 전문 고객 서비스",
      "Third-party platform": "제3자 플랫폼",
      "Share Dikex's liquidity": "Dikex의 유동성 공유",
      "Integrate Dikex copy trading": "Dikex 복사 거래 통합",
      "Non-disclosure broker model": "비공개 중개 모델",
      "1. How to create API keys?": "1. API 키를 만드는 방법은?",
      CREATE_API_KEYS:
        "1.1. Dikex 계정으로 로그인한 후 사용자 센터의 API 관리 페이지로 이동합니다. 1.2 API 키 생성을 선택합니다. 1.3 참고 사항 및 암호를 만들고 권한 설정을 구성한 다음 IP 주소를 입력합니다. 계정을 확인한 후 다음을 선택합니다.",
      "2. Is it free to use API?": "2. API 사용은 무료입니까?",
      "Using the API is free.": "API 사용은 무료입니다.",
      "3. What is the difference between WebSocket and REST and which one should I choose?":
        "3. WebSocket과 REST의 차이점은 무엇이며 어느 것을 선택해야 합니까?",
      DIFFERENCE_WEBSOCKET_REST:
        "WebSocket은 실시간 시장 동향 및 데이터 업데이트(가격 변동 및 거래량과 같은)를 필요로 하는 경우 더 나은 선택입니다. WebSocket은 메시지 기반 프로토콜로, REST API보다 낮은 대기 시간과 더 효율적인 데이터 전송을 제공합니다. REST API는 가끔 시장 데이터를 확인하는 것만 필요한 경우(거래 내역 및 캔들스틱 차트 데이터와 같은) 더 나은 선택입니다. 이는 HTTP 프로토콜을 기반으로 하며 다양한 응용 프로그램을 지원합니다. 또한 자동화된 거래 프로그램을 개발하는 데 더 나은 선택입니다.",
      "4. Is the API symbol case-sensitive?":
        "4. API 심볼은 대소문자를 구분합니까?",
      "Yes it is. The symbol must be in uppercase.":
        "네, 대소문자를 구분합니다. 심볼은 대문자여야 합니다.",
      "Market API": "시장 API",
      "Dikex VIP service": "Dikex VIP 서비스",
      "Dikex provides a full range of exclusive perks to VIP users.":
        "Dikex는 VIP 사용자에게 독점 혜택의 전 범위를 제공합니다.",
      "Vip News": "VIP 뉴스",
      "Vip Trial Pass": "VIP 체험 패스",
      "VIP requirements": "VIP 요구 사항",
      vip_requirement_1:
        "30일간 거래량: 30일 동안 모든 본 계정 및 부 계정 거래의 총 거래량. 부 계정은 생성 시 메인 계정과 동일한 자금 조달률을 갖게 됩니다.",
      vip_requirement_2:
        "'자산: 메인 및 서브 계정의 모든 계정 자산, 표시, USDC-M 선물, USDT-M 선물, Coin-M 선물, P2P 및 얻을 수 있는 자산. 다음 날 오전 12시 (UTC+0)에 계산되고 계산 시간에 코인-USDT 환율에 따라 변환됩니다. 표시 자산에는 사용 가능한, 잠긴 및 동결된 자산이 포함되어 있으며, 선물 자산에는 마진화된 및 동결된 자산이 포함되어 있습니다. 실현되지 않은 수익/손실은 제외되며, P2P 자산에는 사용 가능한 자산이 포함됩니다(동결된 자산은 '사용 가능'으로 카운트되지 않음).",
      vip_requirement_3:
        "24시간 출금 한도: Dikex는 사용자의 VIP 레벨에 따라 일일 출금 한도를 설정합니다. 모든 동전은 USD로 계산되며 변환 후 최종 금액은 해당 VIP 레벨의 출금 한도보다 작아야 합니다. KYC 검증을 완료하지 않은 사용자의 출금 한도는 20000 USD입니다.",
      vip_requirement_4:
        "사용자의 표시 거래량, 선물 거래량 또는 자산이 서로 다른 VIP 레벨의 자격을 갖추면 사용자는 가장 높은 VIP 레벨의 VIP 레벨을 즐길 수 있습니다. 사용자가 표시 거래 VIP 1에 대한 VIP 요구 사항을 충족하는 경우 다른 모든 거래 유형도 해당하는 VIP 레벨로 업그레이드됩니다. 예를 들어, 사용자가 표시 거래 VIP 1의 VIP 요구 사항을 충족하는 경우 자동으로 선물 거래 VIP 1로 업그레이드됩니다.",
      vip_requirement_5:
        "VIP 레벨은 매일 오전 1시 (UTC+0)에 자동으로 업데이트됩니다. 사용자의 거래 행위가 리스크 컨트롤 시스템을 트리거하는 경우 Dikex는 사용자의 VIP 레벨을 조정할 권리를 보유합니다.",
      "Institutional users are not eligible for the VIP program.":
        "기관 사용자는 VIP 프로그램의 자격이 없습니다.",
      vip_service_contact:
        "Dikex는 VIP 프로그램에 대한 최종 해석 권한을 보유합니다. 추가 질문이 있으시면 vip@dikex.com으로 문의하십시오.",
      "Exclusive VIP perks": "독점 VIP 혜택",
      "Being a Dikex VIP has never been more rewarding.":
        "Dikex VIP가 더욱 보람스러워진 적이 없습니다.",
      "Hot project watchlist": "핫 프로젝트 감시 목록",
      "Premium VIP customer service": "프리미엄 VIP 고객 서비스",
      "Express deposits and withdrawals": "빠른 입금 및 출금",
      "Cutting-edge security": "최첨단 보안",
      "When it comes to security, we go the extra mile.":
        "보안에 관해서는 우리가 더 노력합니다.",
      "Proof-of-Reserves": "보유 증명",
      "We guarantee a minimum 1:1 reserve ratio to cover customer funds":
        "고객 자금을 보호하기 위해 최소 1:1의 예비 비율을 보장합니다.",
      "Cold storage": "콜드 스토리지",
      "Most digital assets are stored in offline multi-signature wallets":
        "대부분의 디지털 자산은 오프라인 다중 서명 지갑에 보관됩니다.",
      "Become a VIP today": "오늘 VIP가 되세요",
      "If you're a VIP on another exchange, we offer you VIP at one level higher.":
        "다른 거래소에서 VIP인 경우 더 높은 VIP를 제공합니다.",
      "Do you want to change the current language?":
        "현재 언어를 변경하시겠습니까?",
      "Exclusive account manager": "독점 계정 관리자",
      "Sign in": "로그인",
      "to get referral code": "추천 코드를 받으려면",
      "No records yet.": "아직 기록이 없습니다.",
      "friends now!": "지금 친구들을 초대하세요!",
      "Three Steps to Attractive Commissions with Our Referral Program":
        "우리의 추천 프로그램으로 유치한 커미션까지 세 단계로!",
      "Join The Dikex Referral program Today!":
        "오늘 Dikex 추천 프로그램에 가입하세요!",
      "Generate passive income and work towards financial independence.":
        "수동 수입을 발생시키고 재정적 독립을 향해 나아가세요.",
      "Sign up & get your referral code": "가입하고 추천 코드를 받으세요",
      "Create an account in minutes and start earning commission.":
        "몇 분 만에 계정을 생성하고 수수료를 시작하세요.",
      "Share your referral link": "추천 링크를 공유하세요",
      "Invite friends or fans to register as new users using your exclusive referral link or invitation code.":
        "독점적인 추천 링크나 초대 코드를 사용하여 친구나 팬을 새로운 사용자로 등록하도록 초대하세요.",
      "Earn 5% on Every Referral Trade": "모든 추천 거래에서 5%를 법으세요",
      "Receive a 5% commission on every trade your referrals make-no limits, just continuous earnings!":
        "귀하의 추천으로 발생하는 모든 거래에서 5%의 수수료를 받으세요 - 제한 없이 지속적인 수익만을 얻으세요!",
      "Continue with MetaMask": "MetaMask로 계속하기",
      "New Here?": "여기에 처음이신가요?",
      "Create an account": "계정 생성",
      "Order quantity": "주문 수량",
      "Invitation ID": "초대 ID",
      "Invitation Link": "초대 링크",
      Referral: "추천",
      "Total number of addresses": "총 주소 수",
      "You can add up to 500 on-chain addresses and internal addresses":
        "온체인 주소와 내부 주소를 최대 500개까지 추가할 수 있습니다",
      "On-chain Address": "온체인 주소",
      "Internal Transfer Address": "내부 전송 주소",
      "After verification": "검증 후",
      "Commission records": "커미션 기록",
      "Withdrawal records": "출금 기록",
      "Your name": "당신의 이름",
      "Enter your mail to reset the password":
        "비밀번호를 재설정하려면 이메일을 입력하세요",
      "Back to sign in": "로그인 화면으로 돌아가기",
      Login: "로그인",
      Profile: "프로필",
      "Manage Addresses": "주소 관리",
      "Identity Verification": "신원 확인",
      Affiliates: "제휴사",
      "Futures Account": "선물 계좌",
      "Futures Records": "선물 기록",
      "Spot Records": "현물 기록",
      "Deposit Records": "입금 기록",
      "Withdraw Records": "출금 기록",
      "Commission Records": "수수료 기록",
      "Select An Account": "계정 선택",
      "Transfer Amount": "이체 금액",
      "Transfer out": "출금",
      "Transfer in": "입금",
      "Choose A Network": "네트워크 선택",
      "Deposit Address": "입금 주소",
      "Minimum Deposit Amount": "최소 입금 금액",
      "Transfer To": "이체 대상",
      Wallet: "지갑",
      "Withdraw To": "출금 대상",
      "Choose from address book": "주소록에서 선택",
      "Confirm that the receiving platform accepts the crypto and network you have selected. You may lose your assets if you choose an incompatible network":
        "수신 플랫폼이 선택한 암호화폐 및 네트워크를 수락하는지 확인하십시오. 호환되지 않는 네트워크를 선택하면 자산을 잃을 수 있습니다",
      "On-chain": "온체인",
      "Internal Transfer": "내부 이체",
      "Select Network": "네트워크 선택",
      "Withdraw Address": "출금 주소",
      "Transfer records": "이체 기록",
      Reload: "새로 고침",
      Balance: "잔액",
      Buy: "구매",
      "Deposit Confirmation": "입금 확인",
      "Phone Number": "전화번호",
      "I have read and agree to the above agreement":
        "위의 약관을 읽고 동의합니다",
      "Mail OTP": "메일 OTP",
      "Index price": "지수 가격",
      Success: "성공",
      Source: "출처",
      Order: "주문",
      "Flash close": "즉시 닫기",
      "Full position TP/SL": "전체 포지션 TP/SL",
      Batch: "배치",
      Trailing: "추적",
      "Partial position TP/SL": "부분 포지션 TP/SL",
      "Trailing TP/SL": "추적 TP/SL",
      Details: "세부사항",
      Lite: "라이트",
      "the position will close {{amount}} at {{price}}. Expected return":
        "포지션은 {{price}}에서 {{amount}}에 닫힙니다. 예상 수익",
      "Continue to add": "계속 추가",
      "By order time": "주문 시간별",
      "By trigger price": "트리거 가격별",
      "Cancel all orders": "모든 주문 취소",
      "Execution price": "실행 가격",
      "Can not place tp/sl order": "TP/SL 주문을 할 수 없습니다",
      "Your position is too small to place TP/SL":
        "포지션이 너무 작아 TP/SL을 할 수 없습니다",
      "Please input TP price": "TP 가격을 입력하세요",
      "Please input SL price": "SL 가격을 입력하세요",
      "Please input trigger price": "트리거 가격을 입력하세요",
      "TP/SL Market": "TP/SL 시장",
      "Callback rate": "콜백 비율",
      "Remaining quantity for TP/SL": "TP/SL 남은 수량",
      "Trailing Stop": "추적 정지",
      "Filled orders only": "체결된 주문만",
      "Show current": "현재 표시",
      "Other options": "다른 옵션",
      "Filter by type": "유형별 필터",
      "Filter by futures": "선물별 필터",
      "Trigger MMR": "트리거 MMR",
      "Current MMR": "현재 MMR",
      "When MMR ≥ {{mmrTrigger}}%, the position will be closed at market price. The higher the MMR, the higher the risk. Position liquidation/reduction will be triggered when the MMR reaches 100%.":
        "MMR이 {{mmrTrigger}}% 이상일 때, 포지션은 시장 가격으로 청산됩니다. MMR이 높을수록 위험이 높아집니다. MMR이 100%에 도달하면 포지션 청산/감소가 트리거됩니다.",
      "Update MMR success": "MMR 업데이트 성공",
      "Maintenance margin": "유지 증거금",
      "Maintenance margin rate (MMR)": "유지 증거금 비율 (MMR)",
      "Position tier intro": "포지션 계층 소개",
      "TP buy price": "TP 구매 가격",
      "Share profit": "이익 공유",
      "Shared at": "공유 시간",
      "PnL amount": "PnL 금액",
      "Scan QR to sign up to Dikex now.":
        "지금 Dikex에 가입하려면 QR을 스캔하세요.",
      Download: "다운로드",
      Copy: "복사",
      "Copy image success": "이미지 복사 성공",
      "Full TP/SL": "전체 TP/SL",
      "Partial TP/SL": "부분 TP/SL",
      "SL buy price": "SL 구매 가격",
      "SL sell price": "SL 판매 가격",
      "Trading panel": "거래 패널",
      "Can not place TP order": "TP 주문을 할 수 없습니다",
      "Your position is too small to place TP":
        "포지션이 너무 작아 TP를 할 수 없습니다",
      "Can not place SL order": "SL 주문을 할 수 없습니다",
      "Your position is too small to place SL":
        "포지션이 너무 작아 SL을 할 수 없습니다",
      References: "참고자료",
      Standard: "표준",
      "Pro-Left": "프로-왼쪽",
      "Pro-Right": "프로-오른쪽",
      "Candlestick time": "캔들스틱 시간",
      "TP/SL order": "TP/SL 주문",
      "Trailing stop order": "추적 정지 주문",
      "Trailing percent": "추적 백분율",
      "Copy Image": "이미지 복사",
      "Sponsor not found": "스폰서를 찾을 수 없음",
      "If you continue to register, the system will skip updating the sponsor. You can update later in the settings.":
        "계속 등록하면 시스템이 스폰서 업데이트를 건너뜁니다. 나중에 설정에서 업데이트할 수 있습니다.",
      "Spot trading notifications": "현물 거래 알림",
      "Spot notification sounds": "현물 거래 알림 소리",
      "Spot notifications": "현물 거래 알림",
      "Trigger order": "트리거 주문",
      "Futures trading notifications": "선물 거래 알림",
      "Futures notification sounds": "선물 거래 알림 소리",
      "Post only order": "게시 전용 주문",
      "Futures notifications": "선물 거래 알림",
      "Vip Upgrade Progress": "VIP 업그레이드 진행 상황",
      "Spot Vol": "현물 거래량",
      "Futures Vol": "선물 거래량",
      "Account Assets": "계정 자산",
      Loading: "로딩 중",
      "Your VIP upgrade progress": "귀하의 VIP 업그레이드 진행 상황",
      "You still need": "아직 필요",
      "to reach": "도달하기 위해",
      "User level": "사용자 레벨",
      "Fast track application": "빠른 신청",
      "Spot fees": "현물 수수료",
      "Current tier": "현재 등급",
      "Maker/Taker": "메이커/테이커",
      "Futures transaction fees": "선물 거래 수수료",
      "Spot order confirmation": "현물 주문 확인",
      "Futures order confirmation": "선물 주문 확인",
      "Help center": "도움말 센터",
      "Futures trading help center": "선물 거래 도움말 센터",
      "See other questions": "다른 질문 보기",
      "Beginner's Guide": "초보자 가이드",
      "Futures overview": "선물 개요",
      "Risk management": "위험 관리",
      "Trading mechanism": "거래 메커니즘",
      "Feature guide": "기능 가이드",
      "Spot trading help center": "현물 거래 도움말 센터",
      "Spot guide": "현물 가이드",
      Guideline: "가이드라인",
      "Blockchain Assets": "블록체인 자산",
      "Please inter trigger price": "트리거 가격을 입력하세요",
      "Please check your phone number ({{phone}}) for the verification code we just sent you and enter that code in the box below":
        "보내드린 인증 코드를 확인하고 아래 상자에 입력하세요 ({{phone}})",
      "Since we still need your email, please enter it before registering.":
        "이메일이 아직 필요하니 등록 전에 입력해 주세요.",
      "Your email": "이메일",
      "Email already exists": "이메일이 이미 존재합니다",
      "Scan this code with the Dikex app to log in instantly.":
        "Dikex 앱으로 이 코드를 스캔하여 즉시 로그인하세요.",
      Activities: "활동",
      "Change password": "비밀번호 변경",
      "Enable GA": "GA 활성화",
      "Disable GA": "GA 비활성화",
      "Update username": "사용자 이름 업데이트",
      "Update Anti-fishing code": "피싱 방지 코드 업데이트",
      "Enable withdrawal whitelist": "출금 허용 목록 활성화",
      "Disable withdrawal whitelist": "출금 허용 목록 비활성화",
      "Disable phone number": "전화번호 비활성화",
      "All type": "모든 유형",
      Device: "기기",
      "Remove device": "기기 제거",
      "Login at": "로그인 시간",
      "Device manager": "기기 관리자",
      Seconds: "초",
      Minutes: "분",
      Hours: "시간",
      Days: "일",
      Notifications: "알림",
      ALL: "전체",
      Campaigns: "캠페인",
      "System messages": "시스템 메시지",
      Previous: "이전",
      "Mark all as read": "모두 읽음 처리",
      "Mark all as read successfully": "모두 읽음 처리 성공",
      "Mark all as read failed": "모두 읽음 처리 실패",
      "Show less": "간략히 보기",
      "Show more": "자세히 보기",
      "Alert logs": "경고 로그",
      "Create notice successfully": "공지 생성 성공",
      "Create notice failed": "공지 생성 실패",
      Pair: "페어",
      "Alert type": "경고 유형",
      "Price Rise": "가격 상승",
      "Price 24h Rise Percent": "24시간 가격 상승 비율",
      "Price 24h Fall Percent": "24시간 가격 하락 비율",
      "Price Rise To Percent": "가격이 특정 비율 상승",
      "Price Fall To Percent": "가격이 특정 비율 하락",
      "Entering Channel": "채널 진입",
      "Exiting Channel": "채널 이탈",
      "Price Alert": "가격 경고",
      "Upper Bound": "상한선",
      "Lower Bound": "하한선",
      "The upper bound must be greater than the lower bound":
        "상한선은 하한선보다 커야 합니다",
      Frequency: "주기",
      "Only Once": "한 번만",
      "Once A Day": "매일 한 번",
      "Every Time": "매 번",
      "When the condition reaches the set value, it will raise an alert via the selected method, and will not be received again after one reminder":
        "조건이 설정한 값에 도달하면 선택한 방법으로 경고를 발생시키며, 한 번의 알림 후에는 다시 받지 않습니다",
      "Remind once a day (between 0:00 and 24:00). It will be triggered when the condition reaches the next day":
        "하루에 한 번 알림 (0:00 ~ 24:00 사이). 조건이 다음 날에 도달할 때 트리거됩니다",
      "Remind continuously until cancelled. In order not to disturb you, we set the reminder rules:":
        "취소할 때까지 계속 알림을 받습니다. 방해되지 않도록 알림 규칙을 설정했습니다:",
      "No more than 1 times per second": "1초에 한 번 이상은 아닙니다",
      "No more than 2 times per minute": "1분에 2회 이상은 아닙니다",
      "No more than 3 times within 1 hour": "1시간 내에 3회 이상은 아닙니다",
      "No more than 10 times a day": "하루에 10회 이상은 아닙니다",
      "Please select alerts to delete": "삭제할 경고를 선택하세요",
      "Selected alerts deleted successfully!":
        "선택한 경고가 성공적으로 삭제되었습니다!",
      "Failed to delete selected alerts!": "선택한 경고 삭제 실패!",
      "Update alert status successfully!": "경고 상태 업데이트 성공!",
      "Update alert status failed!": "경고 상태 업데이트 실패!",
      "Alert monitoring": "경고 모니터링",
      "Bot creation notice": "봇 생성 공지",
    },
  },
  zh: {
    translation: {
      Account: "账户",
      "Email is not valid": "电子邮件无效",
      "Please Enter your email": "请输入您的电子邮件",
      "Please Enter your password": "请输入您的密码",
      "Invalid Password": "无效密码",
      "Please check your e-mail for the verification code we just sent you and enter that code in the box below":
        "请查看我们刚刚发送给您的电子邮件以获取验证码，并在下方框中输入该代码",
      "Please Enter your name": "请输入您的姓名",
      Username: "用户名",
      Email: "电子邮件",
      Password: "密码",
      "Sign Up": "注册",
      "Terms of Service & Privacy Policy": "服务条款和隐私政策",
      "By signing up, I agree to our": "通过注册，我同意我们的",
      OR: "或者",
      Back: "返回",
      Register: "注册",
      Thank: "谢谢",
      "Your account has been created. We'll take you to the login page momentarily":
        "您的帐户已创建。我们将立即带您转到登录页面",
      "Forgot Password": "忘记密码",
      "Sign In": "登录",
      WRONG_CREDENTIALS: "错误的凭据",
      Settings: "设置",
      "Sign Out": "登出",
      Dashboard: "仪表板",
      "Security Checkup": "安全检查",
      "Two-Factor Authentication (2FA)": "双因素认证（2FA）",
      "Authentication Settings": "身份验证设置",
      "Sign in password": "登录密码",
      "The password that used to log in to your account":
        "用于登录您的帐户的密码",
      Manager: "管理",
      "Confirm Password": "确认密码",
      "Please enter confirm password": "请输入确认密码",
      Cancel: "取消",
      Save: "保存",
      "Current Password": "当前密码",
      "Change password success": "更改密码成功",
      PASSWORD_WRONG: "当前密码无效",
      "Google Authentication (GA 2FA)": "Google身份验证（GA 2FA）",
      "Use Google Authenticator to protect your account and transactions":
        "使用Google Authenticator保护您的帐户和交易",
      ON: "开启",
      OFF: "关闭",
      "Enable Google authentication": "启用Google身份验证",
      "Scan with Google Authenticator App on your phone":
        "使用手机上的Google Authenticator应用进行扫描",
      "Enter this code in the app": "在应用程序中输入此代码",
      Continue: "继续",
      "Enter GA code to enable": "输入GA代码以启用",
      "Please Enter your Ga Code": "请输入您的GA代码",
      "Ga code": "GA代码",
      "Enable GA Authentication success": "启用GA身份验证成功",
      "Disabled Google Authentication": "已禁用Google身份验证",
      "Enter GA code to disable": "输入GA代码以禁用",
      Disable: "禁用",
      "Disable GA Authentication success": "禁用GA身份验证成功",
      "Reset password": "重置密码",
      Submit: "提交",
      "Forgot Password?": "忘记密码？",
      "Your password has been reset. We'll take you to the login page momentarily":
        "您的密码已重置。我们将立即带您转到登录页面",
      "Please enter GA code in the box below": "请在下面的框中输入GA代码",
      "Don't receive OTP?": "没有收到OTP？",
      "Resend after": "之后重新发送",
      "Resend OTP": "重新发送OTP",
      "Resend OTP success": "重新发送OTP成功",
      "Advanced Security": "高级安全",
      "Contains at least 8 characters": "至少包含8个字符",
      "At least one uppercase": "至少一个大写字母",
      "At least one lowercase": "至少一个小写字母",
      "At least one number": "至少一个数字",
      "At least one special character": "至少一个特殊字符",
      "Anti-phishing code": "防钓鱼代码",
      "All emails we sends to you will include your anti-phishing code so you know it's from us":
        "我们发送给您的所有电子邮件都将包含您的防钓鱼代码，以便您知道它是来自我们的",
      "Set Anti-phishing Code": "设置防钓鱼代码",
      "What is an anti-phishing code?": "什么是防钓鱼代码？",
      "The anti-phishing code is a string of characters that you set yourself, which can help you identify websites and emails that are counterfeit":
        "防钓鱼代码是您自己设置的一系列字符，可以帮助您识别伪造的网站和电子邮件",
      "Where will the anti-phishing code appear?": "防钓鱼代码将出现在哪里？",
      "When set, this code will be included in emails from Diket. Any other emails without this code are scams":
        "设置后，此代码将包含在来自Diket的电子邮件中。没有此代码的任何其他电子邮件都是诈骗",
      "Please Enter Anti-phishing code": "请输入防钓鱼代码",
      "Withdrawal whitelist": "提款白名单",
      "When enabled, you'll only be able to withdraw to whitelisted addresses":
        "启用后，您只能提款到白名单中的地址",
      Close: "关闭",
      "All coin": "所有币种",
      "Not found": "未找到",
      "Update success": "更新成功",
      "Not verified": "未验证",
      "Update your name": "更新您的姓名",
      "Update Referral ID": "更新推荐ID",
      "Anti-phishing  protect": "防钓鱼保护",
      "Invite Code": "邀请码",
      "Please Enter invite code": "请输入邀请码",
      "Update Profile": "更新资料",
      Enable: "启用",
      "Enable withdraw whitelist": "启用提款白名单",
      "Disable withdraw whitelist": "禁用提款白名单",
      "Please enter login password": "请输入登录密码",
      "All network": "所有网络",
      "Add new address": "添加新地址",
      Address: "地址",
      "Please Enter address": "请输入地址",
      Tag: "标签",
      Note: "备注",
      "Invalid address": "无效地址",
      "Add address success": "添加地址成功",
      Delete: "删除",
      Confirm: "确认",
      "Are you sure you want to delete?": "您确定要删除吗？",
      "Delete success": "删除成功",
      "Invite code [ Optional ]": "邀请码 [ 可选 ]",
      "Invited friends": "已邀请的朋友",
      Level: "等级",
      "View team": "查看团队",
      Funding: "资金",
      Spot: "现货",
      Future: "期货",
      "24H Change": "24小时变化",
      "24H High": "24小时最高",
      "24H Low": "24小时最低",
      "24H Turnover": "24小时成交量",
      "24H Volume": "24小时交易量",
      Price: "价格",
      Amount: "数量",
      Total: "总计",
      "Order book": "订单簿",
      "Market Trades": "市场交易",
      BUY: "买入",
      SELL: "卖出",
      Limit: "限价",
      Market: "市价",
      "TP/SL": "止盈/止损",
      Available: "可用",
      "Order price": "订单价格",
      Quantity: "数量",
      Coin: "币种",
      "Price must be greater than or equal to": "价格必须大于或等于",
      "Price must be less than or equal to": "价格必须小于或等于",
      "Quantity must be greater than or equal to": "数量必须大于或等于",
      "Quantity must be less than or equal to": "数量必须小于或等于",
      "You have never logged in to your account on this device. To continue logging in, please confirm it is you by entering OTP in the box below and clicking Accept":
        "您从未在此设备上登录过您的帐户。要继续登录，请在下方框中输入OTP并点击接受以确认是您本人",
      "Trusted device": "信任设备",
      Accept: "接受",
      "Type OTP here": "在此输入OTP",
      "Min quantity is": "最小数量为",
      "Mail OTP confirm": "邮件OTP确认",
      "Request OTP": "请求OTP",
      "Request success": "请求成功",
      Balances: "余额",
      "24h Fluctuations": "24小时波动",
      "Latest price": "最新价格",
      "Order success": "订单成功",
      "Open order": "未完成订单",
      "Order history": "订单历史",
      Symbol: "符号",
      Side: "方向",
      "Time in Force": "有效时间",
      Filled: "已成交",
      Type: "类型",
      "Cancel order success": "取消订单成功",
      Date: "日期",
      "Close this order": "关闭此订单",
      Status: "状态",
      "Market price": "市场价格",
      "Trigger price": "触发价格",
      "Trigger Type": "触发类型",
      "Limit price": "限价",
      "Trailing Percent": "跟踪百分比",
      Time: "时间",
      "Select interval": "选择间隔",
      "Order detail": "订单详情",
      Fee: "费用",
      "The Total Value Of Assets": "资产总价值",
      Deposit: "存款",
      Withdraw: "提款",
      Transfer: "转账",
      "Assets Overview": "资产概览",
      "Spot Account": "现货账户",
      "Future Account": "期货账户",
      "Select A Coin": "选择币种",
      "Choose A Networks": "选择网络",
      Tips: "提示",
      "Do not deposit assets order than": "不要存入除",
      "as doing so may result in the irretrievability of the deposited assets":
        "否则可能导致存入的资产无法找回",
      "Deposits below the minimum amount will not be credited and cannot be refunded":
        "低于最低金额的存款将不被记入账户且无法退款",
      "Ensure the security of your computer and browser to prevent information from being tampered with or leaked":
        "确保您的计算机和浏览器的安全，防止信息被篡改或泄露",
      Effect: "效果",
      "Buy/Long": "买入/做多",
      Expense: "费用",
      Maximum: "最大",
      "Margin mode": "保证金模式",
      "Under cross margin mode, the full amount of funds in your available balance will be drawn to prevent liquidation. Any realized PnL from other positions can be drawn to add margin to a negative position. Under isolated margin mode, your liability is limited to the initial margin posted. The margin that the position can lose is limited to the initial margin allocated. The position is liquidated when the initial margin is used up. The remaining available margin will not be drawn to cover the loss.":
        "在全仓模式下，为防止强制平仓，将提取您可用余额中的全部资金。其他仓位的已实现盈亏可提取用于增加负仓位的保证金。在逐仓模式下，您的责任仅限于已投入的初始保证金。仓位可亏损的保证金仅限于分配的初始保证金。初始保证金用尽时，该仓位将被强制平仓。剩余可用保证金将不会被提取来覆盖亏损。",
      "Adjust leverage": "调整杠杆",
      Leverage: "杠杆",
      "Maximum position at current leverage": "当前杠杆下的最大仓位",
      "Selecting to trade with a leverage greater than 10x can easily lead to liquidation. Please adjust with caution":
        "选择使用大于10倍的杠杆交易可能会导致容易被强制平仓。请谨慎调整",
      Positions: "仓位",
      "Select futures": "选择期货",
      "Est. liq. price": "预估强平价格",
      "Mark price": "标记价格",
      "Average price": "平均价格",
      Position: "仓位",
      "Open orders": "挂单",
      Margin: "保证金",
      "Unrealized Pnl (ROE)": "未实现盈亏 (ROE)",
      "Realized Pnl": "已实现盈亏",
      "Limit order": "限价单",
      "Market order": "市价单",
      Action: "操作",
      "Adjust Leverage success": "调整杠杆成功",
      "Adjust margin": "调整保证金",
      "Close Position": "平仓",
      "Limit Close": "限价平仓",
      "Current price": "当前价格",
      "Open price": "开仓价格",
      "Max close": "最大平仓",
      "Close price": "平仓价格",
      "Close quantity": "平仓数量",
      "Close success": "平仓成功",
      "Market Close": "市价平仓",
      "Welcome to Dikex": "欢迎来到 Dikex",
      "Have an account?": "有账号吗？",
      "Enter your email": "输入您的邮箱",
      "Referral code (Optional)": "推荐码（可选）",
      "Close position": "平仓",
      "Increase margin": "增加保证金",
      "Decrease margin": "减少保证金",
      "Increase Amount": "增加金额",
      Max: "最大",
      "Can increase by": "可增加",
      "Est. liq. Price": "预估强平价格",
      "Est. liq. Price after increase": "增加后的预估强平价格",
      "Decrease Amount": "减少金额",
      "Can decrease by": "可减少",
      "Please input amount": "请输入金额",
      "Amount must be greater than": "金额必须大于",
      "Amount must be less than": "金额必须小于",
      "Position history": "仓位历史",
      Futures: "期货",
      "Open time": "开仓时间",
      "Avg. entry price": "平均入场价",
      "Avg. exit price": "平均平仓价",
      "Position PNL": "仓位盈亏",
      "Close/Liq. time": "平仓/强平时间",
      "Post only": "仅发布",
      bullish: "看涨",
      bearish: "看跌",
      "Close amount": "平仓数量",
      "Take profit": "止盈",
      Clear: "清除",
      "Trailing percent must be greater than 0%": "跟踪百分比必须大于0%",
      "When the {{mode}} reaches": "当{{mode}}达到",
      "the position will be closed. Expected return": "仓位将被关闭。预期回报",
      "Last price": "最新价格",
      "Stop loss": "止损",
      Trigger: "触发",
      "Total margin": "总保证金",
      "Filled Size": "已成交量",
      "Filled margin": "已成交保证金",
      "Est. liq. Price after decrease": "减少后的预估强平价格",
      "Take profit success": "止盈成功",
      "Stop loss success": "止损成功",
      "Tiered maintenance margin rate": "分级维持保证金率",
      "I have read and agreed to the Dikex": "我已阅读并同意 Dikex",
      "User Agreement": "用户协议",
      and: "和",
      "Privacy policy": "隐私政策",
      "Already have an account?": "已有账号？",
      "You're almost there": "您快完成了",
      "Welcome back!": "欢迎回来！",
      "Forgot your password?": "忘记密码？",
      "Don't worry, If happens to all of us": "别担心，大家都会遇到这种情况",
      "Enter a new password": "输入新密码",
      "Once you reset your password, any withdrawals and P2P sales will be put on hold for 24 hours.":
        "重置密码后，任何提现和P2P销售将被暂停24小时。",
      Asset: "资产",
      "Total Balance": "总余额",
      "Today's PnL": "今日盈亏",
      "Transaction history": "交易记录",
      Crypto: "加密货币",
      "Hide zero - balance assets": "隐藏零余额资产",
      Reserved: "已预留",
      "Available Balance": "可用余额",
      Actions: "操作",
      Trade: "交易",
      "Buy Crypto": "购买加密货币",
      "USDT-M futures": "USDT-M期货",
      Frozen: "冻结",
      "Asset Account": "资产账户",
      "Unrealized PnL": "未实现盈亏",
      "Estimated assets": "估计资产",
      "Trade Smarter": "更聪明地交易",
      With: "使用",
      "Sign up now and get a welcome gift of": "立即注册并获得欢迎礼物",
      "Email / Phone number": "电子邮件/电话号码",
      "Sign up Now": "立即注册",
      "Sign Up for Rewards": "注册以获取奖励",
      "Top Gainers": "涨幅榜",
      "New Listings": "新上市",
      "Catch Your Next Trading Opportunity": "抓住您的下一个交易机会",
      "Hot Derivatives": "热门衍生品",
      "Hot Coins": "热门币种",
      "Search Coins": "搜索币种",
      "Trade Anytime, Anywhere": "随时随地交易",
      "Open new positions instantly, whether it's on Dikex App or Web.":
        "立即开设新仓位，无论是在Dikex应用程序还是网页上。",
      "Buy, Sell, And Trade On The Go": "随时随地买卖和交易",
      "Manage your holdings from your mobile device":
        "通过移动设备管理您的持仓",
      "Take Control Of Your Wealth": "掌控您的财富",
      "Rest assured you (and only you) have access to your funds":
        "放心，只有您可以访问您的资金",
      "GET IT ON": "下载",
      "Download on the": "下载在",
      "Your Trusted Crypto Exchange": "您值得信赖的加密货币交易所",
      "Let's Trade Now": "立即交易",
      Fiat: "法定货币",
      "Over 149 fiat currencies supported": "支持超过149种法定货币",
      "Futures, spot, Margin, bot....": "期货、现货、保证金、机器人...",
      Earn: "赚取",
      "Boost your crypto profits safely and easily!":
        "安全轻松地提升您的加密货币收益！",
      "We are the most trusted cryptocurrency platform.":
        "我们是最值得信赖的加密货币平台。",
      Clarity: "清晰度",
      "We help you make sense of the coins, the terms, the dense charts and market changes.":
        "我们帮助您理解各种硬币、术语、密集的图表和市场变化。",
      Confidence: "信心",
      "Our markets are always up to date, sparking curiosity with real-world relevance.":
        "我们的市场始终保持最新，引发对现实世界相关性的好奇心。",
      Community: "社区",
      "We supports the crypto community, putting data in the hands which need it most.":
        "我们支持加密货币社区，将数据交给最需要的人。",
      "Our Commitment to Compliance and Your Protection":
        "我们对合规和您的保护的承诺",
      "Triple-Layer Asset": "三层资产保护",
      "Experience Triple Fund Protection with our cold wallet storage, ensuring strong security for user assets through comprehensive protection, for a safe and reliable experience.":
        "通过我们的冷钱包存储体验三重资金保护，通过全面保护确保用户资产的强大安全性，获得安全可靠的体验。",
      "User funds are securely stored offline in cold wallets.":
        "用户资金安全地存储在离线冷钱包中。",
      "Privacy Protection": "隐私保护",
      "We integrate a privacy-first approach in all our products and services.":
        "我们在所有产品和服务中都采用隐私优先的方法。",
      "We are transparent about the data we gather from you, clearly stating how we utilize and share it.":
        "我们对我们从您那里收集的数据保持透明，清楚地说明我们如何利用和分享它。",
      "Advanced Data Protection": "高级数据保护",
      "Data is encrypted during storage and while in transit. Strict authorization controls are in place to ensure that only you can access your personal and private information.":
        "数据在存储和传输过程中都进行了加密。严格的授权控制确保只有您可以访问您的个人和私人信息。",
      "Real-Time Monitoring": "实时监控",
      "Dikex's risk controls actively monitor and analyze user behavior in real-time. Upon detecting suspicious activity, withdrawals undergo enhanced authentication measures for added security.":
        "Dikex的风险控制积极监控和分析用户行为。一旦发现可疑活动，出金将经过增强的身份验证措施以提高安全性。",
      "Security by Design": "设计安全",
      "Our system prioritizes security, featuring a secure development life cycle, thorough security testing, and continuous bug bounty programs.":
        "我们的系统优先考虑安全性，具有安全的开发生命周期、彻底的安全测试和持续的漏洞赏金计划。",
      "Need Help?": "需要帮助？",
      "24/7 Customer Support": "24/7客户支持",
      "Get 24/7 support from our friendly Customer Support agents.":
        "从我们友好的客户支持代理那里获得全天候的支持。",
      "Chat now": "立即聊天",
      FAQs: "常见问题",
      "Explore FAQs for detailed instructions on specific features.":
        "浏览常见问题以获取特定功能的详细说明。",
      "Learn more": "了解更多",
      Learn: "学习",
      "Discover a range of trading guides tailored for beginners and advanced traders alike.":
        "发现专为初学者和高级交易者量身定制的一系列交易指南。",
      "Embark on Your Crypto Journey Today!": "立即开始您的加密货币之旅！",
      "Discover how specific cryptocurrencies work — and get a bit of each crypto to try out for yourself.":
        "了解特定加密货币的工作原理——并尝试一点点加密货币来自己尝试。",
      "Add to Favorites": "添加到收藏夹",
      "Add funds": "添加资金",
      "Trade Smarter With Dikex": "使用Dikex更智能地交易",
      "Becoming Street": "成为街头",
      "Becoming Street Smarter": "变得更聪明的街头",
      Trending: "趋势",
      Markets: "市场",
      Fees: "费用",
      "Trading pair": "交易对",
      Change: "变化",
      "Account Assets": "账户资产",
      "Total unrealized PnL": "总未实现利润和损失",
      "Trading bonuses": "交易奖金",
      "Total realized PnL": "总实现利润和损失",
      "Fund leverage": "基金杠杆",
      Used: "已使用",
      "Account perks": "账户特权",
      "Current limit": "当前限制",
      "Identity verification": "身份验证",
      "Level 1 identity verification": "一级身份验证",
      "Crypto deposit": "加密货币存款",
      "Fiat deposit": "法定货币存款",
      "P2P trading": "P2P交易",
      "Verification requirements": "验证要求",
      "Government-issued documents": "政府发布的文件",
      "Face recognition verification": "人脸识别验证",
      "Estimated review time: 60 min(s)": "预计审核时间：60分钟",
      Verify: "验证",
      "FREQUENTLY ASKED QUESTIONS": "常见问题解答",
      "Why do I need to verify my identity?": "为什么需要验证我的身份？",
      "Why can't I select my country/region?":
        "为什么我无法选择我的国家/地区？",
      "What documents can I use to verify my identity?":
        "我可以使用哪些文件来验证我的身份？",
      More: "更多",
      "Update referral": "更新推荐",
      "Fill your name": "填写您的姓名",
      "Verify your document": "验证您的文件",
      "We are reviewing your information": "我们正在审核您的信息",
      Waiting: "等待中",
      Hi: "您好",
      "Please complete all verification steps": "请完成所有验证步骤",
      Processing: "处理中",
      Verified: "已验证",
      "Please select coin and network": "请选择币种和网络",
      "Please contact our customer support team if deposit services of the funds you deposited have been temporarily suspended. Once the deposited funds are confirmed on the blockchain, they will be secured and credited to your account as soon as the deposit services resume. You can also use another blockchain to complete the deposit.":
        "如果您存入的资金的存款服务已被暂时暂停，请联系我们的客户支持团队。一旦存款资金在区块链上确认，一旦存款服务恢复，资金将被保护并存入您的账户。您也可以使用其他区块链来完成存款。",
      "How to Deal with Wrong Deposit?": "如何处理错误存款？",
      "How to Deal with Deposit not Credited?": "如何处理未入账存款？",
      "How to Deposit Cryptocurrency to Dikex Account?":
        "如何将加密货币存入Dikex账户？",
      to: "至",
      "Contract Address": "合约地址",
      Next: "下一步",
      "If your withdrawals have been suspended, please contact our customer support for assistance. We assure you that your funds are secured and should you need immediate access to your fund, you can also submit another withdrawal via other networks.":
        "如果您的提款被暂停，请联系我们的客户支持寻求帮助。我们向您保证您的资金是安全的，如果您需要立即访问您的资金，您也可以通过其他网络提交另一笔提款。",
      "Account email": "账户邮箱",
      "Enter email": "输入邮箱",
      "Select email": "选择邮箱",
      "Add Withdrawal Address": "添加提款地址",
      Normal: "普通",
      Universal: "通用",
      "Withdrawal Limit": "提款限额",
      "Add withdrawal address": "添加提款地址",
      "For the safety of your funds, our customer support team may contact you by phone to confirm your withdrawal request. Please pay attention to incoming calls":
        "为了您的资金安全，我们的客户支持团队可能会通过电话与您联系以确认您的提款请求。请注意接听电话。",
      "Be aware of scams! Dikex will never ask you for your account, password or any personal information, nor will it request private transfers or withdrawals":
        "提防欺诈！Dikex绝不会要求您提供账户、密码或任何个人信息，也不会要求私人转账或提款",
      "Withdrawal Limit Adjustment?": "提款限额调整？",
      "How to Withdraw Cryptocurrency from Dikex Account?":
        "如何从Dikex账户提取加密货币？",
      "Withdrawal Limits": "提款限额",
      "Single limit": "单笔限额",
      "Daily Limit": "每日限额",
      "Daily Available": "每日可用",
      "Monthly Limit": "月度限额",
      "Monthly Available": "每月可用",
      "Address, email, uid": "地址，电子邮件，UID",
      "Edit notes": "编辑备注",
      "Delete address": "删除地址",
      "Are you sure you want to delete from your address book?":
        "确定要从地址簿中删除吗？",
      "Funding rate / countdown": "资金费率 / 倒计时",
      "Dikex provides you with a powerful API, enabling you to access market data, perform trades, transfer assets, and make withdrawals":
        "Dikex为您提供强大的API，使您能够访问市场数据，进行交易，转移资产并进行提取",
      "When you create a new API, you need to set a passphrase to call the API and query the secret key. Make sure to keep your passphrase safe as if you lose it, you will need to create a new API key":
        "创建新API时，您需要设置调用API和查询秘密密钥的密码。确保将您的密码保持安全，因为如果您丢失它，您将需要创建新的API密钥",
      "The secret and passphrase are used for private API calls and should never be disclosed":
        "秘密和密码用于私有API调用，不应该被披露",
      "For security reasons, IPs must be bound to API keys. Up to 30 IPs can be bound to each key. If no IP is bound, you may be restricted from accessing certain functions, such as withdrawing assets or publishing and editing P2P ads":
        "出于安全原因，IP必须绑定到API密钥。每个密钥最多可以绑定30个IP。如果未绑定IP，则可能会限制您访问某些功能，例如提取资产或发布和编辑P2P广告",
      "Address book": "地址簿",
      "Create Api key": "创建API密钥",
      Create: "创建",
      "Please enter API-Key name": "请输入API密钥名称",
      Name: "名称",
      "Api-key name": "API密钥名称",
      "IP, domain, ...": "IP，域，...",
      Scopes: "范围",
      "Add key success": "添加密钥成功",
      Key: "密钥",
      Edit: "编辑",
      "Edit Api key": "编辑API密钥",
      "Update key success": "更新密钥成功",
      Update: "更新",
      "Liq. Price": "流动性价格",
      "Spot trading volume (30D) (USDT)": "现货交易量 (30D) (USDT)",
      or: "或",
      "Asset balance (USD)": "资产余额 (USD)",
      "Maker fee": "做市商费",
      "Taker fee": "吃单费",
      "24h withdraw limit": "24小时提取限额",
      "Futures trading volume (30D) (USDT)": "期货交易量 (30D) (USDT)",
      "Fees and withdrawal limits": "费用和提取限额",
      "Dikex VIPs make trades with some of the lowest fees in the market and keep more of their profits":
        "Dikex VIP在市场上交易时收取的费用是最低的之一，并且保留更多的利润",
      "Vip Services": "VIP服务",
      "Cancel All": "取消全部",
      "Are you sure to cancel all order?": "确定取消所有订单吗？",
      "of total": "总计",
      "Asset allocation": "资产配置",
      "Recent Activity": "最近活动",
      "In order": "按顺序",
      History: "历史",
      "Funding Rate": "资金费率",
      "Spot records": "现货记录",
      "Select symbol": "选择符号",
      "All Futures": "所有期货",
      "Funding Rate History": "资金费率历史",
      "Period Time": "周期时间",
      Hours: "小时",
      From: "从",
      To: "到",
      "Base interest rate": "基础利率",
      "Futures records": "期货记录",
      "All Coin": "所有币种",
      "Select coin": "选择币种",
      "Fund History": "资金历史",
      "Select type": "选择类型",
      "All Type": "所有类型",
      "Edit photo": "编辑照片",
      "Select photo": "选择照片",
      "Use photo": "使用照片",
      "Select another photo": "选择另一张照片",
      "Update avatar success": "更新头像成功",
      "Do not remind me again": "不再提醒我",
      "Confirm settings": "确认设置",
      "Show Margin Type description": "显示保证金类型描述",
      "When enabled, you will see the description each time you open Margin Type popup":
        "启用后，您将在每次打开保证金类型弹出窗口时看到描述",
      "Quantity is too small": "数量太少",
      "Account Id": "帐户ID",
      "Created time": "创建时间",
      "Key successfully created": "密钥创建成功",
      "Your key has been created": "您的密钥已创建",
      "API key": "API密钥",
      "Secret key": "密钥",
      "Make sure you copy secret key below as it won't be shown again":
        "确保您复制下方的秘钥，因为它将不再显示",
      Page: "页码",
      "Page size": "每页数量",
      "Load more": "加载更多",
      "Nothing found": "未找到任何内容",
      Filter: "筛选",
      Direction: "方向",
      "Order Type": "订单类型",
      Turnover: "成交额",
      Unfilled: "未完成",
      "Avg. filed price": "平均成交价格",
      Maker: "做市商",
      Taker: "吃单者",
      "Tx Hash": "交易哈希",
      "Transaction fee": "交易费用",
      "No data": "无数据",
      "Sell/Short": "卖出/做空",
      Home: "主页",
      Favorites: "收藏夹",
      Assets: "资产",
      "Spot Trading": "现货交易",
      "Futures Trading": "期货交易",
      Chart: "图表",
      Orderbook: "订单簿",
      "Filter by date": "按日期筛选",
      "Funding rate": "资金费率",
      "Margin rate": "保证金率",
      "Liq. price": "清算价格",
      "Liquidation Clearance": "清算结算",
      "Closing PnL": "收益/损失",
      "Close at": "关闭于",
      "Open at": "开启于",
      "Filter by coin": "按币种筛选",
      Network: "网络",
      "Transfer Network": "转账网络",
      "Address/Address Tag": "地址/地址标签",
      Notes: "备注",
      "Filter by network": "按网络筛选",
      Search: "搜索",
      "Est. PnL": "估计利润/损失",
      "Deposit records": "存款记录",
      "Withdraw records": "提款记录",
      "Continue with Google": "继续使用 Google",
      "Already processing. Please wait or confirm on your wallet":
        "已在处理中，请等待或在您的钱包上确认",
      "Login to Dikex": "登录 Dikex",
      "Link your Dikex account": "链接您的 Dikex 账户",
      "If you already have a Dikex account, you can connect it to your wallet account":
        "如果您已经拥有 Dikex 账户，您可以将其连接到您的钱包账户",
      "Link existing Dikex account": "链接现有的 Dikex 账户",
      "Your Dikex account (email)": "您的 Dikex 账户（电子邮件）",
      "Phone setting": "手机设置",
      "Please Enter your phone": "请输入您的电话号码",
      "Phone number": "电话号码",
      "When enabled, you will allow to receive OTP via phone number":
        "启用后，您将允许通过电话号码接收 OTP",
      "Phone OTP": "电话 OTP",
      "Enable phone number": "启用电话号码",
      "OTP will be sent to phone number": "OTP 将发送到电话号码",
      "Setup sign in password": "设置登录密码",
      "This is your first time logging into our system. Please set a login password":
        "这是您第一次登录我们的系统。请设置一个登录密码",
      "Please use this password for subsequent logins":
        "请使用此密码进行后续登录",
      "Confirm and Sign in": "确认并登录",
      "Change verification method": "更改验证方法",
      "Choose verification method": "选择验证方法",
      "Phone number verification": "电话号码验证",
      "Email OTP": "电子邮件 OTP",
      "Confirm order": "确认订单",
      Long: "做多",
      Short: "做空",
      "Address Tag": "地址标签",
      "Enter The Withdrawal Address Tag": "输入提款地址标签",
      "Withdraw Address Tag": "提款地址标签",
      "Please Enter Address Tag": "请输入地址标签",
      "Please Enter Address": "请输入地址",
      "Address Is Not Valid": "地址无效",
      "Please Enter phone number": "请输入电话号码",
      "About Dikex": "关于 Dikex",
      ABOUT_CONTENT:
        "Dikex 背后是一群早期采用者，他们相信未来将基于区块链。自 2018 年以来，我们一直致力于构建一个交易所，致力于通过提供安全、一站式的加密投资解决方案帮助用户更智能地交易。",
      "Our Story": "我们的故事",
      ABOUT_STORY:
        "Dikex 的创立源于对坚定信念的追求。Dikex 的创始团队拥有传统金融背景，他们于 2015 年发现了区块链技术，但当时这被业界视为 '郁金香狂潮'。2018 年，我们在研究比特币白皮书和以太坊生态系统后对加密货币产生了兴趣。我们相信，加密货币将在未来发挥重要作用，甚至惠及无银行账户的群体。然而，市场暴跌使大多数投机者离开了，不到 10% 的人选择坚持自己的信念并重建。Dikex 于同年正式成立。在熊市中诞生的 Dikex 坚持将用户放在首位，专注于产品创新，并以诚恳的精神倡导长期发展。公司的目标是激励人们接受加密货币，并逐个改善他们的交易方式。",
      "We believe in a more equitable future world driven by crypto evolution.":
        "我们相信加密货币的发展将推动一个更加公平的未来世界。",
      Mission: "使命",
      "We empower the future of finance by ensuring secure, efficient and smart digital transactions.":
        "我们通过确保安全、高效和智能的数字交易来赋予金融的未来。",
      "Our Value": "我们的价值观",
      "Our Team": "我们的团队",
      "With 1500+ employees from across 60 countries and regions, the Dikex team boasts professionals from all corners of the world, giving us a truly global presence.":
        "拥有来自全球 60 多个国家和地区的 1500 多名员工，Dikex 团队汇集了来自世界各地的专业人士，使我们真正拥有全球性的存在。",
      "Our Services": "我们的服务",
      "Trade USDT-M, USDC-M, and Coin-M futures. Go long or short on over 200 coins with high liquidity and low fees.":
        "交易 USDT-M、USDC-M 和 Coin-M 期货。在 200 多种高流动性、低费用的币种上做多或做空。",
      "Build your portfolio from over 750+ promising listed tokens. Join Dikex Launchpad to get early access to crypto gems.":
        "从 750 多种有前景的上市代币中构建您的投资组合。加入 Dikex 发射台，提前访问加密货币宝石。",
      "Start Your Crypto Journey Now!": "立即开始您的加密货币之旅！",
      "Trade on a platform trusted by millions of users":
        "在数百万用户信任的平台上进行交易",
      "Join us": "加入我们",
      "Largest crypto copy trading platform": "最大的加密复制交易平台",
      "25 million": "2500 万",
      "Registered users worldwide": "全球注册用户",
      "10 billion USDT": "100 亿 USDT",
      "Daily trading volume": "每日交易量",
      "Users First": "用户至上",
      "We genuinely care about the voice of each and every user":
        "我们真诚关注每个用户的声音",
      "Integrity & Honesty": "诚信与正直",
      "We prioritize earning trust by consistently adhering to responsible and ethical standards.":
        "我们通过始终遵守负责任和道德标准来优先赢得信任。",
      "Open Communication": "开放式沟通",
      "We communicate transparently and promptly, sharing all the good, the bad, and the ugly.":
        "我们透明且及时地沟通，分享一切美好、不好和丑陋的事物。",
      "Deliver Results": "交付成果",
      "Change won't happen itself. We take deliberate actions to make it happen.":
        "变革不会自发发生。我们采取有意的行动使之发生。",
      "Dikex global community": "Dikex 全球社区",
      COMMUNITY_WELCOME:
        "欢迎来到 Dikex 全球社区！在这里，您可以了解最新的平台发展，并获得专业的市场见解。此外，您还可以与来自世界各地的加密货币爱好者讨论交易策略。立即访问以下链接，开始您的加密货币之旅吧！",
      "Business inquiries": "商务咨询",
      "Quant & MM": "量化与市场制造商",
      "VIP Services": "VIP服务",
      "Enjoy higher discounts and perks": "享受更高的折扣和福利",
      "Fee Schedule": "费率表",
      "VIP perks": "VIP福利",
      "Withdrawal Fees": "提现手续费",
      "Trade Now": "立即交易",
      "Withdrawal Fee": "提现手续费",
      "Platform fee rate": "平台手续费率",
      "My fee rate": "我的费率",
      "Product news": "产品新闻",
      "Search questions, keywords, articles": "搜索问题、关键词、文章",
      Announcement: "公告",
      "API Trading'": "API交易",
      "Download/Register/Log in": "下载/注册/登录",
      Policies: "政策",
      "Deposit/Withdraw": "存款/提现",
      "KYB Verification Guideline": "KYB验证指南",
      Other: "其他",
      "Video Guide for Beginners": "初学者视频指南",
      Savings: "储蓄",
      Support: "支持",
      "Recent activity": "最近活动",
      "Invite friends to get up to 1,62 USDT in cash":
        "邀请朋友，最多可获得1.62 USDT现金",
      "You both can get rewards": "您们都可以获得奖励",
      "Referral code": "推荐代码",
      "Referral link": "推荐链接",
      Invite: "邀请",
      "Referral history": "推荐历史",
      "Terms and Conditions": "条款和条件",
      "Task progress": "任务进展",
      "In the past 7 days": "过去7天",
      "In the past 30 days": "过去30天",
      "In the past 120 days": "过去120天",
      "Privacy Policy": "隐私政策",
      "Related articles": "相关文章",
      "Legal Statement": "法律声明",
      "Term of Use": "使用条款",
      "Risk Disclosure": "风险披露",
      "How can we help?": "我们如何帮助您？",
      "Still can't solve your problem?": "仍然无法解决您的问题？",
      "Our multilingual online customer service helps you solve your problems":
        "我们的多语言在线客服帮助您解决问题",
      Contact: "联系我们",
      "Announcement center": "公告中心",
      "All topics": "所有主题",
      "Maintenance or system updates": "维护或系统更新",
      "Coin Listings": "币种列表",
      Latest: "最新",
      FAQ: "常见问题解答",
      "API Announcement": "API公告",
      Guideline: "指南",
      "Last News": "最新消息",
      "Blockchain Assets": "区块链资产",
      "Latest Activities": "最新活动",
      "Beginner's guide": "新手指南",
      "Features overview": "功能概述",
      "Risk management": "风险管理",
      "Trading mechanism": "交易机制",
      "Self-Service": "自助服务",
      "Latest articles": "最新文章",
      "KYC Submission": "KYC提交",
      "Reset 2FA": "重置2FA",
      "Demo Version": "演示版本",
      "Submit a Ticket": "提交工单",
      Company: "公司",
      "About dikex": "关于dikex",
      Product: "产品",
      "Referral Program": "推荐计划",
      "Vip Program": "VIP 计划",
      "Submit A Request": "提交请求",
      "Help Center": "帮助中心",
      "Legal & Disclosures": "法律与披露",
      "Terms of User": "用户条款",
      "Start Crypto Journey Now!": "立即开始加密之旅！",
      Today: "今天",
      "View more": "查看更多",
      Losers: "失败者",
      New: "新",
      Volume: "成交量",
      Referrals: "推荐",
      "Affiliate Program": "联盟计划",
      "Trade faster and more flexibly with the Dikex API":
        "使用 Dikex API 更快更灵活地交易",
      "Create API keys": "创建 API 密钥",
      "View document": "查看文档",
      "How to connect to the Dikex API?": "如何连接到 Dikex API？",
      CONNECT_DIKEX_API:
        "Dikex API 允许开发人员执行程序交易、数据获取、集成复制交易服务和广告发布",
      "Apply/Manage API keys": "申请/管理 API 密钥",
      User: "用户",
      "Deploy API": "部署 API",
      "Request/Subscribe": "请求/订阅",
      "Trading API": "交易 API",
      "Market WebSocket": "市场 WebSocket",
      "Designed for institutional and professional users":
        "专为机构和专业用户设计",
      "Specialized solutions based on user needs": "基于用户需求的专业解决方案",
      "Contact Us": "联系我们",
      "Custom-tailored solutions": "定制解决方案",
      "Better funding rate": "更好的资金率",
      "Lower latency": "更低的延迟",
      "Higher trading limit": "更高的交易限制",
      "Contact BD": "联系 BD",
      "Market makers": "市场制造商",
      "Quantitative trading": "量化交易",
      "Free standardized API": "免费的标准化 API",
      "SDK in five programming languages": "五种编程语言的 SDK",
      "24/7 professional customer service": "全天候专业客户服务",
      "Third-party platform": "第三方平台",
      "Share Dikex's liquidity": "分享 Dikex 的流动性",
      "Integrate Dikex copy trading": "集成 Dikex 复制交易",
      "Non-disclosure broker model": "非披露经纪模式",
      "1. How to create API keys?": "1. 如何创建 API 密钥？",
      CREATE_API_KEYS:
        "1.1 登录您的 Dikex 帐户，然后转到用户中心的 API 管理页面。1.2 选择创建 API 密钥。1.3 创建您的注释和密码，配置您的权限设置，然后填写您的 IP 地址。验证您的帐户，然后选择下一步。",
      "2. Is it free to use API?": "2. 使用 API 是否免费？",
      "Using the API is free.": "使用 API 是免费的。",
      "3. What is the difference between WebSocket and REST and which one should I choose?":
        "3. WebSocket 和 REST 之间的区别是什么？我应该选择哪一个？",
      DIFFERENCE_WEBSOCKET_REST:
        "如果您需要实时市场趋势和数据更新（例如价格变动和成交量等），WebSocket 是更好的选择。WebSocket 是一种基于消息的协议，提供比 REST API 更高效的数据传输和更低的延迟。如果您只需要偶尔检查市场数据（例如历史交易和蜡烛图数据），REST API 是更好的选择。它基于 HTTP 协议，具有广泛的应用。它也是开发自动化交易程序的更好选择。",
      "4. Is the API symbol case-sensitive?":
        "4. API 符号是否区分大小写敏感吗？",
      "Yes it is. The symbol must be in uppercase.":
        "是的，它是区分大小写的。符号必须为大写。",
      "Market API": "市场 API",
      "Dikex VIP service": "Dikex VIP 服务",
      "Dikex provides a full range of exclusive perks to VIP users.":
        "Dikex 为 VIP 用户提供全方位的独家优惠。",
      "Vip News": "VIP 新闻",
      "Vip Trial Pass": "VIP 试用通行证",
      "VIP requirements": "VIP 要求",
      vip_requirement_1:
        "30天交易量：30天内所有主账户和子账户交易的总交易量。创建子账户时，子账户将具有与主账户相同的资金率。",
      vip_requirement_2:
        "资产：所有主账户和子账户中的所有资产，包括现货、USDC-M 期货、USDT-M 期货、Coin-M 期货、P2P 和 Earn 资产。在第二天凌晨12:00 (UTC+0) 进行计算，并根据计算时的币-USDT 转换率进行转换。现货资产包括可用、锁定和冻结资产；期货资产包括已抵押和冻结资产，不包括未实现的盈亏；而 P2P 资产包括可用资产（冻结资产不计为“可用”）。",
      vip_requirement_3:
        "24小时提款限额：Dikex 将根据用户的 VIP 等级设置用户的每日提款限额。所有货币将以美元计算，转换后的最终金额必须小于相应 VIP 等级的提款限额。未完成 KYC 验证的用户提款限额为 20,000 美元。",
      vip_requirement_4:
        "如果用户的现货交易量、期货交易量或资产符合不同的 VIP 等级要求，则用户可以享受最高等级的 VIP 等级。如果用户满足任何交易类型的 VIP 等级要求，则所有其他交易类型都将升级到相应的 VIP 等级。例如，如果用户满足现货交易 VIP 1 的 VIP 要求，则其将自动升级为期货交易 VIP 1。",
      vip_requirement_5:
        "VIP 等级将每天在凌晨1:00 (UTC+0) 自动更新。如果用户的交易行为触发风险控制系统，Dikex 保留调整其 VIP 等级的权利。",
      "Institutional users are not eligible for the VIP program.":
        "机构用户无权参加 VIP 计划。",
      vip_service_contact:
        "Dikex 对 VIP 计划拥有最终解释权。如果您有任何疑问，请通过 vip@dikex.com 与我们联系。",
      "Exclusive VIP perks": "独家 VIP 权益",
      "Being a Dikex VIP has never been more rewarding.":
        "成为 Dikex VIP 从未如此令人满意。",
      "Hot project watchlist": "热门项目监视列表",
      "Premium VIP customer service": "高级 VIP 客户服务",
      "Express deposits and withdrawals": "快速存款和提款",
      "Cutting-edge security": "尖端安全",
      "When it comes to security, we go the extra mile.":
        "在安全方面，我们做得更多。",
      "Proof-of-Reserves": "储备证明",
      "We guarantee a minimum 1:1 reserve ratio to cover customer funds":
        "我们保证最低1:1的储备比例以覆盖客户资金",
      "Cold storage": "冷存储",
      "Most digital assets are stored in offline multi-signature wallets":
        "大多数数字资产存储在离线多签名钱包中",
      "Become a VIP today": "立即成为VIP",
      "If you're a VIP on another exchange, we offer you VIP at one level higher.":
        "如果您在其他交易所是VIP，我们将为您提供更高一级的VIP。",
      "Do you want to change the current language?": "您想要更改当前语言吗？",
      "Exclusive account manager": "专属账户经理",
      "Sign in": "登录",
      "to get referral code": "获取推荐码",
      "No records yet.": "暂无记录。",
      "friends now!": "现在邀请朋友！",
      "Three Steps to Attractive Commissions with Our Referral Program":
        "通过我们的推荐计划获得吸引人的佣金的三个步骤",
      "Join The Dikex Referral program Today!": "立即加入Dikex推荐计划！",
      "Generate passive income and work towards financial independence.":
        "产生 passively 收入并朝财务独立努力。",
      "Sign up & get your referral code": "注册并获取您的推荐码",
      "Create an account in minutes and start earning commission.":
        "在几分钟内创建一个账户并开始赚取佣金。",
      "Share your referral link": "分享您的推荐链接",
      "Invite friends or fans to register as new users using your exclusive referral link or invitation code.":
        "邀请朋友或粉丝使用您的独家推荐链接或邀请码注册为新用户。",
      "Earn 5% on Every Referral Trade": "每笔推荐交易赚取5%",
      "Receive a 5% commission on every trade your referrals make-no limits, just continuous earnings!":
        "您的推荐交易所产生的每笔交易均可获得5%的佣金-没有限制，只有持续的收入！",
      "Continue with MetaMask": "继续使用 MetaMask",
      "New Here?": "新用户？",
      "Create an account": "创建账户",
      "Order quantity": "订单数量",
      "Invitation ID": "邀请 ID",
      "Invitation Link": "邀请链接",
      Referral: "推荐",
      "Total number of addresses": "地址总数",
      "You can add up to 500 on-chain addresses and internal addresses":
        "您最多可以添加 500 个链上地址和内部地址",
      "On-chain Address": "链上地址",
      "Internal Transfer Address": "内部转账地址",
      "After verification": "验证后",
      "Commission records": "佣金记录",
      "Withdrawal records": "提款记录",
      "Your name": "你的名字",
      "Enter your mail to reset the password": "输入您的邮箱以重置密码",
      "Back to sign in": "返回登录",
      Login: "登录",
      Profile: "个人资料",
      "Manage Addresses": "管理地址",
      "Identity Verification": "身份验证",
      Affiliates: "关联公司",
      "Futures Account": "期货账户",
      "Futures Records": "期货记录",
      "Spot Records": "现货记录",
      "Deposit Records": "存款记录",
      "Withdraw Records": "取款记录",
      "Commission Records": "佣金记录",
      "Select An Account": "选择账户",
      "Transfer Amount": "转账金额",
      "Transfer out": "转出",
      "Transfer in": "转入",
      "Choose A Network": "选择网络",
      "Deposit Address": "存款地址",
      "Minimum Deposit Amount": "最低存款金额",
      "Transfer To": "转账到",
      Wallet: "钱包",
      "Withdraw To": "提取到",
      "Choose from address book": "从地址簿选择",
      "Confirm that the receiving platform accepts the crypto and network you have selected. You may lose your assets if you choose an incompatible network":
        "确认接收平台接受您选择的加密货币和网络。如果选择不兼容的网络，您可能会丢失资产",
      "On-chain": "链上",
      "Internal Transfer": "内部转账",
      "Select Network": "选择网络",
      "Withdraw Address": "取款地址",
      "Transfer records": "转账记录",
      Reload: "重新加载",
      Balance: "余额",
      Buy: "购买",
      "Deposit Confirmation": "存款确认",
      "Phone Number": "电话号码",
      "I have read and agree to the above agreement": "我已阅读并同意上述协议",
      "Mail OTP": "邮件OTP",
      "Index price": "指数价格",
      Success: "成功",
      Source: "来源",
      Order: "订单",
      "Flash close": "快速关闭",
      "Full position TP/SL": "全仓止盈/止损",
      Batch: "批次",
      Trailing: "跟踪",
      "Partial position TP/SL": "部分仓位止盈/止损",
      "Trailing TP/SL": "跟踪止盈/止损",
      Details: "详情",
      Lite: "轻量",
      "the position will close {{amount}} at {{price}}. Expected return":
        "仓位将在{{price}}以{{amount}}关闭。预期回报",
      "Continue to add": "继续添加",
      "By order time": "按订单时间",
      "By trigger price": "按触发价格",
      "Cancel all orders": "取消所有订单",
      "Execution price": "执行价格",
      "Can not place tp/sl order": "无法下止盈/止损订单",
      "Your position is too small to place TP/SL":
        "您的仓位太小，无法设置止盈/止损",
      "Please input TP price": "请输入止盈价格",
      "Please input SL price": "请输入止损价格",
      "Please input trigger price": "请输入触发价格",
      "TP/SL Market": "止盈/止损市场",
      "Callback rate": "回调率",
      "Remaining quantity for TP/SL": "止盈/止损剩余数量",
      "Trailing Stop": "追踪止损",
      "Filled orders only": "仅已成交订单",
      "Show current": "显示当前",
      "Other options": "其他选项",
      "Filter by type": "按类型筛选",
      "Filter by futures": "按期货筛选",
      "Trigger MMR": "触发MMR",
      "Current MMR": "当前MMR",
      "When MMR ≥ {{mmrTrigger}}%, the position will be closed at market price. The higher the MMR, the higher the risk. Position liquidation/reduction will be triggered when the MMR reaches 100%.":
        "当MMR ≥ {{mmrTrigger}}%时，仓位将以市场价格平仓。MMR越高，风险越大。当MMR达到100%时，将触发仓位清算/减少。",
      "Update MMR success": "更新MMR成功",
      "Maintenance margin": "维持保证金",
      "Maintenance margin rate (MMR)": "维持保证金率(MMR)",
      "Position tier intro": "仓位级别介绍",
      "TP buy price": "止盈买入价",
      "Share profit": "分享利润",
      "Shared at": "分享于",
      "PnL amount": "盈亏金额",
      "Scan QR to sign up to Dikex now.": "扫描二维码立即注册Dikex。",
      Download: "下载",
      Copy: "复制",
      "Copy image success": "复制图片成功",
      "Full TP/SL": "全仓止盈/止损",
      "Partial TP/SL": "部分仓位止盈/止损",
      "SL buy price": "止损买入价",
      "SL sell price": "止损卖出价",
      "Trading panel": "交易面板",
      "Can not place TP order": "无法下止盈订单",
      "Your position is too small to place TP": "您的仓位太小，无法设置止盈",
      "Can not place SL order": "无法下止损订单",
      "Your position is too small to place SL": "您的仓位太小，无法设置止损",
      References: "参考资料",
      Standard: "标准",
      "Pro-Left": "专业版-左",
      "Pro-Right": "专业版-右",
      "Candlestick time": "蜡烛图时间",
      "TP/SL order": "止盈/止损订单",
      "Trailing stop order": "追踪止损订单",
      "Trailing percent": "追踪百分比",
      "Copy Image": "复制图片",
      "Sponsor not found": "未找到赞助商",
      "If you continue to register, the system will skip updating the sponsor. You can update later in the settings.":
        "如果您继续注册，系统将跳过更新赞助商。您可以稍后在设置中更新。",
      "Spot trading notifications": "现货交易通知",
      "Spot notification sounds": "现货通知声音",
      "Spot notifications": "现货通知",
      "Trigger order": "触发订单",
      "Futures trading notifications": "期货交易通知",
      "Futures notification sounds": "期货通知声音",
      "Post only order": "仅发布订单",
      "Futures notifications": "期货通知",
      "Vip Upgrade Progress": "VIP升级进度",
      "Spot Vol": "现货交易量",
      "Futures Vol": "期货交易量",
      "Account Assets": "账户资产",
      Loading: "加载中",
      "Your VIP upgrade progress": "您的VIP升级进度",
      "You still need": "您还需要",
      "to reach": "达到",
      "User level": "用户级别",
      "Fast track application": "快速申请",
      "Spot fees": "现货费用",
      "Current tier": "当前等级",
      "Maker/Taker": "挂单/吃单",
      "Futures transaction fees": "期货交易费用",
      "Spot order confirmation": "现货订单确认",
      "Futures order confirmation": "期货订单确认",
      "Help center": "帮助中心",
      "Futures trading help center": "期货交易帮助中心",
      "See other questions": "查看其他问题",
      "Beginner's Guide": "新手指南",
      "Futures overview": "期货概述",
      "Risk management": "风险管理",
      "Trading mechanism": "交易机制",
      "Feature guide": "功能指南",
      "Spot trading help center": "现货交易帮助中心",
      "Spot guide": "现货指南",
      Guideline: "指导方针",
      "Blockchain Assets": "区块链资产",
      "Please inter trigger price": "请输入触发价格",
      "Please check your phone number ({{phone}}) for the verification code we just sent you and enter that code in the box below":
        "请检查您的电话号码 ({{phone}}) 获取我们刚发送的验证码，并在下面的框中输入该验证码",
      "Since we still need your email, please enter it before registering.":
        "由于我们仍需要您的电子邮件，请在注册前输入。",
      "Your email": "您的电子邮件",
      "Email already exists": "电子邮件已存在",
      "Scan this code with the Dikex app to log in instantly.":
        "用Dikex应用扫描此代码以立即登录。",
      Activities: "活动",
      "Change password": "更改密码",
      "Enable GA": "启用GA",
      "Disable GA": "禁用GA",
      "Update username": "更新用户名",
      "Update Anti-fishing code": "更新防钓鱼代码",
      "Enable withdrawal whitelist": "启用提款白名单",
      "Disable withdrawal whitelist": "禁用提款白名单",
      "Disable phone number": "禁用电话号码",
      "All type": "所有类型",
      Device: "设备",
      "Remove device": "移除设备",
      "Login at": "登录时间",
      "Device manager": "设备管理",
      Seconds: "秒",
      Minutes: "分钟",
      Hours: "小时",
      Days: "天",
      Notifications: "通知",
      ALL: "全部",
      Campaigns: "活动",
      "System messages": "系统消息",
      Previous: "上一页",
      "Mark all as read": "全部标记为已读",
      "Mark all as read successfully": "全部标记为已读成功",
      "Mark all as read failed": "全部标记为已读失败",
      "Show less": "显示更少",
      "Show more": "显示更多",
      "Alert logs": "警报日志",
      "Create notice successfully": "成功创建通知",
      "Create notice failed": "创建通知失败",
      Pair: "交易对",
      "Alert type": "警报类型",
      "Price Rise": "价格上涨",
      "Price 24h Rise Percent": "24小时价格上涨百分比",
      "Price 24h Fall Percent": "24小时价格下跌百分比",
      "Price Rise To Percent": "价格上涨至百分比",
      "Price Fall To Percent": "价格下跌至百分比",
      "Entering Channel": "进入通道",
      "Exiting Channel": "退出通道",
      "Price Alert": "价格警报",
      "Upper Bound": "上限",
      "Lower Bound": "下限",
      "The upper bound must be greater than the lower bound":
        "上限必须大于下限",
      Frequency: "频率",
      "Only Once": "仅一次",
      "Once A Day": "每天一次",
      "Every Time": "每次",
      "When the condition reaches the set value, it will raise an alert via the selected method, and will not be received again after one reminder":
        "当条件达到设定值时，将通过选定的方法触发警报，一次提醒后不会再收到",
      "Remind once a day (between 0:00 and 24:00). It will be triggered when the condition reaches the next day":
        "每天提醒一次（0:00至24:00之间）。条件达到下一天时将触发",
      "Remind continuously until cancelled. In order not to disturb you, we set the reminder rules:":
        "持续提醒直到取消。为了不打扰您，我们设置了提醒规则：",
      "No more than 1 times per second": "每秒最多1次",
      "No more than 2 times per minute": "每分钟最多2次",
      "No more than 3 times within 1 hour": "1小时内最多3次",
      "No more than 10 times a day": "每天最多10次",
      "Please select alerts to delete": "请选择要删除的警报",
      "Selected alerts deleted successfully!": "成功删除选定的警报！",
      "Failed to delete selected alerts!": "删除选定的警报失败！",
      "Update alert status successfully!": "成功更新警报状态！",
      "Update alert status failed!": "更新警报状态失败！",
      "Alert monitoring": "警报监控",
      "Bot creation notice": "机器人创建通知",
    },
  },
};
i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
