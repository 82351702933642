import { Reducer } from "redux";
import { UserActionTypes, UserActionsType } from "./actions";

const initialState: TUserReducerInitialState = {
  information: null,
  F1Information: null,
  isLoading: true,
};

export const UserReducer: Reducer<TUserReducerInitialState, UserActionsType> = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case UserActionTypes.SET_ANYTHING:
      return { ...state, ...payload };
    default:
      return { ...state };
  }
};
