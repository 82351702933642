import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_lang from 'translate/language/en_lang.json'
import ja_lang from 'translate/language/ja_lang.json'
import ind_lang from 'translate/language/ind_lang.json'
import es_lang from 'translate/language/es_lang.json'
import de_lang from 'translate/language/de_lang.json'
import cn_lang from 'translate/language/cn_lang.json'
import fr_lang from 'translate/language/fr_lang.json'


const getLangParams = ()=>{
    const langList = ['ind', 'es', 'ja', 'de', 'fr', 'cn']
    const url = window.location
    const splitUrl =  url.pathname.split('/').filter(Boolean)[0]
    const foundUrl = langList.find((e)=> e.includes(splitUrl))

    return foundUrl
}
i18n
  .use(initReactI18next)
  .use(new LanguageDetector(null, { lookupLocalStorage: "lang" }))
  .init({
    returnNull: false,
    resources: {
        en: {
            common: en_lang
        },
        ind: {
            common: ind_lang
        },
        ja:{
            common: ja_lang
        },
        de:{
          common: de_lang
        },
        fr:{
          common: fr_lang
        },
        cn:{
          common: cn_lang
        },
        es:{
          common: es_lang
        }
    },
    fallbackLng: 'en',
    lng: `${getLangParams() || 'en'}`,
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n;

