import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import dot from 'assets/button/Active.png'
import dotActive from 'assets/button/Hover.png'
import { useTranslation } from "react-i18next";
export default function MenuComponent({ menus }: { menus: TMenus }) {
  const {pathname} = useLocation()
  const {t} = useTranslation()
  return (
    <>
      <List>
        {menus.map((menu, index) => (
          <React.Fragment key={index}>
            {menu.subMenus ? (
              <NestedList menu={menu} key={index} />
            ) : (
              <Link
                to={menu.to}
                key={index}
                style={{ color: "unset", textDecoration: "unset" }}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon sx={{minWidth:'40px'}}>
                      {/* {menu.icon} */}
                      <img src={pathname === menu.to ? menu.iconActive : menu.icon} alt='img' width={20} height={20}/>
                    </ListItemIcon>
                    <ListItemText primary={t(`common:dashboard.${menu.label}`)} sx={{ 'span':{fontWeight: pathname === menu.to ? 700: 400}}} />
                  </ListItemButton>
                </ListItem>
              </Link>
            )}
          </React.Fragment>
        ))}
      </List>
    </>
  );
}

function NestedList({ menu }: { menu: TMenu }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const {pathname} = useLocation()
  const {i18n, t} = useTranslation()
  const conversePathname = () =>{
    let words
    if(i18n.language === 'en') {
       words = pathname.split('/')[1].split('-')
    }
    else if(i18n.language !== 'en' && pathname.split('/').length > 2){
      words = pathname.split('/')[2].split('-')
    }
    
    // console.log(words, i18n)
    let result = words?.map((word, index) =>  {
      if (index === 0) {
        return word;
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1)
      }
    }).join('');
    return t(`common:dashboard.${result}`)
  }
  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon sx={{minWidth: '40px'}}>
        <img src={pathname === menu.to || conversePathname() === t(`common:dashboard.${menu.label}`) ? menu.iconActive : menu.icon} alt='img' width={20} height={20}/>
        </ListItemIcon>
        <ListItemText primary={<Typography sx={{fontWeight: conversePathname() === t(`common:dashboard.${menu.label}`) ? 700: 400}}>{t(`common:dashboard.${menu.label}`)}</Typography>} />
        <ListItemIcon sx={{ p: 0, minWidth: "unset" }}>
          {open ? <IconChevronDown /> : <IconChevronRight />}
        </ListItemIcon>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            // borderLeft: "2px solid rgba(0, 0, 0, 0.12)",
            ml: 3.3,
          }}
        >
          {menu.subMenus?.map((subMenu, index) => (
            <Link
              to={subMenu.to}
              key={index}
              style={{ color: "unset", textDecoration: "unset", display:'flex', alignItems:'center' }}
            >
              <div>
                <img src={pathname === subMenu.to ? dotActive : dot} alt='dot'/>
              </div>
              <ListItemButton
                sx={{
                  pl: '22px',
                }}
              >
                <ListItemText
                  primary={
                    <Typography sx={{ opacity: 0.77,  fontWeight: pathname === subMenu.to ? 700: 400 }}>
                      {t(`common:dashboard.${subMenu.label}`)}
                    </Typography>
                  }
                />
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
  );
}
