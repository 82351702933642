import { Box, Typography } from "@mui/material";
import { CustomPaper } from "components/common/CustomComponents";
import HigherOrderComponent from "components/HOC/HigherOrderComponent";
import { UserEndpointList } from "constants/endpoints";
import { t } from "i18next";

export default function FuturesTradingQuery() {
  return (
    <Box>
      <Typography variant="h5" fontWeight={700} mb={2}>
        {t('common:table.Futures Trading Query')}
      </Typography>
      <CustomPaper variant="outlined">
        <TableComponent />
      </CustomPaper>
    </Box>
  );
}

function TableComponent(props: any) {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.TradeManagement.FuturesQuery,
    updateEndpoint: UserEndpointList.PartnerManagement.ChangeSettingCommission,
    getMethod: 'GET',
    showFields: [
      {
        key: `orderId`,
        label: "Order ID",
      },
      {
        key: "userId",
        label: "UID",
      },
      {
        key: "createdAt",
        label: "Trade time",
        dataType: "date-time",
      },
      {
        key: "symbol",
        label: "Trading Pair",
      },
      // {
      //   key: "initMargin",
      //   label: "Margin",
      // },
      // {
      //   key: "mode",
      //   label: "Mode",
      // },
      {
        key: "leverage",
        label: "Leverage",
      },
      {
        key: "tradeSide",
        label: "Side",
      },
      {
        key: "marginMode",
        label: "Margin Type",
      },
      {
        key: "avgOpenPrice",
        label: "Avg. Price | Order Price",
        dataType: "double",
      },
      {
        key: "filledQuantity",
        label: "Filled | Size",
        dataType: "amount",
      },
      {
        key: "realizedPnlOrder",
        label: "PNL",
        dataType: "amount",
      },
      {
        key: "fee",
        label: "FEE",
      },
    ],
    title: "detail",
    filterBy: [],
    inputFields: [],
    extraFilters: [
      {
        key: "accountId",
        label: "UID",
      },
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
      {
        key: `orderId`,
        label: "Order ID",
      },
      {
        key: "currency",
        label: "Currency"
      },
    ],
    ...props,
  })();
}
