export const vn={
    whitepaper:`Whitepaper`,
    litepaper:`Lite Paper`,
    go_to_app:`Mở Ứng Dụng`,
    move_to_earn:`MOVE TO EARN`,
    subtitle_intro:`Sprint là một ứng dụng tập thể dục nhận thưởng dựa trên Web3, cho phép người dùng tập luyện với NFT của họ và kiếm tiền.`,
    active_users:`Người dùng tích cực`,
    runners:`Người chạy`,
    process_infomation:`THÔNG TIN QUY TRÌNH`,
    total:`Tổng`,
    burned:`Đã đốt`,
    watch_nft:`WATCH NFT`,
    box_nft:`BOX NFT`,
    about_us:`VỀ CHÚNG TÔI`,
    sprint_and_more:`SPRINT VÀ HƠN NỮA`,
    subtitle_about:`Sprint là cuộc cách mạng công nghệ trong việc cải thiện sức khỏe và thể dục. NFT và phần thưởng SWP trao cho người dùng hợp lệ hàng ngày.`,
    about_title_1:`Dễ sử dụng`,
    about_subtitle_1:`Không cần hiểu về NFT hoặc tiền điện tử để sử dụng ứng dụng. Bạn có thể bắt đầu bằng cách tải ứng dụng, tham gia vào việc tập thể dục, hoặc mua một NFT đơn giản.`,
    about_title_2:`Xây dựng thói quen`,
    about_subtitle_2:`Những phần thưởng thực tế của Sprint cùng với các nền tảng truyền thông xã hội giúp dễ dàng tạo ra thói quen vận động, duy trì sức khỏe và lịch tập luyện.`,
    about_title_3:`Dễ tìm kiếm`,
    about_subtitle_3:`Nếu bạn sở hữu NFT riêng và tham gia đi bộ, chạy và tập thể dục, bạn có thể nhận được phần thưởng từ Sprint.`,
    collection:`BỘ SƯU TẬP`,
    box_info:`THÔNG TIN BOX`,
    token:`TOKEN`,
    token_economy:`KINH TẾ TOKEN`,
    subtitle_token:`Sprint phát hành 2 loại token riêng biệt để tránh xung đột giữa hệ thống phần thưởng và hệ thống tài chính dự án. Mỗi loại token có nhiệm vụ và chức năng khác nhau.`,
    describe_watch:`MÔ TẢ NFT WATCH`,
    user_manual:`HƯỚNG DẪN NGƯỜI DÙNG`,
    the_app:`ỨNG DỤNG`,
    step_1:`Bước 1`,
    step_1_label:`Tải ứng dụng Sprint`,
    step_2:`Bước 2`,
    step_2_label:`Đăng ký tài khoản trên màn hình bắt đầu của ứng dụng`,
    step_3:`Bước 3`,
    step_3_label:`Kết nối ví hoặc bỏ qua bước này.`,
    step_4:`Bước 4`,
    step_4_label:`Mua NFT Watch hoặc sử dụng NFT Free`,
    step_5:`Bước 5`,
    step_5_label:`Trải nghiệm ứng dụng và bắt đầu tập luyện.`,
    step_6:`Bước 6`,
    step_6_label:`Mời bạn bè tham gia tập thể dục cùng nhau.`,
    meet:`GẶP`,
    our_partner:`ĐỐI TÁC CỦA CHÚNG TÔI`,
    about_sprint:`VỀ SPRINT`,
    subscribe_subtitle:`Đăng ký nhận tin tức từ chúng tôi để cập nhật thông tin mới nhất về ra mắt, sự kiện, cập nhật, và hơn thế nữa....`,
    email_address:`Địa chỉ Email`,
    subscribe:`ĐĂNG KÝ`,
    ecosystem:`HỆ SINH  THÁI`,
    sprint_app:`Ứng dụng Sprint`,
    tokenomics:`Tokenomics`,
    nft_marketplace:`NFT Marketplace`,
    document:`TÀI LIỆU`,
    terms_of_use:`Điều khoản sử dụng.`,
    privacy_policy:`Chính sách bảo mật`,
    contact_us:`LIÊN HỆ CHÚNG TÔI`,
    join_us:`THAM GIA CÙNG CHÚNG TÔI`,
}