export const CHANGE_LANGUAGE = `CHANGE_LANGUAGE`;

export const SettingStoreConstant = {
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  SET_ERROR_CODE: "SET_ERROR_CODE",
  SET_SERVER_CONFIG: "SET_SERVER_CONFIG",
  SET_TABLE_CONFIG: "SET_TABLE_CONFIG",
  SET_SELECTED_USER: "SET_SELECTED_USER",
  SET_ASSETS: "SET_ASSETS",
};
export const GeneralPoint = {
  ApplyForm: "/user/apply",
};
export const SettingEndpoints = {
  SERVER_CONFIG: "/v1/configuration",
  Table_Configs: "/configuration/table-config",
};
