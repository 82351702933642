import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { IconLogout } from "@tabler/icons-react";
import ProfilePopover from "components/ProfilePopover";
import useUser from "hooks/useUser";
import * as React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { setUserReducer } from "store/user/actions";
import { logoutReturnTo } from "utils/auth";
import MenuComponent from "./MenuComponent";
import { userMenus } from "./UserMenu";
import { Grid, Typography } from "@mui/material";
import logoutIcon from 'assets/menu/logoutHover.png'
import { SelectLanguage } from "components/SelectLanguage";
import { languageList } from "constants/langlist";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
const drawerWidth = 300;

interface Props {
  window?: () => Window;
}

export default function UserMainLayout(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const dispatch = useAppDispatch();
  const { logout: exchangeLogout } = useUser();
  const [pathBase, setPathBase] =  React.useState('')
  const url = useLocation()
  const lang = localStorage.getItem('lang') || ''
  const {i18n} = useTranslation()
  
  const getLangParams = React.useMemo(() => {
      const langList = ['ind', 'es', 'ja', 'de', 'fr', 'cn'];
      const splitUrl = url.pathname.split("/").filter(Boolean)[0];
      const foundUrl = langList.find((e) => e.includes(splitUrl));
      return foundUrl || 'en';
  }, [lang]);

  React.useEffect(()=>{
    setPathBase(getLangParams === "en"
    ? "/"
    : `/${getLangParams || ""}${getLangParams ? "/" : ""}`)
  },[i18n.language, lang])

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const logout = () => {
    exchangeLogout(() => {});
    logoutReturnTo();
    dispatch(
      setUserReducer({
        information: null,
      })
    );
  };
  const drawer = (
    <div>
      <Toolbar>
        <Box component={Link} to="/">
          <Box component={"img"} src={"/svg/Light ver_1.svg"} width={100} />
        </Box>
      </Toolbar>
      <Divider sx={{borderColor:'#E5FCF7'}}/>
      <MenuComponent menus={userMenus(pathBase)} />
      <Divider sx={{borderColor:'#E5FCF7'}}/>
      <List>
        <ListItem disablePadding onClick={logout}>
          <ListItemButton>
            <ListItemIcon sx={{minWidth:'40px'}}>
              {/* <IconLogout /> */}
              <img src={logoutIcon} alt='logoutImg' width={20} height={20}/>
            </ListItemIcon>
            <ListItemText primary={t("common:dashboard.logout")} sx={{'span':{color:'#E70000'}}}/>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex"}}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: "none",
          background: "#F4FCFA",
          height: 'max-content'
        }}
        color="transparent"
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Grid sx={{display: {sm: 'none',xs:'none', md: 'block'}}}>
            <Typography fontWeight={600} color='gray' fontSize={22}>
              {/* Hi Admin, */}
              {t('common:dashboard.hiAdmin')}
            </Typography>
            <Typography fontWeight={700} fontSize={36}>
              {t('common:dashboard.welcome')}
            </Typography>
          </Grid>
          <div />
          <ProfilePopover />
        </Toolbar>
        {/* <Divider /> */}
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderColor:'#E5FCF7'
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderColor:'#E5FCF7'
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar sx={{display:'inline-flex', marginBottom:5}}/>
        <Outlet />
      </Box>
      <div style={{height:'60px', position:'fixed', bottom:'1%', right:0}}>
        <SelectLanguage langList={languageList}/>
      </div>
    </Box>
  );
}
