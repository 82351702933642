import { Box, Typography } from "@mui/material";
import HigherOrderComponent from "components/HOC/HigherOrderComponent";
import { CustomPaper } from "components/common/CustomComponents";
import { UserEndpointList } from "constants/endpoints";
import { useTranslation } from "react-i18next";

export default function ReferralStatistics() {
  const {t} = useTranslation()
  return (
    <Box>
      <Typography variant="h5" fontWeight={700} mb={2}>
        {t('common:table.referralStatic')}
      </Typography>
      <CustomPaper variant="outlined">
        <TableComponent />
      </CustomPaper>
    </Box>
  );
}

function TableComponent(props: any) {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.DataPanel.RefStatistic,
    getMethod: 'GET',
    showFields: [
      {
        key: "date",
        label: "Date",
        dataType: "date",
      },
      {
        key: "all",
        label: "All",
      },
      {
        key: "totalDirectReferral",
        label: "Direct Referral",
        dataType: "amount",
      },
      {
        key: "totalInDirectReferral",
        label: "Indirect Referral",
        dataType: "amount",
      },
    ],
    title: "Detail",
    filterBy: [],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
    ],
    sortAble:['date','all','totalDirectReferral', 'totalInDirectReferral'],
    ...props,
  })();
}
