import { languageList } from "constants/langlist"
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
export const useChangeParamWithLocale = (lang:any) => {
    const navigate = useNavigate()
    // const currentPath = window.location.pathname
    // const query = window.location.search
    // let url = ''
    // const path:any = languageList.find((value:any)=>{
    //   const find = value?.lang === lang
    //   if (find) {
    //     return value
    //   }
    // })
    // const paramsUrl = currentPath.split('/')
    // const languagePath = paramsUrl[0]
    // if ( lang !== 'en') {    
    //   if(languageList.some((item:any)=>{return item.lang === languagePath})){
    //     url = currentPath === '/' ? `/${path.lang}` : `/${path.lang}`;
    //   }
    //   else{
    //     url = currentPath === '/' ? `/${path.lang}` : `/${path.lang}${currentPath.replace(/\/(ind|es|en|ja|de|cn|fr)/g, '')}`;
    //   }
    // }
    // else {
    //   url = currentPath.replace(/\/(ind|es|ja|de|cn|fr)/g, '')
    // }
  
    // window.history.replaceState({}, '', url + query || '/')
    // navigate(url + query || '/', { replace: true })
    const changeParamWithLocale = (lang: string) => {
      const currentPath = window.location.pathname;
      const query = window.location.search;
  
      let url = '';
      const path = languageList.find((value: any) => value?.lang === lang);
      const paramsUrl = currentPath.split('/');
      const languagePath = paramsUrl[1]; // Lấy phần tử thứ hai sau dấu '/'
  
      if (lang !== 'en') {
        if (languageList.some((item: any) => item.lang === languagePath)) {
          url = currentPath === '/' ? `/${path?.lang}` : `/${path?.lang}${currentPath.replace(/\/(ind|es|en|ja|de|cn|fr)/g, '')}`;
        } else {
          url = currentPath === '/' ? `/${path?.lang}` : `/${path?.lang}${currentPath.replace(/\/(ind|es|ja|de|cn|fr)/g, '')}`;
        }
      } else {
        url = currentPath.replace(/\/(ind|es|ja|de|cn|fr)/g, '');
      }
  
      navigate(url + query || '/', { replace: true });
    };
  
    return changeParamWithLocale; // Đảm bảo trả về hàm
  }

const getLangParams = (pathname:any) => {
  const langList = ['ind', 'es', 'ja', 'de', 'fr', 'cn'];
  const splitUrl = pathname.split("/").filter(Boolean)[0];
  const foundUrl = langList.find((e) => e.includes(splitUrl));
  return foundUrl;
};  
const lang = localStorage.getItem('lang')

export const useBasePath = () => {
  const {pathname} = useLocation();
  const [currentBasePath, setCurrentBasePath] = useState(getLangParams(pathname));
  useEffect(() => {
    console.log(pathname, lang)
    setCurrentBasePath(getLangParams(pathname));
  }, [ lang]);

  return currentBasePath === "en"
    ? "/"
    : `/${currentBasePath || ""}${currentBasePath ? "/" : ""}`;
};
