import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CustomDrawer from "components/common/CustomDrawer";
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "store/hooks";
import { get, post } from "utils/api";
import FieldInput from "./FieldInput";

export default function CreateForm({
  fields,
  handleRefresh,
  createEndpoint,
  seekEndpoint,
}: {
  fields: TInput[];
  handleRefresh: any;
  createEndpoint: string;
  seekEndpoint: string;
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { settings } = useAppSelector((state) => state);
  const { serverConfig } = settings;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const body: { id: number; fields: { [key: string]: any } } = {
      id: e.target.id.value,
      fields: {},
    };
    fields.forEach((field) => {
      if (field.key !== "id") {
        if (field.type === "number") {
          body.fields[field.key] = parseFloat(e.target[field.key].value);
        } else if (field.type === "date-time") {
          body.fields[field.key] = moment(e.target[field.key].value)
            .utcOffset(
              serverConfig?.appConfig.utcOffset
                ? serverConfig?.appConfig.utcOffset
                : 0
            )
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          body.fields[field.key] = e.target[field.key].value;
        }
      }
    });
    setLoading(true);
    post(
      createEndpoint,
      body,
      () => {
        toast.success("Success");
        handleRefresh();
        setLoading(false);
        setOpen(false);
      },
      (error: any) => {
        setLoading(false);
        toast.error(
          `Message: ${error.errors[0].message}. Code: ${error.errors[0].code}`
        );
      }
    );
  };

  const seekData = () => {
    get(
      seekEndpoint,
      () => {
        toast.success("Success");
        handleRefresh();
      },
      () => toast.error("Fail")
    );
  };

  return (
    <>
      {createEndpoint ? (
        <Button variant="outlined" onClick={() => setOpen(true)} sx={{ mb: 2 }}>
          Add new
        </Button>
      ) : null}
      {/* {seekEndpoint ? (
        <Button
          variant="outlined"
          color="error"
          onClick={seekData}
          sx={{ ml: 1 }}
        >
          Seek data
        </Button>
      ) : null} */}
      <CustomDrawer open={open}>
        <Box>
          <AppBar position="sticky" sx={{ py: 1 }}>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton onClick={() => setOpen(false)}>
                  <ArrowBackIcon fontSize="small" sx={{ fill: "#fff" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="body1">Back</Typography>
              </Grid>
            </Grid>
          </AppBar>
          <Box p={2} component={"form"} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {fields?.map((field, index) => (
                <Grid item xs={field.col ? field.col : 12} key={index}>
                  <FieldInput field={field} />
                </Grid>
              ))}
              <Grid item xs={12}>
                <LoadingButton
                  loading={loading}
                  variant="outlined"
                  type="submit"
                >
                  Create
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CustomDrawer>
    </>
  );
}
