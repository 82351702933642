import { UserEndpointList } from "constants/endpoints";
import { AppDispatch } from "store";
import { get } from "utils/api";
import { exchangeGet } from "utils/exchange-api";

export enum UserActionTypes {
  SET_ANYTHING = "SET_ANYTHING",
}

interface SetUserReducer {
  type: UserActionTypes.SET_ANYTHING;
  payload: {
    [Property in keyof TUserReducerInitialState]?: any;
  };
}

export const setUserReducer = (payload: {
  [Property in keyof TUserReducerInitialState]?: any;
}): SetUserReducer => ({
  type: UserActionTypes.SET_ANYTHING,
  payload,
});

export const getUserInformation = () => (dispatch: AppDispatch) => {
  get(
    UserEndpointList.Partner.Profile,
    (data: any) => {
      exchangeGet(`profile`, (exchangeInfo: any) => {
        dispatch(
          setUserReducer({
            isLoading: false,
            information: { ...exchangeInfo, ...data },
          })
        );
      });
    },
    (error: any) => {
      dispatch(setUserReducer({ isLoading: false }));
    }
  );
};

export const getF1Information = () => (dispatch: AppDispatch) => {
  get(
    UserEndpointList.Partner.Profile,
    (data: any) => {
      dispatch(
        setUserReducer({
          F1Information: data,
        })
      );
    },
    (error: any) => {
      console.log(error);
    }
  );
};

export type UserActionsType = SetUserReducer;
