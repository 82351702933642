import { SvgIcon, useTheme } from "@mui/material";

const EyeIcon = (props: any) => {
  const theme = useTheme();

  return (
    <SvgIcon
      viewBox="0 0 32 32"
      {...props}
      style={{
        color: theme.palette.text.secondary,
        width: props?.size || 20,
        height: props?.size || 20,
        ...props.sx,
      }}
    >
      <path
        fill="currentColor"
        d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68M16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25"
      />
      <path
        fill="currentColor"
        d="M16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6m0 10a4 4 0 1 1 4-4a4 4 0 0 1-4 4"
      />
    </SvgIcon>
  );
};

export default EyeIcon;
