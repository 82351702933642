import HigherOrderComponent from "components/HOC/HigherOrderComponent";
import { UserEndpointList } from "constants/endpoints";
import { t } from "i18next";
import { useAppSelector } from "store/hooks";

function History(props: any) {
  return HigherOrderComponent({
    getEndpoint: UserEndpointList.Dashboard.TradeHistory,
    getMethod: "GET",
    showFields: [
      {
        key: "accountId",
        label: "Account Id",
        // dataType: "date",
      },
      {
        key: "completedDate",
        label: "Time",
        dataType: "date",
      },
      {
        key: "isDirectReferral",
        label: "Referral Type",
        dataType: "referral-type",
      },
      {
        key: "asset",
        label: "Asset",
        // dataType: "date",
      },
      {
        key: "totalAmountValue",
        label: "Total Commission",
        dataType: "amount",
      },
      // {
      //   key: "totalFeeValue",
      //   label: "Total Fee (USDT)",
      //   dataType: "amount",
      // },
      {
        key: "totalVolumeValue",
        label: "Total Trading Volume (USDT)",
        dataType: "amount",
      },
    ],
    sortAble:['accountId','asset','completedDate','isDirectReferral','totalAmountValue', 'totalVolumeValue'],
    extraFilters: [
      {
        key: "startDate",
        label: "Start Date",
        type: "date",
      },
      {
        key: "endDate",
        label: "End Date",
        type: "date",
      },
      {
        key: "isDirectRef",
        label: "Referral Type",
        type: "select",
        options:[    
          {label:  t("common:table.All"), value: ''},
          {label:  t("common:table.Indirect"), value: "0"},
          {label:  t("common:table.Direct"), value: "1"}
        ]
      },
      {
        key: "fromAccountId",
        label: "From Account Id",
      },
    ],
    ...props,
  })();
}

export default function HistoryData() {
  const userStore = useAppSelector((state) => state.user);
  const { information } = userStore;

  if (!information) return null;
  return <History />;
}
