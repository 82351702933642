import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import AppModal from "components/AppModal";
import AppSelect from "components/AppSelect";
import Confirm2Fa from "components/Confirm2Fa";
import ConfirmCaptchaV4 from "components/ConfirmCaptchaV4";
import { CustomLoadingButton } from "components/CustomLoadingButton";
import Iconify from "components/Iconify";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import AppTextField from "components/input-fields/AppTextField";
import { Form, FormikProvider, useFormik } from "formik";
import useUser from "hooks/useUser";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { exchangeGet, exchangePost } from "utils/exchange-api";
import { getMachineId, jsonToUrlParams } from "utils/utils";
import { recoverMessageAddress } from "viem";
import { useConnect, useSignMessage } from "wagmi";
import * as Yup from "yup";
import LoginByQr from "./LoginByQr";

const StepOne = ({ onCompleted, onNextStep }: any) => {
  const theme = useTheme();
  const [submitting, setSubmitting] = useState(false);
  const [captchaValue, setCaptchaValue] = useState<any>(null);
  const [googleAccount, setGoogleAccount] = useState<any>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [phoneOption, setPhoneOption] = useState([]);
  const [phoneCode, setPhoneCode] = useState<any>(null);
  const [blockedTo, setBlockedTo] = useState(0);
  const [tempProfile, setTempProfile] = useState<any>(null);
  const [unlocking, setUnlocking] = useState(false);
  const [unlockSuccess, setUnlockSuccess] = useState(false);

  const handleGetPhoneOption = () => {
    exchangeGet(`phoneCodes`, (result) => {
      setPhoneOption(
        result.map((item: any) => ({
          ...item,
          value: item.code,
          label: item.iso,
        }))
      );
    });
  };

  const signIn = useGoogleLogin({
    // clientId: GOOGLE_CLIENT_ID,
    onSuccess: (result) => onRequestGoogleAccountSuccess(result),
    onError: () => setSubmitting(false),
    onNonOAuthError: () => setSubmitting(false),
  });

  const { signMessage } = useSignMessage();
  const { connect, connectors } = useConnect();

  const message = t("Login to Dikex");

  const navigate = useNavigate();
  const {
    loginAccount,
    loginWithGoogle,
    loginWithWallet,
    connectWeb3Account,
    loginWithPhone,
  } = useUser();

  const Schema = Yup.object().shape({
    email:
      tabIndex === 0 &&
      (Yup.string()
        .required(`${t("Please Enter your email")}`)
        .trim() as any),
    // phone:
    //   tabIndex === 1 &&
    //   (Yup.string()
    //     .required(`${t("Please Enter phone number")}`)
    //     .trim() as any),
    password: Yup.string()
      .required(`${t("Please Enter your password")}`)
      .trim() as any,
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      password: "",
    },
    validationSchema: Schema,
    onSubmit: async () => {},
  });

  const handleCheckAccount = async (
    account: any,
    onSuccess: any,
    onError: any
  ) => {
    const params = {
      email: account?.email,
      deviceCode: getMachineId(),
      signature: account?.signature,
      address: account?.address,
      signMessage: message,
      phoneNumber: account.phoneNumber,
      phoneIsoCode: account.phoneIsoCode,
    };
    await exchangeGet(
      `account/exist-checking?${jsonToUrlParams(params)}`,
      () => {
        onSuccess({ account: { ...params, ...account } });
      },
      (error) => {
        onError({ error: error, account: { ...params, ...account } });
      }
    );
  };

  // Default Login
  const handleLogin = (value: any) => {
    console.log(';TRRRRRRS')
    setSubmitting(true);
    const params = {
      email: formik.values.email,
      password: formik.values.password,
      captcha: {
        lotNumber: value?.lot_number,
        captchaOutput: value?.captcha_output,
        passToken: value?.pass_token,
        genTime: value?.gen_time,
      },
      loginType: "Normal",
    };
    loginAccount(
      params,
      (result: any) => {
        setSubmitting(false);
        onCompleted();
      },
      (error: any) => {
        setSubmitting(false);
        if (error.code === "GA_CODE_REQUIRED")
          onNextStep({ ...params, api: error.api });
        else {
          if (error.code === "ACCOUNT_BLOCKED") {
            let blockedTo = JSON.parse(
              window.localStorage.getItem("blockedTo") || "0"
            );
            if (!blockedTo) {
              blockedTo = moment()
                .add(
                  parseFloat(error.msg?.replace(/(^.+)(\w\d+\w)(.+$)/i, "$2")),
                  "minute"
                )
                .unix();
              window.localStorage.setItem(
                "blockedTo",
                JSON.stringify(blockedTo)
              );
            }
            setBlockedTo(blockedTo);
            exchangeGet(
              `account/blocked-account-info?email=${params.email}`,
              (profile) => setTempProfile(profile)
            );
          } else {
            toast.error(`${error.msg}`);
          }
        }
      }
    );
  };

  // Login by phone

  const handleLoginByPhone = (value: any) => {
    const params = {
      phoneNumber: `${phoneCode.code}${formik.values.phone}`,
      phoneIsoCode: phoneCode.iso,
      password: formik.values.password,
      captcha: {
        lotNumber: value?.lot_number,
        captchaOutput: value?.captcha_output,
        passToken: value?.pass_token,
        genTime: value?.gen_time,
      },
      loginType: "Phone",
    };
    setSubmitting(true);
    handleCheckAccount(
      params,
      () => {
        loginWithPhone(
          params,
          () => {
            onCompleted();
            setSubmitting(false);
          },
          (error: any) => {
            if (error.code === "GA_CODE_REQUIRED") {
              onNextStep({ ...params, ...error, loginType: "Phone" });
            } else {
              if (error.code === "ACCOUNT_BLOCKED") {
                let blockedTo = JSON.parse(
                  window.localStorage.getItem("blockedTo") || "0"
                );
                if (!blockedTo) {
                  blockedTo = moment()
                    .add(
                      parseFloat(
                        error.msg?.replace(/(^.+)(\w\d+\w)(.+$)/i, "$2")
                      ),
                      "minute"
                    )
                    .unix();
                  window.localStorage.setItem(
                    "blockedTo",
                    JSON.stringify(blockedTo)
                  );
                }
                setBlockedTo(blockedTo);
                exchangeGet(
                  `account/blocked-account-info?phoneNumber=${params.phoneNumber}&phoneIsoCode=${params.phoneIsoCode}`,
                  (profile) => setTempProfile(profile)
                );
              } else {
                toast.error(`${error.msg}`);
              }
              setSubmitting(false);
            }
          }
        );
      },
      (error: any) => {
        setSubmitting(false);
        toast.error(error.error.msg);
      }
    );
  };

  // Login with google

  const handleRequestGoogleAccount = (value: any) => {
    setCaptchaValue({
      lotNumber: value.lot_number,
      captchaOutput: value.captcha_output,
      passToken: value.pass_token,
      genTime: value.gen_time,
      byPass: "skip",
    });
    setSubmitting(true);
    signIn();
  };

  const onRequestGoogleAccountSuccess = async (params: any) => {
    const { access_token } = params;
    try {
      const response = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      await handleCheckAccount(
        { ...params, ...response?.data },
        (successStatus: any) => {
          setGoogleAccount(successStatus?.account);
        },
        (errorStatus: any) => {
          if (errorStatus?.error?.code === "ACCOUNT_NOTFOUND") {
            const { access_token, captcha } = errorStatus?.account;
            const connectParams = {
              accessToken: access_token,
              captcha: captchaValue,
            };
            exchangePost(
              "account/connect-google",
              connectParams,
              (result) => {
                setGoogleAccount(errorStatus?.account);
              },
              (error: any) => {
                toast.error(error.msg);
              }
            );
          }
        }
      );
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleLoginByGoogle = (googleAccount: any, captcha: any) => {
    const params = {
      deviceCode: googleAccount?.deviceCode,
      accessToken: googleAccount?.access_token,
      email: googleAccount?.email,
      captcha: captcha,
    };
    loginWithGoogle(
      params,
      () => {
        setSubmitting(false);
        onCompleted();
      },
      (error: any) => {
        if (error.code === "GA_CODE_REQUIRED")
          onNextStep({ ...params, loginType: "Google" });
        else {
          setSubmitting(false);
          toast.error(`${error.msg}`);
        }
      }
    );
  };

  useEffect(() => {
    if (captchaValue && googleAccount?.access_token) {
      handleLoginByGoogle(googleAccount, captchaValue);
    }
  }, [captchaValue, googleAccount]);

  // Login with web 3

  const handleRequestMetaMaskAccount = async (captcha: any) => {
    setSubmitting(true);
    connect(
      { connector: connectors[0] },
      {
        onError: ({ message, code }: any) => {
          if (code === -32002) {
            toast.error(
              t("Already processing. Please wait or confirm on your wallet.")
            );
            setSubmitting(false);
          }
        },
        onSettled: async (a, b, c) => {
          await signMessage(
            { message: message },
            {
              onSuccess: async (signature, variables) => {
                const address = await recoverMessageAddress({
                  message: variables.message,
                  signature: signature,
                });
                const params = {
                  signature: signature,
                  address: address,
                  captcha: {
                    lotNumber: captcha?.lot_number,
                    captchaOutput: captcha?.captcha_output,
                    passToken: captcha?.pass_token,
                    genTime: captcha?.gen_time,
                    byPass: "skip",
                  },
                };
                await handleCheckAccount(
                  { ...params },
                  (successStatus: any) => {
                    handleLoginByWallet({ ...successStatus?.account });
                  },
                  (errorStatus: any) => {
                    if (errorStatus?.error?.code === "ACCOUNT_NOTFOUND") {
                      connectWeb3Account({
                        ...params,
                        successCallback: () => {
                          onCompleted();
                        },
                        errorCallback: (error: any) => {
                          setSubmitting(false);
                          if (error.code === "GA_CODE_REQUIRED")
                            onNextStep({
                              ...params,
                              ...error,
                              loginType: "Wallet",
                              skipConnectAccount: !Boolean(error.email),
                            });
                        },
                      });
                    }
                  }
                );
              },
              onError: () => {
                setSubmitting(false);
              },
            }
          );
        },
      }
    );
  };

  const handleLoginByWallet = (signature: any) => {
    const params = { ...signature };
    loginWithWallet(
      params,
      (result: any) => {
        setSubmitting(false);
        onCompleted();
      },
      (error: any) => {
        setSubmitting(false);
        if (error.code === "GA_CODE_REQUIRED") {
          console.log("error", error);
          onNextStep({
            ...params,
            ...error,
            loginType: "Wallet",
            skipConnectAccount: !Boolean(error.email),
          });
        } else toast.error(`${error.msg}`);
      }
    );
  };

  // unlock account

  const handleUnlockAccount = (otp: any) => {
    const params = {
      email: tempProfile?.email,
      phoneNumber: tempProfile?.phoneNumber,
      phoneIsoCode: tempProfile?.phoneIsoCode,
      emailOtp: otp?.otp,
      phoneOtp: otp?.phoneOtp,
      gaCode: otp?.gaCode,
    };
    setUnlocking(true);
    exchangePost(
      "account/unlock",
      params,
      () => {
        setUnlocking(false);
        setTempProfile(null);
        window.localStorage.removeItem("blockedTo");
        setUnlockSuccess(true);
      },
      (error: any) => {
        toast.error(error.msg);
        setUnlocking(false);
      }
    );
  };

  const handleUnlockSuccess = () => {
    setBlockedTo(0);
    setUnlockSuccess(false);
  };

  useEffect(() => {
    handleGetPhoneOption();
  }, []);

  const { getFieldProps, errors, touched, handleReset, values } = formik;

  const isError =
    (tabIndex === 0 && (errors.email || values?.email?.length === 0)) ||
    // (tabIndex === 1 && (errors.phone || values?.phone?.length === 0)) ||
    values?.password?.length === 0 ||
    errors.password;

  return (
    <Box>
      {/* <FlexRowAlign gap={2} mt={2}>
        <Button
          onClick={() => setTabIndex(2)}
          className={tabIndex === 2 ? "active" : ""}
          sx={{
            width: 130,
            borderRadius: 35,
            height: 35,
            "&.active": {
              background: theme.palette.text.primary,
              color: theme.palette.background.default,
            },
          }}
        >
          {t("QR")}
        </Button>
        <Button
          onClick={() => setTabIndex(0)}
          className={tabIndex === 0 ? "active" : ""}
          sx={{
            width: 130,
            borderRadius: 35,
            height: 35,
            "&.active": {
              background: theme.palette.text.primary,
              color: theme.palette.background.default,
            },
          }}
        >
          {t("Email")}
        </Button>
        <Button
          onClick={() => setTabIndex(1)}
          className={tabIndex === 1 ? "active" : ""}
          sx={{
            width: 130,
            borderRadius: 35,
            height: 35,
            "&.active": {
              background: theme.palette.text.primary,
              color: theme.palette.background.default,
            },
          }}
        >
          {t("Phone number")}
        </Button>
      </FlexRowAlign> */}
      {tabIndex < 2 ? (
        <FormikProvider value={formik}>
          <Form>
            <Stack gap={3} mt={5}>
              {/* {tabIndex === 0 && ( */}
                <StyledInput
                  fullWidth
                  placeholder={t("Email")}
                  {...getFieldProps("email")}
                  error={Boolean(errors.email) && Boolean(touched.email)}
                  helperText={Boolean(touched.email) && errors.email}
                />
               {/* )} */}
              {/* {tabIndex === 1 && phoneOption.length > 0 && (
                <>
                  <StyledInput
                    fullWidth
                    placeholder={t("Phone number")}
                    {...getFieldProps("phone")}
                    error={Boolean(errors.phone) && Boolean(touched.phone)}
                    helperText={Boolean(touched.phone) && errors.phone}
                    InputProps={{
                      startAdornment: (
                        <AppSelect
                          data={phoneOption}
                          allowNull={false}
                          allowSearch
                          defaultValue={navigator?.language.split("-")?.[1]}
                          contentSx={{
                            width: 300,
                            maxHeight: 350,
                          }}
                          onSelect={(value: any) => setPhoneCode(value)}
                          buttonRender={(value: any) => (
                            <FlexRowAlign
                              disableRipple
                              width="100%"
                              gap={1}
                              justifyContent="flex-start"
                            >
                              <Typography color={"text.primary"}>
                                {value.label}
                              </Typography>
                              <Typography
                                color={"text.primary"}
                                sx={{ whiteSpace: "nowrap" }}
                              >
                                +{value.code}
                              </Typography>
                            </FlexRowAlign>
                          )}
                          optionRender={(value: any) => (
                            <FlexRowAlign
                              width="100%"
                              gap={1}
                              justifyContent="flex-start"
                            >
                              <Typography color={"text.primary"}>
                                {value.label}
                              </Typography>
                              <Typography color={"text.primary"}>
                                +{value.code}
                              </Typography>
                              <Box flexGrow={1} />
                              <Typography>{value.country}</Typography>
                            </FlexRowAlign>
                          )}
                        />
                      ),
                    }}
                  />
                </>
              )} */}
              <StyledInput
                fullWidth
                placeholder={t("Password")}
                type="password"
                {...getFieldProps("password")}
                error={Boolean(errors.password) && Boolean(touched.password)}
                helperText={Boolean(touched.password) && errors.password}
              />
              {/* <ConfirmCaptchaV4
                disabled={submitting}
                type={"submit"}
                beforeOnClick={() => setSubmitting(true)}
                onClick={(value: any) =>
                  tabIndex === 0
                    ? handleLogin(value)
                    : handleLoginByPhone(value)
                }
                onCancel={() => setSubmitting(false)}
              >
                <CustomLoadingButton
                  sx={{ height: 40 }}
                  variant="contained"
                  disabled={Boolean(isError)}
                  className={""}
                  loading={submitting}
                >
                  {t("Sign In")}
                </CustomLoadingButton>
              </ConfirmCaptchaV4> */}
              <CustomLoadingButton
                  sx={{ height: 40 }}
                  variant="contained"
                  disabled={Boolean(isError)}
                  className={""}
                  loading={submitting}
                  onClick={(value: any) =>handleLogin(value)}
                >
                  {t("Sign In")}
                </CustomLoadingButton>
              <Divider
                sx={{ color: theme.palette.text.secondary, fontWeight: 600 }}
              >
                OR
              </Divider>
              <FlexBetween gap={2}>
                <ConfirmCaptchaV4
                  disabled={submitting}
                  onClick={(value: any) => handleRequestGoogleAccount(value)}
                  sx={{ width: "100%" }}
                >
                  <CustomLoadingButton
                    loading={submitting}
                    sx={{
                      height: 40,
                      background: `${theme.palette.divider} !important`,
                      color: theme.palette.text.primary,
                    }}
                  >
                    <Iconify icon={"flat-color-icons:google"} size={25} />
                    <Typography ml={2} fontSize={10} fontWeight={900}>
                      {t("Continue with Google")}
                    </Typography>
                  </CustomLoadingButton>
                </ConfirmCaptchaV4>
                <Hidden mdDown>
                  <ConfirmCaptchaV4
                    disabled={submitting}
                    onClick={(value: any) =>
                      handleRequestMetaMaskAccount(value)
                    }
                    sx={{ width: "100%" }}
                  >
                    <CustomLoadingButton
                      loading={submitting}
                      sx={{
                        height: 40,
                        background: `${theme.palette.divider} !important`,
                        color: theme.palette.text.primary,
                      }}
                    >
                      <Iconify icon={"logos:metamask-icon"} size={20} />
                      <Typography ml={2} fontSize={10} fontWeight={900}>
                        {t("Continue with MetaMask")}
                      </Typography>
                    </CustomLoadingButton>
                  </ConfirmCaptchaV4>
                </Hidden>
              </FlexBetween>
            </Stack>
          </Form>
        </FormikProvider>
      ) : (
        <LoginByQr onCompleted={onCompleted} />
      )}

      <AppModal open={Boolean(blockedTo > 0)} onClose={() => setBlockedTo(0)}>
        {unlockSuccess ? (
          <Stack alignItems={"center"} justifyContent={"center"}>
            <Avatar
              src="/images/unlock-user.png"
              sx={{ width: 70, height: 70, my: 5 }}
            />
            <Typography
              variant="h5"
              maxWidth={300}
              textAlign={"center"}
              fontWeight={600}
            >
              {t("Congrats! Your account has been unlocked.")}
            </Typography>
            <Typography
              my={2}
              maxWidth={400}
              color={"text.secondary"}
              textAlign={"center"}
              variant="body2"
            >
              {t(
                "You can now login again. Reset your password if you've forgotten it."
              )}
            </Typography>
            <LoadingButton
              onClick={() => handleUnlockSuccess()}
              sx={{ maxWidth: 400, my: 2 }}
            >
              OK
            </LoadingButton>
          </Stack>
        ) : (
          <Stack alignItems={"center"} justifyContent={"center"}>
            <Avatar
              src="/images/block-user.png"
              sx={{ width: 70, height: 70, my: 5 }}
            />
            <Typography variant="h5" fontWeight={600}>
              {t("Account temporarily blocked")}
            </Typography>
            <CustomCountDown
              nextTime={blockedTo}
              onComplete={() => setBlockedTo(0)}
            />
            <FlexBetween gap={2} width={"100%"} p={2}>
              <Button
                onClick={() => setBlockedTo(0)}
                variant="outlined"
                sx={{ flex: 1, borderRadius: 50 }}
              >
                {t("Cancel")}
              </Button>
              <Confirm2Fa
                submitting={unlocking}
                disabled={unlocking}
                profile={tempProfile}
                endpoint={"account/unlock/send-otp"}
                params={{ email: tempProfile?.email }}
                onClick={(otp: any) => handleUnlockAccount(otp)}
                phoneNumber={tempProfile?.phoneNumber}
                sx={{ flex: 1 }}
              >
                <Button
                  variant="outlined"
                  sx={{ width: "100%", borderRadius: 50 }}
                >
                  {"Unlock"}
                </Button>
              </Confirm2Fa>
            </FlexBetween>
          </Stack>
        )}
      </AppModal>
    </Box>
  );
};

const CustomCountDown = ({ nextTime, onComplete }: any) => {
  const [countdownTime, setCountdownTime] = useState(-1);
  useEffect(() => {
    if (nextTime) {
      const temp = nextTime - moment().unix();
      if (temp > 0) {
        setCountdownTime(temp);
      }
    }
  }, [nextTime]);

  return (
    <>
      {countdownTime >= 0 && (
        <Countdown
          date={Date.now() + countdownTime * 1000}
          onComplete={onComplete}
          renderer={({ seconds, minutes, hours }) => (
            <Typography
              color={"text.secondary"}
              className="number"
              variant="body2"
              width={"80%"}
              textAlign={"center"}
              py={3}
            >
              {t(
                "Your account is temporarily blocked for {{time}} due to over 5 incorrect password attempts. Verify your security item to unlock your account now"
              ).replace(
                "{{time}}",
                `0${hours}:${minutes > 9 ? minutes : `0${minutes}`}:${
                  seconds > 9 ? seconds : `0${seconds}`
                }s`
              )}
            </Typography>
          )}
        />
      )}
    </>
  );
};

const StyledInput = styled(AppTextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    background: theme.palette.background.paper,
    paddingLeft: "1.5rem",
    borderRadius: "50px",
    overflow: "hidden",
    paddingRight: "0px",
    height: 50,
    border: "none",
    fontSize: "0.85rem",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      outline: "none",
    },
  },
}));

export default StepOne;

// const StepOne = ({ onCompleted, onNextStep }: any) => {
//   const theme = useTheme();
//   const [submitting, setSubmitting] = useState(false);
//   const [captchaValue, setCaptchaValue] = useState<any>(null);
//   const [googleAccount, setGoogleAccount] = useState<any>(null);
//   const [tabIndex, setTabIndex] = useState(2);
//   const [phoneOption, setPhoneOption] = useState([]);
//   const [phoneCode, setPhoneCode] = useState<any>(null);

//   const handleGetPhoneOption = () => {
//     exchangeGet(`phoneCodes`, (result) => {
//       setPhoneOption(
//         result.map((item: any) => ({
//           ...item,
//           value: item.code,
//           label: item.iso,
//         }))
//       );
//     });
//   };

//   const signIn = useGoogleLogin({
//     // clientId: GOOGLE_CLIENT_ID,
//     onSuccess: (result: any) => onRequestGoogleAccountSuccess(result),
//     onError: () => setSubmitting(false),
//     onNonOAuthError: () => setSubmitting(false),
//   });

//   const { signMessage } = useSignMessage();
//   const { connect, connectors } = useConnect();

//   const message = t("Login to Dikex");

//   const navigate = useNavigate();
//   const {
//     user,
//     loginAccount,
//     loginWithGoogle,
//     loginWithWallet,
//     connectWeb3Account,
//     loginWithPhone,
//   } = useUser();
//   // if (user && user.accessToken) navigate(-1);

//   const Schema = Yup.object().shape({
//     email:
//       tabIndex === 0 &&
//       (Yup.string()
//         .required(`${t("Please Enter your email")}`)
//         .trim() as any),
//     phone:
//       tabIndex === 1 &&
//       (Yup.string()
//         .required(`${t("Please Enter phone number")}`)
//         .trim() as any),
//     password: Yup.string()
//       .required(`${t("Please Enter your password")}`)
//       .trim() as any,
//   });

//   const formik = useFormik({
//     initialValues: {
//       email: "",
//       phone: "",
//       password: "",
//     },
//     validationSchema: Schema,
//     onSubmit: async () => {},
//   });

//   const handleCheckAccount = async (
//     account: any,
//     onSuccess: any,
//     onError: any
//   ) => {
//     const params = {
//       email: account?.email,
//       deviceCode: getMachineId(),
//       signature: account?.signature,
//       address: account?.address,
//       signMessage: message,
//       phoneNumber: account.phoneNumber,
//       phoneIsoCode: account.phoneIsoCode,
//     };
//     await exchangeGet(
//       `account/exist-checking?${jsonToUrlParams(params)}`,
//       () => {
//         onSuccess({ account: { ...params, ...account } });
//       },
//       (error) => {
//         onError({ error: error, account: { ...params, ...account } });
//       }
//     );
//   };

//   // Default Login

//   const handleLogin = (value: any) => {
//     setSubmitting(true);
//     const params = {
//       email: formik.values.email,
//       password: formik.values.password,
//       captcha: {
//         lotNumber: value?.lot_number,
//         captchaOutput: value?.captcha_output,
//         passToken: value?.pass_token,
//         genTime: value?.gen_time,
//       },
//       loginType: "Normal",
//     };
//     console.log("params", params);

//     loginAccount(
//       params,
//       (result: any) => {
//         setSubmitting(false);
//         onCompleted();
//       },
//       (error: any) => {
//         setSubmitting(false);
//         if (error.code === "GA_CODE_REQUIRED")
//           onNextStep({ ...params, api: error.api });
//         else toast.error(`${error.msg}`);
//       }
//     );
//   };

//   // Login by phone

//   const handleLoginByPhone = (value: any) => {
//     const params = {
//       phoneNumber: `${phoneCode.code}${formik.values.phone}`,
//       phoneIsoCode: phoneCode.iso,
//       password: formik.values.password,
//       captcha: {
//         lotNumber: value?.lot_number,
//         captchaOutput: value?.captcha_output,
//         passToken: value?.pass_token,
//         genTime: value?.gen_time,
//       },
//       loginType: "Phone",
//     };
//     setSubmitting(true);
//     handleCheckAccount(
//       params,
//       () => {
//         loginWithPhone(
//           params,
//           () => {
//             onCompleted();
//             setSubmitting(false);
//           },
//           (error: any) => {
//             if (error.code === "GA_CODE_REQUIRED") {
//               onNextStep({ ...params, ...error, loginType: "Phone" });
//             } else {
//               toast.error(error.msg);
//               setSubmitting(false);
//             }
//           }
//         );
//       },
//       (error: any) => {
//         setSubmitting(false);
//         toast.error(error.error.msg);
//       }
//     );
//   };

//   // Login with google

//   const handleRequestGoogleAccount = (value: any) => {
//     setCaptchaValue({
//       lotNumber: value.lot_number,
//       captchaOutput: value.captcha_output,
//       passToken: value.pass_token,
//       genTime: value.gen_time,
//       byPass: "skip",
//     });
//     setSubmitting(true);
//     signIn();
//   };

//   const onRequestGoogleAccountSuccess = async (params: any) => {
//     const { access_token } = params;
//     try {
//       const response = await axios.get(
//         "https://www.googleapis.com/oauth2/v3/userinfo",
//         {
//           headers: {
//             Authorization: `Bearer ${access_token}`,
//           },
//         }
//       );
//       await handleCheckAccount(
//         { ...params, ...response?.data },
//         (successStatus: any) => {
//           setGoogleAccount(successStatus?.account);
//         },
//         (errorStatus: any) => {
//           if (errorStatus?.error?.code === "ACCOUNT_NOTFOUND") {
//             const { access_token, captcha } = errorStatus?.account;
//             const connectParams = {
//               accessToken: access_token,
//               captcha: captchaValue,
//             };
//             exchangePost(
//               "account/connect-google",
//               connectParams,
//               (result) => {
//                 setGoogleAccount(errorStatus?.account);
//               },
//               (error: any) => {
//                 toast.error(error.msg);
//               }
//             );
//           }
//         }
//       );
//     } catch (err) {
//       console.log("err", err);
//     }
//   };

//   const handleLoginByGoogle = (googleAccount: any, captcha: any) => {
//     const params = {
//       deviceCode: googleAccount?.deviceCode,
//       accessToken: googleAccount?.access_token,
//       email: googleAccount?.email,
//       captcha: captcha,
//     };
//     loginWithGoogle(
//       params,
//       () => {
//         setSubmitting(false);
//         onCompleted();
//       },
//       (error: any) => {
//         if (error.code === "GA_CODE_REQUIRED")
//           onNextStep({ ...params, loginType: "Google" });
//         else {
//           setSubmitting(false);
//           toast.error(`${error.msg}`);
//         }
//       }
//     );
//   };

//   useEffect(() => {
//     if (captchaValue && googleAccount?.access_token) {
//       handleLoginByGoogle(googleAccount, captchaValue);
//     }
//   }, [captchaValue, googleAccount]);

//   // Login with web 3

//   const handleRequestMetaMaskAccount = async (captcha: any) => {
//     setSubmitting(true);
//     connect(
//       { connector: connectors[0] },
//       {
//         onError: ({ message, code }: any) => {
//           if (code === -32002) {
//             toast.error(
//               t("Already processing. Please wait or confirm on your wallet.")
//             );
//             setSubmitting(false);
//           }
//         },
//         onSettled: async (a, b, c) => {
//           await signMessage(
//             { message: message },
//             {
//               onSuccess: async (signature, variables) => {
//                 const address = await recoverMessageAddress({
//                   message: variables.message,
//                   signature: signature,
//                 });
//                 const params = {
//                   signature: signature,
//                   address: address,
//                   captcha: {
//                     lotNumber: captcha?.lot_number,
//                     captchaOutput: captcha?.captcha_output,
//                     passToken: captcha?.pass_token,
//                     genTime: captcha?.gen_time,
//                     byPass: "skip",
//                   },
//                 };
//                 await handleCheckAccount(
//                   { ...params },
//                   (successStatus: any) => {
//                     handleLoginByWallet({ ...successStatus?.account });
//                   },
//                   (errorStatus: any) => {
//                     if (errorStatus?.error?.code === "ACCOUNT_NOTFOUND") {
//                       connectWeb3Account({
//                         ...params,
//                         successCallback: () => {
//                           onCompleted();
//                         },
//                         errorCallback: (error: any) => {
//                           setSubmitting(false);
//                           if (error.code === "GA_CODE_REQUIRED")
//                             onNextStep({
//                               ...params,
//                               ...error,
//                               loginType: "Wallet",
//                               skipConnectAccount: !Boolean(error.email),
//                             });
//                         },
//                       });
//                     }
//                   }
//                 );
//               },
//               onError: () => {
//                 setSubmitting(false);
//               },
//             }
//           );
//         },
//       }
//     );
//   };

//   const handleLoginByWallet = (signature: any) => {
//     const params = { ...signature };
//     loginWithWallet(
//       params,
//       (result: any) => {
//         setSubmitting(false);
//         onCompleted();
//       },
//       (error: any) => {
//         setSubmitting(false);
//         if (error.code === "GA_CODE_REQUIRED") {
//           console.log("error", error);
//           onNextStep({
//             ...params,
//             ...error,
//             loginType: "Wallet",
//             skipConnectAccount: !Boolean(error.email),
//           });
//         } else toast.error(`${error.msg}`);
//       }
//     );
//   };

//   useEffect(() => {
//     handleGetPhoneOption();
//   }, []);

//   const { getFieldProps, errors, touched, handleReset, values } = formik;

//   const isError =
//     (tabIndex === 0 && (errors.email || values?.email?.length === 0)) ||
//     (tabIndex === 1 && (errors.phone || values?.phone?.length === 0)) ||
//     values?.password?.length === 0 ||
//     errors.password;

//   return (
//     <Box>
//       <FlexRowAlign gap={2} mt={2}>
//         <Button
//           onClick={() => setTabIndex(2)}
//           className={tabIndex === 2 ? "active" : ""}
//           sx={{
//             width: 130,
//             borderRadius: 35,
//             height: 35,
//             "&.active": {
//               background: theme.palette.text.primary,
//               color: theme.palette.background.default,
//             },
//           }}
//         >
//           {t("QR")}
//         </Button>
//         <Button
//           onClick={() => setTabIndex(0)}
//           className={tabIndex === 0 ? "active" : ""}
//           sx={{
//             width: 130,
//             borderRadius: 35,
//             height: 35,
//             "&.active": {
//               background: theme.palette.text.primary,
//               color: theme.palette.background.default,
//             },
//           }}
//         >
//           {t("Email")}
//         </Button>
//         <Button
//           onClick={() => setTabIndex(1)}
//           className={tabIndex === 1 ? "active" : ""}
//           sx={{
//             width: 130,
//             borderRadius: 35,
//             height: 35,
//             "&.active": {
//               background: theme.palette.text.primary,
//               color: theme.palette.background.default,
//             },
//           }}
//         >
//           {t("Phone number")}
//         </Button>
//       </FlexRowAlign>
//       {tabIndex < 2 ? (
//         <FormikProvider value={formik}>
//           <Form>
//             <Stack gap={3} mt={5}>
//               {tabIndex === 0 && (
//                 <>
//                   <StyledInput
//                     fullWidth
//                     placeholder={t("Email")}
//                     {...getFieldProps("email")}
//                     error={Boolean(errors.email) && Boolean(touched.email)}
//                     helperText={Boolean(touched.email) && errors.email}
//                   />
//                 </>
//               )}
//               {tabIndex === 1 && phoneOption.length > 0 && (
//                 <>
//                   <StyledInput
//                     fullWidth
//                     placeholder={t("Phone number")}
//                     {...getFieldProps("phone")}
//                     error={Boolean(errors.phone) && Boolean(touched.phone)}
//                     helperText={Boolean(touched.phone) && errors.phone}
//                     InputProps={{
//                       startAdornment: (
//                         <AppSelect
//                           data={phoneOption}
//                           allowNull={false}
//                           allowSearch
//                           defaultValue={navigator?.language.split("-")?.[1]}
//                           contentSx={{
//                             width: 300,
//                             maxHeight: 350,
//                           }}
//                           onSelect={(value: any) => setPhoneCode(value)}
//                           buttonRender={(value: any) => (
//                             <FlexRowAlign
//                               disableRipple
//                               width="100%"
//                               gap={1}
//                               justifyContent="flex-start"
//                             >
//                               <Typography color={"text.primary"}>
//                                 {value.label}
//                               </Typography>
//                               <Typography
//                                 color={"text.primary"}
//                                 sx={{ whiteSpace: "nowrap" }}
//                               >
//                                 +{value.code}
//                               </Typography>
//                             </FlexRowAlign>
//                           )}
//                           optionRender={(value: any) => (
//                             <FlexRowAlign
//                               width="100%"
//                               gap={1}
//                               justifyContent="flex-start"
//                             >
//                               <Typography color={"text.primary"}>
//                                 {value.label}
//                               </Typography>
//                               <Typography color={"text.primary"}>
//                                 +{value.code}
//                               </Typography>
//                               <Box flexGrow={1} />
//                               <Typography>{value.country}</Typography>
//                             </FlexRowAlign>
//                           )}
//                         />
//                       ),
//                     }}
//                   />
//                 </>
//               )}
//               <StyledInput
//                 fullWidth
//                 placeholder={t("Password")}
//                 type="password"
//                 {...getFieldProps("password")}
//                 error={Boolean(errors.password) && Boolean(touched.password)}
//                 helperText={Boolean(touched.password) && errors.password}
//               />
//               <ConfirmCaptchaV4
//                 disabled={isError || submitting}
//                 type={"submit"}
//                 onClick={(value: any) =>
//                   tabIndex === 0
//                     ? handleLogin(value)
//                     : handleLoginByPhone(value)
//                 }
//               >
//                 <CustomLoadingButton
//                   sx={{ height: 40 }}
//                   variant="contained"
//                   disabled={Boolean(isError)}
//                   className={isError ? "disabled" : ""}
//                   loading={submitting}
//                 >
//                   {t("Sign In")}
//                 </CustomLoadingButton>
//               </ConfirmCaptchaV4>
//               <Divider
//                 sx={{ color: theme.palette.text.secondary, fontWeight: 600 }}
//               >
//                 OR
//               </Divider>
//               <FlexBetween gap={2}>
//                 <ConfirmCaptchaV4
//                   disabled={submitting}
//                   onClick={(value: any) => handleRequestGoogleAccount(value)}
//                   sx={{ width: "100%" }}
//                 >
//                   <CustomLoadingButton
//                     loading={submitting}
//                     sx={{
//                       height: 40,
//                       background: `${theme.palette.divider} !important`,
//                       color: theme.palette.text.primary,
//                     }}
//                   >
//                     <Iconify icon={"flat-color-icons:google"} size={25} />
//                     <Typography ml={2} fontSize={10} fontWeight={900}>
//                       {t("Continue with Google")}
//                     </Typography>
//                   </CustomLoadingButton>
//                 </ConfirmCaptchaV4>
//                 <Hidden mdDown>
//                   <ConfirmCaptchaV4
//                     disabled={submitting}
//                     onClick={(value: any) =>
//                       handleRequestMetaMaskAccount(value)
//                     }
//                     sx={{ width: "100%" }}
//                   >
//                     <CustomLoadingButton
//                       loading={submitting}
//                       sx={{
//                         height: 40,
//                         background: `${theme.palette.divider} !important`,
//                         color: theme.palette.text.primary,
//                       }}
//                     >
//                       <Iconify icon={"logos:metamask-icon"} size={20} />
//                       <Typography ml={2} fontSize={10} fontWeight={900}>
//                         {t("Continue with MetaMask")}
//                       </Typography>
//                     </CustomLoadingButton>
//                   </ConfirmCaptchaV4>
//                 </Hidden>
//               </FlexBetween>
//             </Stack>
//           </Form>
//         </FormikProvider>
//       ) : (
//         <LoginByQr onCompleted={onCompleted} />
//       )}
//     </Box>
//   );
// };

// export default StepOne;

// const StyledInput = styled(AppTextField)(({ theme }) => ({
//   "& .MuiInputBase-root": {
//     background: theme.palette.background.paper,
//     paddingLeft: "1.5rem",
//     borderRadius: "50px",
//     overflow: "hidden",
//     paddingRight: "0px",
//     height: 50,
//     border: "none",
//     fontSize: "0.85rem",
//     "& .MuiOutlinedInput-notchedOutline": {
//       border: "none",
//       outline: "none",
//     },
//   },
// }));
