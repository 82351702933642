
export default function UserBenefitManagement(props: any) {
  // const userStore = useAppSelector((state) => state.user);
  // const { information, F1Information } = userStore;
  // const dispatch = useAppDispatch();

  // React.useEffect(() => {
  //   if (information) {
  //     dispatch(getF1Information());
  //   }
  // }, [dispatch, information]);

  // console.log(F1Information);

  return null;
}
