import { ButtonBase } from "@mui/material";
import { ConfirmCaptchaContext } from "contexts/captcharContext";
import { useContext } from "react";
import { CAPTCHA_ID } from "utils/constants";

const ConfirmCaptchaV4 = ({ onClick, children, disabled, ...props }: any) => {
  const { handlePreSubmit, isSubmitting } = useContext<any>(
    ConfirmCaptchaContext
  );

  const handleClick = (event: any) => {
    event.preventDefault();
    window.initGeetest4(
      {
        captchaId: CAPTCHA_ID,
        product: "bind",
        nativeButton: { width: "260px", height: "10px", background: "red" },
      },
      function (captcha: any) {
        captcha.appendTo("#captcha-container");
        captcha.onReady(() => {
          captcha.showCaptcha();
        });
        captcha.onSuccess(() => {
          const result = captcha.getValidate();
          onClick(result);
          captcha.destroy();
          captcha = null;
          // setOpen(false);
        });
        captcha.onError(() => {
          // setOpen(false);
        });
        captcha.onClose(() => {
          // setOpen(false);
        });
      }
    );
  };

  return (
    <ButtonBase
      {...props}
      sx={{ borderRadius: 0, ...props.sx }}
      onClick={handleClick}
      disabled={disabled || isSubmitting}
    >
      {children}
    </ButtonBase>
  );
};

export default ConfirmCaptchaV4;
