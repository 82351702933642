import { Box, Grid, Stack, Typography } from "@mui/material";
import { IconChevronRight } from "@tabler/icons-react";
import { CustomPaper } from "components/common/CustomComponents";
import CustomModal from "components/common/CustomModal";
import CopyContent from "components/CopyContent";
import { UserEndpointList } from "constants/endpoints";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { AppConfig } from "settings";
import { useAppSelector } from "store/hooks";
import { get } from "utils/api";
import { formatNumberWithDecimal } from "utils/format";
import copyBtn from 'assets/button/Union.png'
import { useTranslation } from "react-i18next";

export default function RefCommission() {
  const [data, setData] = React.useState<{
    rateCommission: number;
    f1RateCommissionHighest: number;
  }>({
    rateCommission: 0,
    f1RateCommissionHighest: 0,
  });
  const userStore = useAppSelector((state) => state.user);
  const { information } = userStore;
  const {t} = useTranslation()
  React.useEffect(() => {
    get(UserEndpointList.Dashboard.RateCommission, (data) => {
      setData(data.data);
    });
  }, []);

  const link = `${AppConfig.WEBSITE_URL}register?ref=${information?.inviteCode}`;

  return (
    <CustomPaper variant="outlined">
      <Box p={2}>
        <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
          <Typography fontWeight={700} variant="h6" sx={{opacity:0.7}}>
            {/* Commission Ratio */}
            {t('common:dashboard.commissionRatio')}
          </Typography>
          <Popup />
        </Stack>
        <Grid container>
          <Grid item xs={12} md={6} lg={4}>
            <Typography sx={{ color: '#00DDB3', fontWeight:500 }}>
              {t('common:dashboard.myCommission')} 
            </Typography>
            <Typography fontWeight={600}>
              {formatNumberWithDecimal(data.rateCommission, 4)}%
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography sx={{ color: '#00DDB3', fontWeight:500 }}>
              {t('common:dashboard.highestDirect')} 
            </Typography>
            <Typography fontWeight={600}>
              {formatNumberWithDecimal(data.f1RateCommissionHighest, 4)}%
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography display='flex' alignItems='center' sx={{ color: '#00DDB3', fontWeight:500 }}>
              {t('common:dashboard.refLink')} 
              <CopyContent
                text={link}
                textSx={{ whitespace: "nowrap" }}
                img={copyBtn}
              />  
            </Typography>
            <Typography fontWeight={600}>{link}</Typography>
          </Grid>
        </Grid>
      </Box>
    </CustomPaper>
  );
}

interface IData {
  accountId: 57864;
  email: "magaming2@splabs.info";
  name: null;
  perRate: 0.05;
  newRate: 0.4;
  status: 1;
  requestById: 57863;
  reson: "123123";
  id: 1;
  isDeleted: false;
  createdAt: "2024-04-29T07:48:28.903121";
  updatedAt: "2024-04-29T08:11:39.791999";
  deletedAt: null;
  createdBy: 57863;
  updatedBy: 1;
}

const Popup = () => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<IData[]>([]);

  React.useEffect(() => {
    if (open)
      get(UserEndpointList.Dashboard.RateCommissionHistory, (data) =>
        setData(data.data)
      );
  }, [open]);
  const {t} = useTranslation()
  return (
    <>
      <Grid onClick={() => setOpen(true)} sx={{border:'1px solid #00DDB3', borderRadius:'15px', minWidth:'90px', cursor:'pointer'}} display='flex' alignItems='center' justifyContent='center' padding='5px 5px'>
        <Stack direction={"row"} alignItems={"center"} >
          <Typography marginBottom='4px' paddingLeft='4px' color='#00DDB3' fontWeight={500}>{t('common:dashboard.history')}</Typography>
          <IconChevronRight size={16} color="#00DDB3"/>
        </Stack>
      </Grid>
      <CustomModal
        open={open}
        handleClose={() => setOpen(false)}
        hasCloseButton={true}
        title={t('common:modal.commissionRatioHis')}
      >
        <Box sx={{ borderBottom: 1, borderColor: "#F4FCFA" }}>
          <Stack direction={"row"} justifyContent={"space-between"} p={2}>
            <Typography>{t('common:modal.time')} </Typography>
            <Typography >{t('common:modal.before')}</Typography>
            <Typography>{t('common:modal.after')}</Typography>
          </Stack>
        </Box>
        {data.map((item) => (
          <Box key={item.id} sx={{ borderBottom: 1, borderColor: "#F4FCFA", background:'#F4FCFA', '&:hover':{background:'#93ECD9'} }}>
            <Stack direction={"row"} justifyContent={"space-between"} p={2}>
              <Typography>
                {moment(item.createdAt).format("YYYY-MM-DD")}
              </Typography>
              <Typography sx={{marginLeft:'-40px'}}>
                {formatNumberWithDecimal(item.perRate, 2)}%
              </Typography>
              <Typography>
                {formatNumberWithDecimal(item.newRate, 2)}%
              </Typography>
            </Stack>
          </Box>
        ))}
      </CustomModal>
    </>
  );
};
