export const jp={
    whitepaper:`ホワイトペーパー`,
    litepaper:`ライトペーパー`,
    go_to_app:`アプリに移動する`,
    move_to_earn:`MOVE TO EARN`,
    subtitle_intro:`Sprintは、NFTと一緒に運動してお金を稼ぐことができるWeb3のウォーキングフィットネス報酬アプリケーションです。`,
    active_users:`アクティブユーザー`,
    runners:`ランナー`,
    process_infomation:`処理情報`,
    total:`合計`,
    burned:`燃えた`,
    watch_nft:`NFTを見る`,
    box_nft:`NFT ボックス`,
    about_us:`私たちについて`,
    sprint_and_more:`SPRINTとその他`,
    subtitle_about:`Sprintは、健康とフィットネスの向上における技術革命です。NFT報酬とSWPトークンは、毎日有効なユーザーファイルに使用されます。`,
    about_title_1:`使いやすい`,
    about_subtitle_1:`アプリを使うにはNFTやクリプトを理解する必要はありません。アプリをダウンロードして運動に参加したり、シンプルなNFTを購入したりして始めることができます。`,
    about_title_2:`習慣をつくる`,
    about_subtitle_2:`Sprintのリアルな報酬とソーシャルメディアプラットフォームは、活動的で健康的な運動習慣を作りやすくします。`,
    about_title_3:`稼ぎやすい`,
    about_subtitle_3:`NFTを所有して歩いたり走ったり運動したりすれば、Sprintから報酬を得ることができます。`,
    collection:`コレクション`,
    box_info:`ボックス情報`,
    token:`トークン`,
    token_economy:`トークンエコノミー`,
    subtitle_token:`Sprintは、報酬システムとプロジェクト財務システムの間の衝突を避けるために、2種類の別々のトークンを発行します。各種類のトークンには異なるタスクと機能があります。`,
    describe_watch:`腕時計 NFT`,
    user_manual:`アプリケーションの使用方法`,
    the_app:`アプリ`,
    step_1:`ステップ1`,
    step_1_label:`Sprintアプリをダウンロードします。`,
    step_2:`ステップ2`,
    step_2_label:`アプリのスタート画面でアカウントを登録します。`,
    step_3:`ステップ3`,
    step_3_label:`ウォレットを接続するか、このステップをスキップします。`,
    step_4:`ステップ4`,
    step_4_label:`NFT Watchのペアを購入するか、無料バージョンのNFTを使用します。`,
    step_5:`ステップ5`,
    step_5_label:`アプリを体験して運動を始めます。`,
    step_6:`ステップ6`,
    step_6_label:`友達を招待して一緒に運動しましょう。`,
    meet:`会う`,
    our_partner:`パートナー`,
    about_sprint:`SPRINT について`,
    subscribe_subtitle:`ニュースレターに登録して、ローンチ、イベント、アップデートなどの最新情報を入手しよう…`,
    email_address:`メールアドレス`,
    subscribe:`登録する`,
    ecosystem:`エコシステム`,
    sprint_app:`Sprintアプリ`,
    tokenomics:`トークンエコノミクス`,
    nft_marketplace:`NFTマーケットプレイス`,
    document:`ドキュメント`,
    terms_of_use:`利用規約`,
    privacy_policy:`プライバシーポリシー`,
    contact_us:`お 問い合わせ`,
    join_us:`私たちに参加する`,
}