import { LoadingButton } from "@mui/lab";
import { Button, Paper, Tab, TextField, styled } from "@mui/material";

export const CustomPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(3),
  border: `1px solid white`,
  minWidth: "max-content"
}));

export const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "unset",
  color: "#A3A3A3",
  fontSize: "1rem",
  '&.Mui-selected': {     
    color: "#00DDB3",     
    fontWeight: "bold", 
    background:'#E5FCF7',
  },
  borderTopLeftRadius:'20px',
  borderTopRightRadius:'20px'
}));

export const SpecialTab = styled(Tab)(({ theme }) => ({
  // border: "1px solid #d9d9d9",
  boxSizing: "border-box",
  textTransform: "none",
  padding: "8px 16px",
  // minHeight: "unset",
  fontWeight: 700,
  color:'gray',
  fontSize:'16px',
  height:'100%',
  "&.Mui-selected": {
    color:'#00DDB3',
    background: '#E5FCF7',
    borderTopRightRadius:'20px',
    borderTopLeftRadius:'20px !important',
    '&:first-of-type.Mui-selected': {
      borderBottomLeftRadius: '0px !important',
    },
    '&:last-of-type.Mui-selected': {
      borderBottomRightRadius: '0px !important', 
    },
  },
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: theme.spacing(1.5),
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: '15px',
  borderColor: '#00DDB3',
  color:'#00DDB3',
  '&:hover':{
    borderColor: '#00DDB3',
  },
  textTransform: 'none',
  display:'flex',
}));

export const CustomLoadingButton = styled(LoadingButton)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  background:'#00DDB3',
  '&:hover':{
    background:'#00DDB3'
  }
}));
